import Icon from 'components/Icon';
import React from 'react';
import clx from 'classnames';

type AppProps = {
  checked?: boolean;
  size?: number;
};

const Checkbox: React.FC<AppProps> = ({ checked = false, size = 20 }) => {
  return (
    <div>
      <Icon
        name="check-circle"
        size={size}
        className={clx({
          'text-success': checked,
          'text-gray-400': !checked,
        })}
      />
    </div>
  );
};

export default Checkbox;

import React, { Suspense, lazy } from 'react';
import useMedia from 'hooks/useMedia';
import Loader, { Variant as LoaderVariant } from 'components/Loader';

const OrganisationMobile = lazy(() => import('./Organisation.mobile'));
const OrganisationDesktop = lazy(() => import('./Organisation.desktop'));

const Organisation = () => {
  const { isMobile } = useMedia();
  return (
    <Suspense
      fallback={
        <Loader variant={LoaderVariant.RoofLoader} label="Loading..." />
      }
    >
      {isMobile ? <OrganisationMobile /> : <OrganisationDesktop />}
    </Suspense>
  );
};

export default Organisation;

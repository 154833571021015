import React from 'react';
import Form from './Form';
import BottomSheet from 'components/BottomSheet';
import Icon from 'components/Icon';

type AppProps = {
  open: boolean;
  closeModal: () => any;
  companyId?: string;
  claimId?: string;
  initial?: Record<string, any>;
  onSuccess?: (...args: any) => any;
};

const CreateMortgageCompany: React.FC<AppProps> = ({
  open,
  closeModal,
  companyId = '',
  claimId,
  initial = {},
  onSuccess = () => null,
}) => {
  return (
    <BottomSheet open={open}>
      <div className="pb-4">
        <div className="flex justify-between">
          <div className="page-heading">
            {companyId ? 'Update Mortgage Company' : 'Create Mortgage Company'}
          </div>
          <div>
            <Icon
              name="close"
              size={32}
              onClick={closeModal}
              className="cursor-pointer"
            />
          </div>
        </div>
      </div>
      <Form
        onSuccess={(res) => {
          onSuccess?.(res);
          closeModal();
        }}
        companyId={companyId}
        claimId={claimId}
        initial={initial}
      />
    </BottomSheet>
  );
};

export default CreateMortgageCompany;

import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Button from 'components/Button';
import { Layout } from 'components/Form';
import { importRoofTemplatesForManufacturers } from 'queries/company';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { FieldType } from 'utils/enum';
import { actionLabel, readAxiosErr, snakeToTitle } from 'utils/misc';
import * as yup from 'yup';

interface IFormValues {
  manufacturers: string[];
}

const OPTIONS = [
  'Owens Corning',
  'GAF',
  'Atlas',
  'Certainteed',
  'Malarkey',
  'IKO',
];

const ImportManufacturers = () => {
  const queryClient = useQueryClient();

  const createMutation = useMutation(
    (formData: any) => importRoofTemplatesForManufacturers(formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['rl-light-template-choices']);
      },
    }
  );

  const schema = yup.object({
    manufacturers: yup.array().of(yup.string()),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormValues> = async (formData) => {
    createMutation.mutate(formData);
  };

  const fields = [
    {
      name: 'manufacturers',
      type: FieldType.Multiselect,
      label: 'Select Manufacturers',
      placeholder: 'Select Manufacturers',
      isCreatable: true,
      // @ts-ignore
      error: errors.manufacturers?.message,
      control,
      options: OPTIONS.map((o) => ({
        label: o,
        value: o,
      })),
    },
  ];

  return (
    <div>
      <div className="py-3 mb-2">
        It looks like you don’t have any roof templates. Please select the
        manufacturers that you would like to import templates for.
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Layout fields={fields} loading={createMutation.isLoading} />

        <Button
          label="Import Templates"
          className="mt-8"
          loading={createMutation.isLoading}
        />
      </form>
    </div>
  );
};

export default ImportManufacturers;

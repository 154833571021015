import Icon from 'components/Icon';
import { truncate } from 'lodash';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { formatAmt } from 'utils/misc';
import clx from 'classnames';
import Button, { Size } from 'components/Button';
import useMedia from 'hooks/useMedia';
import useModal from 'hooks/useModal';
import FileClaim from 'pages/JobDetail/components/Claims/components/FileClaim/ModalForm';

type AppProps = {
  job: Record<string, any>;
  className?: string;
};

const ClaimBox: React.FC<AppProps> = ({ job, className = '' }) => {
  const navigate = useNavigate();
  const { isMobile } = useMedia();
  const [create, showCreate, closeCreate] = useModal();

  const claim = [
    {
      id: 'number',
      label: 'Claim #',
      value: truncate(job.claim?.claim_number, { length: 16 }),
      class: 'font-bold',
    },
    {
      id: 'deductible',
      label: 'Deductible',
      value: formatAmt(job.claim?.deductible),
    },
    {
      id: 'status',
      label: 'Status',
      value: job.claim?.claim_status,
    },
  ];

  return (
    <>
      <div
        className={clx(
          'pl-3 pt-2 bg-white shadow-md rounded-lg relative',
          className
        )}
      >
        <div className="flex pt-1">
          <Icon
            name="house-damage"
            size={18}
            className="text-primary relative top-[2px]"
          />
          <span className="pl-1 title">Claim</span>
        </div>
        {job.claim ? (
          <div className="mt-1.5">
            {claim.map((cl) => (
              <div key={cl.id} className="text-xs v-center space-y-[2px]">
                <div className="w-[78px] text-text-medium">{cl.label}:</div>{' '}
                <div className={cl.class}>{cl.value}</div>
              </div>
            ))}
            {!job.claim?.has_claim_handler && (
              <Link to={`/jobs/${job.id}/claims/${job.claim.id}/claim_handler`}>
                <div className="flex mt-2 text-primary">
                  <Icon name="plus" size={16} className="mr-[2px]" />
                  <div className="text-xs">Add Claim Handler</div>
                </div>
              </Link>
            )}
            <Link to={`/jobs/${job.id}/claims/${job.claim.id}`}>
              <div
                className={clx('v-center text-xs text-primary', {
                  'mt-8': job.claim?.has_claim_handler,
                  'mt-2': !job.claim?.has_claim_handler,
                })}
              >
                View claim detail{' '}
                <Icon name="arrow-right" className="ml-1" size={16} />
              </div>
            </Link>
          </div>
        ) : (
          <div>
            <div className="w-40">
              <div className="text-sm mb-2 text-text-light">
                No Claim Filed{' '}
              </div>
              <Button
                label="File Claim"
                size={Size.Medium}
                leftIcon="plus"
                className="mt-8 mb-2 relative right-1"
                onClick={() =>
                  isMobile
                    ? navigate(`/jobs/${job.id}/claims/create`)
                    : showCreate()
                }
              />
            </div>
          </div>
        )}
      </div>
      {create && <FileClaim open={create} closeModal={closeCreate} />}
    </>
  );
};

export default ClaimBox;

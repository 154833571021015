import React from 'react';
import clx from 'classnames';

export enum Variant {
  Normal = 'normal',
  Small = 'small',
}

type AppProps = {
  name: string;
  className?: string;
  variant?: Variant;
};

const Tag: React.FC<AppProps> = ({
  name,
  className,
  variant = Variant.Normal,
}) => {
  const tagStyle = clx(
    `rounded-md bg-primary-negative text-text mr-1 mb-1 ${className}`,
    {
      'px-2.5 py-1.5 text-xs': variant === Variant.Normal,
      'px-2 py-[3px] text-[10px]': variant === Variant.Small,
    }
  );

  return <div className={tagStyle}>{name}</div>;
};

export default Tag;

import BottomSheet from 'components/BottomSheet';
import Button, { Size, Variants } from 'components/Button';
import Icon from 'components/Icon';
import React from 'react';

type AppProps = {
  open: boolean;
  closeModal: () => any;
  title: string;
  description: string;
};

const InfoModal: React.FC<AppProps> = ({
  open,
  closeModal,
  title,
  description,
}) => {
  return (
    <BottomSheet open={open} closeSheet={closeModal}>
      <div className="pb-8">
        <div className="flex justify-end">
          <Icon name="close" size={24} onClick={closeModal} />
        </div>
        <div className="text-lg font-bold -mt-1">{title}</div>
        <div className="mt-2 text-text-medium">{description}</div>
        <div className="mt-4">
          <Button
            label="close"
            variant={Variants.PrimaryOutline}
            size={Size.Medium}
            onClick={closeModal}
          />
        </div>
      </div>
    </BottomSheet>
  );
};

export default InfoModal;

import React, { lazy, Suspense } from 'react';
import useMedia from 'hooks/useMedia';
import Loader, { Variant as LoaderVariant } from 'components/Loader';
import { useMatch } from 'react-router-dom';

const AppShellMobile = lazy(() => import('./AppShell.mobile'));
const AppShellDesktop = lazy(() => import('./AppShell.desktop'));

const AppShell: React.FC<any> = (props) => {
  const { isMobile } = useMedia();
  const isSign = useMatch('/sign/:signId');

  if (isSign) {
    return <div className="h-screen">{props?.children}</div>;
  }

  return (
    <Suspense
      fallback={
        <Loader
          variant={LoaderVariant.RoofLoader}
          label="Setting up appshell..."
        />
      }
    >
      {isMobile ? (
        <AppShellMobile {...props} />
      ) : (
        <AppShellDesktop {...props} />
      )}
    </Suspense>
  );
};

export default AppShell;

import Icon from 'components/Icon';
import React from 'react';
import clx from 'classnames';
import { useController } from 'react-hook-form';
import { getFileIcon } from 'utils/misc';
import { truncate } from 'lodash';

type AppProps = {
  label: string;
  name: string;
  className?: string;
  boxClass?: string;
  error?: string;
  required?: boolean;
  reset?: () => any;
  control: any;
  multi?: boolean;
};

const FileUpload: React.FC<AppProps> = ({
  name,
  label,
  control,
  className = '',
  boxClass = '',
  error = null,
  required = false,
  multi = true,
}) => {
  const { field } = useController({ name, control });
  const handleOnChange = (acceptedFiles: any[]) => {
    field.onChange(acceptedFiles);
  };

  return (
    <div className={className}>
      <div className="text-text-medium text-xs mb-1">
        {label}
        {required && <span className="text-error">*</span>}
      </div>

      {!!field.value?.length && (
        <div>
          {field.value.map((f: any, idx: number) => (
            <div
              key={idx}
              className="border rounded-sm px-2 py-1 mb-3 apart items-center bg-primary-negative"
            >
              <div className="v-center space-x-2">
                <div>
                  <Icon
                    name={getFileIcon(f.name)}
                    size={16}
                    className="text-text-medium"
                  />
                </div>
                <div>{truncate(f.name, { length: 40 })}</div>
              </div>
              <div
                onClick={() => {
                  handleOnChange(
                    field.value.filter((v: any, i: number) => i !== idx)
                  );
                }}
              >
                <Icon name="delete" className="text-error" size={18} />
              </div>
            </div>
          ))}
        </div>
      )}
      <label className="cursor-pointer">
        <input
          type="file"
          name={name}
          accept="image/*,.pdf,.doc"
          hidden
          multiple={multi}
          onChange={(e) => {
            // @ts-ignore
            handleOnChange(Object.values(e.target.files));
            // onChange(e);
            // if (e.target.files?.[0]) {
            //   setPreview(URL.createObjectURL(e.target.files[0]));
            // }
          }}
        />
        {field.value?.length ? (
          multi ? (
            <div className="v-center space-x-1 text-primary border rounded-sm px-2 py-1">
              <Icon name="upload" size={20} />
              <span>Add more files</span>
            </div>
          ) : null
        ) : (
          <div
            className={clx(
              'rounded-md border border-border center p-2 relative',
              boxClass
            )}
          >
            <div className="flex flex-col items-center">
              <Icon name="upload" size={32} className="text-text-lighter" />
              <div className="text-sm">Upload</div>
            </div>
          </div>
        )}
      </label>
      {error && <div className="text-error text-xs">*{error}</div>}
    </div>
  );
};

FileUpload.displayName = 'FileUpload';

export default FileUpload;

import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FieldType } from 'utils/enum';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createInspection } from 'queries/inspections';
import toast from 'react-hot-toast';
import { actionLabel, readAxiosErr } from 'utils/misc';
import { usePreInspectionTemplateChoices } from 'queries/company';
import { Layout } from 'components/Form';
import Button from 'components/Button';
import Icon from 'components/Icon';
import useMedia from 'hooks/useMedia';

interface IFormValues {
  template: number;
}

type AppProps = {
  closeModal: () => void;
};

const CreateInspection: React.FC<AppProps> = ({ closeModal }) => {
  const { jobId = '' } = useParams();
  const queryClient = useQueryClient();
  const { isMobile } = useMedia();
  const navigate = useNavigate();

  const addInspection = useMutation(
    (formData: any) => createInspection(jobId, formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: (res: any) => {
        queryClient.invalidateQueries(['inspections', { job: jobId }]);
        queryClient.invalidateQueries(['inspection-choices', jobId]);
        queryClient.invalidateQueries(['job-detail', jobId]);
        queryClient.invalidateQueries(['job-checklist', jobId]);
        queryClient.setQueryData(['job-stats', jobId], (old: any = {}) => ({
          ...old,
          inspections: old?.inspections + 1,
        }));
        closeModal();
        return navigate(`/jobs/${jobId}/inspections/${res.id}`);
      },
    }
  );

  const schema = yup.object({
    template: yup.number(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>({ resolver: yupResolver(schema) });

  const onSubmit: SubmitHandler<IFormValues> = async (formData) => {
    addInspection.mutate({ ...formData, job: jobId });
  };

  const fields = [
    {
      name: 'template',
      type: FieldType.AsyncSelect,
      label: 'Select Template',
      placeholder: 'Select template',
      error: errors.template?.message,
      control,
      optionsPromise: usePreInspectionTemplateChoices,
      orderByLabel: false,
      position: isMobile ? 'top' : 'bottom',
    },
  ];

  return (
    <div className="pb-4">
      <div className="flex justify-between">
        <div className="text-2xl font-bold mb-6">Create Inspection</div>
        {!addInspection.isLoading && (
          <div>
            <Icon
              name="close"
              size={32}
              onClick={closeModal}
              className="cursor-pointer"
            />
          </div>
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Layout fields={fields} loading={addInspection.isLoading} />

        <Button
          label={actionLabel(null, 'Inspection', addInspection.isLoading)}
          className="mt-8"
          loading={addInspection.isLoading}
        />
      </form>
    </div>
  );
};

export default CreateInspection;

import BottomSheet from 'components/BottomSheet';
import Button, { Size, Variants } from 'components/Button';
import Icon from 'components/Icon';
import ImportDoctemplate from 'components/ImportDoctemplate';
import useModal from 'hooks/useModal';
import { isEmpty } from 'lodash';
import {
  enableSignautre,
  impersonateAndSign,
  voidSignatures,
} from 'queries/documents';
import React from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { IMPERSONATE_SESSION_KEY } from 'utils/constants';
import { setItem } from 'utils/localstorage';
import { readAxiosErr } from 'utils/misc';
import { formatServerTime } from 'utils/time';
import Checkbox from './Checkbox';
import Documents from './Documents';
import { uploadContract } from 'queries/job';
import usePageLoader from 'hooks/usePageLoader';
import clx from 'classnames';

type AppProps = {
  data: Record<string, any>;
  renderInTable?: boolean;
};

const SignContract: React.FC<AppProps> = ({ data, renderInTable = false }) => {
  const { jobId = '' } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [createContract, showCreateContract, closeCreateContract] = useModal();
  const [contract, showContract, closeContract] = useModal();
  const { startLoader, stopLoader } = usePageLoader();
  const [actionOpen, showActions, closeActions] = useModal();

  const uploadMutation = useMutation(
    (formData: any) => uploadContract(jobId, formData),
    {
      onMutate: () => {
        startLoader();
      },
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
        stopLoader();
      },
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(['job-last-checklist', jobId]),
          queryClient.invalidateQueries(['job-checklist', jobId]),
        ]);
        queryClient.invalidateQueries(['job-detail', jobId]);
        queryClient.invalidateQueries(['jobs']);
        stopLoader();
        closeActions();
      },
    }
  );

  const impersonateMutation = useMutation(
    (signerId: number) => impersonateAndSign(signerId),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: async (res: any) => {
        await Promise.all([
          queryClient.invalidateQueries(['job-last-checklist', jobId]),
          queryClient.invalidateQueries(['job-checklist', jobId]),
        ]);
        queryClient.invalidateQueries(['job-detail', jobId]);
        queryClient.invalidateQueries(['jobs']);
        setItem(IMPERSONATE_SESSION_KEY, res.token);
        window.location.href = res.redirect_url;
      },
    }
  );

  const enableSignatureMutation = useMutation(
    (id: number) => enableSignautre(id),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(['job-last-checklist', jobId]),
          queryClient.invalidateQueries(['job-checklist', jobId]),
        ]);
        queryClient.invalidateQueries(['job-detail', jobId]);
        queryClient.invalidateQueries(['jobs']);
      },
    }
  );

  const voidSignatureMutation = useMutation(
    (id: number) => voidSignatures(id),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(['job-last-checklist', jobId]),
          queryClient.invalidateQueries(['job-checklist', jobId]),
        ]);
        queryClient.invalidateQueries(['job-detail', jobId]);
        queryClient.invalidateQueries(['jobs']);
      },
    }
  );

  const handleFileUpload = (file: any) => {
    const formData = new FormData();
    formData.append('contract', file);
    uploadMutation.mutate(formData);
  };

  const uploadHardCopy = () => {
    return (
      <div>
        <label>
          <input
            disabled={
              enableSignatureMutation.isLoading ||
              voidSignatureMutation.isLoading
            }
            type="file"
            accept="image/*,video/*,.pdf,.doc,.xlsx"
            hidden
            onChange={(e) => handleFileUpload(e.target.files?.[0])}
          />
          <div className="bg-primary-negative px-2 py-1.5 text-[15px] text-center rounded-lg text-primary">
            Upload hard copy
          </div>
        </label>
      </div>
    );
  };

  const noContract = () => {
    return (
      <div className="apart">
        <div className="value">Contract Not Created</div>
        <div>
          <Button
            label="Create"
            size={Size.Small}
            variant={Variants.Tag}
            onClick={showActions}
          />
        </div>
        <BottomSheet open={actionOpen}>
          <div className="pb-4">
            <div className="flex justify-between">
              <div className="page-heading">Actions</div>
              {!uploadMutation.isLoading && (
                <div>
                  <Icon name="close" size={32} onClick={closeActions} />
                </div>
              )}
            </div>
            <div className="mt-4 space-y-4">
              {uploadHardCopy()}
              <Button
                label="Create From Template"
                size={Size.Big}
                variant={Variants.Tag}
                onClick={showCreateContract}
              />
            </div>
          </div>
        </BottomSheet>
      </div>
    );
  };

  const handleSign = (signId: string, entity: Record<string, any>) => {
    if (entity.sign_method === 'sign') {
      return navigate(`/sign/${signId}`);
    }
    if (entity.sign_method === 'impersonate_and_sign') {
      return impersonateMutation.mutate(entity.signer_id);
    }
  };

  const renderSign = (signId: string, entity: Record<string, any>) => {
    if (!entity?.can_sign_now) {
      return (
        <div className="text-sm v-center">
          <Icon name="alert" size={16} className="text-gray-600 mr-1" />
          <b>{entity?.signed_by.name}</b> &nbsp; can not sign yet
        </div>
      );
    }

    if (entity?.date_signed) {
      return (
        <div className="text-xs mb-4">
          <div className="inline-block relative top-1">
            <Icon name="check-circle" size={16} className="text-success mr-1" />
          </div>
          <b>{entity.signed_by.name}</b> completed on{' '}
          <b>
            {formatServerTime({
              date: entity.date_signed,
              includeTime: true,
              parseFormat: 'YYYY-MM-DDThh:mm:ssZ',
            })}
          </b>
        </div>
      );
    }
    return (
      <Button
        label={`${entity?.signed_by.name} sign now`}
        variant={Variants.Tag}
        size={Size.Medium}
        onClick={() => handleSign(signId, entity)}
        disabled={voidSignatureMutation.isLoading}
      />
    );
  };

  const contractNotComplete = () => {
    const showClose = () => {
      if (uploadMutation.isLoading) {
        return false;
      }
      if (enableSignatureMutation.isLoading) {
        return false;
      }
      if (voidSignatureMutation.isLoading) {
        return false;
      }
      return true;
    };

    if (!isEmpty(data.sign_meta)) {
      return (
        <>
          <div className="apart">
            <div>
              <div className="title">Contract Not Signed</div>
              <div className="text-xs text-primary">Waiting on signatures</div>
            </div>
            <div>
              <Button
                label="Actions"
                size={Size.Small}
                variant={Variants.Tag}
                onClick={showActions}
              />
            </div>
          </div>
          <BottomSheet open={actionOpen}>
            <div className="pb-4">
              <div className="flex justify-between">
                <div className="page-heading">Actions</div>
                {showClose() && (
                  <div>
                    <Icon name="close" size={32} onClick={closeActions} />
                  </div>
                )}
              </div>
              <div className="mt-4 space-y-4">
                <div className="space-y-2 mt-2">
                  <div className="space-y-2 mb-4">
                    {renderSign(
                      data.sign_meta.sign_id,
                      data.sign_meta.customer
                    )}
                    {renderSign(data.sign_meta.sign_id, data.sign_meta.rep)}
                  </div>
                  <div className="flex space-x-2">
                    <Button
                      label="View Contract"
                      size={Size.Big}
                      variant={Variants.Primary}
                      onClick={showContract}
                      disabled={!showClose()}
                    />
                    <Button
                      label="Void Signatures"
                      size={Size.Big}
                      variant={Variants.ErrorNegative}
                      onClick={() =>
                        voidSignatureMutation.mutate(data.sign_meta.sign_id)
                      }
                      loading={voidSignatureMutation.isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </BottomSheet>
        </>
      );
    }

    return (
      <>
        <div className="apart">
          <div className="value">Contract Not Signed</div>
          <div>
            <Button
              label="Actions"
              size={Size.Small}
              variant={Variants.Tag}
              onClick={showActions}
            />
          </div>
        </div>
        <BottomSheet open={actionOpen}>
          <div className="pb-4">
            <div className="flex justify-between">
              <div className="page-heading">Actions</div>
              {showClose() && (
                <div>
                  <Icon name="close" size={32} onClick={closeActions} />
                </div>
              )}
            </div>
            <div className="mt-4 space-y-4">
              <Button
                label="View Contract"
                size={Size.Big}
                variant={Variants.Primary}
                onClick={showContract}
                disabled={!showClose()}
              />
              <Button
                label="Enable Signatures"
                size={Size.Big}
                variant={Variants.Tag}
                onClick={() => enableSignatureMutation.mutate(data.document)}
                loading={enableSignatureMutation.isLoading}
              />
              {uploadHardCopy()}
            </div>
          </div>
        </BottomSheet>
      </>
    );
  };

  const complete = () => {
    return (
      <div>
        <div className="apart">
          <div className="value">Contract Signed</div>
          <div>
            <Button
              label="View"
              size={Size.Small}
              variant={Variants.Tag}
              onClick={showContract}
            />
          </div>
        </div>
        <div className="-mt-1">
          <span className="key">Completed at: </span>
          <span className="key">
            {formatServerTime({
              date: data.date,
              includeTime: true,
              parseFormat: 'YYYY-MM-DDThh:mm:ssZ',
            })}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={clx({ card: !renderInTable, 'px-2 pb-1': renderInTable })}>
      <div className="flex space-x-2">
        <Checkbox checked={data.complete} />
        <div className="w-full">
          {' '}
          {(() => {
            if (!data.complete && !data.document) {
              return noContract();
            }
            if (!data.complete && data.document) {
              return contractNotComplete();
            }
            return complete();
          })()}
        </div>
      </div>
      <BottomSheet open={createContract} closeSheet={closeCreateContract}>
        <ImportDoctemplate
          q={{ type: 'contract' }}
          closeModal={closeCreateContract}
        />
      </BottomSheet>
      <BottomSheet open={contract} closeSheet={closeContract}>
        <Documents
          label="Contracts"
          q={{ is_contract: true }}
          closeModal={closeContract}
        />
      </BottomSheet>
    </div>
  );
};

export default SignContract;

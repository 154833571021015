import { useContext } from 'react';

import { PageLoaderContext } from 'context/PageLoader';

const usePageLoader = () => {
  const pl = useContext(PageLoaderContext);
  return pl;
};

export default usePageLoader;

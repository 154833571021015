import React from 'react';
import { Menu as RMenu, Transition } from '@headlessui/react';
import clx from 'classnames';
import useMedia from 'hooks/useMedia';
import Icon, { Loader } from 'components/Icon';
import BottomSheet from 'components/BottomSheet';
import useModal from 'hooks/useModal';
import Tooltip from 'components/Tooltip';

export interface IOption {
  key: string;
  icon?: string;
  iconSize?: number;
  iconClassName?: string;
  label: React.ReactNode | string;
  isDestroyAction?: boolean;
  element?: (styles: string, active?: boolean) => React.ReactNode;
  onClick?: () => void;
  loading?: boolean;
  selected?: boolean;
}

type AppProps = {
  trigger: React.ReactNode;
  options: IOption[];
  wider?: boolean;
  optionClass?: string;
  renderAsActions?: boolean;
  flattenOptions?: string[];
  renderAsMenuOnly?: boolean;
};

const Menu: React.FC<AppProps> = ({
  trigger,
  options,
  wider = false,
  optionClass = '',
  renderAsActions = false,
  flattenOptions = [],
  renderAsMenuOnly = false,
}) => {
  const { isMobile } = useMedia();
  const [show, openShow, closeShow] = useModal();

  if (!options.length) {
    return null;
  }

  if (!renderAsMenuOnly && isMobile) {
    return (
      <>
        <div
          className="w-fit"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            openShow();
          }}
        >
          {trigger}
        </div>
        <BottomSheet open={show} closeSheet={closeShow} padded={false}>
          <div className="pb-8">
            <div className="apart pt-4 pb-2.5 px-4">
              <div className="text-lg font-bold">Options</div>
              <Icon
                name="close"
                size={24}
                className="relative top-1"
                onClick={closeShow}
              />
            </div>
            <div className="mt-1 text-text">
              {options?.map((option, idx) => {
                if (option.element) {
                  return (
                    <div
                      className={clx('apart px-4 py-2', {
                        'border-b': idx < options.length - 1,
                      })}
                    >
                      {option.element('')}
                    </div>
                  );
                }
                return (
                  <div
                    key={option.key}
                    onClick={() => {
                      option.onClick?.();
                      closeShow();
                    }}
                    className={clx('apart px-4 py-2', {
                      'border-b': idx < options.length - 1,
                    })}
                  >
                    <div className="v-center space-x-1">
                      {option?.icon && (
                        <Icon
                          name={option?.icon}
                          className="text-text-medium"
                          size={20}
                        />
                      )}
                      <span className={clx({ 'font-bold': option.selected })}>
                        {option.label}
                      </span>
                    </div>
                    {option.loading && <Loader />}
                    {option.selected && (
                      <Icon name="tick" size={16} className="text-primary" />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </BottomSheet>
      </>
    );
  }

  if (renderAsActions) {
    return (
      <div className="v-center space-x-2">
        {options?.map((option) => (
          <div
            key={option.key}
            onClick={option.onClick}
            className="cursor-pointer"
          >
            <Tooltip
              tooltipContent={option.label}
              width="120px"
              position="top-4 right-2"
            >
              <Icon
                name={option.icon || ''}
                className={option.iconClassName || ''}
                size={option.iconSize || 18}
              />
            </Tooltip>
          </div>
        ))}
      </div>
    );
  }

  const _options = flattenOptions?.length
    ? options?.filter((option) => !flattenOptions?.includes(option.key))
    : options;

  const optionStyle = (active: boolean, idx: number) =>
    clx('cursor-pointer pl-3 py-1.5 v-center space-x-1', {
      'bg-primary-negative font-bold': active,
      'bg-white text-text': !active,
      'border-b border-border-light': idx !== _options.length - 1,
      'text-sm': !isMobile,
      'text-text': isMobile,
    });

  return (
    <div className="v-center space-x-1.5">
      {!!flattenOptions?.length &&
        options
          .filter((option) => flattenOptions?.includes(option.key))
          ?.map((option) => (
            <div key={option.key}>
              <Icon
                name={option.icon || ''}
                className={option.iconClassName || 'text-text-light'}
                size={option.iconSize || 18}
                onClick={option.onClick}
              />
            </div>
          ))}
      {!!_options.length && (
        <RMenu as="div" className="relative">
          <RMenu.Button>{trigger}</RMenu.Button>
          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <RMenu.Items
              className={clx(
                `z-50 origin-top-right right-0 absolute -mt-1 mr-2 rounded-lg shadow-xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${optionClass}`,
                {
                  'min-w-[120px]': !wider,
                  'min-w-max pr-2': wider,
                  'right-0': isMobile,
                }
              )}
            >
              <div className="py-1">
                {_options.map((option, idx) => (
                  <RMenu.Item key={option.key}>
                    {({ active }: any) => {
                      if (option.element) {
                        return (
                          <div>
                            {option.element(optionStyle(active, idx), active)}
                          </div>
                        );
                      }
                      return (
                        <div
                          className={optionStyle(active, idx)}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (option.onClick) {
                              option?.onClick();
                            }
                          }}
                        >
                          <span className="text-[13px] text-text">
                            {option.label}
                          </span>
                          {option.loading && <Loader />}
                        </div>
                      );
                    }}
                  </RMenu.Item>
                ))}
              </div>
            </RMenu.Items>
          </Transition>
        </RMenu>
      )}
    </div>
  );
};

export default Menu;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import Icon from 'components/Icon';
import { starJob, unstarJob } from 'queries/job';
import React from 'react';
import clx from 'classnames';

export enum Variant {
  Fill = 'fill',
  Outline = 'outline',
}

type AppProps = {
  job: Record<string, any>;
  variant?: Variant;
};

const Star: React.FC<AppProps> = ({ job, variant = Variant.Fill }) => {
  const jobId = job.id?.toString();
  const queryClient = useQueryClient();

  const starJobMutation = useMutation((id: string) => starJob(id), {
    onSuccess: async () => {
      queryClient.invalidateQueries(['jobs', 'starred']);
      const prevJob = queryClient.getQueryData(['job-detail', jobId]) || {};
      // @ts-ignore
      const newJob = { ...prevJob, starred: true };
      queryClient.setQueryData(['job-detail', jobId], newJob);
      return newJob;
    },
  });

  const unstarJobMutation = useMutation((id: string) => unstarJob(id), {
    onSuccess: async () => {
      queryClient.invalidateQueries(['jobs', 'starred']);
      const prevJob = queryClient.getQueryData(['job-detail', jobId]) || {};
      // @ts-ignore
      const newJob = { ...prevJob, starred: false };
      queryClient.setQueryData(['job-detail', jobId], newJob);
      return newJob;
    },
  });

  return (
    <Icon
      name={
        variant === Variant.Fill
          ? 'star'
          : job.starred
          ? 'star'
          : 'star-outline'
      }
      size={28}
      disabled={starJobMutation.isLoading || unstarJobMutation.isLoading}
      className={clx('cursor-pointer', {
        'text-text-lighter': variant === Variant.Outline && !job.starred,
        'text-white': variant === Variant.Fill && !job.starred,
        'text-yellow-400': job.starred,
        'animate-spin':
          starJobMutation.isLoading || unstarJobMutation.isLoading,
      })}
      onClick={() =>
        job.starred
          ? unstarJobMutation.mutate(jobId)
          : starJobMutation.mutate(jobId)
      }
    />
  );
};

export default Star;

import React, { useState } from 'react';
import Icon from 'components/Icon';
import CreateFromScratch from './CreateFromScratch';
import CreateFromDrawing from './CreateFromDrawing';
import CreateFromMeasurements from './CreateFromMeasurements';
import CreateSumoquote from './CreateSumoquote';
import { useJobDetail, useJobStats } from 'queries/job';
import SkeletonLoader, { ContentType } from 'components/SkeletonLoader';
import useModal from 'hooks/useModal';
import { CreateRoofDrawing, CreateRoofMeasurement } from 'components/Alerts';
import useAuth from 'hooks/useAuth';
import CreateFromEstimateTemplates from './CreateFromEstimateTemplates';

type AppProps = {
  jobId: string;
  step?: null | string;
  closeModal: () => void;
};

const CreateEstimate: React.FC<AppProps> = ({
  closeModal,
  jobId,
  step = null,
}) => {
  const { userInfo } = useAuth();
  const [form, setForm] = useState<string | null>(step);
  const { data: jobData } = useJobDetail(jobId);
  const [drawing, showDrawing, closeDrawing] = useModal();
  const [measurement, showMeasure, closeMeasure] = useModal();
  const { data, status } = useJobStats(jobId);

  const mainScreen = () => setForm(null);

  if (status === 'loading') {
    return (
      <div>
        <div className="flex justify-between">
          <div className="text-xl font-bold">Create Estimate</div>
          <div>
            <Icon name="close" size={30} onClick={closeModal} />
          </div>
        </div>
        <SkeletonLoader contentType={ContentType.Form} size={3} />
      </div>
    );
  }

  if (form === 'blank') {
    return <CreateFromScratch closeModal={closeModal} goBack={mainScreen} />;
  }

  if (form === 'drawing') {
    return <CreateFromDrawing closeModal={closeModal} goBack={mainScreen} />;
  }

  if (form === 'measurements') {
    return (
      <CreateFromMeasurements closeModal={closeModal} goBack={mainScreen} />
    );
  }

  if (form === 'estimate_template') {
    return (
      <CreateFromEstimateTemplates
        closeModal={closeModal}
        goBack={mainScreen}
      />
    );
  }

  if (form === 'sumoquote') {
    return <CreateSumoquote closeModal={closeModal} goBack={mainScreen} />;
  }

  const renderCreate = (
    label: string,
    icon: string,
    iconSize: number,
    onClick: () => void,
    className = ''
  ) => {
    return (
      <div
        key={label}
        onClick={onClick}
        className="border rounded-md px-2 py-3 mb-4 flex justify-between cursor-pointer hover:bg-primary-negative"
      >
        <div>
          <div className="text-text v-center">
            <div className="w-8">
              <Icon name={icon} size={iconSize} className={className} />
            </div>
            {label}
          </div>
        </div>
        <div>
          <Icon name="caret-right-outline" className="text-text" />
        </div>
      </div>
    );
  };

  return (
    <div className="pb-4">
      <div className="flex justify-between">
        <div className="page-heading">Create Estimate</div>
        <div>
          <Icon
            name="close"
            size={32}
            onClick={closeModal}
            className="cursor-pointer"
          />
        </div>
      </div>
      <div className="mt-6">
        <div className="key">Select an option to create estimate</div>
      </div>
      {jobData?.sumoquote_meta?.status === 'processing' ? (
        <div className="text-text-medium mt-4 flex">
          <Icon name="sumoquote" className="w-6 h-6 object-cover" />
          <span>
            Waiting for sumoquote. You can not create a new estimate until this
            process is completed or cancelled.
          </span>
        </div>
      ) : (
        <div className="mt-4">
          {renderCreate('Create From Scratch', 'plus', 28, () =>
            setForm('blank')
          )}
          {renderCreate('Create From Drawing', 'shapes', 24, () => {
            if (!data?.drawings) {
              return showDrawing();
            }
            return setForm('drawing');
          })}
          {renderCreate('Create From Measurements', 'measurement', 24, () => {
            if (!data?.measurements) {
              return showMeasure();
            }
            return setForm('measurements');
          })}
          {!!data?.estimate_templates &&
            renderCreate(
              'Create From Estimate Template',
              'template',
              24,
              () => {
                return setForm('estimate_template');
              }
            )}
          {userInfo?.integrations?.sumoquote &&
            renderCreate(
              'Create Sumoquote',
              'sumoquote',
              24,
              () => setForm('sumoquote'),
              'relative -left-1'
            )}
        </div>
      )}
      <CreateRoofDrawing open={drawing} closeModal={closeDrawing} job={jobId} />
      <CreateRoofMeasurement
        open={measurement}
        closeModal={closeMeasure}
        job={jobId}
      />
    </div>
  );
};

export default CreateEstimate;

import React from 'react';
import Checkbox from './Checkbox';
import clx from 'classnames';
import Button, { Size, Variants } from 'components/Button';
import useModal from 'hooks/useModal';
import DateForm from './DateForm';
import { setRoofScheduleDate } from 'queries/job';
import { useParams } from 'react-router-dom';
import ButtonWithMore, { Variant } from 'components/ButtonWithMore';
import Confirmation from 'components/Confirmation';
import { formatServerTime } from 'utils/time';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { readAxiosErr } from 'utils/misc';

type AppProps = {
  data: Record<string, any>;
  renderInTable?: boolean;
};

const RoofScheduled: React.FC<AppProps> = ({ data, renderInTable = false }) => {
  const { jobId = '' } = useParams();
  const [schedule, showSchedule, closeSchedule] = useModal();
  const [confirm, showConfirm, closeConfirm] = useModal();
  const queryClient = useQueryClient();

  const unscheduleMutation = useMutation(
    () => setRoofScheduleDate(jobId, { date: null }),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(['job-last-checklist', jobId]),
          queryClient.invalidateQueries(['job-checklist', jobId]),
        ]);
        queryClient.invalidateQueries(['job-detail', jobId]);
        queryClient.invalidateQueries(['jobs']);

        closeConfirm();
      },
    }
  );

  const incomplete = () => {
    return (
      <div className="apart">
        <div className="value">Roof Not Scheduled</div>
        {data.permissions.can_schedule && (
          <div>
            <Button
              label="Schedule"
              size={Size.Small}
              variant={Variants.Tag}
              onClick={showSchedule}
            />
          </div>
        )}
      </div>
    );
  };

  const complete = () => {
    return (
      <div>
        <div className="apart">
          <div className="value">Roof Scheduled</div>
          <div>
            <ButtonWithMore
              label="Re-Schedule"
              variant={Variant.Tag}
              onClick={showSchedule}
              permission={data.permissions.can_schedule}
              options={[
                {
                  key: 'undo',
                  label: 'Unschedule',
                  onClick: showConfirm,
                  permission: data.permissions.can_undo,
                },
              ]}
            />
          </div>

          {confirm && (
            <Confirmation
              title="Unschedule Roof?"
              message="Are you sure you want unschedule Roof?"
              open={confirm}
              closeConfirm={closeConfirm}
              onClick={() => unscheduleMutation.mutate()}
              isLoading={unscheduleMutation.isLoading}
            />
          )}
        </div>
        <div className="mt-1">
          <span className="key">Scheduled At: </span>
          <span className="key">
            {formatServerTime({
              date: data.date,
              parseFormat: 'YYYY-MM-DDThh:mm:ssZ',
              includeTime: true,
            })}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={clx({ card: !renderInTable, 'px-2 pb-1': renderInTable })}>
      <div className="flex space-x-2">
        <Checkbox checked={data.complete} />
        <div className="w-full">
          {data.complete ? complete() : incomplete()}
        </div>
      </div>
      {schedule && (
        <DateForm
          title="Schedule Roof"
          label="Scheduled Date"
          open={schedule}
          closeModal={closeSchedule}
          jobId={jobId}
          initial_date={data?.date}
          mutationFn={setRoofScheduleDate}
        />
      )}
    </div>
  );
};

export default RoofScheduled;

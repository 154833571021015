import BottomSheet from 'components/BottomSheet';
import Icon from 'components/Icon';
import React from 'react';
import { readAxiosErr, titleCase } from 'utils/misc';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FieldType } from 'utils/enum';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useSurveyChoices } from 'queries/company';
import { Layout } from 'components/Form';
import Button from 'components/Button';

type AppProps = {
  open: boolean;
  closeModal: () => any;
  entityId: string | number;
  entityName: string;
  queryKey: any;
  attachFunction: (...args: any) => any;
  initial?: number;
  removeFunction?: (...args: any) => any;
};

interface IFormValues {
  survey: number;
}

const AttachSurvey: React.FC<AppProps> = ({
  entityId,
  entityName,
  open,
  closeModal,
  attachFunction,
  queryKey = '',
  initial,
  removeFunction,
}) => {
  const queryClient = useQueryClient();
  const schema = yup.object({
    survey: yup.number().required(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: yupResolver(schema),
    defaultValues: { survey: initial },
  });

  const attachMutation = useMutation(
    (formData: any) => attachFunction(entityId, formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKey);
        closeModal();
      },
    }
  );

  // @ts-ignore
  const removeMutation = useMutation(() => removeFunction(entityId), {
    onError: (err: any) => {
      toast.error(readAxiosErr(err));
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKey);
      closeModal();
    },
  });

  const onSubmit: SubmitHandler<IFormValues> = async (formData) => {
    attachMutation.mutate(formData);
  };

  const fields = [
    {
      name: 'survey',
      type: FieldType.AsyncSelect,
      label: 'Select Survey',
      placeholder: 'Select Survey',
      error: errors.survey?.message,
      control,
      optionsPromise: useSurveyChoices,
    },
  ];

  return (
    <BottomSheet open={open} maxWidth="max-w-xl">
      <div>
        <div className="flex justify-between mb-4">
          <div className="page-heading">
            Attach Survey to {titleCase(entityName)}
          </div>
          <div>
            <Icon
              name="close"
              size={32}
              onClick={closeModal}
              className="cursor-pointer"
            />
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Layout fields={fields} />
          <Button
            label="Attach Survey"
            className="mt-8"
            loading={attachMutation.isLoading}
          />
        </form>
        {!!removeFunction && !!initial && (
          <div
            className="mt-4 text-sm text-error center space-x-1 cursor-pointer"
            onClick={() => !removeMutation.isLoading && removeMutation.mutate()}
          >
            <Icon name="delete" size={18} />
            <span>Remove Survey</span>
          </div>
        )}
      </div>
    </BottomSheet>
  );
};

export default AttachSurvey;

import React from 'react';
import clx from 'classnames';

type AppProps = {
  className?: string;
};

const Companycam: React.FC<AppProps> = ({ className = 'h-11' }) => {
  return (
    <div className={clx('flex items-center')}>
      <img src={require('./companycam.png')} className={className} />
    </div>
  );
};

export default Companycam;

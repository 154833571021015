import JobDetailSidebar from 'components/JobDetailSidebar';
import useModal from 'hooks/useModal';
import React, { createContext, useState } from 'react';

export const JobDetailSidebarContext = createContext({
  isSidebarOpen: false,
  openSidebar: (id: number) => {},
  closeSidebar: () => {},
  job: null,
});

type AppProps = {
  children: React.ReactNode;
};

const JobDetailSidebarProvider: React.FC<AppProps> = ({ children }) => {
  const [job, setJob] = useState<number | null>(null);
  const [sidebar, showSidebar, closeSidebar] = useModal();

  const openSidebar = (id: number) => {
    setJob(id);
    showSidebar();
  };

  return (
    <JobDetailSidebarContext.Provider
      value={{
        isSidebarOpen: sidebar,
        openSidebar,
        closeSidebar,
        // @ts-ignore
        job,
      }}
    >
      {children}
      {job && (
        <>
          <JobDetailSidebar
            job={job}
            isOpen={sidebar}
            closeSidebar={() => {
              setJob(null);
              closeSidebar();
            }}
          />
        </>
      )}
    </JobDetailSidebarContext.Provider>
  );
};

export default JobDetailSidebarProvider;

import React from 'react';

type AppProps = {
  label: string;
  initial?: string;
};

const Counter = React.forwardRef<HTMLInputElement, AppProps>(() => {
  return <div>Hello nput</div>;
});

Counter.displayName = 'Counter';

export default Counter;

import React, { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FieldType } from 'utils/enum';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { readAxiosErr } from 'utils/misc';
import { Layout } from 'components/Form';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { scheduleInspection, useScheduleInspectionConfig } from 'queries/job';
import { useActiveEmployeeChoices } from 'queries/company';
import { serverTimeToDate, startOfHour } from 'utils/time';
import { processFields, processInitialValues, processSchema } from 'utils/form';
import SkeletonLoader, { ContentType } from 'components/SkeletonLoader';

interface IFormValues {
  inspector: number;
  date: string;
  end_date: string;
  note: string;
  notify_customer?: boolean;
  notify_customer_via_sms?: boolean;
  notify_customer_via_email?: boolean;
  add_to_user_cal?: boolean;
}

type AppProps = {
  jobId: string;
  closeModal: () => void;
};

const ScheduleInspection: React.FC<AppProps> = ({ jobId, closeModal }) => {
  const queryClient = useQueryClient();

  const { data: config, isFetching } = useScheduleInspectionConfig(jobId);

  const scheduleInsMutation = useMutation(
    (formData: any) => scheduleInspection(jobId, formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(['job-last-checklist', jobId]),
          queryClient.invalidateQueries(['job-checklist', jobId]),
        ]);
        queryClient.invalidateQueries(['job-detail', jobId]);
        queryClient.invalidateQueries(['jobs']);
        queryClient.invalidateQueries(['job-events', jobId]);
        queryClient.invalidateQueries(['todays-events']);
        return closeModal();
      },
    }
  );

  const schema = yup.object(
    processSchema(
      {
        inspector: yup.number(),
        date: yup.date(),
        end_date: yup.date(),
        note: yup.string(),
        notify_customer: yup.boolean(),
        notify_customer_via_sms: yup.boolean(),
        notify_customer_via_email: yup.boolean(),
        add_to_user_cal: yup.boolean(),
      },
      config
    )
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    watch,
    setValue,
    reset,
  } = useForm<IFormValues>({ resolver: yupResolver(schema) });

  const onSubmit: SubmitHandler<IFormValues> = async (formData) => {
    scheduleInsMutation.mutate(formData);
  };

  const _start = watch('date');
  const _end = watch('end_date');

  useEffect(() => {
    if (config) {
      const defaultValues = processInitialValues(config);
      if (!config.date?.initial) {
        defaultValues['date'] = startOfHour(1);
      } else {
        defaultValues['date'] = serverTimeToDate(config.date.initial);
      }

      if (!config.end_date?.initial) {
        defaultValues['end_date'] = startOfHour(2);
      } else {
        defaultValues['end_date'] = serverTimeToDate(config.end_date.initial);
      }
      reset(defaultValues);
    }
  }, [config]);

  useEffect(() => {
    if (_end && _start > _end) {
      setValue('end_date', _start);
    }
  }, [_start, _end]);

  const fields = [
    {
      name: 'inspector',
      type: FieldType.AsyncSelect,
      label: 'Select Inspector',
      error: errors.inspector?.message,
      control,
      optionsPromise: useActiveEmployeeChoices,
    },
    {
      name: 'date',
      type: FieldType.DatePicker,
      label: 'Start At',
      placeholder: 'Start At',
      error: errors.date?.message,
      isClearable: true,
      control,
    },
    {
      name: 'end_date',
      type: FieldType.DatePicker,
      label: 'End At',
      placeholder: 'End At',
      error: errors.end_date?.message,
      isClearable: true,
      control,
    },
    {
      type: FieldType.TextArea,
      label: 'Note',
      placeholder: 'Type here...',
      error: errors.note?.message,
      ...register('note'),
    },
    {
      type: FieldType.Checkbox,
      label: 'Notify Customer via Email',
      error: errors.notify_customer_via_email?.message,
      className: 'my-4',
      ...register('notify_customer_via_email'),
    },
    {
      type: FieldType.Checkbox,
      label: 'Notify Customer via Sms',
      error: errors.notify_customer_via_sms?.message,
      className: 'my-4',
      ...register('notify_customer_via_sms'),
    },
    {
      type: FieldType.Checkbox,
      label: 'Add to my calendar',
      error: errors.add_to_user_cal?.message,
      className: 'my-4',
      ...register('add_to_user_cal'),
    },
  ];

  if (isFetching) {
    return <SkeletonLoader contentType={ContentType.Form} size={6} />;
  }

  return (
    <div className="pb-4">
      <div className="flex justify-between mb-6">
        <div className="page-heading">Schedule Inspection</div>
        {!scheduleInsMutation.isLoading && (
          <div className="relative bottom-1">
            <Icon
              name="close"
              size={36}
              onClick={closeModal}
              className="cursor-pointer"
            />
          </div>
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Layout
          fields={processFields(fields, config)}
          loading={scheduleInsMutation.isLoading}
        />

        <Button
          label={
            scheduleInsMutation.isLoading
              ? 'Scheduling...'
              : 'Schedule Inspection'
          }
          className="mt-8"
          loading={scheduleInsMutation.isLoading}
        />
      </form>
    </div>
  );
};

export default ScheduleInspection;

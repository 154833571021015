import Icon from 'components/Icon';
import Menu from 'components/Menu';
import React from 'react';
import clx from 'classnames';

interface IOption {
  key: string;
  label: string;
  icon?: string;
  onClick?: () => any;
  permission?: boolean;
}

export enum Variant {
  Primary = 'primary',
  Tag = 'tag',
}

type AppProps = {
  label: string;
  leftIcon?: string;
  onClick: () => any;
  options: IOption[];
  variant?: Variant;
  permission?: boolean;
};

const ButtonWithMore: React.FC<AppProps> = ({
  label,
  onClick,
  options,
  leftIcon,
  variant = Variant.Primary,
  permission = true,
}) => {
  const btnStyles = clx('v-center shadow cursor-pointer', {
    'bg-primary text-white rounded-lg': variant === Variant.Primary,
    'text-primary bg-primary-negative rounded-md': variant === Variant.Tag,
  });

  const _options = options.filter((o) => o.permission !== false);

  if (!_options?.length && !permission) {
    return null;
  }

  return (
    <>
      <div className={btnStyles}>
        <div
          className={clx('v-center', {
            'px-2 py-[4px] text-[15px]': variant === Variant.Primary,
            'pl-2 pr-1.5 py-[2px] text-xs': variant === Variant.Tag,
          })}
          onClick={() => {
            if (permission) {
              onClick();
            }
          }}
        >
          {leftIcon && (
            <Icon
              name={leftIcon}
              size={variant === Variant.Primary ? 20 : 16}
            />
          )}
          <div>{label}</div>
        </div>
        <Menu
          trigger={
            <div
              className={clx('border-l', {
                'px-1 py-[2px] ml-1': variant === Variant.Primary,
                'px-1 py-[1px] relative top-[2px]': variant === Variant.Tag,
              })}
            >
              <Icon
                name="caret-down-plain"
                size={variant === Variant.Primary ? 20 : 15}
              />
            </div>
          }
          options={_options}
        />
      </div>
    </>
  );
};

export default ButtonWithMore;

import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import apiService from 'utils/apiService';

// ******** Get Documents For Job *********
export const getDocuments = async ({ pageParam = 1, ...q }) => {
  const { data } = await apiService.get('/api/light/documents/', {
    ...q,
    page: pageParam,
  });
  return data;
};

export const useDocuments = (q: Record<string, any>) => {
  return useInfiniteQuery(
    ['documents', q],
    ({ pageParam }) => getDocuments({ pageParam, ...q }),
    {
      getNextPageParam: (lastPage: any) => {
        return lastPage.next_page ?? undefined;
      },
      staleTime: 5 * 60 * 1000,
    }
  );
};

export const useDesktopDocuments = (q: Record<string, any>, page = 1) => {
  return useQuery(
    ['documents', q, { page }],
    () => getDocuments({ pageParam: page, ...q }),
    {
      staleTime: 15 * 60 * 1000,
      keepPreviousData: true,
    }
  );
};

const getAllDocuments = async (q: Record<string, any>) => {
  const { data } = await apiService.get('/api/light/documents/', q);
  return data;
};

export const useAllDocuments = (q: Record<string, any>) => {
  return useQuery(['all-documents', q], () => getAllDocuments(q), {
    staleTime: 5 * 60 * 1000,
  });
};

// ********* Get Document Detail *********
const getDocumentDetail = async (id: string) => {
  const { data } = await apiService.get(`/api/light/documents/${id}/`);
  return data;
};

export const useDocumentDetail = (id: string) => {
  return useQuery(['document-detail', id], () => getDocumentDetail(id), {
    staleTime: 15 * 60 * 1000,
  });
};

// ****** Upload Document ******
export const createDocument = async (payload: Record<string, any>) => {
  const { data } = await apiService.post('/api/light/documents/', payload);
  return data;
};

export const deleteDocument = async (
  id: string,
  q: Record<string, any> = {}
) => {
  await apiService.delete(`/api/light/documents/${id}/`, q);
  return true;
};

// ******* Import document ******
export const importDocTemplate = async (
  jobId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/jobs/${jobId}/import_doctemplate/`,
    payload
  );
  return data;
};

export const enableSignautre = async (id: number) => {
  const { data } = await apiService.post(
    `/api/light/documents/${id}/create_signed_document/`
  );
  return data;
};

export const impersonateAndSign = async (signerId: number) => {
  const { data } = await apiService.post(
    `/api/light/documentsigners/${signerId}/impersonate_and_sign/`
  );
  return data;
};

// ******** document signing ******
const getSignDocumentInfo = async (id: string) => {
  const { data } = await apiService.get(
    `/api/light/signed-documents/${id}/template_info/`
  );
  return data;
};

export const useSignDocumentInfo = (id: string, enabled = false) => {
  return useQuery(['signed-document-info', id], () => getSignDocumentInfo(id), {
    staleTime: 0,
    enabled,
  });
};

const getSignDocumentMeta = async (id: string) => {
  const { data } = await apiService.get(
    `/api/light/signed-documents/${id}/meta/`
  );
  return data;
};

export const useSignDocumentMeta = (id: string) => {
  return useQuery(['signed-document-meta', id], () => getSignDocumentMeta(id), {
    staleTime: 0,
  });
};

export const updateCustomerDetails = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/signed-documents/${id}/update_customer/`,
    payload
  );
  return data;
};

export const voidSignatures = async (id: number) => {
  await apiService.delete(`/api/light/signed-documents/${id}/`);
  return true;
};

const getDocumentChoices = async ({ pageParam = 1, ...q }) => {
  const { data } = await apiService.get('/api/light/document_choices/', {
    ...q,
    page: pageParam,
  });
  return data;
};

export const useDocumentChoices = (q: Record<string, any>) => {
  return useInfiniteQuery(
    ['document-choices', q],
    ({ pageParam }) => getDocumentChoices({ pageParam, ...q }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next_page ?? undefined;
      },
      staleTime: 15 * 60 * 1000,
    }
  );
};

// *********************** Get Document By Employeee ***************8

const getEmployeeDocs = async ({ ...q }) => {
  const { data } = await apiService.get('/api/light/documents/', {
    ...q,
  });
  return data;
};

export const useEmployeeDocuments = (q: Record<string, any>) => {
  return useInfiniteQuery(
    ['employee-docs', q],
    ({ pageParam }) => getEmployeeDocs({ pageParam, ...q }),
    {
      getNextPageParam: (lastPage: any) => {
        return lastPage.next_page ?? undefined;
      },
      staleTime: 5 * 60 * 1000,
    }
  );
};

export const updateUserCredentials = async (
  payload: Record<string, any>,
  userId?: number
) => {
  apiService.updateContentType('multipart/form-data');
  const { data } = await apiService.patch(
    `/api/light/auth/update_credentials/?user=${userId}`,
    payload
  );
  apiService.updateContentType('application/json');
  return data;
};

export const markFieldComplete = async (
  signerId: number,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/documentsigners/${signerId}/field_completed/`,
    payload
  );
  return data;
};

export const completeSigning = async (
  signerId: number,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/documentsigners/${signerId}/completed/`,
    payload
  );

  return data;
};

export const attachSurveyToDocument = async (
  id: number,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/signed-documents/${id}/attach_survey/`,
    payload
  );
  return data;
};

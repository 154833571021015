import React from 'react';
import DatePicker from 'react-datepicker';
import { Portal } from 'react-portal';
import { useController } from 'react-hook-form';
import clx from 'classnames';

type AppProps = {
  name: string;
  isClearable?: boolean;
  className?: string;
  control: any;
  required?: boolean;
  height?: string;
  disabled?: boolean;
  error?: string;
};

const CalendarContainer = ({ children }: any) => {
  return (
    <Portal>
      <div className="w-full absolute z-[99999]">{children}</div>
    </Portal>
  );
};

const RangeSelector: React.FC<AppProps> = ({
  name,
  control,
  height = 'h-10',
  error = null,
}) => {
  const { field } = useController({ name, control });

  const inputStyle = clx(
    'w-full outline-none placeholder:text-text-light text-text text-sm rounded-lg focus:outline-none focus:ring-0',
    height,
    {
      'focus:border-error-dark border-error': !!error,
      'focus:border-primary border-border ': !error,
    }
  );

  return (
    <div className="v-center space-x-1">
      <DatePicker
        key="start"
        className={inputStyle}
        selected={field.value?.start}
        onChange={(date) => field.onChange({ ...field.value, start: date })}
        selectsStart
        startDate={field.value?.start}
        endDate={field.value?.end}
        popperContainer={CalendarContainer}
        placeholderText="Start Date"
      />
      <div> - </div>
      <DatePicker
        key="end"
        className={inputStyle}
        selected={field.value?.end}
        onChange={(date) => field.onChange({ ...field.value, end: date })}
        selectsEnd
        startDate={field.value?.start}
        endDate={field.value?.end}
        minDate={field.value?.start}
        popperContainer={CalendarContainer}
        placeholderText="End Date"
      />
    </div>
  );
};

export default RangeSelector;

import Icon from 'components/Icon';
import Sidebar from 'components/Sidebar';
import React from 'react';

type AppProps = {
  open: boolean;
  closeModal: () => any;
};

const TemplateTypeInfo: React.FC<AppProps> = ({ open, closeModal }) => {
  return (
    <Sidebar open={open} closeSidebar={closeModal}>
      <div className="pb-4 py-2 px-4">
        <div className="apart border-b pb-2">
          <div className="page-heading">Template Type Info</div>
          <Icon name="close" size={28} onClick={closeModal} />
        </div>
        <div className="mt-4">
          <div className="border-b pb-2 mb-2">
            <div className="text-base font-bold text-text">
              Cost Plus Template
            </div>
            <div className="text-text-medium">
              This type of template will calculate your customer price by
              marking up your material and labor costs by a certain percentage.
            </div>
            <div className="my-2">Example</div>
            <div className="pl-4 border-l-2 border-border-light">
              <div className="v-center space-x-1">
                <div className="w-28 text-sm">Material:</div>
                <div className="font-bold">$3,000</div>
              </div>
              <div className="v-center space-x-1">
                <div className="w-28 text-sm">Labor:</div>
                <div className="font-bold">$2,000</div>
              </div>
              <div className="v-center space-x-1">
                <div className="w-28 text-sm">Total Cost:</div>
                <div className="font-bold">$5,000</div>
              </div>
              <div className="v-center space-x-1">
                <div className="w-28 text-sm">Markup:</div>
                <div className="font-bold">100%</div>
              </div>
              <div className="v-center space-x-1">
                <div className="w-28 text-sm">Customer Price:</div>
                <div className="font-bold">$10,000</div>
              </div>
            </div>
          </div>
          <div className="border-b pb-2 mb-2 mt-6">
            <div className="text-base font-bold text-text">
              Fixed Price Template
            </div>
            <div className="text-text-medium">
              This type of template allows you to enter a fixed customer price
              for each item in the template.
            </div>
            <div className="my-2">Example</div>
            <div className="pl-4 border-l-2 border-border-light">
              <div className="v-center space-x-1">
                <div className="w-28 text-sm">Roof:</div>
                <div className="font-bold">$350/SQ</div>
              </div>
              <div className="v-center space-x-1">
                <div className="w-28 text-sm">Gutters:</div>
                <div className="font-bold">$10/LF</div>
              </div>
              <div className="v-center space-x-1">
                <div className="w-28 text-sm">Ridge Vent:</div>
                <div className="font-bold">$4/LF</div>
              </div>
            </div>
          </div>
          <div className="border-b pb-2 mb-2 mt-6">
            <div className="text-base font-bold text-text">
              Gross Margin Template
            </div>
            <div className="text-text-medium">
              This type of template allows you to enter a Gross Margin % that
              you want to make on the job. Roof.Link defines Gross Margin as
              your profit margin on the job <b>BEFORE</b> commissions and
              company overhead.
            </div>
            <div className="my-2">Example</div>
            <div className="pl-4 border-l-2 border-border-light">
              <div className="v-center space-x-1">
                <div className="w-28 text-sm">Material:</div>
                <div className="font-bold">$3,000</div>
              </div>
              <div className="v-center space-x-1">
                <div className="w-28 text-sm">Labor:</div>
                <div className="font-bold">$2,000</div>
              </div>
              <div className="v-center space-x-1">
                <div className="w-28 text-sm">Total Cost:</div>
                <div className="font-bold">$5,000</div>
              </div>
              <div className="v-center space-x-1">
                <div className="w-28 text-sm">Gross Margin:</div>
                <div className="font-bold">30%</div>
              </div>
              <div className="v-center space-x-1">
                <div className="w-28 text-sm">Customer Price:</div>
                <div className="font-bold">$7,150</div>
              </div>
            </div>
          </div>
          <div className="border-b pb-2 mb-2 mt-6">
            <div className="text-base font-bold text-text">
              Adjusted Gross Margin Template
            </div>
            <div className="text-text-medium">
              This type of template allows you to enter an Adjusted Gross Margin
              % that you want to make on the job. [Roof.Link](http://Roof.Link)
              defines Adjusted Gross Margin as your profit margin on the job
              <b> BEFORE</b> commissions but <b>AFTER DEDUCTING</b> company
              overhead.
            </div>
            <div className="my-2">Example</div>
            <div className="pl-4 border-l-2 border-border-light">
              <div className="v-center space-x-1">
                <div className="w-36 text-sm">Material:</div>
                <div className="font-bold">$3,000</div>
              </div>
              <div className="v-center space-x-1">
                <div className="w-36 text-sm">Labor:</div>
                <div className="font-bold">$2,000</div>
              </div>
              <div className="v-center space-x-1">
                <div className="w-36 text-sm">Total Cost:</div>
                <div className="font-bold">$5,000</div>
              </div>
              <div className="v-center space-x-1">
                <div className="w-36 text-sm">Adjusted Gross Margin:</div>
                <div className="font-bold">30%</div>
              </div>
              <div className="v-center space-x-1">
                <div className="w-36 text-sm">Company Overhead:</div>
                <div className="font-bold">10%</div>
              </div>
              <div className="v-center space-x-1">
                <div className="w-36 text-sm">Customer Price:</div>
                <div className="font-bold">$7,900</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default TemplateTypeInfo;

import React, { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FieldType } from 'utils/enum';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { readAxiosErr } from 'utils/misc';
import { Layout } from 'components/Form';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { editDateApproved } from 'queries/job';
import { serverTimeToDate, startOfHour } from 'utils/time';

interface IFormValues {
  date_approved: string;
}

type AppProps = {
  date_approved: string;
  jobId: string;
  closeModal: () => void;
};

const EditDateApproved: React.FC<AppProps> = ({
  date_approved,
  jobId,
  closeModal,
}) => {
  const queryClient = useQueryClient();

  const editDateApprovedMutation = useMutation(
    (formData: any) => editDateApproved(jobId, formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: () => {
        toast.success('Date Updated!');
        queryClient.invalidateQueries(['job-detail', jobId]);
        queryClient.invalidateQueries(['job-checklist', jobId]);
        return closeModal();
      },
    }
  );

  const schema = yup.object({
    date_approved: yup.date(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      // @ts-ignore
      date_approved: serverTimeToDate(date_approved),
    },
  });

  const onSubmit: SubmitHandler<IFormValues> = async (formData) => {
    editDateApprovedMutation.mutate(formData);
  };

  useEffect(() => {
    // @ts-ignore
    setValue('date', startOfHour(1));
  }, []);

  const fields = [
    {
      name: 'date_approved',
      type: FieldType.DatePicker,
      label: 'Date Approved',
      placeholder: 'Date Approved',
      error: errors.date_approved?.message,
      isClearable: true,
      control,
    },
  ];

  return (
    <div className="pb-48">
      <div className="flex justify-between mb-8">
        <div className="page-heading">Edit Date Approved</div>
        {!editDateApprovedMutation.isLoading && (
          <div className="relative bottom-1">
            <Icon name="close" size={36} onClick={closeModal} />
          </div>
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Layout fields={fields} loading={editDateApprovedMutation.isLoading} />

        <Button
          label={
            editDateApprovedMutation.isLoading
              ? 'Updating...'
              : 'Update Date Approved'
          }
          className="mt-8"
          loading={editDateApprovedMutation.isLoading}
        />
      </form>
    </div>
  );
};

export default EditDateApproved;

import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import apiService from 'utils/apiService';

// ******** Get Sms For Job *********
export const getSmses = async ({ pageParam = 1, ...q }) => {
  const { data } = await apiService.get('/api/light/sms/', {
    ...q,
    page: pageParam,
  });
  return data;
};

export const useSmses = (q: Record<string, any>) => {
  return useInfiniteQuery(
    ['sms', q],
    ({ pageParam }) => getSmses({ pageParam, ...q }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next_page ?? undefined;
      },
      staleTime: 15 * 60 * 1000,
    }
  );
};

export const useDesktopSms = (q: Record<string, any>, page = 1) => {
  return useQuery(
    ['sms-desktop', q, { page }],
    () => getSmses({ pageParam: page, ...q }),
    {
      staleTime: 16 * 60 * 1000,
      keepPreviousData: true,
    }
  );
};

// ********* Get Sms Detail *********
const getSmsDetail = async (id: string) => {
  const { data } = await apiService.get(`/api/light/sms/${id}/`);
  return data;
};

export const useSmsDetail = (id: string) => {
  return useQuery(['sms-detail', id], () => getSmsDetail(id), {
    staleTime: 15 * 60 * 1000,
  });
};

// ********** Get Sms field config **********
const getSmsFieldConfig = async () => {
  const { data } = await apiService.get(`/api/light/sms/fields_config/`);
  return data;
};

export const useSmsFieldConfig = () => {
  return useQuery(['sms-field-config'], getSmsFieldConfig, {
    staleTime: Infinity,
  });
};

// ****** Create Sms *******
export const createSms = async (payload: Record<string, any>, job: string) => {
  const { data } = await apiService.post(`/api/light/sms/?job=${job}`, payload);
  return data;
};

// ******** Get SentSms For Job *********
export const getSentSms = async ({ pageParam = 1, ...q }) => {
  const { data } = await apiService.get('/api/light/sent-sms/', {
    ...q,
    page: pageParam,
  });
  return data;
};

export const useSentSms = (q: Record<string, any>) => {
  return useInfiniteQuery(
    ['sent-sms', q],
    ({ pageParam }) => getSentSms({ pageParam, ...q }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next_page ?? undefined;
      },
      staleTime: 15 * 60 * 1000,
    }
  );
};

export const useDesktopSentSms = (q: Record<string, any>, page = 1) => {
  return useQuery(
    ['sent-sms', 'desktop', q, { page }],
    () => getSentSms({ pageParam: page, ...q }),
    {
      staleTime: 15 * 60 * 1000,
      keepPreviousData: true,
    }
  );
};

const getSentSmsDetail = async (id: number) => {
  const { data } = await apiService.get(`/api/light/sent-sms/${id}/`);
  return data;
};

export const useSentSmsDetail = (id: number) => {
  return useQuery(['sent-sms', id?.toString()], () => getSentSmsDetail(id), {
    staleTime: Infinity,
  });
};

import { deleteDocument } from 'queries/documents';
import React, { useMemo, useState } from 'react';
import Menu from 'components/Menu';
import Icon from 'components/Icon';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import Button, { Size } from 'components/Button';
import { truncate, uniq } from 'lodash';
import { formatServerTime } from 'utils/time';
import { getFileType, readAxiosErr } from 'utils/misc';
import { FileType } from 'utils/enum';
import useModal from 'hooks/useModal';
import Confirmation from 'components/Confirmation';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import useMedia from 'hooks/useMedia';
import clx from 'classnames';
import Modal, { Variant } from 'components/Modal';
import { useNavigate } from 'react-router-dom';

export enum FindFileTypeFrom {
  Name = 'name',
  Url = 'url',
}

type AppProps = {
  document: Record<string, any>;
  closeModal: () => void;
  findFileTypeFrom?: FindFileTypeFrom;
  deleteQuery?: (...args: any) => any;
  queryParams?: Record<string, any>;
  queryKey?: string[];
};

const DocumentDetail: React.FC<AppProps> = ({
  document,
  closeModal,
  findFileTypeFrom = FindFileTypeFrom.Name,
  deleteQuery = deleteDocument,
  queryParams = {},
  queryKey = ['documents'],
}) => {
  const { isMobile } = useMedia();
  const [confirmOpen, showConfirmModal, closeConfirmModal] = useModal();
  const queryClient = useQueryClient();
  const [template, showTemplate, closeTemplate] = useModal();
  const navigate = useNavigate();

  const deleteDocumentMutation = useMutation(
    () => deleteQuery(document.id, queryParams),
    {
      onError: (e: any) => {
        toast.error(readAxiosErr(e));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
        closeModal();
      },
    }
  );

  const [numPages, setNumPages] = useState(0);

  const renderNoPreview = () => {
    return (
      <div className="w-full pt-12 center">
        <div className="w-2/3 flex flex-col justify-center items-center space-y-2">
          <span className="text-text-medium">Unable to preview the file.</span>
          <a href={document.url} target="_blank" rel="noreferrer" download>
            <Button label="Download" leftIcon="download" />
          </a>
        </div>
      </div>
    );
  };

  const renderPdf = () => {
    return (
      <div className="center bg-gray-400">
        <Document
          key={document.id}
          className={isMobile ? 'max-w-screen' : 'max-w-[280mm]'}
          file={document.url}
          onLoadSuccess={({ numPages }) => {
            setNumPages(numPages);
          }}
          loading={
            <div
              className={clx('p-4 page-center bg-white', {
                'w-screen': isMobile,
                'w-[280mm]': !isMobile,
              })}
            >
              Fetching your file...
            </div>
          }
          error={renderNoPreview}
        >
          <div className="pb-12 max-w-screen">
            {Array(numPages)
              .fill(null)
              .map((_, i) => {
                return (
                  <div key={i}>
                    <Page pageNumber={i + 1} width={window.innerWidth - 10} />
                    <div className="w-full center my-2 text-xxs text-white">
                      Page {i + 1} of {numPages}
                    </div>
                  </div>
                );
              })}
          </div>
        </Document>
      </div>
    );
  };

  const renderImage = () => {
    return (
      <div className="center w-screen bg-background-dark">
        <img
          src={document.url}
          alt={document.name}
          className={clx('object-cover', {
            'w-screen min-h-[480px] max-h-[680px]': isMobile,
            'max-h-[90vh] aspect-auto': !isMobile,
          })}
        />
      </div>
    );
  };

  const renderPreview = () => {
    const _fileType =
      findFileTypeFrom === FindFileTypeFrom.Name
        ? getFileType(document.name)
        : getFileType(document.url?.split('?')[0]);
    if (_fileType === FileType.Pdf) {
      return renderPdf();
    }
    if (_fileType === FileType.Img) {
      return renderImage();
    }
    return renderNoPreview();
  };

  const options = useMemo(() => {
    let _options: any[] = [];
    if (document?.can_manage_template) {
      _options = [
        ..._options,
        {
          key: 'template',
          label: 'Manage Template',
          icon: 'template',
          iconSize: 24,
          iconClassName: 'text-primary',
          onClick: showTemplate,
        },
      ];
    }
    _options = [
      ..._options,
      {
        key: 'delete',
        label: 'Delete',
        icon: 'delete',
        iconSize: 25,
        iconClassName: 'text-error',
        onClick: showConfirmModal,
      },
      {
        key: 'download',
        label: 'Download',
        icon: 'cloud-download',
        iconSize: 28,
        iconClassName: 'text-primary',
        onClick: () => window.open(document.url, '_blank'),
      },
    ];
    return _options;
  }, []);

  return (
    <div className="relative bg-white h-full">
      <div className="apart p-2 bg-white sticky top-0 z-40 drop-shadow-md">
        <div className="font-semibold">
          {truncate(document.name, { length: isMobile ? 30 : 120 })}
        </div>
        <div className="v-center space-x-2">
          <Menu
            renderAsActions={!isMobile}
            flattenOptions={['download']}
            trigger={
              <Icon
                name="more"
                size={22}
                className={clx('cursor-pointer', {
                  'relative top-1 left-[2px]': isMobile,
                })}
              />
            }
            options={options}
            optionClass="!mt-1 !px-2"
            renderAsMenuOnly
            wider
          />
          <Icon
            className="cursor-pointer"
            size={28}
            name="close"
            onClick={closeModal}
          />
        </div>
      </div>
      {renderPreview()}
      <div className="text-xs text-white fixed bottom-0 left-0 right-0 p-2 bg-black bg-opacity-70">
        <div>
          <div className="text-sm">
            {truncate(document.name, { length: 64 })}
          </div>
          <div className="flex items-center">
            <Icon name="user" size={14} className="mr-1" />{' '}
            {document.created_by?.name && `${document.created_by?.name} on `}
            {formatServerTime({
              date: document.date_created,
              includeTime: true,
            })}
          </div>
          {!!document.tags?.length && (
            <div className="flex items-center">
              <Icon name="tags" size={14} className="mr-1" />
              <div>{uniq(document.tags).join(', ')}</div>
            </div>
          )}
        </div>
      </div>
      {confirmOpen && (
        <Confirmation
          title="Delete Document?"
          message="Are you sure you want to delete this document? This can not be undone."
          open={confirmOpen}
          closeConfirm={closeConfirmModal}
          onClick={() => deleteDocumentMutation.mutate()}
          isLoading={deleteDocumentMutation.isLoading}
        />
      )}
      {template && (
        <Modal
          variant={Variant.Center}
          open={template}
          closeModal={closeTemplate}
        >
          <div>
            <div>
              This document was generated from a Document Template. Would you
              like to make changes to that template?
            </div>
            <div className="flex items-center justify-end space-x-4 mt-6">
              <div>
                <Button label="No" size={Size.Medium} onClick={closeTemplate} />
              </div>
              <div>
                <Button
                  label="Yes"
                  size={Size.Medium}
                  onClick={() =>
                    navigate(`/doctemplates/${document?.template}/builder`)
                  }
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default DocumentDetail;

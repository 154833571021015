import { range } from 'lodash';
import { TimeZoneType } from './enum';

export const PRIMARY_COLOR = '#095dcf';
export const PRIMARY_NEGATIVE_COLOR = '#dbeafe';
export const GREY_COLOR = '#9ca3af';
export const ERROR_COLOR = '#ef4444';
export const TEXT_COLOR = '#374151';
export const SESSION_KEY = 'sid';
export const IMPERSONATE_SESSION_KEY = 'ninja_sid';
export const LAST_META_STATE_URL = 'last_meta_state_url';
export const BRANDING_ROUTES = ['/login', '/register'];
export const SHOW_SIDEBAR = 'sidebar';
export const JOB_OVERVIEW = 'job_overview';
export const JOB_CHECKLIST = 'job_checklist';

export const ROOF_PITCH_CHOICES = range(0, 25).map((num) => ({
  value: num,
  label: `${num}/12`,
}));

export const WASTE_PCT_CHOICES = [
  { value: 0, label: '0' },
  { value: 5, label: '5' },
  { value: 7.5, label: '7.5' },
  { value: 10, label: '10' },
  { value: 12.5, label: '12.5' },
  { value: 15, label: '15' },
  { value: 17.5, label: '17.5' },
  { value: 20, label: '20' },
  { value: 25, label: '25' },
  { value: 30, label: '30' },
];

export const MONTH_LIST = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const FONT_CHOICES = [
  { value: 'lato', label: 'Lato' },
  { value: 'open_sans', label: 'Open Sans' },
  { value: 'roboto', label: 'Roboto' },
  // { value: 'courier', label: 'Courier Prime' },
  { value: 'montserrat', label: 'Montserrat' },
  { value: 'pt_sans', label: 'PT Sans' },
  { value: 'poppins', label: 'Poppins' },
];

export const TIMEZONE_CHOICES = [
  {
    label: 'Central (CST)',
    value: TimeZoneType.CST,
  },
  {
    label: 'Eastern (EST)',
    value: TimeZoneType.EST,
  },
  {
    label: 'Mountain (MDT)',
    value: TimeZoneType.MDT,
  },
  {
    label: 'Mountain (MST)',
    value: TimeZoneType.MST,
  },
  {
    label: 'Pacific (PST)',
    value: TimeZoneType.PST,
  },
];

export const UNIT_LABEL_MAP: Record<string, string> = {
  BD: 'Bundles',
  SQ: 'Squares',
  LF: 'Linear Feet',
  EA: 'Each',
};

import moment, { unitOfTime } from 'moment';

interface IFormatServerTime {
  date: string;
  includeTime?: boolean;
  parseFormat?: string;
  format?: string;
}

export const serverToUnix = (ds: string) => {
  return moment(ds, 'MM/DD/YYYY hh:mm A').unix();
};

export const daysBetween = (future: Date, past?: Date) => {
  return moment(future).diff(past ? moment(past) : moment(), 'days');
};

export const formatServerTime = ({
  date,
  includeTime = false,
  parseFormat = 'MM/DD/YYYY hh:mm A',
  format = '',
}: IFormatServerTime) => {
  if (!date) {
    return null;
  }
  const _format =
    format || (includeTime ? 'DD MMM YYYY, hh:mm a' : 'DD MMM YYYY');
  return moment(date, parseFormat).format(_format);
};

export const formatDateObj = (date?: Date, format = 'MM/DD/YYYY') => {
  if (!date) {
    return '';
  }
  return moment(date).format(format);
};

export const formatDate = (
  date?: moment.Moment | null,
  format = 'DD MMM YYYY, hh:mm a'
) => {
  let _date = date;
  if (!_date) {
    _date = moment();
  }
  return _date.format(format);
};

export const parseDate = (date_str: string, format: string) => {
  return moment(date_str, format);
};

export const parseAndFormat = (
  datestr: string,
  parseFormat = 'YYYY-MM-DD',
  format = 'LL'
) => {
  if (!datestr) {
    return '';
  }

  return moment(datestr, parseFormat).format(format);
};

export const parseEpochAndFormat = (
  date: number,
  format = 'hh:mm a, DD MMM YYYY'
) => {
  return moment.unix(date).format(format);
};

export const humanReadableDuration = (duration: number) => {
  if (duration < 60) {
    return `${duration} seconds`;
  }
  // @ts-ignore
  const mins = parseFloat(duration / 60).toFixed(1);
  return `${mins} mins`;
};

export const serverTimeToDate = (
  date: string,
  parseFormat = 'YYYY-MM-DDThh:mm:ssZ'
) => {
  return moment(date, parseFormat).toDate();
};

export const epochToDate = (date: number) => {
  return moment.unix(date).toDate();
};

export const todayDate = () => {
  return moment().format('DD MMM YYYY');
};

export const tomorrowDate = () => {
  return moment().add(1, 'day').format('DD MMM YYYY');
};

export const xDaysLater = (x: number) => {
  return moment().add(x, 'days').toDate();
};

export const xDaysAgo = (x: number) => {
  return moment().subtract(x, 'days').toDate();
};

export const now = () => {
  return moment().toDate();
};

export const epochNow = () => {
  return moment().unix();
};

export const setHours = (hour: number) => {
  return moment().set('hour', hour).set('minute', 0).toDate();
};

export const startOfHour = (delta: number) => {
  return moment().add(delta, 'hours').startOf('hour').toDate();
};

export const startOf = (unit: unitOfTime.DurationConstructor) =>
  moment().startOf(unit).toDate();

export const endOf = (unit: unitOfTime.DurationConstructor) =>
  moment().endOf(unit).toDate();

export const startOfDay = (time: Date) => moment(time).startOf('day');

export const endOfDay = (time: Date) => moment(time).endOf('day');

export const momentOfTime = (time?: Date | string) => moment(time);

export const minutesDiff = (start: moment.Moment, end: moment.Moment) =>
  moment.duration(end.diff(start)).asMinutes();

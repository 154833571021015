import React, { useMemo, useState } from 'react';
import { useController } from 'react-hook-form';
import clx from 'classnames';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {
  ERROR_COLOR,
  GREY_COLOR,
  PRIMARY_COLOR,
  TEXT_COLOR,
} from 'utils/constants';
import lodash, { find } from 'lodash';
import useModal from 'hooks/useModal';
import useMedia from 'hooks/useMedia';
import Icon from 'components/Icon';
import BottomSheet from 'components/BottomSheet';
import Button, { Size } from 'components/Button';

interface IOptions {
  value: string;
  label: string;
}

type AppProps = {
  label: string;
  name: string;
  options: IOptions[];
  placeholder?: string;
  isCreatable?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  className?: string;
  control: any;
  required?: boolean;
  error?: string;
  loading?: boolean;
  disabled?: boolean;
  position?: string;
  onCreate?: (...args: any) => any;
};

const MultiSelect: React.FC<AppProps> = ({
  name,
  label,
  control,
  placeholder = 'Select options',
  isCreatable = false,
  isClearable = false,
  isSearchable = true,
  className = '',
  options = [],
  error = null,
  required = false,
  loading = false,
  disabled = false,
  position = 'auto',
  onCreate = () => null,
}) => {
  const { isMobile } = useMedia();
  const { field } = useController({ name, control });
  const [select, showSelect, closeSelect] = useModal();
  const [search, setSearch] = useState('');

  const selectStyle = clx(
    'w-full rounded-lg outline-none focus:outline-none focus:ring-0',
    {
      'focus:border-error-dark border-error': !!error,
      'focus:border-primary border-border ': !error,
    }
  );

  const _filteredData = useMemo(() => {
    if (search) {
      const items = options.filter((d: any) =>
        d?.label?.toLowerCase().includes(search?.toLowerCase())
      );
      if (!isCreatable || find(options, { label: search })) {
        return items;
      }
      return [...items, { label: search, value: search, create: true }];
    }
    return options;
  }, [options, search]);

  const onChange = (vs: any[]) => {
    field.onChange(vs.map((v: any) => v.value));
  };

  const value = field.value
    ?.map((v: any) => lodash.find(options, { value: v }))
    .filter((t: any) => t);

  if (isMobile) {
    return (
      <div className={`space-y-1 mb-2 ${className}`}>
        <div className="text-text text-sm">
          {label}
          {required && <span className="text-error">*</span>}
        </div>
        <div
          className={clx(
            'border py-2 relative px-2 v-center text-base',
            selectStyle,
            {
              'bg-background-light': loading || disabled,
            }
          )}
          onClick={() => (loading || disabled ? null : showSelect())}
        >
          {value?.length ? (
            <div className="flex flex-wrap">
              {value?.map((v: any) => (
                <div
                  key={v.value}
                  className="text-xs text-white bg-primary px-1.5 py-1 rounded-md mr-1 mb-1"
                >
                  {v.label}
                </div>
              ))}
            </div>
          ) : (
            <span className="text-text-light">
              {placeholder || 'Select Value'}
            </span>
          )}
          <div className="absolute right-2">
            <Icon
              name="caret-down-outline"
              size={22}
              className="text-text-light"
            />
          </div>
        </div>
        <BottomSheet open={select}>
          <div className="pb-4">
            <div className="flex justify-between">
              <div className="text-lg font-bold mb-4">{label}</div>
              <div>
                <Icon
                  name="close"
                  size={28}
                  onClick={() => {
                    closeSelect();
                    setSearch('');
                  }}
                  className="cursor-pointer"
                />
              </div>
            </div>
            {isSearchable && (
              <div>
                <input
                  className="w-full rounded-md px-2 py-1.5 outline-none focus:border-primary border border-border placeholder:text-gray-400 text-text focus:outline-none focus:ring-0"
                  placeholder={isCreatable ? 'Search or Create new' : 'Search'}
                  onChange={(e) => setSearch(e.target.value)}
                  autoFocus={false}
                />
              </div>
            )}
            <div className={clx({ 'mt-4': isSearchable })}>
              <div className="text-primary text-sm apart">
                {value?.length ? (
                  <div>
                    <Button
                      label="Done"
                      size={Size.Medium}
                      onClick={() => {
                        closeSelect();
                        setSearch('');
                      }}
                    />
                  </div>
                ) : (
                  <div>&nbsp;</div>
                )}
                <div className="w-32" onClick={() => onChange([])}>
                  Clear All Selections
                </div>
              </div>
            </div>
            <div
              className={clx('max-h-96 overflow-y-auto', {
                'mt-4': isSearchable && !isClearable,
                'mt-2': !isSearchable && isClearable,
              })}
            >
              {_filteredData?.map((d: any) => {
                const isSelected = find(value, { value: d.value });

                return (
                  <div
                    key={d.value}
                    className={
                      'border-b border-border py-2.5 px-2 apart items-center text-sm'
                    }
                    onClick={() => {
                      if (isSelected) {
                        onChange(value.filter((v: any) => v.value !== d.value));
                      } else {
                        onChange([...(value || []), d]);
                      }
                    }}
                  >
                    <span
                      className={clx({
                        'font-bold': isSelected,
                      })}
                    >
                      {d?.create && (
                        <span className="text-xs text-primary">Create </span>
                      )}
                      <span
                        className={clx({ 'text-base font-bold': d.create })}
                      >
                        {d.label}
                      </span>
                    </span>
                    {isSelected && (
                      <Icon name="tick" className="text-success" size={16} />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </BottomSheet>
      </div>
    );
  }

  const RSelect = isCreatable ? CreatableSelect : Select;

  return (
    <div className={`space-y-1 mb-2 ${className}`}>
      <div className="text-text-medium text-xs">
        {label}
        {required && <span className="text-error">*</span>}
      </div>
      <RSelect
        {...field}
        isSearchable={isSearchable}
        isMulti
        // loadingMessage={() => 'Loading...'}
        // noOptionsMessage={() => (loading ? 'Loading...' : 'No Options')}
        options={options}
        value={value}
        onChange={(vs: any) => onChange(vs)}
        placeholder={<span className="text-text-light">{placeholder}</span>}
        isClearable={isClearable}
        className={selectStyle}
        styles={customStyles(!!error)}
        onCreateOption={(v) => {
          onCreate?.(v);
        }}
        components={{ IndicatorSeparator: () => null }}
        isDisabled={loading || disabled}
        menuPortalTarget={document.body}
        // @ts-ignore
        menuPlacement={position}
      />
      {error && <div className="text-error text-xs">*{error}</div>}
    </div>
  );
};

MultiSelect.displayName = 'MultiSelect';

export default MultiSelect;

const customStyles = (error: boolean) => ({
  input: (provided: any) => ({
    ...provided,
    color: TEXT_COLOR,
    fontSize: 14,
    height: '32px',
    'input:focus': {
      boxShadow: 'none',
      borderColor: 'green',
    },
  }),
  control: (base: any, state: any) => ({
    ...base,
    boxShadow: 'none',
    color: TEXT_COLOR,
    borderRadius: 8,
    minHeight: '42px',
    maxHeight: 'auto',
    borderColor: error
      ? ERROR_COLOR
      : state.isFocused
      ? PRIMARY_COLOR
      : GREY_COLOR,
  }),
  singleValue: (base: any) => ({
    ...base,
    color: TEXT_COLOR,
  }),
  option: (base: any, { isFocused }: any) => {
    let backgroundColor = '#fff';
    let color = TEXT_COLOR;
    if (isFocused) {
      backgroundColor = PRIMARY_COLOR;
      color = '#fff';
    }
    return {
      ...base,
      backgroundColor,
      color,
      fontSize: 14,
    };
  },
  multiValue: (base: any) => {
    return {
      ...base,
      color: '#fff',
      borderRadius: 8,
      paddingLeft: 4,
      backgroundColor: PRIMARY_COLOR,
    };
  },
  multiValueLabel: (base: any) => {
    return {
      ...base,
      color: '#fff',
    };
  },
  noOptionsMessage: (base: any) => ({
    ...base,
    fontSize: 16,
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: 0,
    padding: 0,
    margin: 0,
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 99999,
  }),
});

import React from 'react';
import { useController } from 'react-hook-form';
import clx from 'classnames';
import Select from 'react-select';
import { GREY_COLOR, PRIMARY_COLOR, TEXT_COLOR } from 'utils/constants';
import usePlacesAutocomplete, {
  getGeocode,
  getZipCode,
  getLatLng,
  getDetails,
} from 'use-places-autocomplete';
import lodash from 'lodash';
import { formatAddressDetails } from 'pages/Map/utils';

type AppProps = {
  name: string;
  control: any;
  setInitial?: boolean;
  label?: string;
  placeholder?: string;
  isClearable?: boolean;
  autoFocus?: boolean;
  className?: string;
  error?: string;
  onBlur?: () => void;
  required?: boolean;
  loading?: boolean;
  onlyAddress?: boolean;
  disabled?: boolean;
};

const AddressSearch: React.FC<AppProps> = ({
  name,
  setInitial = false,
  label = '',
  control,
  placeholder = 'Search address...',
  isClearable = false,
  autoFocus = false,
  className = '',
  required = false,
  error = null,
  onBlur = () => null,
  onlyAddress = false,
  disabled = false,
}) => {
  const { field } = useController({ name, control });
  const {
    suggestions: { data, loading },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: 'us',
      },
    },
    debounce: 300,
  });

  const selectStyle = clx(
    'w-full rounded-lg outline-none focus:outline-none focus:ring-0',
    {
      'focus:border-error-dark border-error': !!error,
      'focus:border-primary border-border ': !error,
    }
  );

  const handleChangeOption = async (v: any) => {
    if (typeof v === 'string') {
      return field.onChange(onlyAddress ? '' : {});
    }
    const [geoDetails, detail] = await Promise.all([
      getGeocode({ placeId: v.value }),
      getDetails({ placeId: v.value }),
    ]);
    if (!geoDetails) {
      return null;
    }
    const data = geoDetails[0];
    const { lat, lng } = getLatLng(data);
    const zipcode = getZipCode(data, false);
    const formattedDetails = formatAddressDetails(detail);
    const payload = {
      placeId: v.value,
      latitude: parseFloat(lat.toFixed(8)),
      longitude: parseFloat(lng.toFixed(8)),
      ...formattedDetails,
      zipcode,
    };
    field.onChange(onlyAddress ? payload.formattedAddress : payload);
  };

  const options = data.map((suggestion) => ({
    label: suggestion.description,
    value: suggestion.place_id,
  }));

  const getOptions = () => {
    if (options.length) {
      return options;
    }
    if (setInitial && field.value) {
      if (typeof field.value === 'string') {
        return [
          {
            label: field.value,
            value: field.value,
          },
        ];
      } else {
        return [
          {
            label: field.value?.formattedAddress,
            value: field.value,
          },
        ];
      }
    }
    return [];
  };

  const _options = getOptions();
  return (
    <div className={`space-y-1 mb-2 ${className}`}>
      {label && (
        <div className="text-text text-sm">
          {label}
          {required && <span className="text-error">*</span>}
        </div>
      )}
      <Select
        {...field}
        onBlur={onBlur}
        autoFocus={autoFocus}
        isLoading={loading}
        isDisabled={disabled}
        options={_options}
        value={lodash.find(_options, { value: field.value })}
        onChange={handleChangeOption}
        onInputChange={(v) => setValue(v)}
        placeholder={<span className="text-text-light">{placeholder}</span>}
        isClearable={isClearable}
        className={selectStyle}
        styles={customStyles}
        noOptionsMessage={({ inputValue }) => {
          if (!inputValue) {
            return 'Start typing location...';
          }
          return 'No place found';
        }}
        menuPlacement="auto"
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
        }}
      />

      {error && <div className="text-error text-xs">*{error}</div>}
    </div>
  );
};

AddressSearch.displayName = 'AddressSearch';

export default AddressSearch;

const customStyles = {
  input: (provided: any) => ({
    ...provided,
    color: TEXT_COLOR,
    fontSize: 14,
    height: '32px',
    'input:focus': {
      boxShadow: 'none',
      borderColor: 'green',
    },
  }),
  control: (base: any, state: any) => ({
    ...base,
    boxShadow: 'none',
    color: TEXT_COLOR,
    borderRadius: 8,
    minHeight: '36px',
    height: '40px',
    borderColor: state.isFocused ? PRIMARY_COLOR : GREY_COLOR,
  }),
  singleValue: (base: any) => ({
    ...base,
    color: TEXT_COLOR,
  }),
  option: (base: any, { isFocused }: any) => {
    let backgroundColor = '#fff';
    let color = TEXT_COLOR;
    if (isFocused) {
      backgroundColor = PRIMARY_COLOR;
      color = '#fff';
    }
    return {
      ...base,
      backgroundColor,
      color,
      fontSize: 14,
    };
  },
  noOptionsMessage: (base: any) => ({
    ...base,
    fontSize: 14,
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: 0,
    padding: 0,
    margin: 0,
  }),
};

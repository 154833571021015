import React from 'react';
import Checkbox from './Checkbox';
import clx from 'classnames';
import Button, { Size, Variants } from 'components/Button';
import useModal from 'hooks/useModal';
import { useParams } from 'react-router-dom';
import { formatServerTime } from 'utils/time';
import ButtonWithMore, { Variant } from 'components/ButtonWithMore';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  approveClaim,
  denyClaim,
  undoApproveClaim,
  undoDenyClaim,
} from 'queries/job';
import toast from 'react-hot-toast';
import { readAxiosErr } from 'utils/misc';
import Confirmation from 'components/Confirmation';

type AppProps = {
  data: Record<string, any>;
  renderInTable?: boolean;
};

const ClaimApproved: React.FC<AppProps> = ({ data, renderInTable }) => {
  const { jobId = '' } = useParams();
  const queryClient = useQueryClient();
  const [deny, showDeny, closeDeny] = useModal();
  const [approve, showApprove, closeApprove] = useModal();
  const [undoDeny, showUndoDeny, closeUndoDeny] = useModal();
  const [undoApprove, showUndoApprove, closeUndoApprove] = useModal();

  const commonCallback = {
    onError: (err: any) => {
      toast.error(readAxiosErr(err));
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(['job-checklist', jobId]),
        queryClient.invalidateQueries(['job-last-checklist', jobId]),
        queryClient.invalidateQueries(['claim-detail']),
      ]);
    },
  };

  const denyMutation = useMutation(() => denyClaim(jobId), commonCallback);
  const undoDenyMutation = useMutation(
    () => undoDenyClaim(jobId),
    commonCallback
  );
  const approveMutation = useMutation(
    () => approveClaim(jobId),
    commonCallback
  );
  const undoApproveMutation = useMutation(
    () => undoApproveClaim(jobId),
    commonCallback
  );

  const notFiled = () => {
    return (
      <div>
        <div className="value">Claim Not Approved</div>
        <div className="text-xs text-error">*Claim not filed</div>
      </div>
    );
  };

  const claimDenied = () => {
    return (
      <div>
        <div className="apart">
          <div className="value">Claim Denied</div>
          <div>
            <Button
              variant={Variants.Tag}
              label="Undo"
              size={Size.Small}
              onClick={showUndoDeny}
            />
          </div>
        </div>
        <div className="-mt-1">
          <span className="key">Claim Denied By: </span>
          <span className="key">{data.denied_by?.name}</span>
        </div>
        <div className="-mt-1">
          <span className="key">Claim Denied At: </span>
          <span className="key">
            {formatServerTime({
              date: data.date,
              parseFormat: 'YYYY-MM-DDThh:mm:ssZ',
              includeTime: true,
            })}
          </span>
        </div>
      </div>
    );
  };

  const incomplete = () => {
    return (
      <div className="apart">
        <div className="value">Claim Not Approved</div>
        <div>
          <ButtonWithMore
            variant={Variant.Tag}
            label="Approve"
            onClick={showApprove}
            options={[
              {
                key: 'deny',
                label: 'Deny Claim',
                onClick: showDeny,
              },
            ]}
          />
        </div>
      </div>
    );
  };

  const complete = () => {
    return (
      <div>
        <div className="apart">
          <div className="value">Claim Approved</div>
          <div>
            <Button
              variant={Variants.Tag}
              label="Undo"
              size={Size.Small}
              onClick={showUndoApprove}
            />
          </div>
        </div>
        <div className="-mt-1">
          <span className="key">Claim Approved By: </span>
          <span className="key">{data.completed_by?.name}</span>
        </div>
        <div className="-mt-1">
          <span className="key">Claim Approved At: </span>
          <span className="key">
            {formatServerTime({
              date: data.date,
              parseFormat: 'YYYY-MM-DDThh:mm:ssZ',
              includeTime: true,
            })}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={clx({ card: !renderInTable, 'px-2 pb-1': renderInTable })}>
      <div className="flex space-x-2">
        <Checkbox checked={data.complete} />
        <div className="w-full">
          {(() => {
            if (data?.variant === 'NOT_FILED') {
              return notFiled();
            }
            if (data?.variant === 'DENIED') {
              return claimDenied();
            }
            if (data?.variant === 'NOT_APPROVED') {
              return incomplete();
            }
            return complete();
          })()}
        </div>
      </div>
      {deny && (
        <Confirmation
          open={deny}
          closeConfirm={closeDeny}
          title="Deny this claim?"
          message="Are you sure you want to deny this claim?"
          isLoading={denyMutation.isLoading}
          onClick={() => denyMutation.mutate()}
        />
      )}
      {undoDeny && (
        <Confirmation
          open={undoDeny}
          closeConfirm={closeUndoDeny}
          title="Undo Denied claim?"
          message="Are you sure you want to mark this claim not denied?"
          isLoading={undoDenyMutation.isLoading}
          onClick={() => undoDenyMutation.mutate()}
        />
      )}
      {approve && (
        <Confirmation
          open={approve}
          closeConfirm={closeApprove}
          title="Approve this claim?"
          message="Are you sure you want to approve this claim?"
          isLoading={approveMutation.isLoading}
          onClick={() => approveMutation.mutate()}
        />
      )}
      {undoApprove && (
        <Confirmation
          open={undoApprove}
          closeConfirm={closeUndoApprove}
          title="Undo Approved claim?"
          message="Are you sure you want to mark this claim as not Approved?"
          isLoading={undoApproveMutation.isLoading}
          onClick={() => undoApproveMutation.mutate()}
        />
      )}
    </div>
  );
};

export default ClaimApproved;

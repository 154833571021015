import BottomSheet from 'components/BottomSheet';
import Button, { Size, Variants } from 'components/Button';
import useModal from 'hooks/useModal';
import { cancelAdjMtg } from 'queries/job';
import React from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { readAxiosErr } from 'utils/misc';
import { formatServerTime } from 'utils/time';
import Checkbox from './Checkbox';
import ScheduleAdjMtgForm from 'components/ScheduleAdjMtg';
import Confirmation from 'components/Confirmation';
import Icon from 'components/Icon';
import clx from 'classnames';

type AppProps = {
  data: Record<string, any>;
  renderInTable?: boolean;
  job?: string;
};

const ScheduleAdjMtg: React.FC<AppProps> = ({
  data,
  renderInTable = false,
  job = '',
}) => {
  const { jobId: _jobId = '' } = useParams();
  const jobId = job || _jobId;
  const queryClient = useQueryClient();
  const [schedule, showSchedule, closeSchedule] = useModal();
  const [confirmOpen, showConfirmModal, closeConfirmModal] = useModal();
  const [actionOpen, showActions, closeActions] = useModal();

  const cancelAdjMtgMutation = useMutation(() => cancelAdjMtg(jobId), {
    onError: (err: any) => {
      toast.error(readAxiosErr(err));
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(['job-last-checklist', jobId]),
        queryClient.invalidateQueries(['job-checklist', jobId]),
      ]);
      queryClient.invalidateQueries(['job-detail', jobId]);
      queryClient.invalidateQueries(['jobs']);

      closeActions();
    },
  });

  const incomplete = () => {
    return (
      <div className="apart">
        <div className="value">Adjuster Mtg Not Scheduled</div>
        <div>
          <Button
            label="Schedule"
            size={Size.Small}
            variant={Variants.Tag}
            onClick={showSchedule}
          />
        </div>
      </div>
    );
  };

  const complete = () => {
    return (
      <div>
        <div className="apart">
          <div className="value">Adjuster Mtg Scheduled</div>
          <div>
            <Button
              variant={Variants.Tag}
              label="Actions"
              size={Size.Small}
              onClick={showActions}
            />
          </div>
        </div>
        <div className="-mt-1">
          <span className="key">Scheduled At: </span>
          <span className="key">
            {formatServerTime({
              date: data.date,
              parseFormat: 'YYYY-MM-DDThh:mm:ssZ',
              includeTime: true,
            })}
          </span>
        </div>
        <BottomSheet open={actionOpen}>
          <div className="pb-4">
            <div className="flex justify-between">
              <div className="page-heading">Actions</div>
              {!cancelAdjMtgMutation.isLoading && (
                <div>
                  <Icon name="close" size={32} onClick={closeActions} />
                </div>
              )}
            </div>
            <div className="mt-4 space-y-4">
              <Button
                variant={Variants.Tag}
                label="Reschedule Adjuster Meeting"
                size={Size.Big}
                onClick={() => showSchedule()}
                disabled={cancelAdjMtgMutation.isLoading}
              />
              <Button
                variant={Variants.ErrorNegative}
                label="Cancel Adjuster Meeting?"
                size={Size.Big}
                onClick={showConfirmModal}
                loading={cancelAdjMtgMutation.isLoading}
              />
            </div>
          </div>
        </BottomSheet>

        {confirmOpen && (
          <Confirmation
            title="Cancel Adjuster Meeting?"
            message="Are you sure you want to cancel the adjuster meeting?"
            open={confirmOpen}
            closeConfirm={closeConfirmModal}
            onClick={() => cancelAdjMtgMutation.mutate()}
            isLoading={cancelAdjMtgMutation.isLoading}
          />
        )}
      </div>
    );
  };

  return (
    <div className={clx({ card: !renderInTable, 'px-2 pb-1': renderInTable })}>
      <div className="flex space-x-2">
        <Checkbox checked={data.complete} />
        <div className="w-full">
          {data.complete ? complete() : incomplete()}
        </div>
      </div>
      {schedule && (
        <BottomSheet
          open={schedule}
          closeSheet={() => {
            closeSchedule();
            closeActions();
          }}
        >
          <ScheduleAdjMtgForm
            jobId={jobId}
            closeModal={() => {
              closeSchedule();
              closeActions();
            }}
          />
        </BottomSheet>
      )}
    </div>
  );
};

export default ScheduleAdjMtg;

import { Loader } from 'components/Icon';
import React, { useState, createContext } from 'react';
import clx from 'classnames';

export const PageLoaderContext = createContext({
  startLoader: (...args: any) => {},
  stopLoader: () => {},
});

type AppProps = {
  children: React.ReactNode;
};

const PageLoaderProvider: React.FC<AppProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('');

  const startLoader = (_title = '') => {
    setTitle(_title);
    setIsLoading(true);
  };

  const stopLoader = () => setIsLoading(false);

  return (
    <PageLoaderContext.Provider
      value={{
        startLoader,
        stopLoader,
      }}
    >
      {isLoading && (
        <div className="fixed z-[999999] top-0 bottom-0 left-0 right-0 bg-white bg-opacity-80 center">
          <div
            className={clx('flex flex-col items-center', {
              ' bg-white p-4 rounded-xl': !!title,
            })}
          >
            <Loader />
            {!!title && (
              <div className="text-xs font-bold text-primary mt-2">
                {title || 'Please wait...'}
              </div>
            )}
          </div>
        </div>
      )}
      {children}
    </PageLoaderContext.Provider>
  );
};

export default PageLoaderProvider;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  createRoofTemplate,
  updateRoofTemplate,
  useTemplateFieldConfig,
} from 'queries/rooftemplates';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FieldType } from 'utils/enum';
import { InputVariant, Layout } from 'components/Form';
import { processFields, processInitialValues, processSchema } from 'utils/form';
import toast from 'react-hot-toast';
import { actionLabel, getFNName, readAxiosErr } from 'utils/misc';
import { useNavigate } from 'react-router-dom';
import { templateTypeLabelMap } from '../../constants';
import {
  useAllProductChoices,
  useRegions,
  useSupplierChoices,
} from 'queries/company';
import SkeletonLoader, { ContentType } from 'components/SkeletonLoader';
import Icon from 'components/Icon';
import Button from 'components/Button';
import Number from 'components/Form/components/Number';
import useModal from 'hooks/useModal';
import CreateSupplier from 'pages/Suppliers/components/Create';
import TemplateTypeInfo from '../TemplateTypeInfo';
import useMedia from 'hooks/useMedia';
import clx from 'classnames';
import { estimateFormatOptions } from 'pages/RoofTemplates/utils';
import { filter } from 'lodash';

type AppProps = {
  rooftemplateId?: string;
  closeModal?: () => any;
};

interface IFormValues {
  name: string;
  estimate_format?: string;
  product?: number;
  customer_unit_price?: number;
  template_type: string;
  regions: number[];
  markup_pct: number;
  gross_margin_pct: number;
  agp_margin_pct: number;
  supplier: number;
  supplier_contact_name: string;
  supplier_contact_phone: string;
  supplier_contact_email: string;
}

const Form: React.FC<AppProps> = ({ rooftemplateId = '', closeModal }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [trade, setTrade] = useState<any>(null);
  const [showTrade, setShowTrade] = useState(false);
  const [createProduct, showCreateProduct, closeCreateProduct] = useModal();
  const [customProduct, setCustomProduct] = useState('');

  const [customSupplier, setCustomSupplier] = useState('');
  const [createSupplier, showCreateSupplier, closeCreateSupplier] = useModal();
  const [info, showInfo, closeInfo] = useModal();
  const { isMobile } = useMedia();

  const { data: config, isLoading } = useTemplateFieldConfig({
    template: rooftemplateId,
  });

  const schema = yup.object(
    processSchema(
      {
        name: yup.string(),
        template_type: yup.string(),
        estimate_format: yup.string(),
        product: yup.number(),
        customer_unit_price: yup.number(),
        regions: yup.array().of(yup.number()),
        supplier: yup.number(),
        supplier_contact_name: yup.string(),
        supplier_contact_phone: yup.string().phone('US', false),
        supplier_contact_email: yup.string(),
      },
      config
    )
  );

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IFormValues>({ resolver: yupResolver(schema) });

  const _supplier = watch('supplier');
  const _estimate_format = watch('estimate_format');
  const _template_type = watch('template_type');

  useEffect(() => {
    if (config) {
      const defaultValues = processInitialValues(config);
      reset(defaultValues);
    }
  }, [config]);

  const createMutation = useMutation(
    (formData: any) => createRoofTemplate(formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: (res: any) => {
        queryClient.invalidateQueries(['rooftemplates']);
        return navigate(`/rooftemplates/${res.id}/builder`);
      },
    }
  );

  const updateMutation = useMutation(
    (formData: any) => updateRoofTemplate(rooftemplateId, formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['rooftemplates']);
        queryClient.invalidateQueries(['rooftemplates', rooftemplateId]);
        if (isMobile) {
          return navigate(`/rooftemplates/${rooftemplateId}/builder`);
        } else {
          closeModal?.();
        }
      },
    }
  );

  const onSubmit: SubmitHandler<IFormValues> = async (formData) => {
    const { first_name, last_name } = getFNName(formData.supplier_contact_name);
    const payload = {
      ...formData,
      supplier_contact_first_name: first_name,
      supplier_contact_last_name: last_name,
    };

    if (rooftemplateId) {
      updateMutation.mutate(payload);
    } else {
      createMutation.mutate(payload);
    }
  };

  const fields = rooftemplateId
    ? []
    : [
        {
          name: 'template_type',
          type: FieldType.Select,
          label: 'Template Type',
          placeholder: 'Select Template Type',
          error: errors.template_type?.message,
          control,
          options: Object.entries(templateTypeLabelMap).map(([k, v]) => ({
            label: v,
            value: k,
          })),
          customInfo: (
            <Icon
              name="info"
              size={18}
              className="text-text-medium"
              onClick={showInfo}
            />
          ),
        },
      ];

  const markup_pct = {
    name: 'markup_pct',
    label: 'Markup %',
    type: FieldType.Number,
    placeholder: '0',
    error: errors.markup_pct?.message,
    control,
  };

  const gross_margin_pct = {
    name: 'gross_margin_pct',
    label: 'Gross Margin %',
    type: FieldType.Number,
    placeholder: '0',
    error: errors.gross_margin_pct?.message,
    control,
  };

  const agp_margin_pct = {
    name: 'agp_margin_pct',
    label: 'Adjusted Gross margin %',
    type: FieldType.Number,
    placeholder: '0',
    error: errors.agp_margin_pct?.message,
    control,
  };

  const fields2 = [
    {
      type: FieldType.Input,
      label: 'Name',
      placeholder: 'Name',
      error: errors.name?.message,
      ...register('name'),
    },
    {
      name: 'estimate_format',
      type: FieldType.Select,
      label: 'Estimate Format',
      placeholder: 'Estimate Format',
      control,
      error: errors.estimate_format?.message,
      options: estimateFormatOptions,
    },
  ];

  const fields3 = [
    {
      name: 'product',
      required: true,
      type: FieldType.AsyncSelect,
      label: 'Select Product',
      placeholder: 'Select Product',
      labelAction: (
        <div className="v-center space-x-2">
          {!showTrade && (
            <span
              className="text-xs text-primary cursor-pointer"
              onClick={() => setShowTrade(true)}
            >
              Filter By Trade
            </span>
          )}
        </div>
      ),
      error: errors?.product?.message,
      control,
      optionsPromise: useAllProductChoices,
      orderByLabel: false,
      dependencies: [trade],
      isCreatable: true,
      onCreate: (v: any) => {
        setCustomProduct(v);
        showCreateProduct();
      },
      filterFn: (choices: any) => {
        if (showTrade && trade) {
          return choices?.filter((c: any) => {
            const v = filter(c.trades, { id: trade });
            return v.length;
          });
        }
        return choices;
      },
    },
  ];

  const customer_unit_price = {
    name: 'customer_unit_price',
    type: FieldType.Number,
    placeholder: '0',
    error: errors.customer_unit_price?.message,
    control,
  };

  const fields4 = [
    {
      name: 'regions',
      type: FieldType.AsyncMultiSelect,
      label: 'Regions',
      // @ts-ignore
      error: errors.regions?.message,
      control,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      optionsPromise: () => useRegions(),
    },
    {
      name: 'supplier',
      type: FieldType.AsyncSelect,
      label: 'Supplier',
      error: errors.supplier?.message,
      control,
      isClearable: true,
      isCreatable: true,
      optionsPromise: useSupplierChoices,
      postSelect: (selected: any) => {
        setValue('supplier_contact_name', selected?.contact?.name);
        setValue('supplier_contact_email', selected?.contact?.email);
        setValue('supplier_contact_phone', selected?.contact?.phone);
      },
      onCreate: (value: string) => {
        setCustomSupplier(value);
        showCreateSupplier();
      },
    },
  ];

  const fields5 = [
    {
      type: FieldType.Input,
      label: 'Supplier Contact Name',
      placeholder: 'Supplier Contact Name',
      error: errors.supplier_contact_name?.message,
      ...register('supplier_contact_name'),
    },
  ];

  const fields6 = [
    {
      type: FieldType.Input,
      label: 'Supplier Contact Email',
      placeholder: 'jon@gmail.com',
      error: errors.supplier_contact_email?.message,
      variant: InputVariant.Email,
      ...register('supplier_contact_email'),
    },
    {
      type: FieldType.Input,
      label: 'Supplier Contact Phone',
      placeholder: '8888888888',
      error: errors.supplier_contact_phone?.message,
      ...register('supplier_contact_phone'),
    },
  ];

  if (isLoading) {
    return <SkeletonLoader contentType={ContentType.Form} size={4} />;
  }

  const isProcessing = createMutation.isLoading || updateMutation.isLoading;
  return (
    <>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Layout
            fields={processFields(fields, config)}
            loading={isProcessing}
          />
          {(_template_type === 'c' || !!config?.markup_pct?.initial) && (
            <Number {...markup_pct} required className="mt-4" />
          )}
          {(_template_type === 'g' || !!config?.gross_margin_pct?.initial) && (
            <Number {...gross_margin_pct} required className="mt-4" />
          )}
          {(_template_type === 'a' || !!config?.agp_margin_pct?.initial) && (
            <Number {...agp_margin_pct} required className="mt-4" />
          )}
          <Layout
            fields={processFields(fields2, config)}
            loading={isProcessing}
            className="space-y-4 mt-4"
          />
          {_estimate_format === 's' && (
            <Layout
              fields={processFields(fields3, config)}
              loading={isProcessing}
              className="space-y-4 mt-4"
            />
          )}
          {_estimate_format === 's' && _template_type === 'f' && (
            <div className="mt-3.5">
              <div className="text-sm">
                Customer Price <span className="text-error text-xs">*</span>
              </div>
              <div className="v-center space-x-1">
                <Icon name="dollar" />
                <div className="flex-1">
                  <Number {...customer_unit_price} label="" />
                </div>
                <span className="px-2 text-2xl font-bold">/</span>
                <div className="flex-1 text-lg font-bold">SQ</div>
              </div>
            </div>
          )}
          <Layout
            fields={processFields(fields4, config)}
            loading={isProcessing}
            className="space-y-4 mt-4"
          />
          {!!_supplier && (
            <Layout
              fields={processFields(fields5, config)}
              loading={isProcessing}
              className="space-y-4 mt-4"
            />
          )}
          {!!_supplier && (
            <Layout
              fields={processFields(fields6, config)}
              loading={isProcessing}
              className={clx('mt-4', {
                ' grid grid-cols-2 gap-4': !isMobile,
                'space-y-4': isMobile,
              })}
            />
          )}
          <Button
            label={actionLabel(rooftemplateId, 'Roof Template', isProcessing)}
            className="mt-8"
            loading={isProcessing}
          />
        </form>
        {createSupplier && (
          <CreateSupplier
            open={createSupplier}
            closeModal={closeCreateSupplier}
            initial={{ name: customSupplier }}
            onSuccess={(res) => {
              const supplierContact = res.primary_supplier_contact;
              setValue('supplier', res.supplier.id);
              setValue('supplier_contact_email', supplierContact?.email);
              setValue('supplier_contact_phone', supplierContact?.phone);
              setValue('supplier_contact_name', supplierContact?.name);
            }}
          />
        )}
      </div>
      {info && <TemplateTypeInfo open={info} closeModal={closeInfo} />}
    </>
  );
};

export default Form;

import BottomSheet from 'components/BottomSheet';
import Button, { Size, Variants } from 'components/Button';
import Icon from 'components/Icon';
import useMedia from 'hooks/useMedia';
import useModal from 'hooks/useModal';
import CreateManualRoofLayout from 'pages/JobDetail/components/CreateManualRoofLayout/ModalForm';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type AppProps = {
  open: boolean;
  closeModal: () => any;
  job: string;
};

const CreateRoofMeasurement: React.FC<AppProps> = ({
  open,
  job,
  closeModal,
}) => {
  const navigate = useNavigate();
  const { isMobile } = useMedia();
  const [createModal, showCreateModal, closeCreateModal] = useModal();

  return (
    <>
      <BottomSheet open={open} closeSheet={closeModal}>
        <div>
          <div className="flex justify-between mb-6">
            <div className="text-xl text-text font-bold">
              No Roof Measurement Found
            </div>
            <div>
              <Icon
                name="close"
                size={30}
                onClick={closeModal}
                className="cursor-pointer"
              />
            </div>
          </div>
          <div>You need a Roof Measurement to proceed with this action.</div>

          <div className="flex justify-end space-x-4 mt-8">
            <div>
              <Button
                size={Size.Medium}
                label="Close"
                variant={Variants.Error}
                onClick={closeModal}
              />
            </div>
            <div>
              <Button
                label="Create Roof Measurement"
                size={Size.Medium}
                onClick={() =>
                  isMobile
                    ? navigate(`/jobs/${job}/manualrooflayouts/create`)
                    : showCreateModal()
                }
              />
            </div>
          </div>
        </div>
      </BottomSheet>
      {createModal && (
        <CreateManualRoofLayout
          open={createModal}
          closeModal={closeCreateModal}
        />
      )}
    </>
  );
};

export default CreateRoofMeasurement;

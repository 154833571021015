import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import apiService from 'utils/apiService';

// ******** Get Emails For Job *********
export const getEmails = async ({ pageParam = 1, ...q }) => {
  const { data } = await apiService.get('/api/light/emails/', {
    ...q,
    page: pageParam,
  });
  return data;
};

export const useEmails = (q: Record<string, any>) => {
  return useInfiniteQuery(
    ['emails', q],
    ({ pageParam }) => getEmails({ pageParam, ...q }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next_page ?? undefined;
      },
      staleTime: 15 * 60 * 1000,
    }
  );
};

export const useDesktopEmails = (q: Record<string, any>, page = 1) => {
  return useQuery(
    ['desktop-emails', q, { page }],
    () => getEmails({ pageParam: page, ...q }),
    {
      staleTime: 15 * 60 * 1000,
      keepPreviousData: true,
    }
  );
};

// ********* Get Email Detail *********
const getEmailDetail = async (id: string, job: string) => {
  const { data } = await apiService.get(`/api/light/emails/${id}/`, { job });
  return data;
};

export const useEmailDetail = (id: string, job: string) => {
  return useQuery(['email-detail', id], () => getEmailDetail(id, job), {
    staleTime: 15 * 60 * 1000,
  });
};

// ********** Get Email field config **********
const getEmailFieldConfig = async (q?: Record<string, any>) => {
  const { data } = await apiService.get(`/api/light/emails/fields_config/`, q);
  return data;
};

export const useEmailFieldConfig = (q?: Record<string, any>) => {
  return useQuery(['email-field-config', q], () => getEmailFieldConfig(q), {
    staleTime: Infinity,
  });
};

// ****** Create Email *******
export const createEmail = async (
  payload: Record<string, any>,
  q: Record<string, any>
) => {
  const { data } = await apiService.post('/api/light/emails/', payload, q);
  return data;
};

// ******** Get SentEmails For Job *********
export const getSentEmails = async ({ pageParam = 1, ...q }) => {
  const { data } = await apiService.get('/api/light/sent-emails/', {
    ...q,
    page: pageParam,
  });
  return data;
};

export const useSentEmails = (q: Record<string, any>) => {
  return useInfiniteQuery(
    ['sent-emails', q],
    ({ pageParam }) => getSentEmails({ pageParam, ...q }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next_page ?? undefined;
      },
      staleTime: 15 * 60 * 1000,
    }
  );
};

export const useDesktopSentEmails = (q: Record<string, any>, page = 1) => {
  return useQuery(
    ['sent-emails', 'desktop', q, { page }],
    () => getSentEmails({ pageParam: page, ...q }),
    {
      staleTime: 15 * 60 * 1000,
      keepPreviousData: true,
    }
  );
};

const getSentEmailDetail = async (id: number) => {
  const { data } = await apiService.get(`/api/light/sent-emails/${id}/`);
  return data;
};

export const useSentEmailDetail = (id: number) => {
  return useQuery(
    ['sent-emails', id?.toString()],
    () => getSentEmailDetail(id),
    {
      staleTime: Infinity,
    }
  );
};

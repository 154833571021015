import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import apiService from 'utils/apiService';

const getClaimDetail = async (id: string) => {
  const { data } = await apiService.get(`/api/light/insurance/claims/${id}/`);
  return data;
};

export const useClaimDetail = (id: string) => {
  return useQuery(['claim-detail', id], () => getClaimDetail(id), {
    staleTime: 15 * 60 * 1000,
  });
};

// ********** Get claim field config **********
const getClaimFieldConfig = async (q: Record<string, any>) => {
  const { data } = await apiService.get(
    `/api/light/insurance/claims/fields_config/`,
    q
  );
  return data;
};

interface IFieldConfig {
  job?: string;
  claim?: string;
}

export const useClaimFieldConfig = (q: IFieldConfig = {}) => {
  const { job, claim } = q;
  return useQuery(
    ['claim-field-config', { job, claim }],
    () => getClaimFieldConfig({ job, claim }),
    {
      staleTime: claim ? 0 : Infinity,
    }
  );
};

const getTypeOfLossChoices = async () => {
  const { data } = await apiService.get(
    '/api/light/insurance/claims/type_of_loss_choices/'
  );
  return data;
};

export const useTypeOfLossChoices = (enabled = true) => {
  return useQuery(['type-of-loss-choices'], getTypeOfLossChoices, {
    enabled,
    staleTime: Infinity,
  });
};

const getClaimStatusChoices = async () => {
  const { data } = await apiService.get(
    '/api/light/insurance/claims/claim_status_choices/'
  );
  return data;
};

export const useClaimStatusChoices = (enabled = true) => {
  return useQuery(['claim-status-choices'], getClaimStatusChoices, {
    enabled,
    staleTime: Infinity,
  });
};

// ****** Create claim *******
export const createClaim = async (payload: Record<string, any>) => {
  const { data } = await apiService.post(
    '/api/light/insurance/claims/',
    payload
  );
  return data;
};

export const updateClaim = async (id: string, payload: Record<string, any>) => {
  const { data } = await apiService.put(
    `/api/light/insurance/claims/${id}/`,
    payload
  );
  return data;
};

export const deleteClaim = async (id: string) => {
  await apiService.delete(`/api/light/insurance/claims/${id}/`);
};

// ******** Insurance company

export const getInsuranceCompanies = async ({ pageParam = 1, ...q }) => {
  const { data } = await apiService.get('/api/light/insurance-companies/', {
    ...q,
    page: pageParam,
  });
  return data;
};

export const useInsuranceCompanies = (q?: Record<string, any>) => {
  return useInfiniteQuery(
    ['insurance-companies', q],
    ({ pageParam }) => getInsuranceCompanies({ pageParam, ...q }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next_page ?? undefined;
      },
      staleTime: 5 * 60 * 1000,
    }
  );
};

export const useDesktopInsuranceCompanies = (
  q?: Record<string, any>,
  page = 1
) => {
  return useQuery(
    ['insurance-companies', 'desktop', q, { page }],
    () => getInsuranceCompanies({ pageParam: page, ...q }),
    {
      staleTime: 15 * 60 * 1000,
      keepPreviousData: true,
    }
  );
};

const getInsuranceFieldConfig = async (q: Record<string, any>) => {
  const { data } = await apiService.get(
    '/api/light/insurance-companies/fields_config/',
    q
  );
  return data;
};

interface IInsuranceCompanyFieldConfig {
  insurance_company?: string;
}

export const useInsuranceCompanyFieldConfig = (
  q: IInsuranceCompanyFieldConfig = {}
) => {
  const { insurance_company } = q;
  return useQuery(
    ['insurance-companies-field-config', { insurance_company }],
    () => getInsuranceFieldConfig({ insurance_company }),
    {
      staleTime: insurance_company ? 0 : Infinity,
    }
  );
};

export const createInsuranceCompany = async (payload: Record<string, any>) => {
  const { data } = await apiService.post(
    '/api/light/insurance-companies/',
    payload
  );
  return data;
};

export const updateInsuranceCompany = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/light/insurance-companies/${id}/`,
    payload
  );
  return data;
};

export const replaceAndDeleteInsuranceCompany = async (
  id: number,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/insurance-companies/${id}/replace_and_delete/`,
    payload
  );

  return data;
};

// ******** Mortgage company

export const getMortgageCompanies = async ({ pageParam = 1, ...q }) => {
  const { data } = await apiService.get('/api/light/mortgage-companies/', {
    ...q,
    page: pageParam,
  });
  return data;
};

export const useMortgageCompanies = (q?: Record<string, any>) => {
  return useInfiniteQuery(
    ['mortgage-companies', q],
    ({ pageParam }) => getMortgageCompanies({ pageParam, ...q }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next_page ?? undefined;
      },
      staleTime: 5 * 60 * 1000,
    }
  );
};

export const useDesktopMortgageCompanies = (
  q?: Record<string, any>,
  page = 1
) => {
  return useQuery(
    ['mortgage-companies', 'desktop', q, { page }],
    () => getMortgageCompanies({ pageParam: page, ...q }),
    {
      staleTime: 15 * 60 * 1000,
      keepPreviousData: true,
    }
  );
};

const getMortgageFieldConfig = async (q: Record<string, any>) => {
  const { data } = await apiService.get(
    '/api/light/mortgage-companies/fields_config/',
    q
  );
  return data;
};

interface IMortgageCompanyFieldConfig {
  mortgage_company?: string;
}

export const useMortgageCompanyFieldConfig = (
  q: IMortgageCompanyFieldConfig = {}
) => {
  const { mortgage_company } = q;
  return useQuery(
    ['mortgage-companies-field-config', { mortgage_company }],
    () => getMortgageFieldConfig({ mortgage_company }),
    {
      staleTime: mortgage_company ? 0 : Infinity,
    }
  );
};

export const createMortgageCompany = async (
  payload: Record<string, any>,
  q: Record<string, any>
) => {
  const { data } = await apiService.post(
    '/api/light/mortgage-companies/',
    payload,
    q
  );
  return data;
};

export const updateMortgageCompany = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/light/mortgage-companies/${id}/`,
    payload
  );
  return data;
};

export const replaceAndDeleteMortgageCompany = async (
  id: number,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/mortgage-companies/${id}/replace_and_delete/`,
    payload
  );

  return data;
};

// ******** Claim users

const getClaimUserFieldConfig = async (
  claimId: string,
  q: Record<string, any>
) => {
  const { data } = await apiService.get(
    `/api/light/insurance/claims/${claimId}/claim_users_fields_config/`,
    q
  );
  return data;
};

interface IClaimUserFieldConfig {
  user_type: string;
}

export const useClaimUserFieldConfig = (
  claimId: string,
  q: IClaimUserFieldConfig
) => {
  return useQuery(
    ['claim-user-field-config', { claim: claimId, ...q }],
    () => getClaimUserFieldConfig(claimId, q),
    {
      staleTime: 0,
    }
  );
};

export const addOrEditClaimUser = async (
  claimId: string,
  payload: Record<string, any>,
  q: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/insurance/claims/${claimId}/add_user/`,
    payload,
    q
  );
  return data;
};

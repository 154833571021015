import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import apiService from 'utils/apiService';

// ******** Get Notes For Job *********
export const getNotes = async ({ pageParam = 1, ...q }) => {
  const { data } = await apiService.get('/api/light/notes/', {
    ...q,
    page: pageParam,
  });
  return data;
};

export const useNotes = (q: Record<string, any>) => {
  return useInfiniteQuery(
    ['notes', q],
    ({ pageParam }) => getNotes({ pageParam, ...q }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next_page ?? undefined;
      },
      staleTime: 5 * 60 * 1000,
    }
  );
};

export const getMyNotes = async () => {
  const { data } = await apiService.get(`/api/light/user-notes/`);
  return data;
};

export const useMyNotes = (enabled = true) => {
  return useQuery(['my-notes'], () => getMyNotes(), {
    staleTime: 15 * 60 * 1000,
    enabled,
  });
};

export const getNoteDetail = async (id: number) => {
  const { data } = await apiService.get(`/api/light/notes/${id}/`);
  return data;
};

export const useNoteDetail = (id: number) => {
  return useQuery(['note-detail', id], () => getNoteDetail(id), {
    staleTime: 30 * 60 * 1000,
  });
};

// ********** Get Note field config **********
const getNoteFieldConfig = async (q?: Record<string, any>) => {
  const { data } = await apiService.get(`/api/light/notes/fields_config/`, q);
  return data;
};

export const useNoteFieldConfig = (q?: Record<string, any>) => {
  return useQuery(['note-field-config', q], () => getNoteFieldConfig(q), {
    staleTime: Infinity,
  });
};

// ****** Create note *******
export const createNote = async (payload: Record<string, any>, q = {}) => {
  const { data } = await apiService.post('/api/light/notes/', payload, q);
  return data;
};

export const createReply = async (
  noteId: number,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/notes/${noteId}/reply/`,
    payload
  );
  return data;
};

export const archiveThread = async (id: string) => {
  const { data } = await apiService.get(`/api/notes/${id}/archive_thread/`);
  return data;
};

export const unarchiveThread = async (id: string) => {
  const { data } = await apiService.get(`/api/notes/${id}/unarchive_thread/`);
  return data;
};

export const markAsRead = async (id: string) => {
  const { data } = await apiService.get(`/api/notes/${id}/mark_as_read/`);
  return data;
};

export const markAllAsRead = async () => {
  const { data } = await apiService.get(`/api/notes/mark_all_as_read/`);
  return data;
};

export const getUserNotes = async ({ pageParam = 1, ...q }) => {
  const { data } = await apiService.get('/api/user-notes/', {
    ...q,
    page: pageParam,
  });
  return data;
};

export const useUserNotes = (q: Record<string, any>) => {
  return useInfiniteQuery(
    ['user-notes', q],
    ({ pageParam }) => getUserNotes({ pageParam, ...q }),
    {
      getNextPageParam: (lastPage) => {
        const next_page = lastPage.next;
        if (next_page) {
          return next_page?.split(`&page=`)[2];
        } else {
          return undefined;
        }
      },
      staleTime: 5 * 60 * 1000,
    }
  );
};

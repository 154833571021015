import { Loader } from 'components/Icon';
import React from 'react';
import Lottie from 'react-lottie';
import * as roofLoaderData from './data/roof_loader.json';
import clx from 'classnames';

const lottieLoader = {
  roof: roofLoaderData,
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: lottieLoader.roof,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export enum Variant {
  RoofLoader = 'roofloader',
  FullScreen = 'fullscreen',
  Fill = 'fill',
}

type AppProps = {
  label?: string;
  variant?: Variant;
  topClass?: string;
};

const RLoader: React.FC<AppProps> = ({
  label,
  variant = Variant.Fill,
  topClass = 'top-14',
}) => {
  if (variant === Variant.RoofLoader) {
    return (
      <div
        className={clx(
          'fixed bg-white bg-opacity-50 bottom-0 left-0 right-0',
          topClass
        )}
      >
        <div className="h-full flex flex-col justify-center items-center">
          <Lottie options={defaultOptions} height={120} width={120} />
          {label && <p className="text-text-medium -mt-4 text-sm">{label}</p>}
        </div>
      </div>
    );
  }

  const containerStyle = clx({
    'fixed bg-white bg-opacity-50 top-0 bottom-0 left-0 right-0':
      variant === Variant.FullScreen,
    'h-full w-full': variant === Variant.Fill,
  });

  return (
    <div className={containerStyle}>
      <div className="h-full flex flex-col justify-center items-center">
        <Loader className="text-primary" />
        {label && <p className="text-text-medium mt-2 text-sm">{label}</p>}
      </div>
    </div>
  );
};

export default RLoader;

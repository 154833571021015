import React from 'react';
import clx from 'classnames';

type AppProps = {
  label: string | React.ReactNode;
  name: string;
  className?: string;
  error?: string;
  loading?: boolean;
  size?: string;
  onChange?: (s: any) => void;
  isChecked?: boolean;
  isPreserveState?: boolean;
};

const RadioButton = React.forwardRef<HTMLInputElement, AppProps>(
  (
    {
      name,
      label,
      onChange,
      className = '',
      error = null,
      loading = false,
      size = 'h-4 w-4',
      isChecked = false,
      isPreserveState = false,
    },
    ref
  ) => {
    return (
      <div className={`space-y-1 mb-2 ${className}`}>
        <label className="flex items-end space-x-2">
          <input
            type="radio"
            className={clx(
              'outline-none focus:outline-none focus:ring-0 rounded-full',
              size
            )}
            name={name}
            onChange={onChange}
            ref={ref}
            disabled={loading}
            {...(isPreserveState && { checked: isChecked })}
          />
          <div className="text-sm text-text relative top-[2px]">{label}</div>
        </label>
        {error && <div className="text-error text-xs">*{error}</div>}
      </div>
    );
  }
);

RadioButton.displayName = 'RadioButton';

export default RadioButton;

import React, { useEffect, useRef } from 'react';
import { useController } from 'react-hook-form';
import clx from 'classnames';
import RDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { now, setHours } from 'utils/time';
import useMedia from 'hooks/useMedia';
import { Portal } from 'react-portal';

export enum Variant {
  Date = 'date',
  DateTime = 'datetime',
}

type AppProps = {
  label: string;
  name: string;
  placeholder?: string;
  isClearable?: boolean;
  className?: string;
  control: any;
  error?: string;
  required?: boolean;
  loading?: boolean;
  showPastDates?: boolean;
  showTimeSelect?: boolean;
  showFutureDates?: boolean;
  showTimeSelectOnly?: boolean;
  dateFormat?: string;
  height?: string;
  disabled?: boolean;
  readOnly?: boolean;
  range?: boolean;
};

const CalendarContainer = ({ children }: any) => {
  return (
    <Portal>
      <div className="w-full absolute z-[99999]">{children}</div>
    </Portal>
  );
};

const DatePicker: React.FC<AppProps> = ({
  name,
  label,
  control,
  placeholder = 'Select Date/Time',
  isClearable = false,
  className = '',
  error = null,
  required = false,
  loading = false,
  showPastDates = false,
  showTimeSelect = true,
  showFutureDates = true,
  showTimeSelectOnly = false,
  dateFormat = 'MM/dd/yyyy h:mm aa',
  height = 'h-10',
  readOnly = false,
  disabled = false,
  range = false,
}) => {
  const pickerRef = useRef<any>(null);
  const { isMobile } = useMedia();

  const { field } = useController({ name, control });

  const inputStyle = clx(
    'w-full outline-none placeholder:text-text-light text-text text-sm rounded-lg focus:outline-none focus:ring-0',
    height,
    {
      'focus:border-error-dark border-error': !!error,
      'focus:border-primary border-border ': !error,
    }
  );

  useEffect(() => {
    if (isMobile && pickerRef.current !== null) {
      pickerRef.current.input.readOnly = true;
    }
  }, [isMobile, pickerRef]);

  return (
    <div className={`space-y-1 mb-2 ${className}`}>
      <div className="text-text text-sm">
        {label}
        {required && <span className="text-error">*</span>}
      </div>
      {readOnly ? (
        <div className="border border-border rounded-lg px-2 py-1.5">
          {field.value}
        </div>
      ) : (
        <RDatePicker
          {...field}
          // portalId="root-portal"
          ref={pickerRef}
          placeholderText={placeholder}
          className={inputStyle}
          name={name}
          selectsRange={range}
          autoComplete="off"
          selected={field.value}
          onChange={(d) => field.onChange(d)}
          showTimeSelectOnly={showTimeSelectOnly}
          showTimeSelect={showTimeSelect}
          autoFocus={false}
          preventOpenOnFocus
          minDate={showPastDates ? undefined : now()}
          maxDate={showFutureDates ? undefined : now()}
          minTime={setHours(7)}
          maxTime={setHours(20)}
          timeFormat="hh:mm a"
          timeIntervals={30}
          disabled={loading || disabled}
          isClearable={isClearable}
          popperClassName="z-[99999]"
          showPopperArrow
          clearButtonClassName="h-8 w-8 bg-yellow-100"
          dateFormat={dateFormat}
          popperContainer={CalendarContainer}
        />
      )}
      {error && <div className="text-error text-xs">*{error}</div>}
    </div>
  );
};

DatePicker.displayName = 'DatePicker';

export default DatePicker;

import BottomSheet from 'components/BottomSheet';
import Button, { Size, Variants } from 'components/Button';
import useModal from 'hooks/useModal';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CreateEstimate from '../../../../../components/CreateEstimate';
import Checkbox from './Checkbox';
import Icon from 'components/Icon';
import { useQueryClient } from '@tanstack/react-query';
import clx from 'classnames';

type AppProps = {
  data: Record<string, any>;
  renderInTable?: boolean;
};

const Estimate: React.FC<AppProps> = ({ data, renderInTable = false }) => {
  const navigate = useNavigate();
  const { jobId = '' } = useParams();
  const [actionOpen, showActions, closeActions] = useModal();
  const queryClient = useQueryClient();

  const sumoquote = () => {
    return (
      <div className="apart">
        <div className="value relative -left-2">Waiting for sumoquote...</div>
        <div>
          <Button
            label="Refresh"
            size={Size.Small}
            variant={Variants.Tag}
            onClick={() => {
              queryClient.invalidateQueries(['job-checklist', jobId]);
              queryClient.invalidateQueries(['job-detail', jobId]);
            }}
          />
        </div>
      </div>
    );
  };

  const incomplete = () => {
    return (
      <>
        <div className="apart">
          <div className="value">Estimate Not Created</div>
          <div>
            <Button
              label="Create"
              size={Size.Small}
              variant={Variants.Tag}
              onClick={showActions}
            />
          </div>
        </div>
        <BottomSheet open={actionOpen} maxWidth="max-w-2xl">
          <CreateEstimate jobId={jobId} closeModal={closeActions} />
        </BottomSheet>
      </>
    );
  };

  const complete = () => {
    return (
      <div className="apart">
        <div className="value">Estimate Created</div>
        <div>
          <Button
            label="View"
            size={Size.Small}
            variant={Variants.Tag}
            onClick={() => navigate(`/jobs/${jobId}/estimates/${data.id}`)}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={clx({ card: !renderInTable, 'px-2 pb-1': renderInTable })}>
      <div className="flex space-x-2">
        {data.type === 'sumoquote' ? (
          <Icon
            name="sumoquote"
            className="w-6 relative -left-[2px] bottom-[2px]"
          />
        ) : (
          <Checkbox checked={data.complete} />
        )}
        <div className="w-full">
          {(() => {
            if (data.type === 'sumoquote') {
              return sumoquote();
            }
            if (data.complete) {
              return complete();
            }
            return incomplete();
          })()}
        </div>
      </div>
    </div>
  );
};

export default Estimate;

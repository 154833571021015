import { Loader } from 'components/Icon';
import useMedia from 'hooks/useMedia';
import { find, truncate } from 'lodash';
import React, { useMemo, useState } from 'react';

type AppProps = {
  photo: Record<string, any>;
  selectedPhotos: any[];
  handleSelect: (...args: any) => any;
};

const PhotoCard: React.FC<AppProps> = ({
  photo,
  selectedPhotos,
  handleSelect,
}) => {
  const [error, setError] = useState(false);

  const imgUrl = useMemo(() => {
    if (error) {
      return photo.url;
    }
    return photo.thumb || photo.url;
  }, [error, photo]);

  return (
    <div
      key={photo.value}
      className="border rounded-md p-2 relative"
      onClick={() => handleSelect(photo)}
    >
      <div className="absolute -top-1 left-1">
        <input
          type="checkbox"
          className="rounded-md"
          checked={!!find(selectedPhotos, { value: photo.value })}
          onClick={() => null}
        />
      </div>
      <img
        src={imgUrl}
        className="h-32 w-32 object-cover"
        onError={() => setError(true)}
      />
      <div className="absolute left-0 right-0 bottom-0 px-1 py-1 bg-black bg-opacity-70 rounded-b-md">
        <div className="text-white text-xs">
          {truncate(photo.display_name, { length: 40 })}
        </div>
      </div>
    </div>
  );
};

export default PhotoCard;

import { useMutation } from '@tanstack/react-query';
import Button, { Size, Variants } from 'components/Button';
import Modal, { Variant } from 'components/Modal';
import useAuth from 'hooks/useAuth';
import { logout } from 'queries/user';
import React from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { reset } from 'utils/localstorage';
import { readAxiosErr } from 'utils/misc';

type AppProps = {
  open: boolean;
};

const SubscriptionExpired: React.FC<AppProps> = ({ open }) => {
  const { userInfo, resetAuth } = useAuth();
  const navigate = useNavigate();
  // const queryClient = useQueryClient();

  // const logoutAction = useMutation(() => logout(), {
  //   onError: (err: any) => {
  //     toast.error(readAxiosErr(err));
  //   },
  //   onSuccess: () => {
  //     // queryClient.clear();
  //     resetAuth();
  //     return navigate('/login');
  //   },
  // });

  return (
    <Modal open={open} variant={Variant.Center}>
      <div>
        <div className="text-xl font-bold text-text">
          Your subscription has expired
        </div>
        <div className="pb-2 pt-3">
          Hey {userInfo?.first_name}, your subscription has expired. To keep
          using rooflink, kindly get in touch with customer support at{' '}
          <a href="mailto:support@roof.link" className="text-primary">
            support@roof.link
          </a>
          .
        </div>
        <div className="flex justify-end space-x-4 mt-4">
          <div>
            <Button
              label="logout"
              leftIcon="logout"
              size={Size.Medium}
              variant={Variants.Error}
              onClick={() => {
                reset();
                window.location.href = '/login';
              }}
            />
          </div>
          <div>
            <a href="mailto:support@roof.link">
              <Button
                label="Contat Support"
                leftIcon="support"
                size={Size.Medium}
              />
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SubscriptionExpired;

import PageBack from 'components/PageBack';
import { useTemplateDetail } from 'queries/rooftemplates';
import React, { useState } from 'react';
import Loader from 'components/Loader';
import { useParams } from 'react-router-dom';
import RoofTemplateOptions from 'pages/RoofTemplates/components/RoofTemplateOptions';
import Tag from 'components/Tag';
import { templateTypeLabelMap } from 'pages/RoofTemplates/constants';
import Icon from 'components/Icon';
import Lineitems from './components/Lineitems';
import useMedia from 'hooks/useMedia';
import clx from 'classnames';
import { formatAmt } from 'utils/misc';
import Accessories from './components/Accessories';

const templateTypeMap: any = {
  c: {
    label: 'Cost Plus',
    pct: 'markup_pct',
    pct_label: 'Markup pct',
  },
  g: {
    label: 'Gross Margin',
    pct: 'gross_margin_pct',
    pct_label: 'Gross Margin pct',
  },
  a: {
    label: 'Adjusted Margin',
    pct: 'agp_margin_pct',
    pct_label: 'Adjusted Margin pct',
  },
  f: {
    label: 'Fixed Price',
    pct: null,
  },
};

type AppProps = {
  template?: string;
  readOnly?: boolean;
};

const RoofTemplateBuilder: React.FC<AppProps> = ({
  template,
  readOnly = false,
}) => {
  const { templateId = '' } = useParams();
  const [jobType, setJobType] = useState<any>(null);
  const { isMobile } = useMedia();

  const _templateId = template || templateId;
  const { data, isLoading } = useTemplateDetail(_templateId, {
    job_type: jobType?.value,
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <div
        className={clx('card rounded-lg shadow-sm mb-2 relative', {
          ' p-2.5': !isMobile,
          'px-2 pt-2 pb-1': isMobile,
        })}
      >
        <div
          className={clx('apart items-start relative', {
            'px-2': !isMobile,
          })}
        >
          <div>
            {!readOnly && (
              <PageBack label="Roof Templates" to={`/rooftemplates`} />
            )}
            <div
              className={clx('v-center space-x-2', {
                'text-2xl': !isMobile,
                'text-xl': isMobile,
              })}
            >
              <div>{data.name}</div>
              {!isMobile && (
                <div className="text-sm font-bold text-text relative top-[2px] px-2 py-1 bg-primary-negative rounded-md shadow-sm">
                  {templateTypeMap[data.template_type].label}{' '}
                  {data.template_type !== 'f' && ' | '}
                  {data[templateTypeMap[data.template_type].pct]}{' '}
                  {data.template_type !== 'f' && '%'}
                </div>
              )}
            </div>
          </div>

          {!readOnly && (
            <div className="mt-2">
              <RoofTemplateOptions rooftemplate={data} iconSize={20} />
            </div>
          )}
        </div>
      </div>
      <div
        className={clx('card !cursor-default', {
          'mx-2': !isMobile,
          '!pt-0': isMobile,
        })}
      >
        <div className="apart">
          <div className="space-y-2.5">
            <div className="v-center space-x-1">
              <div className="key">Type:</div>
              <div className="value">
                {templateTypeLabelMap[data?.template_type]} Template
              </div>
            </div>
            {templateTypeMap[data?.template_type]?.pct && (
              <div className="v-center space-x-1">
                <div className="key">
                  {templateTypeMap[data?.template_type]?.pct_label}:
                </div>
                <div className="value">
                  {data[templateTypeMap[data?.template_type].pct]} %
                </div>
              </div>
            )}
            <div className="v-center space-x-1">
              <div className="key">Estimate Format:</div>
              <div className="value">
                {data?.estimate_format === 'd'
                  ? 'Detailed Lineitems'
                  : 'Single Lineitem'}
              </div>
            </div>
            {data?.estimate_format === 's' && (
              <div className="v-center space-x-1">
                <div className="key">Product:</div>
                <div className="value">{data?.product_name}</div>
              </div>
            )}
            {data?.estimate_format === 's' && data?.template_type === 'f' && (
              <div className="v-center space-x-1">
                <div className="key">Customer Price:</div>
                <div className="value">
                  {formatAmt(data?.customer_unit_price)}
                </div>
              </div>
            )}
            <div className="v-center space-x-1">
              <div className="key">Supplier:</div>
              <div className="value">{data?.supplier?.name}</div>
            </div>
            <div className="v-center space-x-1">
              <div className="key">Supplier Contact:</div>
              <div className="value v-center space-x-4">
                <span>{data?.supplier_contact?.name}</span>
                {data?.supplier_contact?.email && (
                  <a href={`mailto:${data.supplier_contact.email}`}>
                    <Icon name="email" size={20} className="text-primary" />
                  </a>
                )}
                {data?.supplier_contact?.phone && (
                  <a href={`tel:${data.supplier_contact.phone}`}>
                    <Icon name="phone" size={18} className="text-primary" />
                  </a>
                )}
              </div>
            </div>
            <div className="v-center space-x-1">
              <div className="key">Regions:</div>
              <div className="v-center">
                {data?.regions?.map((r: any) => (
                  <Tag name={r.name} key={r.id} className="py-[3px]" />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Lineitems
        data={data}
        jobType={jobType}
        setJobType={setJobType}
        readOnly={readOnly}
      />
      <Accessories data={data} readOnly={readOnly} />
    </div>
  );
};

export default RoofTemplateBuilder;

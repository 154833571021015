import { useMutation } from '@tanstack/react-query';
import BottomSheet from 'components/BottomSheet';
import Icon from 'components/Icon';
import usePageLoader from 'hooks/usePageLoader';
import { copyJob } from 'queries/job';
import React from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { readAxiosErr } from 'utils/misc';

type AppProps = {
  id: number | string;
  open: boolean;
  closeModal: () => any;
};

const CopyJob: React.FC<AppProps> = ({ id, open, closeModal }) => {
  const navigate = useNavigate();
  const { startLoader, stopLoader } = usePageLoader();

  const copyMutation = useMutation((formData: any) => copyJob(id, formData), {
    onMutate: () => {
      startLoader('It will take a moment. Please wait...');
    },
    onError: (err: any) => {
      toast.error(readAxiosErr(err));
    },
    onSuccess: (res: any) => {
      stopLoader();
      closeModal();
      navigate(`/jobs/${res?.id}`);
    },
  });

  return (
    <BottomSheet open={open} closeSheet={closeModal}>
      <div className="pb-4">
        <div className="apart">
          <div className="text-xl font-bold">Copy Job</div>
          <Icon name="close" size={24} onClick={closeModal} />
        </div>
        <div className="mt-4">
          <div
            className="option"
            onClick={() => {
              copyMutation.mutate({ create_job_under: 'same_customer' });
            }}
          >
            Keep Same Customer
          </div>
          <div
            className="option"
            onClick={() => {
              copyMutation.mutate({ create_job_under: 'new_customer' });
            }}
          >
            Create New Customer
          </div>
        </div>
      </div>
    </BottomSheet>
  );
};

export default CopyJob;

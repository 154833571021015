import Button, { Size } from 'components/Button';
import Icon from 'components/Icon';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatAmt, readAxiosErr } from 'utils/misc';
import clx from 'classnames';
import useAuth from 'hooks/useAuth';
import useModal from 'hooks/useModal';
import BottomSheet from 'components/BottomSheet';
import CreateEstimate from 'components/CreateEstimate';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { parseEpochAndFormat } from 'utils/time';
import { removeSumoquote } from 'queries/estimates';
import toast from 'react-hot-toast';
import Confirmation from 'components/Confirmation';

type AppProps = {
  job: Record<string, any>;
};

const EstimateBox: React.FC<AppProps> = ({ job }) => {
  const navigate = useNavigate();
  const { userInfo } = useAuth();
  const [
    createEstimateModal,
    showCreateEstimateModal,
    closeCreateEstimateModal,
  ] = useModal();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [confirmOpen, showConfirmModal, closeConfirmModal] = useModal();

  const removeSumoquoteMutation = useMutation(() => removeSumoquote(job.id), {
    onError: (err: any) => {
      toast.error(readAxiosErr(err));
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(['job-checklist', job.id?.toString()]),
        queryClient.invalidateQueries(['job-detail', job.id?.toString()]),
      ]);
    },
  });

  const hide_profit = userInfo?.controls?.hide_profit;

  const estimate = useMemo(() => {
    const options = [
      {
        id: 'contract',
        label: 'Contract',
        value: job.estimate?.gt_price,
      },
      {
        id: 'payments',
        label: 'Payments',
        value: job.estimate?.payments,
      },
      {
        id: 'balance',
        label: 'Balance',
        value: job.estimate?.balance,
        style: { color: 'red' },
      },
    ];

    if (!hide_profit) {
      options.push({
        id: 'profit',
        label: 'Profit',
        value: job.estimate?.profit,
        style: { color: 'green' },
      });
    }

    return options;
  }, [hide_profit]);

  if (job.sumoquote_meta?.status === 'processing') {
    return (
      <div className="px-3 py-2 bg-white shadow-md rounded-lg">
        <div className="v-center cursor-pointer relative">
          <Icon name="calculator" size={18} className="text-primary" />{' '}
          <span className="pl-1 title">Estimate</span>
          <div className="absolute -right-2 top-0">
            <Icon name="sumoquote" size={14} className="w-8 animate-pulse" />
          </div>
        </div>
        <div className="w-[152px] mt-4">
          <div className="text-xs text-text-medium relative">
            Waiting for sumoquote...
          </div>
          <div className="mt-2">
            <div className="key">Order placed at:</div>
            <div className="text-xs font-bold">
              {parseEpochAndFormat(job.sumoquote_meta?.order_placed_timestamp)}
            </div>
          </div>
        </div>
        <div className="apart mt-8">
          <div
            className="v-center space-x-[2px] cursor-pointer"
            onClick={async () => {
              setLoading(true);
              await Promise.all([
                queryClient.invalidateQueries([
                  'job-checklist',
                  job.id?.toString(),
                ]),
                queryClient.invalidateQueries([
                  'job-detail',
                  job.id?.toString(),
                ]),
              ]);
              setLoading(false);
            }}
          >
            <Icon
              name="refresh"
              size={18}
              className={clx('text-primary', {
                'animate-spin': loading,
              })}
            />
            <span className="text-xs">Refresh</span>
          </div>
          {job.sumoquote_meta?.is_delayed && (
            <div
              className="v-center space-x-[2px] cursor-pointer"
              onClick={showConfirmModal}
            >
              <Icon name="delete" size={16} className="text-error" />
              <span className="text-xs text-error">Delete</span>
            </div>
          )}
        </div>
        {confirmOpen && (
          <Confirmation
            title="Remove Sumoquote?"
            message="This action will remove sumoquote link for this job. You can always place the order with sumoquote later."
            open={confirmOpen}
            closeConfirm={closeConfirmModal}
            onClick={() => removeSumoquoteMutation.mutate()}
            isLoading={removeSumoquoteMutation.isLoading}
          />
        )}
      </div>
    );
  }

  return (
    <div className="px-3 py-2 bg-white shadow-md rounded-lg">
      <div
        className="cursor-pointer"
        onClick={() => {
          if (job.estimate) {
            return navigate(`/jobs/${job.id}/estimates/${job.estimate.id}`);
          }
          return showCreateEstimateModal();
        }}
      >
        <div className="v-center cursor-pointer">
          <Icon name="calculator" size={18} className="text-primary" />{' '}
          <span className="pl-1 title">Estimate</span>
        </div>
        {job.estimate ? (
          <div className="mt-2">
            {estimate.map((est) => (
              <div key={est.id} className="v-center space-y-1">
                <div className="w-[78px] key">{est.label}:</div>{' '}
                <div className="value" style={est.style}>
                  {formatAmt(est.value)}
                </div>
              </div>
            ))}
            <div
              className={clx('v-center text-xs text-primary', {
                'mt-8': hide_profit,
                'mt-2': !hide_profit,
              })}
            >
              View estimate{' '}
              <Icon name="arrow-right" className="ml-1" size={16} />
            </div>
          </div>
        ) : (
          <div>
            <div className="w-[152px] mt-12">
              <div className="text-sm mb-2 text-text-light">No Estimate</div>
              <Button
                label="Create Estimate"
                size={Size.Medium}
                leftIcon="plus"
                className="mt-2 relative right-1"
                onClick={(e) => {
                  e.preventDefault();
                  showCreateEstimateModal();
                }}
              />
            </div>
          </div>
        )}
      </div>
      <BottomSheet
        open={createEstimateModal}
        closeSheet={closeCreateEstimateModal}
        maxWidth="max-w-2xl"
      >
        <CreateEstimate jobId={job.id} closeModal={closeCreateEstimateModal} />
      </BottomSheet>
    </div>
  );
};

export default EstimateBox;

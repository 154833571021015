import axios from 'axios';
import moment from 'moment';
import { capitalize, get } from 'lodash';
import { formatDate, parseDate } from 'utils/time';

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

interface LatLng {
  lat: number;
  lng: number;
}

export const formatAddressDetails: any = (data: any) => {
  const addressComponents: Array<any> = data.address_components;
  let city = '';
  let county = '';
  let state = '';
  let country = '';
  let zipcode = '';
  for (const c of addressComponents) {
    const types: Array<string> = c.types;
    if (
      (types.includes('locality') ||
        types.includes('sublocality') ||
        types.includes('administrative_area_level_3')) &&
      !city
    ) {
      city = c.short_name;
    }
    if (types.includes('administrative_area_level_1')) {
      state = c.short_name;
    } else if (types.includes('administrative_area_level_2')) {
      county = c.short_name;
    } else if (types.includes('country')) {
      country = c.short_name;
    } else if (types.includes('postal_code')) {
      zipcode = c.short_name || '';
    }
  }
  const address_arr = data.formatted_address.split(', ');
  const address = address_arr.slice(0, address_arr.length - 3).join(', ');
  return {
    address,
    city,
    county,
    state,
    country,
    zipcode,
    formattedAddress: `${address}, ${city}, ${state}, ${country}`,
  };
};

export const getAddressFromLatLng = async (latlng: LatLng) => {
  try {
    const response = await axios.get(
      'https://maps.googleapis.com/maps/api/geocode/json' +
        '?sensor=false' +
        `&key=${API_KEY}` +
        `&latlng=${latlng.lat},${latlng.lng}`
    );
    const result = get(response, 'data.results')[0];
    if (result) {
      return formatAddressDetails(result);
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getBoundParams = (bound: any) => {
  const ne = bound.getNorthEast();
  const sw = bound.getSouthWest();

  return `${ne.lat},${ne.lng},${sw.lat},${sw.lng}`;
};

export const diffYearsAndMonths = (date_str: string, format: string) => {
  const current = moment();
  const old = parseDate(date_str, format);
  const years = current.diff(old, 'years');
  old.add(years, 'years');
  const months = current.diff(old, 'months');
  return { years, months };
};

const parseMelissaData = (data: Record<string, any>) => {
  const salesDateStr = get(data, 'SaleInfo.AssessorLastSaleDate', null);
  let yearsOfLiving = null;
  let salesDate = null;
  if (salesDateStr) {
    yearsOfLiving = diffYearsAndMonths(salesDateStr, 'YYYYMMDD');
    salesDate = formatDate(parseDate(salesDateStr, 'YYYYMMDD'), 'MM/DD/YYYY');
  }
  let marketValue = get(data, 'EstimatedValue.EstimatedValue', '');
  if (!marketValue) {
    marketValue = get(data, 'Tax.MarketValueTotal', '');
  }

  return {
    first_name: capitalize(get(data, 'PrimaryOwner.Name1First', '')),
    last_name: capitalize(get(data, 'PrimaryOwner.Name1Last', '')),
    year_built: get(data, 'PropertyUseInfo.YearBuilt', ''),
    area_sqft: get(data, 'PropertySize.AreaBuilding', ''),
    market_value: marketValue,
    sale_date: salesDate,
    years_of_living: yearsOfLiving,
    owned_or_rented: 'Unknown',
    date_data_collected: formatDate(moment(), 'YYYY-MM-DD HH:mm:ss.SSSSSSZ'),
  };
};

export const getMelissaData = async (address: string) => {
  console.log('>>>>', address);
  const _address = address
    .replaceAll(/, USA/gi, '')
    .replace(/, US/gi, '')
    .replace('KCMO', 'Kansas City');
  const baseUrl = 'https://property.melissadata.net/v4/WEB/LookupProperty/';
  const apiKey = process.env.REACT_APP_MELISSA_API_KEY;

  const params = {
    id: apiKey,
    format: 'json',
    ff: _address,
    cols: 'GrpPrimaryOwner',
  };

  try {
    const { data } = await axios.get(baseUrl, { params });
    let record = data.Records ? data.Records[0] : null;
    if (!record || !record.PrimaryOwner) {
      record = null;
    }
    return record ? parseMelissaData(record) : record;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const getNameForInitials = (name: string) => {
  const name_arr = name?.split(' ');
  if (name_arr.length <= 2) {
    return name;
  }
  return name_arr[0] + ' ' + name_arr[1];
};

import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import clx from 'classnames';
import useMedia from 'hooks/useMedia';

export enum Direction {
  Left = 'left',
  Right = 'right',
}

type AppProps = {
  children: React.ReactNode;
  open: boolean;
  closeSidebar?: () => any;
  blocking?: boolean;
  bgClass?: string;
  showShadow?: boolean;
  direction?: Direction;
};

const Sidebar: React.FC<AppProps> = ({
  open,
  closeSidebar,
  children,
  blocking = true,
  bgClass = 'bg-white',
  showShadow = true,
  direction = Direction.Right,
}) => {
  const { isMobile } = useMedia();

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[99999]"
        onClose={closeSidebar ? closeSidebar : () => null}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={blocking ? 'fixed inset-0 bg-black bg-opacity-50' : ''}
          />
        </Transition.Child>

        <div
          className={clx('fixed top-0 bottom-0 ', {
            'w-1/3 max-w-[540px]': !isMobile,
            'w-screen': isMobile,
            'right-0': direction === Direction.Right,
            'left-0': direction === Direction.Left,
          })}
        >
          <Transition.Child
            enter="transition ease-in-out duration-400 transform"
            enterFrom={
              direction === Direction.Right
                ? 'translate-x-full'
                : '-translate-x-full'
            }
            enterTo={
              direction === Direction.Right ? 'translate-x-0' : '-translate-x-0'
            }
            leave="transition ease-in-out duration-300 transform"
            leaveFrom={
              direction === Direction.Right ? 'translate-x-0' : '-translate-x-0'
            }
            leaveTo={
              direction === Direction.Right
                ? 'translate-x-full'
                : '-translate-x-full'
            }
          >
            <Dialog.Panel
              className={clx(
                'w-full h-screen overflow-y-auto text-left align-middle transition-all shadow-x',
                bgClass,
                { ' right-sidebar-shadow': showShadow }
              )}
            >
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Sidebar;

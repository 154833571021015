import React, { useEffect, useMemo, useState } from 'react';
import Icon from 'components/Icon';
import useModal from 'hooks/useModal';
import BottomSheet from 'components/BottomSheet';
import CreateEstimateAndOrders from '../CreateEstimateAndOrders';
import CreateLegacy from './Legacy';
import CreateLight from './Light';
import Tabs from 'components/Tabs';
import { isEmpty } from 'lodash';
import useAuth from 'hooks/useAuth';

type AppProps = {
  goBack: () => void;
  closeModal: () => void;
};

const CreateEstimateFromDrawing: React.FC<AppProps> = ({
  goBack,
  closeModal,
}) => {
  const { userInfo } = useAuth();
  const [payload, setPayload] = useState({});
  const [create, showCreate, closeCreate] = useModal();

  useEffect(() => {
    if (!isEmpty(payload)) {
      showCreate();
    }
  }, [payload]);

  const tabs = useMemo(() => {
    const _tabs = [];
    if (userInfo?.is_enterprise_user) {
      _tabs.push({
        id: 'regular',
        name: 'v1 Roof Templates',
        component: () => <CreateLegacy onSubmit={setPayload} />,
      });
    }
    _tabs.push({
      id: 'light',
      name: 'v2 Roof Templates',
      component: () => <CreateLight onSubmit={setPayload} />,
    });

    return _tabs;
  }, []);

  return (
    <div className="pb-4">
      <div className="flex justify-between pb-4">
        <div className="text-lg font-bold">Create Estimate from Drawing</div>
        <div>
          <Icon
            name="close"
            size={30}
            onClick={closeModal}
            className="cursor-pointer"
          />
        </div>
      </div>
      <Tabs
        tabs={tabs}
        initialActive={userInfo?.is_enterprise_user ? 'regular' : 'light'}
      />
      <div className="center mt-4 cursor-pointer" onClick={goBack}>
        <Icon name="arrow-left" size={16} />
        <div className="text-sm text-text-light ml-2">
          Select another option
        </div>
      </div>
      <BottomSheet open={create}>
        <CreateEstimateAndOrders closeModal={closeCreate} payload={payload} />
      </BottomSheet>
    </div>
  );
};

export default CreateEstimateFromDrawing;

import BottomSheet from 'components/BottomSheet';
import Icon, { Loader } from 'components/Icon';
import SkeletonLoader from 'components/SkeletonLoader';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import clx from 'classnames';
import useMedia from 'hooks/useMedia';
import { find, truncate } from 'lodash';
import Button, { Size, Variants } from 'components/Button';
import { useDocumentChoices } from 'queries/documents';
import { getFileIcon } from 'utils/misc';
import toast from 'react-hot-toast';

type AppProps = {
  open: boolean;
  closeModal: () => any;
  selectedDocs: any[];
  onSelect: (...args: any) => any;
  q?: Record<string, any>;
  submitMutation?: any;
  limit?: number;
};

const DocumentSelectorModal: React.FC<AppProps> = ({
  open,
  closeModal,
  selectedDocs,
  onSelect,
  q = {},
  submitMutation = null,
  limit,
}) => {
  const { isMobile } = useMedia();
  const { ref, inView } = useInView();

  const { status, data, isFetching, isFetchingNextPage, fetchNextPage } =
    useDocumentChoices(q);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  const handleSelect = (doc: any) => {
    if (find(selectedDocs, { value: doc.value })) {
      onSelect(selectedDocs.filter((i: any) => i.value !== doc.value));
    } else {
      if (limit && selectedDocs.length >= limit) {
        return toast.error(`You can only select ${limit} documents`);
      }
      onSelect([...selectedDocs, doc]);
    }
  };

  const totalCount = data?.pages[0]?.count;

  const renderFooter = () => {
    return (
      <div
        className={clx(
          'bg-white px-2 flex items-center justify-end space-x-4',
          { 'pt-4': isMobile, 'py-2 mt-6': !isMobile }
        )}
      >
        <div>
          <Button
            label="Cancel"
            variant={Variants.Outline}
            size={Size.Medium}
            onClick={() => {
              onSelect([]);
              closeModal();
            }}
          />
        </div>
        <div>
          <Button
            label="Confirm"
            size={Size.Medium}
            onClick={async () => {
              onSelect(selectedDocs);
              if (totalCount && submitMutation) {
                await submitMutation?.mutateAsync();
              }
              closeModal();
            }}
            loading={submitMutation?.isLoading}
          />
        </div>
      </div>
    );
  };

  return (
    <BottomSheet maxWidth="max-w-2xl" open={open} closeSheet={closeModal}>
      <div className="relative">
        <div className="pb-2">
          <div className="flex justify-between">
            <div className="text-2xl font-bold mb-2">Select Job Documents</div>
            <div>
              <Icon
                name="close"
                size={32}
                onClick={closeModal}
                className="cursor-pointer"
              />
            </div>
          </div>
        </div>
        {status === 'loading' && (
          <SkeletonLoader
            grid={
              isMobile
                ? { size: { height: 120, width: 156 }, count: 2 }
                : { size: { height: 120, width: 140 }, count: 4 }
            }
            size={isMobile ? 4 : 8}
          />
        )}

        {!!selectedDocs.length && (
          <div className="apart">
            <div className="mb-4 text-xs text-text-medium">
              Selected <b>{selectedDocs.length}</b> Documents
            </div>
            <div
              className="text-primary text-xs hover:underline cursor-pointer"
              onClick={() => onSelect([])}
            >
              Clear Selection
            </div>
          </div>
        )}
        {totalCount ? (
          <div
            className={clx('grid gap-2', {
              'grid-cols-2 max-h-[65vh] overflow-y-auto': isMobile,
              'grid-cols-4': !isMobile,
            })}
          >
            {data?.pages?.map((page: any) => (
              <React.Fragment key={page.next_page}>
                {page.results.map((doc: any) => (
                  <div
                    key={doc.value}
                    className="border rounded-md p-2 relative"
                    onClick={() => handleSelect(doc)}
                  >
                    <div className="absolute -top-1 left-1">
                      <input
                        type="checkbox"
                        className="rounded-md"
                        checked={!!find(selectedDocs, { value: doc.value })}
                        onClick={() => null}
                      />
                    </div>
                    {doc.thumb ? (
                      <img src={doc.thumb} className="h-32 w-32 object-cover" />
                    ) : (
                      <div className="center">
                        <div
                          key={doc.name}
                          className="h-32 w-32 border bg-primary-negative rounded-md flex flex-col justify-center items-center"
                        >
                          <Icon
                            name={getFileIcon(doc.display_name)}
                            size={54}
                            className="text-text-light"
                          />
                        </div>
                      </div>
                    )}
                    <div className="absolute left-0 right-0 bottom-0 px-1 py-1 bg-black bg-opacity-70 rounded-b-md">
                      <div className="text-white text-xxs">
                        {truncate(doc.display_name, { length: 36 })}
                      </div>
                    </div>
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>
        ) : (
          <div>No documents to select</div>
        )}
        {!isFetchingNextPage && <div ref={ref} />}
        <div className={clx({ 'sticky -bottom-6': !isMobile })}>
          {renderFooter()}
        </div>

        {isFetching && (
          <div className="py-4 w-full flex justify-center">
            <Loader />
          </div>
        )}
      </div>
    </BottomSheet>
  );
};

export default DocumentSelectorModal;

import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import apiService from 'utils/apiService';

export const getWorkorders = async ({ pageParam = 1, ...q }) => {
  const { data } = await apiService.get('/api/light/workorders/', {
    ...q,
    page: pageParam,
  });
  return data;
};

export const useWorkorders = (q: Record<string, any>) => {
  return useInfiniteQuery(
    ['workorders', q],
    ({ pageParam }) => getWorkorders({ pageParam, ...q }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next_page ?? undefined;
      },
      staleTime: 5 * 60 * 1000,
    }
  );
};

export const useDesktopWorkOrders = (q?: Record<string, any>, page = 1) => {
  return useQuery(
    ['workorders', 'desktop', q, { page }],
    () => getWorkorders({ pageParam: page, ...q }),
    {
      staleTime: 15 * 60 * 1000,
      keepPreviousData: true,
    }
  );
};

const getWorkorderDetail = async (id: string) => {
  const { data } = await apiService.get(`/api/light/workorders/${id}/`);
  return data;
};

export const useWorkorderDetail = (id: string) => {
  return useQuery(['workorders', id], () => getWorkorderDetail(id), {
    staleTime: 5 * 60 * 1000,
  });
};

const getCrewChoices = async (id: string) => {
  const { data } = await apiService.get(`/api/light/workorders/${id}/crews/`);
  return data;
};

export const useCrewChoices = (id: string) => {
  return useQuery(['workorders', id, 'choices'], () => getCrewChoices(id), {
    staleTime: 30 * 60 * 1000,
  });
};

// ***** mutatiosn ********

export const deleteWorkorder = async (id: string) => {
  await apiService.delete(`/api/light/workorders/${id}/`);
};

export const createBlankWorkorder = async (payload: Record<string, any>) => {
  const { data } = await apiService.post('/api/light/workorders/', payload);
  return data;
};

export const updateWorkorder = async (
  workorderId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/workorders/${workorderId}/`,
    payload
  );
  return data;
};

export const updateWorkorderTotals = async (
  orderId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/light/workorders/${orderId}/update_totals/`,
    payload
  );
  return data;
};

const getWorkorderFieldConfig = async (q: Record<string, any>) => {
  const { data } = await apiService.get(
    '/api/light/workorders/fields_config/',
    q
  );
  return data;
};

interface IFieldConfig {
  job?: string;
  workorder?: string;
}

export const useWorkorderFieldConfig = (q: IFieldConfig = {}) => {
  const { job, workorder } = q;
  return useQuery(
    ['workorders', 'field-config', { job, workorder }],
    () => getWorkorderFieldConfig({ job, workorder }),
    {
      staleTime: workorder ? 0 : Infinity,
    }
  );
};

const getWorkorderTotalsFieldConfig = async (
  id: string,
  q: Record<string, any>
) => {
  const { data } = await apiService.get(
    `/api/light/workorders/${id}/totals_fields_config/`,
    q
  );
  return data;
};

interface IFieldConfig {
  job?: string;
  workorder?: string;
}

export const useWorkorderTotalsFieldConfig = (
  id: string,
  q: IFieldConfig = {}
) => {
  return useQuery(
    ['workorders', 'totals-field-config', id, q],
    () => getWorkorderTotalsFieldConfig(id, q),
    {
      staleTime: 0,
    }
  );
};

// ********* Lineitems *********

interface ILineitemFieldConfig {
  workorder?: string;
  lineitem?: string;
}

const getLineItemFieldConfig = async (q: Record<string, any>) => {
  const { data } = await apiService.get(
    `/api/light/workorder-lineitems/fields_config/`,
    q
  );
  return data;
};

export const useLineItemFieldConfig = (q: ILineitemFieldConfig = {}) => {
  return useQuery(
    ['workorders', 'lineitems-field-config', q],
    () => getLineItemFieldConfig(q),
    {
      staleTime: q.lineitem ? 0 : Infinity,
    }
  );
};

export const createLineitem = async (
  payload: Record<string, any>,
  q?: Record<string, any>
) => {
  const { data } = await apiService.post(
    'api/light/workorder-lineitems/',
    payload,
    q
  );
  return data;
};

export const updateLineitem = async (
  id: string,
  payload: Record<string, any>,
  q?: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/workorder-lineitems/${id}/`,
    payload,
    q
  );
  return data;
};

export const deleteLineitem = async (id: string) => {
  await apiService.delete(`/api/light/workorder-lineitems/${id}/`);
};

export const createWorkorderFromDrawing = async (
  jobId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/jobs/${jobId}/rooflayout_workorder/`,
    payload
  );
  return data;
};

export const createWorkorderFromMeasurements = async (
  jobId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/jobs/${jobId}/manual_rooflayout_workorder/`,
    payload
  );
  return data;
};

export const createLightWorkorderFromDrawing = async (
  jobId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/jobs/${jobId}/light_rooflayout_workorder/`,
    payload
  );
  return data;
};

export const createLightWorkorderFromMeasurements = async (
  jobId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/jobs/${jobId}/light_manual_rooflayout_workorder/`,
    payload
  );
  return data;
};

// ########### Checklist ###########
const getWorkorderPipeline = async (id: number) => {
  const { data } = await apiService.get(
    `/api/light/workorders/${id}/checklist/`
  );
  return data;
};

export const useWorkorderPipeline = (id: number) => {
  return useQuery(
    ['workorders', id?.toString(), 'checklist'],
    () => getWorkorderPipeline(id),
    {
      staleTime: 5 * 60 * 1000,
    }
  );
};

export const submit = async (id: number, payload: Record<string, any>) => {
  const { data } = await apiService.post(
    `/api/light/workorders/${id}/submit/`,
    payload
  );
  return data;
};

export const unsubmit = async (id: number, payload: Record<string, any>) => {
  const { data } = await apiService.post(
    `/api/light/workorders/${id}/unsubmit/`,
    payload
  );
  return data;
};

export const reject = async (id: number, payload: Record<string, any>) => {
  const { data } = await apiService.post(
    `/api/light/workorders/${id}/reject/`,
    payload
  );
  return data;
};

export const undoReject = async (id: number, payload: Record<string, any>) => {
  const { data } = await apiService.post(
    `/api/light/workorders/${id}/undo_reject/`,
    payload
  );
  return data;
};

export const approve = async (id: number, payload: Record<string, any>) => {
  const { data } = await apiService.post(
    `/api/light/workorders/${id}/approve/`,
    payload
  );
  return data;
};

export const complete = async (id: number, payload: Record<string, any>) => {
  const { data } = await apiService.post(
    `/api/light/workorders/${id}/complete/`,
    payload
  );
  return data;
};

export const editComplete = async (
  id: number,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/light/workorders/${id}/edit_complete/`,
    payload
  );
  return data;
};

export const undoComplete = async (id: number) => {
  const { data } = await apiService.post(
    `/api/light/workorders/${id}/undo_complete/`
  );
  return data;
};

export const payOrder = async (id: string, payload: Record<string, any>) => {
  const { data } = await apiService.post(
    `/api/light/workorders/${id}/pay/`,
    payload
  );
  return data;
};

export const undoPay = async (id: string) => {
  const { data } = await apiService.post(
    `/api/light/workorders/${id}/undo_paid/`
  );
  return data;
};

export const scheduleOrder = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/workorders/${id}/schedule/`,
    payload
  );
  return data;
};

export const unscheduleOrder = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/workorders/${id}/unschedule/`,
    payload
  );
  return data;
};

const getScheduleOrderFieldConfig = async (id: string) => {
  const { data } = await apiService.get(
    `/api/light/workorders/${id}/schedule_fields_config/`
  );
  return data;
};

export const useScheduleOrderFieldConfig = (id: string) => {
  return useQuery(
    ['workorders', id.toString(), 'schedule-field-config'],
    () => getScheduleOrderFieldConfig(id),
    {
      staleTime: 0,
    }
  );
};

const getUnScheduleOrderFieldConfig = async (id: string) => {
  const { data } = await apiService.get(
    `/api/light/workorders/${id}/unschedule_fields_config/`
  );
  return data;
};

export const useUnScheduleOrderFieldConfig = (id: string) => {
  return useQuery(
    ['workorders', id.toString(), 'unschedule-field-config'],
    () => getUnScheduleOrderFieldConfig(id),
    {
      staleTime: 0,
    }
  );
};

const getCrewUserChoices = async (id: string) => {
  const { data } = await apiService.get(
    `/api/light/workorders/${id}/crew_user_choices/`
  );
  return data;
};

export const useCrewUserChoices = (id: string) => {
  return useQuery(
    ['workorders', id, 'crew_user_choices'],
    () => getCrewUserChoices(id),
    {
      staleTime: Infinity,
    }
  );
};

const getScheduleOrderRecipientChoices = async (id: string) => {
  const { data } = await apiService.get(
    `/api/light/workorders/${id}/schedule_order_recipients_choices/`
  );
  return data;
};

export const useScheduleOrderRecipientChoices = (id: string) => {
  return useQuery(
    ['workorders', id, 'schedule_order_recipients_choices'],
    () => getScheduleOrderRecipientChoices(id),
    {
      staleTime: Infinity,
    }
  );
};

export const syncToQb = async (id: string) => {
  const { data } = await apiService.post(`/api/workorders/${id}/qb_sync/`);
  return data;
};

export const pullFromQB = async (id: string) => {
  const { data } = await apiService.post(`/api/workorders/${id}/pull_from_qb/`);
  return data;
};

export const pullVCFromQB = async (id: string) => {
  const { data } = await apiService.post(
    `/api/workorders/${id}/pull_vc_from_qb/`
  );
  return data;
};

export const savePdfToDocs = async (id: string, q?: Record<string, any>) => {
  const { data } = await apiService.get(`/api/workorders/${id}/save_pdf/`, q);
  return data;
};

export const printPdf = async (id: string, q?: Record<string, any>) => {
  return await apiService.get(`/api/workorders/${id}/print_pdf/`, q);
};

export const copyWorkOrder = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/workorders/${id}/copy/`,
    payload
  );
  return data;
};

const getMatorderChoices = async (id: string) => {
  const { data } = await apiService.get(
    `/api/light/workorders/${id}/matorder_choices/`
  );
  return data;
};

export const useMatorderChoices = (id: string) => {
  return useQuery(
    ['workorders', id, 'matorder-choices'],
    () => getMatorderChoices(id),
    {
      staleTime: 0,
    }
  );
};

// ######### Crew login ########
export const getCrewWorkorders = async ({ pageParam = 1, filters = {} }) => {
  const { data } = await apiService.get('/api/light/crew-workorders/', {
    page: pageParam,
    ...filters,
  });
  return data;
};

export const useCrewWorkorders = (q: Record<string, any>, enabled = true) => {
  return useInfiniteQuery(
    ['workorders', 'crew', q],
    ({ pageParam }) =>
      getCrewWorkorders({
        pageParam,
        ...q,
      }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next_page ?? undefined;
      },
      staleTime: 25 * 60 * 1000,
      enabled,
    }
  );
};

export const useCrewDesktopWorkorders = (page = 1, filters = {}) => {
  return useQuery(
    ['workorders', 'crew', 'desktop', { page, ...filters }],
    () => getCrewWorkorders({ pageParam: page, filters }),
    {
      staleTime: 25 * 60 * 1000,
      keepPreviousData: true,
    }
  );
};

const getCrewWorkorderDetail = async (id: string) => {
  const { data } = await apiService.get(
    `/api/light/crew-workorders/${id}/detail_page/`
  );
  return data;
};

export const useCrewWorkorderDetail = (id: string) => {
  return useQuery(['crew-workorders', id], () => getCrewWorkorderDetail(id), {
    staleTime: 15 * 60 * 1000,
  });
};

export const getSearch = async (q: Record<string, any>) => {
  const { data } = await apiService.get('/api/light/crew-workorders/search/', {
    ...q,
  });
  return data;
};

export const useWorkorderSearch = (q: Record<string, any>, enabled = true) => {
  return useQuery(['crew-workorders', 'search', q], () => getSearch(q), {
    staleTime: 1 * 60 * 1000,
    enabled,
  });
};

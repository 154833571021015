import useMedia from 'hooks/useMedia';
import React, { Suspense, lazy } from 'react';
import Loader, { Variant as LoaderVariant } from 'components/Loader';

const LineitemsMobile = lazy(() => import('./Lineitems.mobile'));
const LineitemsDesktop = lazy(() => import('./Lineitems.desktop'));

type AppProps = {
  data: Record<string, any>;
  jobType: Record<string, any>;
  setJobType: (a: any) => any;
  readOnly?: boolean;
};

const Lineitems: React.FC<AppProps> = (props) => {
  const { isMobile } = useMedia();

  return (
    <Suspense
      fallback={
        <Loader
          variant={LoaderVariant.RoofLoader}
          label="Fetching Lineitems..."
        />
      }
    >
      {isMobile ? (
        <LineitemsMobile {...props} />
      ) : (
        <LineitemsDesktop {...props} />
      )}
    </Suspense>
  );
};

export default Lineitems;

import ExifReader from 'exifreader';
import { get } from 'lodash';

export const getExifInfo = async (file: any, callback: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async function (readerEvent: any) {
      try {
        const exifInfo = ExifReader.load(readerEvent.target.result, {
          expanded: true,
        });
        const modifiedExifInfo = {
          orientation: get(exifInfo, 'exif.Orientation.value') || 1,
        };
        await callback(modifiedExifInfo);
        resolve('success');
      } catch (error) {
        // Handle error.
        console.log(error);
        callback();
        reject(error);
      }
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsArrayBuffer(file);
  });
};

import React from 'react';
import clx from 'classnames';
import Icon from 'components/Icon';
import toast from 'react-hot-toast';
import useModal from 'hooks/useModal';
import BottomSheet from 'components/BottomSheet';
import UpdateCell from './UpdateCell';
import { formatPhoneNumber } from 'utils/misc';

type AppProps = {
  job: Record<string, any>;
  iconSize?: number;
};

const Cell: React.FC<AppProps> = ({ job, iconSize = 28 }) => {
  const [updateCellModal, showUpdateCellModal, closeUpdateCellModal] =
    useModal();
  const [call, showCall, closeCall] = useModal();

  const iconStyle = (value: string | null) =>
    clx({
      'text-text-lighter': !value,
      'text-primary': !!value,
    });

  const noClickStyle = (value: string | null) =>
    clx({
      'pointer-events-none': !value,
    });

  const both = job.customer?.cell && job.customer?.phone;
  const cellOrPhone = job.customer?.cell || job.customer?.phone;

  return (
    <div
      className="cursor-pointer"
      onClick={
        cellOrPhone
          ? () => null
          : () => {
              toast.error('No cell found!', { position: 'top-center' });
              showUpdateCellModal();
            }
      }
    >
      {both ? (
        <Icon
          name="phone"
          size={iconSize}
          className={iconStyle(cellOrPhone)}
          onClick={showCall}
        />
      ) : (
        <a href={`tel:${cellOrPhone}`} className={noClickStyle(cellOrPhone)}>
          <Icon
            name="phone"
            size={iconSize}
            className={iconStyle(cellOrPhone)}
          />
        </a>
      )}
      <BottomSheet open={updateCellModal}>
        <UpdateCell customer={job.customer} closeModal={closeUpdateCellModal} />
      </BottomSheet>
      <BottomSheet open={call}>
        <div className="pb-4">
          <div className="flex justify-between">
            <div className="page-heading mb-6">Call Customer</div>
            <div>
              <Icon
                name="close"
                size={32}
                onClick={closeCall}
                className="cursor-pointer"
              />
            </div>
          </div>
          <div className="pb-2">
            <a href={`tel:${job.customer.cell}`}>
              <div className="v-center space-x-1">
                <Icon name="mobile" size={16} className="text-text-medium" />
                <div className="text-sm text-text-medium">Cell</div>
              </div>
              <div>
                <div className="text-lg">
                  {formatPhoneNumber(job.customer.cell)}
                </div>
              </div>
            </a>
          </div>
          <div className="pt-2 border-t border-border">
            <a href={`tel:${job.customer.phone}`}>
              <div className="v-center space-x-1">
                <Icon name="phone" size={16} className="text-text-medium" />
                <div className="text-sm text-text-medium">Phone</div>
              </div>
              <div>
                <div className="v-center space-x-1 text-lg">
                  {!!job.customer.phone_ext && (
                    <div>{job.customer.phone_ext}</div>
                  )}
                  <div>{formatPhoneNumber(job.customer.phone)}</div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </BottomSheet>
    </div>
  );
};

export default Cell;

import {
  createRLMeasurements,
  updateRLMeasurements,
  useManualRLFieldConfig,
} from 'queries/rooflayout';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { processFields, processInitialValues, processSchema } from 'utils/form';
import { actionLabel, emptyStringToNull, readAxiosErr } from 'utils/misc';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FieldType } from 'utils/enum';
import { Layout, InputVariant } from 'components/Form';
import { ROOF_PITCH_CHOICES, WASTE_PCT_CHOICES } from 'utils/constants';
import { useRoofProducts } from 'queries/company';
import Icon from 'components/Icon';
import Button from 'components/Button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

interface IFormValues {
  squares: number;
  avg_pitch: number;
  waste_pct: number;
  eave_lf: number;
  rake_lf: number;
  hip_lf: number;
  ridge_lf: number;
  ridge_vent_lf: number;
  valley_lf: number;
  gutter_lf: number;
  step_flashing_lf: number;
  flashing_lf: number;
  two_story_squares: number;
  flat_squares: number;
  flat_perimeter_lf: number;
  seven: number;
  eight: number;
  nine: number;
  ten: number;
  eleven: number;
  twelve: number;
  thirteen: number;
  fourteen: number;
  fifteen: number;
  sixteen: number;
  seventeen: number;
  eighteen: number;
  accessory_1: number;
  accessory_1_qty: number;
  accessory_2: number;
  accessory_2_qty: number;
  accessory_3: number;
  accessory_3_qty: number;
  accessory_4: number;
  accessory_4_qty: number;
  accessory_5: number;
  accessory_5_qty: number;
  accessory_6: number;
  accessory_6_qty: number;
  accessory_7: number;
  accessory_7_qty: number;
  accessory_8: number;
  accessory_8_qty: number;
  accessory_9: number;
  accessory_9_qty: number;
  accessory_10: number;
  accessory_10_qty: number;
}

type AppProps = {
  onSuccess: () => any;
  mrlId?: string;
};

const ManualRoofLayout: React.FC<AppProps> = ({ onSuccess, mrlId = '' }) => {
  const { jobId = '' } = useParams();
  const { data: config, isLoading } = useManualRLFieldConfig({
    job: jobId,
    mrlId,
  });

  const schema = yup.object(
    processSchema(
      {
        squares: yup.number().transform(emptyStringToNull),
        avg_pitch: yup.number(),
        waste_pct: yup.number(),
        eave_lf: yup.number().transform(emptyStringToNull),
        rake_lf: yup.number().transform(emptyStringToNull),
        hip_lf: yup.number().transform(emptyStringToNull),
        ridge_lf: yup.number().transform(emptyStringToNull),
        ridge_vent_lf: yup.number().transform(emptyStringToNull),
        valley_lf: yup.number().transform(emptyStringToNull),
        gutter_lf: yup.number().transform(emptyStringToNull),
        step_flashing_lf: yup.number().transform(emptyStringToNull),
        flashing_lf: yup.number().transform(emptyStringToNull),
        two_story_squares: yup.number().transform(emptyStringToNull),
        flat_squares: yup.number().transform(emptyStringToNull),
        flat_perimeter_lf: yup.number().transform(emptyStringToNull),
        seven: yup.number().transform(emptyStringToNull),
        eight: yup.number().transform(emptyStringToNull),
        nine: yup.number().transform(emptyStringToNull),
        ten: yup.number().transform(emptyStringToNull),
        eleven: yup.number().transform(emptyStringToNull),
        twelve: yup.number().transform(emptyStringToNull),
        thirteen: yup.number().transform(emptyStringToNull),
        fourteen: yup.number().transform(emptyStringToNull),
        fifteen: yup.number().transform(emptyStringToNull),
        sixteen: yup.number().transform(emptyStringToNull),
        seventeen: yup.number().transform(emptyStringToNull),
        eighteen: yup.number().transform(emptyStringToNull),
        accessory_1: yup.number(),
        accessory_1_qty: yup.number().transform(emptyStringToNull),
        accessory_2: yup.number(),
        accessory_2_qty: yup.number().transform(emptyStringToNull),
        accessory_3: yup.number(),
        accessory_3_qty: yup.number().transform(emptyStringToNull),
        accessory_4: yup.number(),
        accessory_4_qty: yup.number().transform(emptyStringToNull),
        accessory_5: yup.number(),
        accessory_5_qty: yup.number().transform(emptyStringToNull),
        accessory_6: yup.number(),
        accessory_6_qty: yup.number().transform(emptyStringToNull),
        accessory_7: yup.number(),
        accessory_7_qty: yup.number().transform(emptyStringToNull),
        accessory_8: yup.number(),
        accessory_8_qty: yup.number().transform(emptyStringToNull),
        accessory_9: yup.number(),
        accessory_9_qty: yup.number().transform(emptyStringToNull),
        accessory_10: yup.number(),
        accessory_10_qty: yup.number().transform(emptyStringToNull),
      },
      config
    )
  );

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormValues>({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (config) {
      const defaultValues = processInitialValues(config);
      reset(defaultValues);
    }
  }, [config]);

  const onSubmit: SubmitHandler<IFormValues> = async (formData) => {
    if (mrlId) {
      updateMeasurementMutation.mutate({ ...formData, job: jobId });
    } else {
      createMeasurementMutation.mutate({ ...formData, job: jobId });
    }
  };

  const fields1 = [
    {
      type: FieldType.Input,
      label: 'Squares',
      placeholder: 'Squares',
      error: errors.squares?.message,
      variant: InputVariant.Number,
      ...register('squares'),
    },
    {
      name: 'avg_pitch',
      type: FieldType.Select,
      label: 'Pitch',
      placeholder: 'Select Pitch',
      error: errors.avg_pitch?.message,
      control,
      options: ROOF_PITCH_CHOICES,
      isSearchable: false,
    },
    {
      name: 'waste_pct',
      type: FieldType.Select,
      label: 'Waste Percentage',
      placeholder: 'Select Waste',
      error: errors.waste_pct?.message,
      control,
      options: WASTE_PCT_CHOICES,
      isSearchable: false,
    },
  ];

  const fields2 = [
    {
      type: FieldType.Input,
      label: 'Eave lf',
      placeholder: 'Eave lf',
      error: errors.eave_lf?.message,
      variant: InputVariant.Number,
      ...register('eave_lf'),
    },
    {
      type: FieldType.Input,
      label: 'Rake lf',
      placeholder: 'Rake lf',
      error: errors.rake_lf?.message,
      variant: InputVariant.Number,
      ...register('rake_lf'),
    },
    {
      type: FieldType.Input,
      label: 'hip lf',
      placeholder: 'hip lf',
      error: errors.hip_lf?.message,
      variant: InputVariant.Number,
      ...register('hip_lf'),
    },
    {
      type: FieldType.Input,
      label: 'Ridge lf',
      placeholder: 'Ridge lf',
      error: errors.ridge_lf?.message,
      variant: InputVariant.Number,
      ...register('ridge_lf'),
    },
    {
      type: FieldType.Input,
      label: 'Ridge Vent lf',
      placeholder: 'Ridge Vent lf',
      error: errors.ridge_vent_lf?.message,
      variant: InputVariant.Number,
      ...register('ridge_vent_lf'),
    },
    {
      type: FieldType.Input,
      label: 'Valley lf',
      placeholder: 'Valley lf',
      error: errors.valley_lf?.message,
      variant: InputVariant.Number,
      ...register('valley_lf'),
    },
    {
      type: FieldType.Input,
      label: 'Gutter lf',
      placeholder: 'Gutter lf',
      error: errors.gutter_lf?.message,
      variant: InputVariant.Number,
      ...register('gutter_lf'),
    },
    {
      type: FieldType.Input,
      label: 'Step Flashing lf',
      placeholder: 'Step Flashing lf',
      error: errors.step_flashing_lf?.message,
      variant: InputVariant.Number,
      ...register('step_flashing_lf'),
    },
    {
      type: FieldType.Input,
      label: 'Flashing lf',
      placeholder: 'Flashing lf',
      error: errors.flashing_lf?.message,
      variant: InputVariant.Number,
      ...register('flashing_lf'),
    },
    {
      type: FieldType.Input,
      label: 'Flat Perimeter lf',
      placeholder: 'Flat Perimeter lf',
      error: errors.flat_perimeter_lf?.message,
      variant: InputVariant.Number,
      ...register('flat_perimeter_lf'),
    },
  ];

  const fields3 = [
    {
      type: FieldType.Input,
      label: 'Two Story Squares',
      placeholder: 'Two Story Squares',
      error: errors.two_story_squares?.message,
      variant: InputVariant.Number,
      ...register('two_story_squares'),
    },
    {
      type: FieldType.Input,
      label: 'Flat Squares',
      placeholder: 'Flat Squares',
      error: errors.flat_squares?.message,
      variant: InputVariant.Number,
      ...register('flat_squares'),
    },
    {
      type: FieldType.Input,
      label: '7/12 SQ',
      placeholder: '7/12 SQ',
      error: errors.seven?.message,
      variant: InputVariant.Number,
      ...register('seven'),
    },
    {
      type: FieldType.Input,
      label: '8/12 SQ',
      placeholder: '8/12 SQ',
      error: errors.eight?.message,
      variant: InputVariant.Number,
      ...register('eight'),
    },
    {
      type: FieldType.Input,
      label: '9/12 SQ',
      placeholder: '9/12 SQ',
      error: errors.nine?.message,
      variant: InputVariant.Number,
      ...register('nine'),
    },
    {
      type: FieldType.Input,
      label: '10/12 SQ',
      placeholder: '10/12 SQ',
      error: errors.ten?.message,
      variant: InputVariant.Number,
      ...register('ten'),
    },
    {
      type: FieldType.Input,
      label: '11/12 SQ',
      placeholder: '11/12 SQ',
      error: errors.eleven?.message,
      variant: InputVariant.Number,
      ...register('eleven'),
    },
    {
      type: FieldType.Input,
      label: '12/12 SQ',
      placeholder: '12/12 SQ',
      error: errors.twelve?.message,
      variant: InputVariant.Number,
      ...register('twelve'),
    },
    {
      type: FieldType.Input,
      label: '13/12 SQ',
      placeholder: '13/12 SQ',
      error: errors.thirteen?.message,
      variant: InputVariant.Number,
      ...register('thirteen'),
    },
    {
      type: FieldType.Input,
      label: '14/12 SQ',
      placeholder: '14/12 SQ',
      error: errors.fourteen?.message,
      variant: InputVariant.Number,
      ...register('fourteen'),
    },
    {
      type: FieldType.Input,
      label: '15/12 SQ',
      placeholder: '15/12 SQ',
      error: errors.fifteen?.message,
      variant: InputVariant.Number,
      ...register('fifteen'),
    },
    {
      type: FieldType.Input,
      label: '16/12 SQ',
      placeholder: '16/12 SQ',
      error: errors.sixteen?.message,
      variant: InputVariant.Number,
      ...register('sixteen'),
    },
    {
      type: FieldType.Input,
      label: '17/12 SQ',
      placeholder: '17/12 SQ',
      error: errors.seventeen?.message,
      variant: InputVariant.Number,
      ...register('seventeen'),
    },
    {
      type: FieldType.Input,
      label: '18/12 SQ',
      placeholder: '18/12 SQ',
      error: errors.eighteen?.message,
      variant: InputVariant.Number,
      ...register('eighteen'),
    },
  ];

  const fields4 = [
    {
      name: 'accessory_1',
      type: FieldType.AsyncSelect,
      label: 'Accessory 1',
      placeholder: 'Select value',
      error: errors.accessory_1?.message,
      control,
      optionsPromise: useRoofProducts,
    },
    {
      type: FieldType.Input,
      label: 'Accessory 1 Quantity',
      placeholder: 'Accessory 1 Quantity',
      error: errors.accessory_1_qty?.message,
      variant: InputVariant.Number,
      ...register('accessory_1_qty'),
    },
    {
      name: 'accessory_2',
      type: FieldType.AsyncSelect,
      label: 'Accessory 2',
      placeholder: 'Select value',
      error: errors.accessory_2?.message,
      control,
      optionsPromise: useRoofProducts,
    },
    {
      type: FieldType.Input,
      label: 'Accessory 2 Quantity',
      placeholder: 'Accessory 2 Quantity',
      error: errors.accessory_2_qty?.message,
      variant: InputVariant.Number,
      ...register('accessory_2_qty'),
    },
    {
      name: 'accessory_3',
      type: FieldType.AsyncSelect,
      label: 'Accessory 3',
      placeholder: 'Select value',
      error: errors.accessory_3?.message,
      control,
      optionsPromise: useRoofProducts,
    },
    {
      type: FieldType.Input,
      label: 'Accessory 3 Quantity',
      placeholder: 'Accessory 3 Quantity',
      error: errors.accessory_3_qty?.message,
      variant: InputVariant.Number,
      ...register('accessory_3_qty'),
    },
    {
      name: 'accessory_4',
      type: FieldType.AsyncSelect,
      label: 'Accessory 4',
      placeholder: 'Select value',
      error: errors.accessory_4?.message,
      control,
      optionsPromise: useRoofProducts,
    },
    {
      type: FieldType.Input,
      label: 'Accessory 4 Quantity',
      placeholder: 'Accessory 4 Quantity',
      error: errors.accessory_4_qty?.message,
      variant: InputVariant.Number,
      ...register('accessory_4_qty'),
    },
    {
      name: 'accessory_5',
      type: FieldType.AsyncSelect,
      label: 'Accessory 5',
      placeholder: 'Select value',
      error: errors.accessory_5?.message,
      control,
      optionsPromise: useRoofProducts,
    },
    {
      type: FieldType.Input,
      label: 'Accessory 5 Quantity',
      placeholder: 'Accessory 5 Quantity',
      error: errors.accessory_5_qty?.message,
      variant: InputVariant.Number,
      ...register('accessory_5_qty'),
    },
    {
      name: 'accessory_6',
      type: FieldType.AsyncSelect,
      label: 'Accessory 6',
      placeholder: 'Select value',
      error: errors.accessory_6?.message,
      control,
      optionsPromise: useRoofProducts,
    },
    {
      type: FieldType.Input,
      label: 'Accessory 6 Quantity',
      placeholder: 'Accessory 6 Quantity',
      error: errors.accessory_6_qty?.message,
      variant: InputVariant.Number,
      ...register('accessory_6_qty'),
    },
    {
      name: 'accessory_7',
      type: FieldType.AsyncSelect,
      label: 'Accessory 7',
      placeholder: 'Select value',
      error: errors.accessory_7?.message,
      control,
      optionsPromise: useRoofProducts,
    },
    {
      type: FieldType.Input,
      label: 'Accessory 7 Quantity',
      placeholder: 'Accessory 7 Quantity',
      error: errors.accessory_7_qty?.message,
      variant: InputVariant.Number,
      ...register('accessory_7_qty'),
    },
    {
      name: 'accessory_8',
      type: FieldType.AsyncSelect,
      label: 'Accessory 8',
      placeholder: 'Select value',
      error: errors.accessory_8?.message,
      control,
      optionsPromise: useRoofProducts,
    },
    {
      type: FieldType.Input,
      label: 'Accessory 8 Quantity',
      placeholder: 'Accessory 8 Quantity',
      error: errors.accessory_8_qty?.message,
      variant: InputVariant.Number,
      ...register('accessory_8_qty'),
    },
    {
      name: 'accessory_9',
      type: FieldType.AsyncSelect,
      label: 'Accessory 9',
      placeholder: 'Select value',
      error: errors.accessory_9?.message,
      control,
      optionsPromise: useRoofProducts,
    },
    {
      type: FieldType.Input,
      label: 'Accessory 9 Quantity',
      placeholder: 'Accessory 9 Quantity',
      error: errors.accessory_9_qty?.message,
      variant: InputVariant.Number,
      ...register('accessory_9_qty'),
    },
    {
      name: 'accessory_10',
      type: FieldType.AsyncSelect,
      label: 'Accessory 10',
      placeholder: 'Select value',
      error: errors.accessory_10?.message,
      control,
      optionsPromise: useRoofProducts,
    },
    {
      type: FieldType.Input,
      label: 'Accessory 10 Quantity',
      placeholder: 'Accessory 10 Quantity',
      error: errors.accessory_10_qty?.message,
      variant: InputVariant.Number,
      ...register('accessory_10_qty'),
    },
  ];

  const queryClient = useQueryClient();
  const [showFields, setShowFields] = useState<Record<string, boolean>>({
    fields1: true,
    fields2: false,
    fields3: false,
    fields4: false,
  });

  const createMeasurementMutation = useMutation(
    (formData: any) => createRLMeasurements(formData, { job: jobId }),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(['job-last-checklist', jobId]),
          queryClient.invalidateQueries(['job-checklist', jobId]),
        ]);
        queryClient.invalidateQueries(['job-detail', jobId]);
        queryClient.removeQueries(['job-stats']);
        queryClient.invalidateQueries(['roof-measurement-choices', jobId]);
        queryClient.removeQueries(['manual-rooflayouts', { job: jobId }]);
        queryClient.removeQueries([
          'desktop-manual-rooflayouts',
          { job: jobId },
        ]);
        return onSuccess();
      },
    }
  );

  const updateMeasurementMutation = useMutation(
    (formData: any) =>
      updateRLMeasurements(mrlId, jobId, formData, { job: jobId }),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['job-detail']);
        queryClient.removeQueries(['manual-rooflayouts', { job: jobId }]);
        queryClient.removeQueries([
          'desktop-manual-rooflayouts',
          { job: jobId },
        ]);
        return onSuccess();
      },
    }
  );

  if (isLoading) {
    return <div className="page-center">Fetching form config...</div>;
  }

  const fieldsMap: Record<string, any> = {
    fields1,
    fields2,
    fields3,
    fields4,
  };

  const fieldsTitle: Record<string, any> = {
    fields1: '',
    fields2: 'Set Linear Measurements',
    fields3: 'Set Square Measurements',
    fields4: 'Set Accessories',
  };

  const renderAccordian = (key = 'fields1') => {
    if (showFields[key]) {
      return (
        <div className="border-b border-b-gray-300 mb-3 pb-8">
          {fieldsTitle[key] && (
            <div
              className="flex justify-between mb-3 cursor-pointer"
              onClick={() =>
                setShowFields((fields) => ({ ...fields, [key]: false }))
              }
            >
              <div className="text-sm font-bold">{fieldsTitle[key]}</div>
              <div>
                <Icon name="caret-up" size={16} className="text-text-light" />
              </div>
            </div>
          )}
          <Layout
            fields={processFields(fieldsMap[key], config)}
            loading={
              createMeasurementMutation.isLoading ||
              updateMeasurementMutation.isLoading
            }
          />
        </div>
      );
    }
    return (
      <div
        className="flex justify-between items-center text-text-medium border-b border-b-gray-300 mb-3 pb-3 cursor-pointer"
        onClick={() => setShowFields((fields) => ({ ...fields, [key]: true }))}
      >
        <div>{fieldsTitle[key]}</div>
        <Icon name="caret-down" size={16} className="text-text-light" />
      </div>
    );
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {renderAccordian('fields1')}
        {renderAccordian('fields2')}
        {renderAccordian('fields3')}
        {renderAccordian('fields4')}
        <Button
          label={actionLabel(
            mrlId,
            'Measurements',
            createMeasurementMutation.isLoading ||
              updateMeasurementMutation.isLoading
          )}
          className="mt-8"
          loading={
            createMeasurementMutation.isLoading ||
            updateMeasurementMutation.isLoading
          }
        />
      </form>
    </div>
  );
};

export default ManualRoofLayout;

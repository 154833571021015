import Button, { Size, Variants } from 'components/Button';
import Confirmation from 'components/Confirmation';
import useModal from 'hooks/useModal';
import { submit, unsubmit } from 'queries/job';
import React from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { readAxiosErr } from 'utils/misc';
import { formatServerTime } from 'utils/time';
import Checkbox from './Checkbox';
import BottomSheet from 'components/BottomSheet';
import Icon from 'components/Icon';
import clx from 'classnames';

type AppProps = {
  data: Record<string, any>;
  renderInTable?: boolean;
  job?: string;
};

const Submit: React.FC<AppProps> = ({
  data,
  renderInTable = false,
  job = '',
}) => {
  const { jobId: _jobId = '' } = useParams();
  const jobId = job || _jobId;
  const queryClient = useQueryClient();
  const [unsubmitOpen, showUnsubmitModal, closeUnsubmitModal] = useModal();
  const [actionOpen, showActions, closeActions] = useModal();

  const submitMutation = useMutation(() => submit(jobId), {
    onError: (err: any) => {
      toast.error(readAxiosErr(err));
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(['job-last-checklist', jobId]),
        queryClient.invalidateQueries(['job-checklist', jobId]),
      ]);
      queryClient.invalidateQueries(['job-detail', jobId]);
      queryClient.invalidateQueries(['jobs']);
    },
  });

  const unsubmitMutation = useMutation(() => unsubmit(jobId), {
    onError: (err: any) => {
      toast.error(readAxiosErr(err));
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(['job-last-checklist', jobId]),
        queryClient.invalidateQueries(['job-checklist', jobId]),
      ]);
      queryClient.invalidateQueries(['job-detail', jobId]);
      queryClient.invalidateQueries(['jobs']);
    },
  });

  const incomplete = () => {
    return (
      <div className="apart">
        <div className="value">Not Submitted</div>
        <div>
          <Button
            label="Submit"
            size={Size.Small}
            variant={Variants.Tag}
            onClick={() => submitMutation.mutate()}
            loading={submitMutation.isLoading}
          />
        </div>
      </div>
    );
  };

  const complete = () => {
    return (
      <div>
        <div className="apart">
          <div className="value">Submitted</div>
          <div>
            <Button
              variant={Variants.Tag}
              label="Actions"
              size={Size.Small}
              onClick={showActions}
            />
          </div>
        </div>
        <div className="-mt-1">
          <span className="key">Submitted By: </span>
          <span className="key">{data.completed_by?.name}</span>
        </div>
        <div className="-mt-1">
          <span className="key">Submitted At: </span>
          <span className="key">
            {formatServerTime({
              date: data.date,
              parseFormat: 'YYYY-MM-DDThh:mm:ssZ',
              includeTime: true,
            })}
          </span>
        </div>

        {actionOpen && (
          <BottomSheet open={actionOpen}>
            <div className="pb-4">
              <div className="flex justify-between">
                <div className="page-heading">Actions</div>
                <div>
                  <Icon name="close" size={32} onClick={closeActions} />
                </div>
              </div>
              <div className="mt-4 space-y-4">
                {data?.permissions?.can_unsubmit && (
                  <Button
                    label="Un-submit Job?"
                    size={Size.Big}
                    variant={Variants.Tag}
                    onClick={showUnsubmitModal}
                    loading={unsubmitMutation.isLoading}
                  />
                )}
              </div>
            </div>
          </BottomSheet>
        )}

        {unsubmitOpen && (
          <Confirmation
            title="Unsubmit Submit Request?"
            message="Are you sure you want to unsubmit the job submit request?"
            open={unsubmitOpen}
            closeConfirm={closeUnsubmitModal}
            onClick={() => unsubmitMutation.mutate()}
            isLoading={unsubmitMutation.isLoading}
          />
        )}
      </div>
    );
  };

  return (
    <div className={clx({ card: !renderInTable, 'px-2 pb-1': renderInTable })}>
      <div className="flex space-x-2">
        <Checkbox checked={data.complete} />
        <div className="w-full">
          {data.complete ? complete() : incomplete()}
        </div>
      </div>
    </div>
  );
};

export default Submit;

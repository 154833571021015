import React from 'react';
import Checkbox from './Checkbox';
import clx from 'classnames';
import Button, { Size, Variants } from 'components/Button';
import useModal from 'hooks/useModal';
import DateForm from './DateForm';
import { closeJob, reopenJob } from 'queries/job';
import { useParams } from 'react-router-dom';
import { formatServerTime } from 'utils/time';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { readAxiosErr } from 'utils/misc';
import AddComment from 'components/AddComment';

type AppProps = {
  data: Record<string, any>;
  renderInTable?: boolean;
};

const JobClosed: React.FC<AppProps> = ({ data, renderInTable = false }) => {
  const { jobId = '' } = useParams();
  const [close, showClose, closeClose] = useModal();
  const [reopen, showReopen, closeReopen] = useModal();

  const queryClient = useQueryClient();

  const reopenMutation = useMutation(
    (formData: any) => reopenJob(jobId, formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(['job-last-checklist', jobId]),
          queryClient.invalidateQueries(['job-checklist', jobId]),
        ]);
        queryClient.invalidateQueries(['job-detail', jobId]);
        queryClient.invalidateQueries(['jobs']);
        closeReopen();
      },
    }
  );

  const incomplete = () => {
    return (
      <div>
        <div className="apart">
          <div className="value">Job Not Closed</div>
          {data.permissions.can_close && (
            <div>
              <Button
                label="Close"
                size={Size.Small}
                variant={Variants.Tag}
                onClick={showClose}
              />
            </div>
          )}
        </div>
        {!!data.message && (
          <div className="text-xs text-error">*{data.message}</div>
        )}
      </div>
    );
  };

  const complete = () => {
    return (
      <div>
        <div className="apart">
          <div className="value">Job Closed</div>
          {data.permissions.can_reopen && (
            <div>
              <Button
                label="Undo"
                variant={Variants.Tag}
                size={Size.Small}
                onClick={showReopen}
              />
            </div>
          )}
        </div>
        <div className="mt-1">
          <span className="key">Closed At: </span>
          <span className="key">
            {formatServerTime({
              date: data.date,
              parseFormat: 'YYYY-MM-DDThh:mm:ssZ',
              includeTime: true,
            })}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={clx({ card: !renderInTable, 'px-2 pb-1': renderInTable })}>
      <div className="flex space-x-2">
        <Checkbox checked={data.complete} />
        <div className="w-full">
          {data.complete ? complete() : incomplete()}
        </div>
      </div>
      {close && (
        <DateForm
          title="Close Job"
          label="Job Close Date"
          open={close}
          closeModal={closeClose}
          showComment
          jobId={jobId}
          initial_date={data?.date}
          mutationFn={closeJob}
        />
      )}
      {reopen && (
        <AddComment
          title="Reopen Job"
          open={reopen}
          closeModal={closeReopen}
          mutation={reopenMutation}
          fieldName="comment"
        />
      )}
    </div>
  );
};

export default JobClosed;

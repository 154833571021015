import { useContext } from 'react';

import { JobDetailSidebarContext } from 'context/JobDetailSidebarContext';

const useJobDetailSidebar = () => {
  const detail = useContext(JobDetailSidebarContext);
  return detail;
};

export default useJobDetailSidebar;

import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import clx from 'classnames';
import Tooltip from 'components/Tooltip';
import Icon from 'components/Icon';

type AppProps = {
  label: string;
  labelAction?: React.ReactNode;
  name: string;
  row: number;
  placeholder?: string;
  className?: string;
  onChange: SubmitHandler<any>;
  error?: string;
  required?: boolean;
  info?: string;
  loading?: boolean;
  disabled?: boolean;
};

const TextArea = React.forwardRef<HTMLTextAreaElement, AppProps>(
  (
    {
      name,
      label,
      labelAction,
      row = 4,
      placeholder = '',
      onChange,
      className = '',
      info = '',
      error = null,
      required = false,
      loading = false,
      disabled = false,
    },
    ref
  ) => {
    const inputStyle = clx(
      'w-full rounded-lg outline-none placeholder:text-gray-300 text-text focus:outline-none focus:ring-0',
      {
        'focus:border-error-dark border-error': !!error,
        'focus:border-primary border-border ': !error,
        'bg-gray-200': disabled || loading,
      }
    );

    return (
      <div className={`space-y-1 mb-2 ${className}`}>
        <div className="apart w-full">
          <div className="text-text text-sm apart w-full">
            <div>
              {label}
              {required && <span className="text-error">*</span>}
            </div>
            <div>{labelAction}</div>
          </div>
          {!!info && (
            <div>
              <Tooltip
                tooltipContent={info}
                position="top-0 right-2"
                width="200px"
              >
                <Icon name="info" size={16} className="text-text-light" />
              </Tooltip>
            </div>
          )}
        </div>
        <textarea
          className={inputStyle}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          ref={ref}
          rows={row}
          disabled={loading || disabled}
        />
        {error && <div className="text-error text-xs">*{error}</div>}
      </div>
    );
  }
);

TextArea.displayName = 'TextArea';

export default TextArea;

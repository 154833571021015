import React from 'react';
import clx from 'classnames';
import Icon from 'components/Icon';
import toast from 'react-hot-toast';
import useModal from 'hooks/useModal';
import BottomSheet from 'components/BottomSheet';
import UpdateCell from './UpdateCell';
import { Link } from 'react-router-dom';

type AppProps = {
  job: Record<string, any>;
  iconSize?: number;
  className?: string;
};

const Sms: React.FC<AppProps> = ({ job, iconSize = 32, className = '' }) => {
  const [updateCellModal, showUpdateCellModal, closeUpdateCellModal] =
    useModal();

  const iconStyle = (value: string | null) =>
    clx(className, {
      'text-text-lighter': !value,
      'text-primary': !!value,
    });

  const noClickStyle = (value: string | null) =>
    clx({
      'pointer-events-none': !value,
    });

  return (
    <div
      className="cursor-pointer"
      onClick={
        job.customer?.cell
          ? () => null
          : () => {
              toast.error('No cell found!', { position: 'top-center' });
              showUpdateCellModal();
            }
      }
    >
      <Link
        to={`/jobs/${job.id}/sms/create?initial_recipient=${job.customer.user_id}`}
        className={noClickStyle(job.customer?.cell)}
      >
        <Icon
          name="sms"
          size={iconSize}
          className={iconStyle(job.customer?.cell)}
        />
      </Link>
      <BottomSheet open={updateCellModal}>
        <UpdateCell customer={job.customer} closeModal={closeUpdateCellModal} />
      </BottomSheet>
    </div>
  );
};

export default Sms;

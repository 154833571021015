import React from 'react';
import Checkbox from './Checkbox';
import clx from 'classnames';
import Button, { Size, Variants } from 'components/Button';
import useModal from 'hooks/useModal';
import FileClaim from '../../Claims/components/FileClaim/ModalForm';
import useMedia from 'hooks/useMedia';
import { useNavigate, useParams } from 'react-router-dom';
import { formatServerTime } from 'utils/time';

type AppProps = {
  data: Record<string, any>;
  renderInTable?: boolean;
};

const ClaimFiled: React.FC<AppProps> = ({ data, renderInTable }) => {
  const { isMobile } = useMedia();
  const { jobId = '' } = useParams();
  const [file, showFile, closeFile] = useModal();
  const navigate = useNavigate();

  const incomplete = () => {
    return (
      <div className="apart">
        <div className="value">Claim Not Filed</div>
        <div>
          <Button
            variant={Variants.Tag}
            label="File Claim"
            size={Size.Small}
            onClick={() =>
              isMobile ? navigate(`/jobs/${jobId}/claims/create`) : showFile()
            }
          />
        </div>
      </div>
    );
  };

  const complete = () => {
    return (
      <div>
        <div className="apart">
          <div className="value">Claim Filed</div>
          <div>
            <Button
              variant={Variants.Tag}
              label="Edit Claim"
              size={Size.Small}
              onClick={showFile}
            />
          </div>
        </div>
        <div className="-mt-1">
          <span className="key">Claim Filed By: </span>
          <span className="key">{data.completed_by?.name}</span>
        </div>
        <div className="-mt-1">
          <span className="key">Claim Filed At: </span>
          <span className="key">
            {formatServerTime({
              date: data.date,
              parseFormat: 'YYYY-MM-DDThh:mm:ssZ',
              includeTime: true,
            })}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={clx({ card: !renderInTable, 'px-2 pb-1': renderInTable })}>
      <div className="flex space-x-2">
        <Checkbox checked={data.complete} />
        <div className="w-full">
          {data.complete ? complete() : incomplete()}
        </div>
      </div>
      {file && (
        <FileClaim
          open={file}
          closeModal={closeFile}
          claimId={data?.data?.id}
        />
      )}
    </div>
  );
};

export default ClaimFiled;

import {
  createMortgageCompany,
  updateMortgageCompany,
  useMortgageCompanyFieldConfig,
} from 'queries/insurance';
import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FieldType } from 'utils/enum';
import { actionLabel, readAxiosErr } from 'utils/misc';
import { processFields, processInitialValues, processSchema } from 'utils/form';
import { Layout, InputVariant } from 'components/Form';
import Button from 'components/Button';
import toast from 'react-hot-toast';
import SkeletonLoader, { ContentType } from 'components/SkeletonLoader';

interface IForm {
  name: string;
  claims_phone?: string;
  claims_extension?: string;
  claims_email?: string;
  claims_address: Record<string, string>;
}

type AppProps = {
  companyId?: string;
  claimId?: string;
  onSuccess: (...args: any) => any;
  initial?: Record<string, any>;
};

const Form: React.FC<AppProps> = ({
  companyId = '',
  claimId,
  onSuccess,
  initial = {},
}) => {
  const queryClient = useQueryClient();

  const { data: config, isLoading } = useMortgageCompanyFieldConfig({
    mortgage_company: companyId,
  });

  const addCompany = useMutation(
    (formData: any) =>
      createMortgageCompany(formData, { claim: claimId || '' }),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: async (res: any) => {
        await queryClient.invalidateQueries(['claim-detail']);
        await queryClient.invalidateQueries(['mortgage-companies']);
        return onSuccess(res);
      },
    }
  );

  const editCompany = useMutation(
    (formData: any) => updateMortgageCompany(companyId, formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['claim-detail']);
        queryClient.invalidateQueries(['mortgage-companies']);
        return onSuccess();
      },
    }
  );

  const schema = yup.object(
    processSchema(
      {
        name: yup.string(),
        claims_phone: yup.string(),
        claims_extension: yup.string(),
        claims_email: yup.string().email(),
        claims_address: yup.object().nullable(),
      },
      config
    )
  );

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IForm>({ resolver: yupResolver(schema), defaultValues: initial });

  useEffect(() => {
    if (config) {
      const defaultValues = processInitialValues(config);
      reset({ ...defaultValues, name: initial?.name || defaultValues.name });
    }
  }, [config]);

  const onSubmit: SubmitHandler<IForm> = async (formData) => {
    const { address, city, state, zipcode } = formData.claims_address || {};
    const payload = {
      ...formData,
      claims_address: address,
      claims_city: city,
      claims_state: state,
      claims_zipcode: zipcode,
    };
    if (companyId) {
      editCompany.mutate(payload);
    } else {
      addCompany.mutate(payload);
    }
  };

  const fields = [
    {
      type: FieldType.Input,
      label: 'Name',
      placeholder: 'Mortgage Company Name',
      error: errors.name?.message,
      ...register('name'),
    },
    {
      type: FieldType.Input,
      label: 'Email',
      placeholder: 'jon@gmail.com',
      error: errors.claims_email?.message,
      variant: InputVariant.Email,
      ...register('claims_email'),
    },
    {
      type: FieldType.Input,
      label: 'Phone',
      placeholder: '8888888888',
      error: errors.claims_phone?.message,
      ...register('claims_phone'),
    },
    {
      type: FieldType.Input,
      label: 'Phone extension ',
      placeholder: '123',
      error: errors.claims_extension?.message,
      ...register('claims_extension'),
    },
    {
      type: FieldType.AddressSearch,
      name: 'claims_address',
      label: 'Address',
      error: errors.claims_address?.message,
      setInitial: true,
      control,
    },
  ];

  if (isLoading) {
    return <SkeletonLoader contentType={ContentType.Form} size={4} />;
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Layout
          fields={processFields(fields, config)}
          loading={addCompany.isLoading || editCompany.isLoading}
        />

        <Button
          label={actionLabel(
            companyId,
            'Company',
            addCompany.isLoading || editCompany.isLoading
          )}
          className="mt-8"
          loading={addCompany.isLoading || editCompany.isLoading}
        />
      </form>
    </div>
  );
};

export default Form;

import BottomSheet from 'components/BottomSheet';
import Icon from 'components/Icon';
import React from 'react';
import Form from './Form';

type AppProps = {
  id?: number;
  open: boolean;
  closeModal: () => any;
  initial?: Record<string, any>;
  onSuccess?: (...args: any) => any;
};

const CreateSupplier: React.FC<AppProps> = ({
  id,
  open,
  initial = {},
  closeModal,
  onSuccess = () => null,
}) => {
  return (
    <BottomSheet open={open}>
      <div className="pb-4">
        <div className="apart">
          <div className="page-heading">
            {id ? 'Update' : 'Create'} Supplier
          </div>
          <Icon name="close" onClick={closeModal} />
        </div>
        <div className="mt-6">
          <Form
            id={id}
            onSuccess={(res) => {
              onSuccess?.(res);
              closeModal();
            }}
            initial={initial}
          />
        </div>
      </div>
    </BottomSheet>
  );
};

export default CreateSupplier;

export const templateTypeLabelMap: Record<string, string> = {
  c: 'Cost Plus',
  f: 'Fixed Price',
  g: 'Gross Margin',
  a: 'Adjusted Margin',
};

export enum TemplateType {
  CostPlus = 'c',
  FixedPrice = 'f',
  GrossMargin = 'g',
  AdjustedMargin = 'a',
}

import React, { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FieldType } from 'utils/enum';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createEstimate, useEstimateFieldConfig } from 'queries/estimates';
import toast from 'react-hot-toast';
import { actionLabel, readAxiosErr } from 'utils/misc';
import { processFields, processInitialValues, processSchema } from 'utils/form';
import { Layout } from 'components/Form';
import Button from 'components/Button';
import { useBidTypeChoices, useRepChoices } from 'queries/company';
import Icon from 'components/Icon';

interface IFormValues {
  rep: number;
  bid_type: string;
}

type AppProps = {
  goBack: () => void;
  closeModal: () => void;
};

const CreateEstimate: React.FC<AppProps> = ({ goBack, closeModal }) => {
  const { jobId = '' } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: config, isLoading } = useEstimateFieldConfig({
    job: jobId,
  });

  const addEstimate = useMutation(
    (formData: any) => createEstimate(formData, { job: jobId }),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: (res: any) => {
        queryClient.invalidateQueries(['estimates', { job: jobId }]);
        queryClient.invalidateQueries(['job-detail', jobId]);
        closeModal();
        return navigate(`/jobs/${jobId}/estimates/${res.id}`);
      },
    }
  );

  const schema = yup.object(
    processSchema(
      {
        rep: yup.number(),
        bid_type: yup.string(),
      },
      config
    )
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormValues>({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (config) {
      const defaultValues = processInitialValues(config);
      reset(defaultValues);
    }
  }, [config]);

  const onSubmit: SubmitHandler<IFormValues> = async (formData) => {
    addEstimate.mutate({ ...formData, job: jobId });
  };

  const fields = [
    {
      name: 'rep',
      type: FieldType.AsyncSelect,
      label: 'Select Rep',
      placeholder: 'Select rep',
      error: errors.rep?.message,
      control,
      optionsPromise: useRepChoices,
    },
    {
      name: 'bid_type',
      type: FieldType.AsyncSelect,
      label: 'Select Bid Type',
      placeholder: 'Select Bid Type',
      error: errors.bid_type?.message,
      control,
      optionsPromise: useBidTypeChoices,
      orderByLabel: false,
    },
  ];

  if (isLoading) {
    return <div className="center h-40">Fetching form config...</div>;
  }

  return (
    <div className="pb-4">
      <div className="flex justify-between mb-6">
        <div className="text-lg font-bold">Create Estimate from Scratch</div>
        {!addEstimate.isLoading && (
          <div>
            <Icon name="close" size={30} onClick={closeModal} />
          </div>
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Layout
          fields={processFields(fields, config)}
          loading={addEstimate.isLoading}
        />

        <Button
          label={actionLabel(null, 'Estimate', addEstimate.isLoading)}
          className="mt-8"
          loading={addEstimate.isLoading}
        />
      </form>
      {!addEstimate.isLoading && (
        <div className="center mt-4 cursor-pointer" onClick={goBack}>
          <Icon name="arrow-left" size={16} />
          <div className="text-sm text-text ml-2">Select another option</div>
        </div>
      )}
    </div>
  );
};

export default CreateEstimate;

import Icon, { Companycam } from 'components/Icon';
import React from 'react';
import clx from 'classnames';
import { truncate } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { openGoogleMapUrl } from 'utils/misc';
import Cell from './components/Cell';
import Sms from './components/Sms';
import Email from './components/Email';
import Star from './components/Star';
import useModal from 'hooks/useModal';
import BottomSheet from 'components/BottomSheet';
import JobCoverImage from 'components/JobCoverImage';
import CopyJob from 'components/CopyJob';

type AppProps = {
  job: Record<string, any>;
};

const JobHeader: React.FC<AppProps> = ({ job }) => {
  const jobId = job?.id.toString();
  const navigate = useNavigate();
  const [options, showOptions, closeOptions] = useModal();
  const [selectCover, showSelectCover, closeSelectCover] = useModal();
  const [copy, showCopy, closeCopy] = useModal();

  const iconStyle = (value: string | null) =>
    clx({
      'text-text-lighter': !value,
      'text-primary': !!value,
    });

  const jobOptions = [
    {
      key: 'edit_job',
      label: 'Edit',
      icon: 'edit',
      onClick: () => navigate(`/jobs/${jobId}/edit`),
    },
    {
      key: 'copy_job',
      label: 'Copy Job',
      icon: 'copy',
      onClick: showCopy,
    },
    {
      key: 'use_v1',
      label: 'Use v1',
      icon: 'switch',
      onClick: () =>
        window.location.replace(`https://www.roof.link/jobs/${jobId}`),
    },
  ];

  const bgImg = job?.cover_photo?.preview_url;
  return (
    <div
      className={clx('relative bg-background-darker', {
        'bg-background-darker bg-opacity-50 bg-cover bg-center': !!bgImg,
      })}
      style={{ backgroundImage: `url(${bgImg})` }}
    >
      <div className="absolute right-0 z-10 px-2 pt-3">
        <Star job={job} />
      </div>
      <div className="absolute left-2 top-3 z-10 p-1" onClick={showSelectCover}>
        <Icon name="image" className="text-white" size={18} />
      </div>
      <div
        className={clx(
          'w-full flex flex-col items-center justify-center pt-10 pb-6 bg-background-darker',
          {
            'bg-opacity-50': !!bgImg,
          }
        )}
      >
        <div className="text-2xl font-bold text-white">
          {truncate(job.customer?.name, {
            length: 48,
          })}
        </div>
        <div className="text-xs text-white pt-[2px]">
          {truncate(job.full_address, { length: 60 })}
        </div>
      </div>

      <div
        className={clx(
          'py-6 flex items-center justify-center bg-white rounded-t-3xl',
          {
            'space-x-6': job?.company_cam_id,
            'space-x-8': !job?.company_cam_id,
          }
        )}
      >
        <Cell job={job} />
        <Sms job={job} />
        <Email job={job} />
        <div
          className="cursor-pointer"
          onClick={() =>
            navigate(`/map?lat=${job.latitude}&lng=${job.longitude}`)
          }
        >
          <Icon
            name="location"
            size={30}
            className={`${iconStyle(job.latitude)} relative bottom-[2px]`}
          />
        </div>
        <div
          className="cursor-pointer"
          onClick={() => openGoogleMapUrl(job.latitude, job.longitude)}
        >
          <Icon
            name="direction"
            size={30}
            className={`${iconStyle(job.latitude)} relative bottom-[2px]`}
          />
        </div>
        {job?.company_cam_id && (
          <a
            className="cursor-pointer"
            href={`https://app.companycam.com/projects/${job?.company_cam_id}/photos`}
            target="_blank"
            rel="noreferrer"
          >
            <Companycam className="h-[26px] relative bottom-[1px]" />
          </a>
        )}
        <Icon
          name="more"
          size={28}
          className="text-text-medium relative top-0 -left-1 right-[2px]"
          onClick={showOptions}
        />
      </div>
      <BottomSheet open={options} closeSheet={closeOptions} padded={false}>
        <div className="pb-4">
          <div className="flex justify-between px-4 pt-4">
            <div className="text-2xl font-bold mb-6">Actions</div>
            <div>
              <Icon
                name="close"
                size={30}
                className="cursor-pointer"
                onClick={closeOptions}
              />
            </div>
          </div>
          <div>
            {job?.company_cam_id && (
              <a
                className="cursor-pointer"
                href={`https://app.companycam.com/projects/${job?.company_cam_id}/photos`}
                target="_blank"
                rel="noreferrer"
              >
                <div className="px-4 border-b v-center h-11 space-x-2">
                  <Companycam className="h-[24px]" />
                  <span className="!text-text">Open In Companycam</span>
                </div>
              </a>
            )}
            <div
              className="py-2 px-4 border-b v-center space-x-2"
              onClick={showSelectCover}
            >
              <Icon name="photos" className="text-text-light" size={20} />
              <span className="!text-text">Set Cover Image</span>
            </div>

            {jobOptions.map((option) => (
              <div
                key={option.key}
                onClick={option.onClick}
                className="py-2 px-4 border-b v-center space-x-2"
              >
                <Icon
                  name={option.icon}
                  className="text-text-light"
                  size={18}
                />
                <span className="!text-text">{option.label}</span>
              </div>
            ))}
          </div>
        </div>
      </BottomSheet>
      <JobCoverImage open={selectCover} closeModal={closeSelectCover} />
      <CopyJob id={jobId} open={copy} closeModal={closeCopy} />
    </div>
  );
};

export default JobHeader;

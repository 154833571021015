import React, { useState } from 'react';
import VerifyLead from './components/VerifyLead';
import ScheduleInspection from './components/ScheduleInspection';
import CompleteInspection from './components/CompleteInspection';
import ScheduleAdjMtg from './components/ScheduleAdjMtg';
import RoofLayout from './components/RoofLayout';
import SignPrecontract from './components/SignPrecontract';
import Estimate from './components/Estimate';
import SignContract from './components/SignContract';
import Submit from './components/Submit';
import Approved from './components/Approved';
import { useParams } from 'react-router-dom';
import {
  useJobChecklist,
  useJobDetail,
  useProspectChecklist,
} from 'queries/job';
import SkeletonLoader, { ContentType } from 'components/SkeletonLoader';
import RoofScheduled from './components/RoofScheduled';
import RoofCompleted from './components/RoofCompleted';
import FirstPaymentReceived from './components/FirstPaymentReceived';
import AllTradesScheduled from './components/AllTradesScheduled';
import AllTradesCompleted from './components/AllTradesCompleted';
import FinalPaymentReceived from './components/FinalPaymentReceived';
import JobClosed from './components/JobClosed';
import ClaimFiled from './components/ClaimFiled';
import Icon from 'components/Icon';
import clx from 'classnames';
import ClaimApproved from './components/ClaimApproved';

export const checklistMap: Record<string, any> = {
  verify_lead: VerifyLead,
  schedule_inspection: ScheduleInspection,
  complete_inspection: CompleteInspection,
  schedule_adj_mtg: ScheduleAdjMtg,
  rooflayout: RoofLayout,
  claim_approved: ClaimApproved,
  claim_filed: ClaimFiled,
  sign_pre_contract: SignPrecontract,
  estimate: Estimate,
  sign_contract: SignContract,
  submit: Submit,
  approved: Approved,
  roof_scheduled: RoofScheduled,
  roof_completed: RoofCompleted,
  first_payment_received: FirstPaymentReceived,
  all_trades_scheduled: AllTradesScheduled,
  all_trades_completed: AllTradesCompleted,
  final_payment_received: FinalPaymentReceived,
  job_closed: JobClosed,
};

type AppProps = {
  embedded?: boolean;
};

const ProspectChecklist: React.FC<AppProps> = ({ embedded }) => {
  const { jobId = '' } = useParams();
  const { data: checklist, isLoading } = useProspectChecklist(jobId);

  if (isLoading) {
    return (
      <div className="px-2">
        <SkeletonLoader contentType={ContentType.Card} size={6} />
      </div>
    );
  }

  return (
    <div
      className={clx('mt-4 space-y-2', {
        'pb-6 px-2': !embedded,
        'pb-3': embedded,
      })}
    >
      {embedded && (
        <div className="mt-3 text-sm v-center space-x-2 px-1">
          <div>
            <Icon name="checklist" className="text-text-medium" size={16} />
          </div>
          <div className="font-bold text-text">Prospect Checklist</div>
        </div>
      )}
      <VerifyLead data={checklist.verify_lead} />
      <ScheduleInspection data={checklist.schedule_inspection} />
      <CompleteInspection data={checklist.complete_inspection} />
      <ScheduleAdjMtg data={checklist.schedule_adj_mtg} />
      <RoofLayout data={checklist.rooflayout} />
      <ClaimFiled data={checklist.claim_filed} />
      <SignPrecontract data={checklist.sign_pre_contract} />
      <Estimate data={checklist.estimate} />
      <SignContract data={checklist.sign_contract} />
      <Submit data={checklist.submit} />
      {!embedded && <Approved data={checklist.approved} />}
    </div>
  );
};

const JobChecklist = () => {
  const { jobId = '' } = useParams();
  const { data: checklist, isLoading } = useJobChecklist(jobId);
  const [prospect, setProspect] = useState(false);

  if (isLoading) {
    return (
      <div className="px-2">
        <SkeletonLoader contentType={ContentType.Card} size={6} />
      </div>
    );
  }

  return (
    <div className="mt-4 px-2 pb-6 space-y-2">
      <div
        className="v-center cursor-pointer"
        onClick={() => setProspect((t) => !t)}
      >
        <div>
          <Icon
            name={prospect ? 'caret-down-outline' : 'caret-right-outline'}
            className="text-primary"
          />
        </div>
        <div className="text-sm text-primary">
          {prospect ? 'Close' : 'View'} prospect checklist
        </div>
      </div>
      {prospect && <ProspectChecklist embedded />}
      <div
        className={clx('pt-3 mt-3 text-sm v-center space-x-2 px-1', {
          'border-t ': !prospect,
        })}
      >
        <div>
          <Icon name="checklist" className="text-text-medium" size={16} />
        </div>
        <div className="font-bold text-text">Job Checklist</div>
      </div>
      <Approved data={checklist.approved} />
      <RoofScheduled data={checklist.roof_scheduled} />
      <RoofCompleted data={checklist.roof_completed} />
      <FirstPaymentReceived data={checklist.first_payment_received} />
      <AllTradesScheduled data={checklist.all_trades_scheduled} />
      <AllTradesCompleted data={checklist.all_trades_completed} />
      <FinalPaymentReceived data={checklist.final_payment_received} />
      <JobClosed data={checklist.job_closed} />
    </div>
  );
};

const Checklist = () => {
  const { jobId = '' } = useParams();
  const { data: job } = useJobDetail(jobId);

  if (job?.date_approved) {
    return <JobChecklist />;
  }

  return <ProspectChecklist />;
};

export default Checklist;

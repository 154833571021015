import BottomSheet from 'components/BottomSheet';
import Button, { Size, Variants } from 'components/Button';
import Icon from 'components/Icon';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type AppProps = {
  open: boolean;
  closeModal: () => any;
  job: string;
};

const CreateRoofDrawing: React.FC<AppProps> = ({ open, job, closeModal }) => {
  const navigate = useNavigate();

  return (
    <BottomSheet open={open} closeSheet={closeModal}>
      <div className="pb-4">
        <div className="flex justify-between mb-6">
          <div className="text-xl text-text font-bold">
            No Roof Drawing Found
          </div>
          <div>
            <Icon
              name="close"
              size={30}
              onClick={closeModal}
              className="cursor-pointer"
            />
          </div>
        </div>
        <div>You need a roof drawing to proceed with this action.</div>

        <div className="flex justify-between space-x-4 mt-8">
          <Button
            label="Create Roof Drawing"
            size={Size.Medium}
            onClick={() => navigate(`/jobs/${job}/rooflayout`)}
          />
          <Button
            label="Close"
            size={Size.Medium}
            variant={Variants.Error}
            onClick={closeModal}
          />
        </div>
      </div>
    </BottomSheet>
  );
};

export default CreateRoofDrawing;

export enum FieldType {
  Input = 'input',
  Number = 'number',
  TextArea = 'textarea',
  Select = 'select',
  AsyncSelect = 'asyncselect',
  AsyncMultiSelect = 'asyncmultiselect',
  AddressSearch = 'addresssearch',
  Multiselect = 'multiselect',
  Checkbox = 'checkbox',
  DatePicker = 'datepicker',
  Toggle = 'toggle',
  File = 'file',
  MultiFile = 'multifile',
  ColorPicker = 'colorpicker',
  DateFilter = 'datefilter',
  JobPhotos = 'jobphotos',
  JobDocs = 'jobdocs',
  Range = 'range',
}

export enum Sort {
  Asc = 'asc',
  Dsc = 'dsc',
}

export enum FileType {
  Pdf = 'pdf',
  Doc = 'doc',
  Excel = 'xls',
  Txt = 'txt',
  Img = 'img',
}

export enum QueueItemType {
  Photo = 'photo',
  Document = 'document',
  Refetch = 'refetch',
}

export enum ActionType {
  Prev = 'PREV',
  Next = 'NEXT',
  Today = 'TODAY',
}

export enum TimeZoneType {
  CST = 'US/Central',
  EST = 'US/Eastern',
  MDT = 'US/Mountain',
  MST = 'MST',
  PST = 'US/Pacific',
}

export enum SpanType {
  Month = 'month',
  Year = 'year',
  All = 'all',
  Custom = 'custom',
}

export enum WidgetView {
  Table = 'TABLE',
  Graph = 'GRAPH',
}

import React from 'react';
import { FieldType } from 'utils/enum';
import AddressSearch from './components/AddressSearch';
import AsyncSelect from './components/AsyncSelect';
import AsyncMultiSelect from './components/AsynMultiSelect';
import Checkbox from './components/Checkbox';
import DatePicker from './components/DatePicker';
import Input from './components/Input';
import Select from './components/Select';
import TextArea from './components/Textarea';
import Toggle from './components/Toggle';
import FileUpload from './components/FileUpload';
import MultiFileUpload from './components/MultiFileUpload';
import ColorPicker from './components/ColorPicker';
import DateFilter from './components/DateFilter';
import Number from './components/Number';
import JobPhotoSelector from './components/JobPhotoSelector';
import JobDocSelector from './components/JobDocSelector';
import RangeSelector from './components/RangeSelector';
import MultiSelect from './components/MultiSelect';

const fieldMap: Record<string, any> = {
  [FieldType.Input]: Input,
  [FieldType.Number]: Number,
  [FieldType.Select]: Select,
  [FieldType.Multiselect]: MultiSelect,
  [FieldType.TextArea]: TextArea,
  [FieldType.AsyncSelect]: AsyncSelect,
  [FieldType.AsyncMultiSelect]: AsyncMultiSelect,
  [FieldType.AddressSearch]: AddressSearch,
  [FieldType.Checkbox]: Checkbox,
  [FieldType.DatePicker]: DatePicker,
  [FieldType.Toggle]: Toggle,
  [FieldType.File]: FileUpload,
  [FieldType.MultiFile]: MultiFileUpload,
  [FieldType.ColorPicker]: ColorPicker,
  [FieldType.DateFilter]: DateFilter,
  [FieldType.JobPhotos]: JobPhotoSelector,
  [FieldType.JobDocs]: JobDocSelector,
  [FieldType.Range]: RangeSelector,
};

type AppProps = {
  fields: Array<Record<string, any>>;
  loading?: boolean;
  className?: string;
};

const Layout: React.FC<AppProps> = ({
  fields,
  loading,
  className = 'space-y-4',
}) => {
  return (
    <div className={className}>
      {fields.map((field) => {
        const Component = fieldMap[field.type];
        return <Component key={field.name} {...field} loading={loading} />;
      })}
    </div>
  );
};

export default Layout;

import React, { useState, useRef } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop, Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import clx from 'classnames';
import Button, { Size } from 'components/Button';
import BottomSheet from 'components/BottomSheet';
import { v4 } from 'uuid';
import { getExifInfo } from 'components/PhotoList/utils';
import { useMutation } from '@tanstack/react-query';
import { createPhoto, markPhotoUpload, uploadToS3 } from 'queries/photos';
import usePageLoader from 'hooks/usePageLoader';
import Icon, { Loader } from 'components/Icon';
import useMedia from 'hooks/useMedia';

type AppProps = {
  photo: Record<string, any>;
  closeModal: () => void;
  open: boolean;
  q?: Record<string, any>;
  submitMutation?: any;
};

const centerAspectCrop = (
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
};

const ASPECT_RATIO = 12 / 16;

const FixedCropperTool: React.FC<AppProps> = ({
  photo,
  open,
  closeModal,
  q,
  submitMutation,
}) => {
  const { startLoader, stopLoader } = usePageLoader();
  const [loading, setLoading] = useState(true);
  const [crop, setCrop] = useState<Crop>();
  const { isMobile } = useMedia();

  const imgRef = useRef<HTMLImageElement>(null);

  const [canvasData, setCanvasData] = useState<HTMLCanvasElement>();
  const uploadPhotoMutation = useMutation((formData: any) =>
    createPhoto(formData)
  );
  const getCroppedImg = async (image: HTMLImageElement, crop: Crop) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx?.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    setCanvasData(canvas);
  };

  const handleComplete = async (crop: Crop) => {
    if (imgRef.current && crop.width && crop.height) {
      await getCroppedImg(imgRef.current, crop);
    }
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, ASPECT_RATIO));
    setLoading(false);
  };

  const uploadPhotoPromise = async (photo: any) => {
    startLoader('Uploading your cropped photo. Please wait...');
    const payload = {
      ...q,
      name: photo.name,
      tags: [],
      is_video: false,
      exif: {},
      cover: true,
    };

    await getExifInfo(photo, (exif: Record<string, any>) => {
      if (exif) {
        payload.exif = exif;
      }
    });

    const { id, upload_url: uploadUrl } = await uploadPhotoMutation.mutateAsync(
      payload
    );

    // Upload to s3
    const formData = new FormData();
    Object.keys(uploadUrl.fields || {}).forEach((key) => {
      formData.append(key, uploadUrl.fields[key]);
    });
    formData.append('file', photo);
    await uploadToS3(uploadUrl.url, formData);
    await markPhotoUpload(id);
    return id;
  };

  const _handleSubmit = () => {
    canvasData?.toBlob(
      async (blob) => {
        if (blob) {
          const file = new File([blob], `${v4()}.jpeg`, { type: 'image/jpeg' });
          const photoId = await uploadPhotoPromise(file);
          await submitMutation?.mutateAsync(photoId);
          stopLoader();
        }
      },
      'image/jpeg',
      0.97
    );
  };

  const _saveOriginal = async () => {
    startLoader();
    await submitMutation?.mutateAsync(photo.value);
    stopLoader();
  };

  return (
    <BottomSheet open={open} maxWidth="max-w-2xl" padded={false}>
      <div className="w-full">
        <div className="w-full flex justify-between px-2 pt-2">
          <div className="mb-4 font-bold text-xl">Crop Image</div>
          <div>
            <Icon name="close" onClick={closeModal} />
          </div>
        </div>

        <div>
          <ReactCrop
            className="w-full flex justify-center"
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => handleComplete(c)}
            aspect={ASPECT_RATIO}
            keepSelection
          >
            {loading && (
              <div className="h-[240px] flex flex-col justify-center items-center">
                <Loader />
                <span>Loading...</span>
              </div>
            )}
            <div className={clx({ hidden: loading })}>
              <img
                className="w-full object-contain max-h-[480px]"
                ref={imgRef}
                alt="Crop"
                src={photo.url}
                onLoad={onImageLoad}
                crossOrigin="anonymous"
              />
            </div>
          </ReactCrop>
          <div className="flex justify-between w-full mt-2 px-2 pb-2">
            <div>
              <Button
                label="Set Original"
                size={Size.Medium}
                onClick={_saveOriginal}
                className="max-w-min"
              />
            </div>
            <div>
              <Button
                leftIcon="crop"
                label="Crop And Set"
                size={Size.Medium}
                onClick={_handleSubmit}
                className="max-w-min"
              />
            </div>
          </div>
        </div>
      </div>
    </BottomSheet>
  );
};

export default FixedCropperTool;

import DocumentCard from 'components/DocumentList/components/DocumentCard';
import Icon from 'components/Icon';
import { useAllDocuments } from 'queries/documents';
import React from 'react';
import { useParams } from 'react-router-dom';

type AppProps = {
  label: string;
  q?: Record<string, any>;
  closeModal: () => any;
};

const Documents: React.FC<AppProps> = ({ label, closeModal, q = {} }) => {
  const { jobId = '' } = useParams();

  const { data, status } = useAllDocuments({
    job: jobId,
    date_deleted__isnull: true,
    ...q,
  });

  const renderDocuments = () => {
    return (
      <div className="space-y-2">
        {data?.results?.map((doc: any) => (
          <DocumentCard key={doc.id} document={doc} />
        ))}
      </div>
    );
  };

  return (
    <div className="pb-4">
      <div className="flex justify-between">
        <div className="page-heading mb-6">{label}</div>
        <div>
          <Icon name="close" size={32} onClick={closeModal} />
        </div>
      </div>
      <div>
        {status === 'loading' ? <div>Loading...</div> : renderDocuments()}
      </div>
    </div>
  );
};

export default Documents;

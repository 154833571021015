import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import apiService from 'utils/apiService';

// ******** Get Suppliers *********
export const getSuppliers = async ({ pageParam = 1, ...q }) => {
  const { data } = await apiService.get('/api/light/suppliers/', {
    ...q,
    page: pageParam,
  });
  return data;
};

export const useSuppliers = (q?: Record<string, any>) => {
  return useInfiniteQuery(
    ['suppliers', q],
    ({ pageParam }) => getSuppliers({ pageParam, ...q }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next_page ?? undefined;
      },
      staleTime: 5 * 60 * 1000,
    }
  );
};

export const useDesktopSuppliers = (q?: Record<string, any>, page = 1) => {
  return useQuery(
    ['suppliers', 'desktop', q, { page }],
    () => getSuppliers({ pageParam: page, ...q }),
    {
      staleTime: 15 * 60 * 1000,
      keepPreviousData: true,
    }
  );
};

// ********* Get Supplier Detail *********
const getSupplierDetail = async (id: string) => {
  const { data } = await apiService.get(`/api/light/suppliers/${id}/`);
  return data;
};

export const useSupplierDetail = (id: string) => {
  return useQuery(['supplier-detail', id], () => getSupplierDetail(id), {
    staleTime: 15 * 60 * 1000,
  });
};

// ********** Get Supplier field config **********
const getSupplierFieldConfig = async (q: Record<string, any>) => {
  const { data } = await apiService.get(
    `/api/light/suppliers/fields_config/`,
    q
  );
  return data;
};

interface IFieldConfig {
  csr?: number;
}

export const useSupplierFieldConfig = (q: IFieldConfig = {}) => {
  const { csr } = q;
  return useQuery(
    ['supplier-field-config', { csr }],
    () => getSupplierFieldConfig({ csr }),
    {
      staleTime: csr ? 0 : Infinity,
    }
  );
};

// ****** Create supplier *******
export const createSupplier = async (payload: Record<string, any>) => {
  const { data } = await apiService.post('/api/light/suppliers/', payload);
  return data;
};

export const updateSupplier = async (
  id: number,
  payload: Record<string, any>
) => {
  const { data } = await apiService.put(`/api/light/suppliers/${id}/`, payload);
  return data;
};

export const removeSupplier = async (id: string) => {
  const { data } = await apiService.post(
    `/api/light/suppliers/${id}/remove_from_company/`
  );
  return data;
};

import React, { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FieldType } from 'utils/enum';
import { useParams } from 'react-router-dom';
import { useRLTemplateAndSupplierFieldConfig } from 'queries/estimates';
import { processFields, processInitialValues, processSchema } from 'utils/form';
import { Layout } from 'components/Form';
import Button from 'components/Button';
import {
  useBidTypeChoices,
  useRepChoices,
  useRLColorChoices,
} from 'queries/company';
import { useRLSupplierChoices, useRLTemplateChoices } from 'queries/job';

interface IFormValues {
  rep: number;
  bid_type: string;
  supplier: number;
  template: number;
  color: string;
}

type AppProps = {
  onSubmit: (...args: any) => any;
};

const CreateEstimateFromDrawing: React.FC<AppProps> = ({ onSubmit }) => {
  const { jobId = '' } = useParams();
  const { data: config, isLoading } =
    useRLTemplateAndSupplierFieldConfig(jobId);

  const schema = yup.object(
    processSchema(
      {
        template: yup.number(),
        rep: yup.number(),
        supplier: yup.number(),
        color: yup.string(),
        bid_type: yup.string(),
      },
      config
    )
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormValues>({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (config) {
      const defaultValues = processInitialValues(config);
      reset(defaultValues);
    }
  }, [config]);

  const _onSubmit: SubmitHandler<IFormValues> = async (formData) => {
    onSubmit(formData);
  };

  const fields = [
    {
      name: 'template',
      type: FieldType.AsyncSelect,
      label: 'Select Template',
      placeholder: 'Select Template',
      error: errors.template?.message,
      control,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      optionsPromise: () => useRLTemplateChoices(jobId),
    },
    {
      name: 'supplier',
      type: FieldType.AsyncSelect,
      label: 'Select Supplier',
      placeholder: 'Select Supplier',
      error: errors.supplier?.message,
      control,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      optionsPromise: () => useRLSupplierChoices(jobId),
    },
    {
      name: 'color',
      type: FieldType.AsyncSelect,
      label: 'Select Color',
      placeholder: 'Select Color',
      error: errors.color?.message,
      control,
      optionsPromise: useRLColorChoices,
    },
    {
      name: 'rep',
      type: FieldType.AsyncSelect,
      label: 'Select Rep',
      placeholder: 'Select rep',
      error: errors.rep?.message,
      control,
      optionsPromise: useRepChoices,
    },
    {
      name: 'bid_type',
      type: FieldType.AsyncSelect,
      label: 'Select Bid Type',
      placeholder: 'Select Bid Type',
      error: errors.bid_type?.message,
      control,
      optionsPromise: useBidTypeChoices,
      orderByLabel: false,
    },
  ];

  if (isLoading) {
    return <div className="center h-40">Fetching form config...</div>;
  }

  return (
    <div>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <Layout className="mt-4" fields={processFields(fields, config)} />
        <Button label="Create Estimate" className="mt-8" />
      </form>
    </div>
  );
};

export default CreateEstimateFromDrawing;

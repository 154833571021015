import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FieldType } from 'utils/enum';
import { Layout } from 'components/Form';
import Button from 'components/Button';
import {
  useBidTypeChoices,
  useEstimateTemplates,
  useRepChoices,
} from 'queries/company';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createEstimateFromTemplate,
  useEstimateFromTemplateFieldConfig,
} from 'queries/estimates';
import toast from 'react-hot-toast';
import { readAxiosErr } from 'utils/misc';
import { processFields, processSchema } from 'utils/form';

interface IFormValues {
  template: number;
  rep: number;
  bid_type: string;
}

const Form = () => {
  const navigate = useNavigate();
  const { jobId = '' } = useParams();
  const { data: config, isLoading } = useEstimateFromTemplateFieldConfig(jobId);

  const schema = yup.object(
    processSchema(
      {
        template: yup.number(),
        rep: yup.number(),
        bid_type: yup.string(),
      },
      config
    )
  );

  const createMutation = useMutation(
    (formData: any) => createEstimateFromTemplate(jobId, formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: (res: any) => {
        return navigate(`/jobs/${jobId}/estimates/${res}`);
      },
    }
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>({ resolver: yupResolver(schema) });

  const _onSubmit: SubmitHandler<IFormValues> = async (formData) => {
    createMutation.mutate(formData);
  };

  const fields = [
    {
      name: 'template',
      type: FieldType.AsyncSelect,
      label: 'Select Template',
      placeholder: 'Select Template',
      error: errors.template?.message,
      control,
      optionsPromise: useEstimateTemplates,
    },
    {
      name: 'rep',
      type: FieldType.AsyncSelect,
      label: 'Select Rep',
      placeholder: 'Select rep',
      error: errors.rep?.message,
      control,
      optionsPromise: useRepChoices,
    },
    {
      name: 'bid_type',
      type: FieldType.AsyncSelect,
      label: 'Select Bid Type',
      placeholder: 'Select Bid Type',
      error: errors.bid_type?.message,
      control,
      optionsPromise: useBidTypeChoices,
      orderByLabel: false,
    },
  ];

  if (isLoading) {
    return <div className="center h-40">Fetching form config...</div>;
  }

  return (
    <div>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <Layout className="mt-4" fields={processFields(fields, config)} />
        <Button
          label="Create Estimate"
          className="mt-8"
          loading={createMutation.isLoading}
        />
      </form>
    </div>
  );
};

export default Form;

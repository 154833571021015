import React from 'react';
import { useController } from 'react-hook-form';
import { Switch } from '@headlessui/react';

type AppProps = {
  label: string;
  name: string;
  className?: string;
  control: any;
  error?: string;
  loading?: boolean;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
};

const Toggle: React.FC<AppProps> = ({
  name,
  label,
  control,
  className = '',
  error = null,
  loading = false,
  onChange = () => null,
  disabled = false,
}) => {
  const { field } = useController({ name, control });

  const handleChange = (v: any) => {
    field.onChange(v);
    onChange(v);
  };

  return (
    <div className={className}>
      <div className="v-center space-x-1">
        <Switch
          checked={field.value}
          onChange={handleChange}
          disabled={loading || disabled}
          className={`${field.value ? 'bg-primary' : 'bg-gray-300'}
          relative inline-flex h-[20px] w-[36px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
        >
          <span
            aria-hidden="true"
            className={`${field.value ? 'translate-x-4' : 'translate-x-0'}
            pointer-events-none inline-block h-[16px] w-[16px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
        <div className="text-sm">{label}</div>
      </div>

      {error && <div className="text-error text-xs mt-1">*{error}</div>}
    </div>
  );
};

Toggle.displayName = 'Toggle';

export default Toggle;

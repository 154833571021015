import BottomSheet from 'components/BottomSheet';
import Button, { Size, Variants } from 'components/Button';
import Icon from 'components/Icon';
import useModal from 'hooks/useModal';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Checkbox from './Checkbox';
import clx from 'classnames';

type AppProps = {
  data: Record<string, any>;
  renderInTable?: boolean;
};

const RoofLayout: React.FC<AppProps> = ({ data, renderInTable = false }) => {
  const navigate = useNavigate();
  const { jobId = '' } = useParams();
  const [actionOpen, showActions, closeActions] = useModal();

  const _complete = data.drawing?.complete || data.measurements?.complete;

  const renderDraw = () => {
    if (data.drawing?.complete) {
      return (
        <Button
          label="View Drawing"
          size={Size.Big}
          variant={Variants.Primary}
          onClick={() => navigate(`/jobs/${jobId}/rooflayout`)}
        />
      );
    }

    return (
      <Button
        label="Draw Roof"
        size={Size.Big}
        variant={Variants.Tag}
        onClick={() => navigate(`/jobs/${jobId}/rooflayout`)}
      />
    );
  };

  const renderMeasurement = () => {
    if (data.measurements?.complete) {
      return (
        <Button
          label="View Measurements"
          size={Size.Big}
          variant={Variants.Primary}
          onClick={() => navigate(`/jobs/${jobId}/manualrooflayouts`)}
        />
      );
    }

    return (
      <Button
        label="Enter Measurements"
        size={Size.Big}
        variant={Variants.Tag}
        onClick={() => navigate(`/jobs/${jobId}/manualrooflayouts`)}
      />
    );
  };

  return (
    <div className={clx({ card: !renderInTable, 'px-2 pb-1': renderInTable })}>
      <div className="flex space-x-2">
        <Checkbox checked={_complete} />
        <div className="w-full apart">
          <div className="value">
            {_complete ? 'Roof Layout' : 'Roof Layout Not Completed'}
          </div>
          <div>
            <Button
              variant={Variants.Tag}
              label={_complete ? 'View' : 'Actions'}
              size={Size.Small}
              onClick={showActions}
            />
          </div>
        </div>
      </div>
      <BottomSheet open={actionOpen}>
        <div className="pb-4">
          <div className="flex justify-between">
            <div className="page-heading">Actions</div>
            <div>
              <Icon name="close" size={32} onClick={closeActions} />
            </div>
          </div>
          <div className="mt-4 space-y-4">
            {renderDraw()}
            {renderMeasurement()}
          </div>
        </div>
      </BottomSheet>
    </div>
  );
};

export default RoofLayout;

import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import clx from 'classnames';
import { Select } from '..';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { now, parseDate, startOfDay, xDaysAgo } from 'utils/time';

interface IOptions {
  value: string;
  label: string;
}

interface IForm {
  range: string;
}

type AppProps = {
  label: string;
  name: string;
  options: IOptions[];
  placeholder?: string;
  isClearable?: boolean;
  className?: string;
  control: any;
  required?: boolean;
  error?: string;
  isCreatable?: boolean;
  loading?: boolean;
  isSearchable?: boolean;
  position?: string;
};

const DateFilter: React.FC<AppProps> = ({
  name,
  label,
  control,
  className = '',
  error = null,
  required = false,
  loading = false,
}) => {
  const { field } = useController({ name, control });

  const [isnull, setIsNull] = useState<boolean | undefined>(
    field.value?.isnull
  );
  const [startDate, setStartDate] = useState<Date | null>(
    field.value?.gte
      ? parseDate(field.value.gte, 'DD, MMM YYYY').toDate()
      : xDaysAgo(30)
  );
  const [endDate, setEndDate] = useState<Date | null>(
    field.value?.lte
      ? parseDate(field.value.lte, 'DD, MMM YYYY').toDate()
      : now()
  );

  const schema = yup.object({
    range: yup.string(),
  });

  const {
    control: rangeControl,
    watch,
    setValue,
  } = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      range: field?.value?.within,
    },
  });

  const _range = watch('range');

  useEffect(() => {
    if (_range === 'any') {
      field.onChange({
        ...field.value,
        gte: undefined,
        lte: undefined,
        within: undefined,
      });
    } else if (_range === 'custom' && startDate && endDate) {
      field.onChange({
        ...field.value,
        within: undefined,
        gte: startOfDay(startDate)?.toISOString(),
        lte: startOfDay(endDate)?.toISOString(),
      });
    } else {
      field.onChange({
        ...field.value,
        within: _range,
        gte: undefined,
        lte: undefined,
      });
    }
  }, [startDate, endDate, _range]);

  const handleSelectRadio = (clickedItem: string) => {
    if (clickedItem === 'hasValue') {
      if (isnull === undefined) {
        setIsNull(false);
        field.onChange({ ...field.value, isnull: false });
      } else if (isnull === true) {
        setIsNull(false);
        field.onChange({ ...field.value, isnull: false });
      } else {
        setIsNull(undefined);
        setValue('range', '');
        field.onChange({ ...field.value, isnull: undefined });
      }
    } else {
      if (isnull === true) {
        setIsNull(undefined);
        setValue('range', '');
        field.onChange({ ...field.value, isnull: undefined });
      } else {
        setIsNull(true);
        setValue('range', '');
        field.onChange({ ...field.value, isnull: true });
      }
    }
  };

  const datePickerStyle = clx(
    'w-full outline-none placeholder:text-text-light text-text text-sm rounded-lg h-10 focus:outline-none focus:ring-0',
    {
      'focus:border-error-dark border-error': !!error,
      'focus:border-primary border-border ': !error,
    }
  );

  const renderDateRange = () => {
    return (
      <div className="v-center space-x-2">
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          className={datePickerStyle}
        />
        <div>-</div>
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          className={datePickerStyle}
        />
      </div>
    );
  };

  return (
    <div className={`space-y-1 mb-2 ${className}`} key={name}>
      <div className="text-text-medium text-xs">
        {label}
        {required && <span className="text-error">*</span>}
      </div>
      <div className="border border-border px-2 py-3 rounded-lg">
        <div className="v-center space-x-8 ">
          <div className="v-center space-x-1">
            <input
              type="radio"
              className="outline-none focus:outline-none focus:ring-0 rounded-full h-4 w-4"
              name={`${name}_hasValue`}
              checked={isnull === false}
              disabled={loading}
              onClick={() => handleSelectRadio('hasValue')}
            />
            <span className="text-xs text-text-medium">Has Value</span>
          </div>
          <div className="v-center space-x-1">
            <input
              type="radio"
              className="outline-none focus:outline-none focus:ring-0 rounded-full h-4 w-4"
              name={`${name}_hasValue`}
              checked={isnull == true}
              disabled={loading}
              onClick={() => handleSelectRadio('noValue')}
            />
            <span className="text-xs text-text-medium">No Value</span>
          </div>
        </div>
        {isnull === false && (
          <div className="mt-4">
            <div>
              <Select
                name="range"
                label="Select Range"
                control={rangeControl}
                options={[
                  { label: 'Any', value: 'any' },
                  { label: 'Within Last 24 hrs', value: '1_days' },
                  { label: 'Within Last 3 days', value: '3_days' },
                  { label: 'Within Last 7 days', value: '7_days' },
                  { label: 'Within Last 14 days', value: '14_days' },
                  { label: 'Within Last 30 days', value: '30_days' },
                  { label: 'Custom Range', value: 'custom' },
                ]}
              />
            </div>
            {_range === 'custom' && <div>{renderDateRange()}</div>}
          </div>
        )}
      </div>

      {error && <div className="text-error text-xs">*{error}</div>}
    </div>
  );
};

DateFilter.displayName = 'DateFilter';

export default DateFilter;

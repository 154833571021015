import { useMutation, useQueryClient } from '@tanstack/react-query';
import BottomSheet from 'components/BottomSheet';
import Icon from 'components/Icon';
import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { copyRoofTemplate } from 'queries/rooftemplates';
import toast from 'react-hot-toast';
import { readAxiosErr } from 'utils/misc';
import { useNavigate } from 'react-router-dom';
import { templateTypeLabelMap } from '../constants';
import { FieldType } from 'utils/enum';
import useModal from 'hooks/useModal';
import { Layout } from 'components/Form';
import Button from 'components/Button';
import TemplateTypeInfo from './TemplateTypeInfo';

interface IFormValues {
  name: string;
  template_type: string;
}

type AppProps = {
  data: Record<string, any>;
  open: boolean;
  closeModal: () => any;
};

const CopyTemplate: React.FC<AppProps> = ({ data, open, closeModal }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [info, showInfo, closeInfo] = useModal();

  const schema = yup.object({
    name: yup.string(),
    template_type: yup.string(),
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: `Copy of ${data.name}`,
      template_type: data.template_type,
    },
  });

  const copyMutation = useMutation(
    (formData: any) => copyRoofTemplate(data.id, formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: (res: any) => {
        queryClient.invalidateQueries(['rooftemplates']);
        return navigate(`/rooftemplates/${res.id}/builder`);
      },
    }
  );

  const fields = [
    {
      type: FieldType.Input,
      label: 'Name',
      placeholder: 'Name',
      error: errors.name?.message,
      ...register('name'),
    },
    {
      name: 'template_type',
      type: FieldType.Select,
      label: 'Template Type',
      placeholder: 'Select Template Type',
      error: errors.template_type?.message,
      control,
      options: Object.entries(templateTypeLabelMap).map(([k, v]) => ({
        label: v,
        value: k,
      })),
      customInfo: (
        <Icon
          name="info"
          size={18}
          className="text-text-medium"
          onClick={showInfo}
        />
      ),
    },
  ];

  const onSubmit: SubmitHandler<IFormValues> = async (formData) => {
    copyMutation.mutate(formData);
  };

  return (
    <>
      <BottomSheet open={open}>
        <div className="pb-4">
          <div className="flex justify-between pb-4">
            <div className="page-heading">Copy Roof Template</div>
            <div>
              <Icon
                name="close"
                size={32}
                onClick={closeModal}
                className="cursor-pointer"
              />
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Layout fields={fields} loading={copyMutation.isLoading} />
            <Button
              label="Copy Roof Template"
              className="mt-8"
              loading={copyMutation.isLoading}
            />
          </form>
        </div>
      </BottomSheet>
      {info && <TemplateTypeInfo open={info} closeModal={closeInfo} />}
    </>
  );
};

export default CopyTemplate;

import React from 'react';
import { useController } from 'react-hook-form';
import clx from 'classnames';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { GREY_COLOR, PRIMARY_COLOR, TEXT_COLOR } from 'utils/constants';
import { find } from 'lodash';

interface IOptions {
  value: string;
  label: string;
}

type AppProps = {
  label: string;
  name: string;
  options: IOptions[];
  placeholder?: string;
  isClearable?: boolean;
  className?: string;
  control: any;
  required?: boolean;
  error?: string;
  isCreatable?: boolean;
  loading?: boolean;
  isSearchable?: boolean;
  position?: string;
  disabled?: boolean;
  postSelect?: (selected: any) => any;
  customInfo?: React.ReactNode;
};

const RSelect: React.FC<AppProps> = ({
  name,
  label,
  control,
  placeholder = 'Select option',
  isClearable = false,
  className = '',
  options = [],
  error = null,
  required = false,
  loading = false,
  isCreatable = false,
  isSearchable = true,
  position = 'auto',
  disabled = false,
  postSelect = null,
  customInfo,
}) => {
  const { field } = useController({ name, control });
  const selectStyle = clx(
    'w-full rounded-lg outline-none focus:outline-none focus:ring-0',
    {
      'focus:border-error-dark border-error': !!error,
      'focus:border-primary border-border ': !error,
      'bg-gray-200': disabled || loading,
    }
  );

  const RSelect = isCreatable ? CreatableSelect : Select;
  const _value = find(options, { value: field.value }) || null;

  const onChange = (value: string) => {
    field.onChange(value);
    if (postSelect) {
      postSelect(find(options, { value }));
    }
  };

  return (
    <>
      <div className={`space-y-1 mb-2 ${className}`}>
        <div className="apart">
          <div className="text-text text-sm">
            {label}
            {required && <span className="text-error">*</span>}
          </div>
          {customInfo}
        </div>
        <RSelect
          {...field}
          onChange={(v: any) => onChange(v?.value)}
          value={_value}
          options={options}
          placeholder={<span className="text-text-light">{placeholder}</span>}
          isClearable={isClearable}
          className={selectStyle}
          styles={customStyles}
          components={{ IndicatorSeparator: () => null }}
          isDisabled={loading || disabled}
          isSearchable={isSearchable ? options.length > 8 : isSearchable}
          menuPortalTarget={document.body}
          // @ts-ignore
          menuPlacement={position}
        />
        {error && <div className="text-error text-xs">*{error}</div>}
      </div>
    </>
  );
};

RSelect.displayName = 'Select';

export default RSelect;

const customStyles = {
  input: (provided: any) => ({
    ...provided,
    color: TEXT_COLOR,
    height: '32px',
    'input:focus': {
      boxShadow: 'none',
      borderColor: 'green',
    },
  }),
  control: (base: any, state: any) => ({
    ...base,
    boxShadow: 'none',
    color: TEXT_COLOR,
    borderRadius: 8,
    height: '42px',
    borderColor: state.isFocused ? PRIMARY_COLOR : GREY_COLOR,
  }),
  singleValue: (base: any) => ({
    ...base,
    color: TEXT_COLOR,
  }),
  option: (base: any, { isFocused }: any) => {
    let backgroundColor = '#fff';
    let color = TEXT_COLOR;
    if (isFocused) {
      backgroundColor = PRIMARY_COLOR;
      color = '#fff';
    }
    return {
      ...base,
      backgroundColor,
      color,
    };
  },
  noOptionsMessage: (base: any) => ({
    ...base,
    fontSize: 16,
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 99999,
  }),
};

import BottomSheet from 'components/BottomSheet';
import Button, { Size, Variants } from 'components/Button';
import Confirmation from 'components/Confirmation';
import useModal from 'hooks/useModal';
import { completeInspection } from 'queries/job';
import React from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { readAxiosErr } from 'utils/misc';
import { formatServerTime } from 'utils/time';
import Checkbox from './Checkbox';
import CreateInspection from './CreateInspection';
import Icon from 'components/Icon';
import clx from 'classnames';

type AppProps = {
  data: Record<string, any>;
  renderInTable?: boolean;
};

const CompleteInspection: React.FC<AppProps> = ({
  data,
  renderInTable = false,
}) => {
  const { jobId = '' } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [createOpen, showCreateModal, closeCreateModal] = useModal();
  const [confirmOpen, showConfirmModal, closeConfirmModal] = useModal();
  const [actionOpen, showActions, closeActions] = useModal();

  const completeMutation = useMutation(() => completeInspection(jobId), {
    onError: (err: any) => {
      toast.error(readAxiosErr(err));
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(['job-last-checklist', jobId]),
        queryClient.invalidateQueries(['job-checklist', jobId]),
      ]);
      queryClient.invalidateQueries(['job-detail', jobId]);
      queryClient.invalidateQueries(['jobs']);

      closeActions();
    },
  });

  const noInspection = () => {
    return (
      <div className="apart">
        <div className="value">Inspection Not Created</div>
        <div>
          <Button
            label="Create"
            size={Size.Small}
            variant={Variants.Tag}
            onClick={showCreateModal}
            loading={completeMutation.isLoading}
          />
        </div>
        <BottomSheet open={createOpen}>
          <CreateInspection closeModal={closeCreateModal} />
        </BottomSheet>
      </div>
    );
  };

  const insNotcomplete = () => {
    return (
      <div className="apart">
        <div className="value">Inspection Not Completed</div>
        <div>
          <Button
            variant={Variants.Tag}
            label="Actions"
            size={Size.Small}
            onClick={showActions}
          />
        </div>
        <BottomSheet open={actionOpen}>
          <div className="pb-4">
            <div className="flex justify-between">
              <div className="page-heading">Actions</div>
              {!completeMutation.isLoading && (
                <div>
                  <Icon name="close" size={32} onClick={closeActions} />
                </div>
              )}
            </div>
            <div className="mt-4 space-y-4">
              <Button
                label="View Inspection"
                size={Size.Big}
                variant={Variants.Primary}
                onClick={() =>
                  navigate(`/jobs/${jobId}/inspections/${data.inspection}`)
                }
              />
              <Button
                label="Complete Inspection"
                size={Size.Big}
                variant={Variants.Tag}
                onClick={() => completeMutation.mutate()}
                loading={completeMutation.isLoading}
              />
            </div>
          </div>
        </BottomSheet>
      </div>
    );
  };

  const complete = () => {
    return (
      <div>
        <div className="apart">
          <div className="value">Inspection Completed</div>
          <div>
            <Button
              variant={Variants.Tag}
              label="Actions"
              size={Size.Small}
              onClick={showActions}
            />
          </div>
        </div>
        <div className="-mt-1">
          <span className="key">Completed By: </span>
          <span className="key">{data.completed_by?.name}</span>
        </div>
        <div className="-mt-1">
          <span className="key">Completed At: </span>
          <span className="key">
            {formatServerTime({
              date: data.date,
              parseFormat: 'YYYY-MM-DDThh:mm:ssZ',
              includeTime: true,
            })}
          </span>
        </div>
        <BottomSheet open={actionOpen}>
          <div className="pb-4">
            <div className="flex justify-between">
              <div className="page-heading">Actions</div>
              {!completeMutation.isLoading && (
                <div>
                  <Icon name="close" size={32} onClick={closeActions} />
                </div>
              )}
            </div>
            <div className="mt-4 space-y-4">
              <Button
                label="View Inspection"
                size={Size.Big}
                variant={Variants.Primary}
                onClick={() =>
                  navigate(`/jobs/${jobId}/inspections/${data.inspection}`)
                }
              />
              <Button
                variant={Variants.ErrorNegative}
                label="Mark as Incomplete?"
                size={Size.Big}
                onClick={showConfirmModal}
                loading={completeMutation.isLoading}
              />
            </div>
          </div>
        </BottomSheet>
        <Confirmation
          title="Mark inspection as incomplete?"
          message="Are you sure you want to mark this inspection as incomplete?"
          open={confirmOpen}
          closeConfirm={closeConfirmModal}
          onClick={() => completeMutation.mutate()}
          isLoading={completeMutation.isLoading}
        />
      </div>
    );
  };

  return (
    <div className={clx({ card: !renderInTable, 'px-2 pb-1': renderInTable })}>
      <div className="flex space-x-2">
        <Checkbox checked={data.complete} />
        <div className="w-full">
          {(() => {
            if (!data.complete && !data.inspection) {
              return noInspection();
            }
            if (!data.complete && data.inspection) {
              return insNotcomplete();
            }
            return complete();
          })()}
        </div>
      </div>
    </div>
  );
};

export default CompleteInspection;

import { updateCustomerCell } from 'queries/job';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import React from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { emptyStringToNull, nullToEmptyString, readAxiosErr } from 'utils/misc';
import { FieldType } from 'utils/enum';
import Icon from 'components/Icon';
import { Layout } from 'components/Form';
import Button from 'components/Button';
import 'yup-phone';
import useJobDetailSidebar from 'hooks/useJobDetailSidebar';
import { processSchema } from 'utils/form';

type AppProps = {
  customer: Record<string, any>;
  closeModal: () => any;
  action?: string;
};

interface IFormValues {
  cell: string;
  phone: string;
  phone_ext: string;
}

const UpdateCell: React.FC<AppProps> = ({
  customer,
  closeModal,
  action = 'call',
}) => {
  const { job } = useJobDetailSidebar();
  const { jobId = '' } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const _jobId = (job || jobId).toString();

  const addCellMutation = useMutation(
    (payload: Record<string, any>) => updateCustomerCell(_jobId, payload),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: (res: any) => {
        queryClient.invalidateQueries(['job-detail', _jobId]);
        closeModal();
        if (action === 'call') {
          window.location.href = `tel:${res.cell}`;
        } else {
          return navigate(
            `/jobs/${_jobId}/sms/create?initial_recipient=${customer.user_id}`
          );
        }
      },
    }
  );

  const schema = yup
    .object(
      processSchema(
        {
          cell: yup.string().phone('US', false),
          phone: yup.string().phone('US', false),
          phone_ext: yup.string(),
        },
        {}
      )
    )
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      cell: customer?.cell,
      phone: customer?.phone,
      phone_ext: customer?.phone_ext,
    },
  });

  const onSubmit: SubmitHandler<IFormValues> = async (formData) => {
    addCellMutation.mutate(formData);
  };

  const fields = [
    {
      type: FieldType.Input,
      label: 'Cell',
      placeholder: '8888888888',
      error: errors.cell?.message,
      ...register('cell'),
    },
    {
      type: FieldType.Input,
      label: 'Phone',
      placeholder: '8888888888',
      error: errors.phone?.message,
      ...register('phone'),
    },
    {
      type: FieldType.Input,
      label: 'Phone Extn.',
      placeholder: '123',
      error: errors.phone_ext?.message,
      ...register('phone_ext'),
    },
  ];

  return (
    <div className="pb-4">
      <div className="flex justify-between">
        <div className="page-heading mb-6">Add Customer Cell/Phone</div>
        {!addCellMutation.isLoading && (
          <div>
            <Icon
              name="close"
              size={32}
              onClick={closeModal}
              className="cursor-pointer"
            />
          </div>
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Layout fields={fields} loading={addCellMutation.isLoading} />

        <Button
          label="Add Cell"
          className="mt-8"
          loading={addCellMutation.isLoading}
        />
      </form>
    </div>
  );
};

export default UpdateCell;

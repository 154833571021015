import Icon from 'components/Icon';
import { truncate } from 'lodash';
import React from 'react';
import { getFileIcon } from 'utils/misc';
import { formatServerTime } from 'utils/time';
import Actions from './Actions';
import Tag, { Variant } from 'components/Tag';
import useModal from 'hooks/useModal';
import Modal, { Variant as ModalVariant } from 'components/Modal';
import DocumentDetail from 'components/DocumentDetail';
import clx from 'classnames';
import SignatureActions from './SignatureActions';

type AppProps = {
  document: Record<string, any>;
};

const DocumentCard: React.FC<AppProps> = ({ document }) => {
  const [documentModal, showDocumentModal, closeDocumentModal] = useModal();

  const renderAvatar = () => {
    if (document.thumb_url) {
      return (
        <img
          src={document.thumb_url}
          alt={document.name}
          className="object-cover h-32 w-48"
        />
      );
    }

    const icon = getFileIcon(document.name);
    return <Icon name={icon} size={48} className="text-text-medium" />;
  };

  return (
    <div
      className={clx(
        'h-fit relative bg-white rounded-lg border drop-shadow-sm px-1 mb-3 cursor-pointer',
        {
          'cursor-pointer': document.is_active,
          'cursor-not-allowed': !document.is_active,
        }
      )}
    >
      {!document.is_active && (
        <div className="absolute top-0 left-0 right-0 progress-bar">
          <div className="progress-bar-value" />
        </div>
      )}
      {!document.is_active && (
        <div className="absolute top-1 right-0 left-0 bottom-0 bg-blue-50 bg-opacity-40">
          <div className="absolute bottom-1 right-1 text-xs">
            In Progress...{' '}
          </div>
        </div>
      )}
      <div
        className="flex"
        onClick={() => {
          if (document.is_active) {
            showDocumentModal();
          } else {
            return null;
          }
        }}
      >
        <div className="w-24 h-32 p-[2px] pl-1 bg-white center border-r-[2px] rounded-l-lg">
          {renderAvatar()}
        </div>
        <div className="p-2 flex-1">
          <div className="flex justify-between">
            <div className="title">
              {truncate(document.name, { length: 28 })}
            </div>
            {document.is_active && <Actions doc={document} />}
          </div>
          <div className="space-y-1.5 mt-2">
            {!!document.created_by && (
              <div className="flex items-center text-xs">
                <Icon name="user" size={12} className="mr-1 key" />{' '}
                {document.created_by?.name}
              </div>
            )}
            {!!document.tags.length && (
              <div className="flex items-center text-xs">
                <Icon name="tags" size={14} className="mr-1 key" />{' '}
                {document.tags.slice(0, 1).map((tag: string) => (
                  <Tag
                    key={tag}
                    name={tag}
                    variant={Variant.Small}
                    className="mr-1"
                  />
                ))}
                {document.tags.length > 2 && (
                  <span className="text-xs text-text-medium">
                    + {document.tags.length - 1}
                  </span>
                )}
              </div>
            )}
            <div className="flex items-center text-xs">
              <Icon name="clock" size={14} className="mr-1 key" />{' '}
              {formatServerTime({
                date: document.date_created,
                includeTime: true,
              })}
            </div>
          </div>
        </div>
      </div>
      <SignatureActions document={document} />
      {documentModal && (
        <Modal
          variant={ModalVariant.FullScreen}
          open={documentModal}
          closeModal={closeDocumentModal}
          backgroundColor="#9ca3af"
        >
          <DocumentDetail
            document={document || {}}
            closeModal={closeDocumentModal}
          />
        </Modal>
      )}
    </div>
  );
};

export default DocumentCard;

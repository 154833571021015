import React, { Suspense, lazy } from 'react';
import useMedia from 'hooks/useMedia';
import Loader, { Variant as LoaderVariant } from 'components/Loader';

const TabsMobile = lazy(() => import('./Tabs.mobile'));
const TabsDesktop = lazy(() => import('./Tabs.desktop'));

interface Tab {
  id: string;
  name: string;
  icon?: string;
  component?: (fn?: any) => React.ReactNode;
  onClick?: () => any;
}

type AppProps = {
  tabs: Tab[];
  initialActive: string;
  updateUrl?: boolean;
};

const Tabs: React.FC<AppProps> = (props) => {
  const { isMobile } = useMedia();
  return (
    <Suspense
      fallback={
        <Loader
          variant={LoaderVariant.RoofLoader}
          label="Creating your job lists..."
        />
      }
    >
      {isMobile ? <TabsMobile {...props} /> : <TabsDesktop {...props} />}
    </Suspense>
  );
};

export default Tabs;

import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import RModal from 'react-modal';
import clx from 'classnames';

export enum Variant {
  FullScreen = 'fullscreen',
  Center = 'center',
  Bottom = 'bottom',
}

type AppProps = {
  open: boolean;
  children: React.ReactNode;
  closeModal?: () => void;
  variant?: Variant;
  top?: string;
  maxWidth?: string;
  overflow?: string;
  padded?: boolean;
  backgroundColor?: string;
  margin?: boolean;
};

RModal.setAppElement('#root');

const fullscreenStyle = ({ backgroundColor }: any) => ({
  overlay: {
    zIndex: 99999,
  },
  content: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 0,
    backgroundColor,
    padding: 0,
  },
});

const centerStyle = {
  overlay: {
    zIndex: 9999,
  },
  content: {
    top: '30%',
    bottom: '50%',
    height: '200px',
    left: '5%',
    right: '5%',
    borderRadius: 0,
    backgroundColor: 'white',
  },
};

const bottomStyle = (top = '40%') => ({
  overlay: {
    zIndex: 9999,
  },
  content: {
    top,
    padding: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 0,
    backgroundColor: 'white',
  },
});

const Modal: React.FC<AppProps> = ({
  open,
  children,
  closeModal = () => null,
  variant = Variant.Center,
  top = '40%',
  maxWidth = 'max-w-xl',
  overflow = 'overflow-auto',
  padded = true,
  backgroundColor = 'white',
  margin = true,
}) => {
  if (variant === Variant.FullScreen || variant === Variant.Bottom) {
    const modalStyle = () => {
      if (variant === Variant.FullScreen) {
        return fullscreenStyle({ backgroundColor });
      }
      if (variant === Variant.Bottom) {
        return bottomStyle(top);
      }
      return centerStyle;
    };

    return (
      <RModal isOpen={open} onRequestClose={closeModal} style={modalStyle()}>
        {children}
      </RModal>
    );
  }

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[99999]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div
              className={clx(
                'flex min-h-full items-center justify-center text-center',
                {
                  'p-4 ': margin,
                }
              )}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={clx(
                    `w-full ${maxWidth} lg:${maxWidth} max-h-[90vh] ${overflow} transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all`,
                    { ' p-6': padded, 'p-2': !padded }
                  )}
                >
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Modal;

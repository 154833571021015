import Icon from 'components/Icon';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import clx from 'classnames';

type AppProps = {
  job: Record<string, any>;
  className?: string;
  grid?: number;
};

const PhotoBox: React.FC<AppProps> = ({ job, className = '', grid = 4 }) => {
  const navigate = useNavigate();

  return (
    <div
      className={clx(
        'mt-5 bg-white rounded-lg p-2 shadow-md cursor-pointer',
        className
      )}
      onClick={() => navigate(`/jobs/${job.id}/photos`)}
    >
      <div className="v-center">
        <Icon name="photos" size={24} className="text-text-medium" />{' '}
        <span className="text-text-medium ml-1 font-bold">
          Photos <span className="text-xs">({job?.photos?.total})</span>
        </span>
      </div>
      <div className={`grid grid-cols-${grid} gap-1 mt-4 min-h-[24px]`}>
        {job?.photos?.thumbnails.map((photo: any) => (
          <div
            key={photo.id}
            className="border flex justify-center items-center h-20 w-20"
          >
            <img
              src={photo.thumb_url}
              className="h-20 w-20 object-cover rounded-lg"
            />
          </div>
        ))}
      </div>
      <div className="flex items-center text-xs mt-4 text-primary">
        View all photos
        <Icon name="arrow-right" size={16} className="ml-1" />
      </div>
    </div>
  );
};

export default PhotoBox;

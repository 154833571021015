import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

type AppProps = {
  redirectTo?: string;
};

const RequiredAuth: React.FC<AppProps> = () => {
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  return <Outlet />;
};

export default RequiredAuth;

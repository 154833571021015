import React from 'react';
import Sidebar from 'components/Sidebar';
import PageHeader from 'components/PageHeader';
import Icon from 'components/Icon';
import { useJobDetail, useJobLastNote } from 'queries/job';
import SkeletonLoader, { ContentType } from 'components/SkeletonLoader';
import { Link, useNavigate } from 'react-router-dom';
import LastCompletedChecklist from 'pages/JobDetail/components/Overview/components/LastCompletedChecklist';
import JobHeader from 'components/JobHeader';
import { formatServerTime } from 'utils/time';
import { truncate } from 'lodash';
import EstimateBox from 'components/JobOverview/EstimateBox';
import ExpenseBox from 'components/JobOverview/ExpenseBox';
import ClaimBox from 'components/JobOverview/ClaimBox';
import PhotoBox from 'components/JobOverview/PhotoBox';
import Button, { Size } from 'components/Button';

type AppProps = {
  job: number | string;
  isOpen: boolean;
  closeSidebar: () => any;
};

const JobDetailSidebar: React.FC<AppProps> = ({
  job,
  isOpen,
  closeSidebar,
}) => {
  const { data, isLoading } = useJobDetail(job?.toString());
  const { data: note } = useJobLastNote(job);
  const navigate = useNavigate();

  const renderJob = () => {
    return (
      <div className="pb-12">
        <JobHeader job={data} />
        <div className="px-2.5">
          <div className="pt-2">
            <Button
              size={Size.Medium}
              label="View Job Details"
              rightIcon="external-link"
              onClick={() => {
                navigate(`/jobs/${job}`);
                closeSidebar();
              }}
            />
          </div>
          <LastCompletedChecklist />
          <Link to={`/jobs/${job}/notes`}>
            {note && (
              <div className="card text-sm pl-2">
                <div className="text-xs text-text-light">Last Note</div>

                <div className="mt-1">
                  <b>{note?.sender}</b>
                  <span className="text-text-medium text-xs">
                    &nbsp;&nbsp; on{' '}
                    {formatServerTime({ date: note?.date_created })}
                  </span>
                </div>
                <blockquote className="text-text-medium border-l pl-2 mt-1">
                  {truncate(note?.message, { length: 78 })}
                </blockquote>
              </div>
            )}
          </Link>
          <div className="grid grid-cols-2 gap-4">
            {/* Estimate box */}
            <EstimateBox job={data} />

            {/* Expenses Box */}
            <ExpenseBox job={data} />
          </div>
          <div className="mt-3">
            <ClaimBox job={data} className="pb-2" />
          </div>
          <div className="-mt-2">
            <PhotoBox job={data} grid={5} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Sidebar open={isOpen} blocking={false} bgClass="bg-background">
      <PageHeader
        title="Job Overview"
        className="px-2 font-bold"
        actions={
          <div className="cursor-pointer">
            <Icon name="close" size={24} onClick={closeSidebar} />
          </div>
        }
      />
      {isLoading ? (
        <SkeletonLoader contentType={ContentType.Card} size={2} />
      ) : (
        renderJob()
      )}
    </Sidebar>
  );
};

export default JobDetailSidebar;

import Icon from 'components/Icon';
import React, { useState } from 'react';
import { formatServerTime } from 'utils/time';
import clx from 'classnames';
import { isEmpty, truncate } from 'lodash';
import Button, { Size, Variants } from 'components/Button';
import useModal from 'hooks/useModal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  attachSurveyToDocument,
  enableSignautre,
  impersonateAndSign,
  voidSignatures,
} from 'queries/documents';
import usePageLoader from 'hooks/usePageLoader';
import toast from 'react-hot-toast';
import { readAxiosErr } from 'utils/misc';
import { useNavigate, useParams } from 'react-router-dom';
import { setItem } from 'utils/localstorage';
import { IMPERSONATE_SESSION_KEY, LAST_META_STATE_URL } from 'utils/constants';
import AttachSurvey from 'components/AttachSurvey';
import useMedia from 'hooks/useMedia';
import { useJobDetail } from 'queries/job';
import BottomSheet from 'components/BottomSheet';
import UpdateEmail from 'components/JobHeader/components/UpdateEmail';

type AppProps = {
  document: Record<string, any>;
};

const SignatureActions: React.FC<AppProps> = ({ document }) => {
  const { jobId = '' } = useParams();
  const { isMobile } = useMedia();
  const [showSigners, setShowSigners] = useState(!isMobile);
  const [survey, showSurvey, closeSurvey] = useModal();
  const { startLoader, stopLoader } = usePageLoader();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data: jobData } = useJobDetail(jobId);
  const [updateEmail, showUpdateEmail, closeUpdateEmail] = useModal();

  const enableSignatureMutation = useMutation(
    (id: number) => enableSignautre(id),
    {
      onMutate: () => {
        startLoader(
          'Enabling signatures. This might take a few seconds. Please wait...'
        );
      },
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['documents', { job: jobId }]);
        queryClient.invalidateQueries(['job-checklist', jobId]);
      },
      onSettled: () => {
        stopLoader();
      },
    }
  );

  const voidSignatureMutation = useMutation(
    (id: number) => voidSignatures(id),
    {
      onMutate: () => {
        startLoader('Removing signatures. Please wait...');
      },
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['documents']);
        queryClient.invalidateQueries(['job-checklist', jobId]);
      },
      onSettled: () => {
        stopLoader();
      },
    }
  );

  const impersonateMutation = useMutation(
    (signerId: number) => impersonateAndSign(signerId),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: (res: any) => {
        queryClient.invalidateQueries(['job-detail', jobId]);
        queryClient.invalidateQueries(['job-checklist', jobId]);
        setItem(IMPERSONATE_SESSION_KEY, res.token);
        setItem(LAST_META_STATE_URL, window.location.href);
        window.location.href = `${window.location.origin}${res.redirect_url}`;
      },
    }
  );

  const handleSign = (signId: string, entity: Record<string, any>) => {
    if (entity.sign_method === 'sign') {
      setItem(LAST_META_STATE_URL, window.location.href);
      return navigate(`/sign/${signId}`);
    }
    if (entity.sign_method === 'impersonate_and_sign') {
      return impersonateMutation.mutate(entity.signer_id);
    }
  };

  const renderSign = (signId: string, entity: Record<string, any>) => {
    if (!entity) {
      return null;
    }

    if (!entity?.can_sign_now) {
      return (
        <div className="text-xs v-center">
          <Icon name="alert" size={16} className="text-gray-600 mr-1" />
          <b>{entity?.signed_by.name}</b> &nbsp; can not sign yet
        </div>
      );
    }

    if (entity?.date_signed) {
      return (
        <div className="text-xs">
          <div className="inline-block relative top-1">
            <Icon name="check-circle" size={16} className="text-success mr-1" />
          </div>
          <b>{entity.signed_by.name}</b> completed on{' '}
          <b>
            {formatServerTime({
              date: entity.date_signed,
              includeTime: true,
              parseFormat: 'YYYY-MM-DDThh:mm:ssZ',
            })}
          </b>
        </div>
      );
    }

    return (
      <div className={isMobile ? 'w-full' : 'max-w-min'}>
        <Button
          label={`${entity?.signed_by.name} sign now`}
          size={Size.Medium}
          leftIcon="signature"
          rightIcon="arrow-right"
          iconClassName="!mx-2"
          onClick={() => handleSign(signId, entity)}
        />
      </div>
    );
  };

  const renderManageAttachment = () => {
    if (document?.version === 'v1') {
      return null;
    }
    if (!document.sign_meta?.survey?.name) {
      return (
        <div className={isMobile ? 'w-full' : 'max-w-min'}>
          <Button
            size={Size.Medium}
            variant={Variants.PrimaryOutline}
            onClick={showSurvey}
            label={document.sign_meta?.survey?.name ?? 'Attach Survey'}
            leftIcon="survey"
            iconClassName="mx-2"
          />
        </div>
      );
    }

    return (
      <div
        className={clx({ apart: isMobile, 'v-center space-x-2': !isMobile })}
        onClick={showSurvey}
      >
        <div className="v-center space-x-1 cursor-pointer">
          <Icon name="survey" size={20} className="text-primary" />
          <span
            className={clx('text-sm', {
              ' text-primary': document.sign_meta?.survey?.name,
            })}
          >
            {document.sign_meta?.survey?.name ?? 'Attach Survey'}
          </span>
        </div>
        <Icon
          name="edit"
          size={isMobile ? 16 : 14}
          className="text-primary cursor-pointer"
        />
      </div>
    );
  };

  if (!document.is_active) {
    return null;
  }

  if (!document.is_contract) {
    return null;
  }

  if (isEmpty(document.sign_meta)) {
    return (
      <>
        <div
          className={clx('v-center space-x-1', {
            'border-t border-t-gray-400 p-2 ': isMobile,
          })}
        >
          <div className={isMobile ? 'w-full' : 'max-w-min'}>
            <Button
              leftIcon="signature"
              size={Size.Medium}
              label="Enable Signatures"
              iconClassName="mr-2"
              onClick={() => {
                if (!jobData?.customer?.email) {
                  return showUpdateEmail();
                }
                return enableSignatureMutation.mutate(document.id);
              }}
            />
          </div>
        </div>
        {updateEmail && (
          <BottomSheet open={updateEmail}>
            <UpdateEmail closeModal={closeUpdateEmail} />
          </BottomSheet>
        )}
      </>
    );
  }

  return (
    <div
      className={clx('w-full relative', {
        'border-t border-t-gray-400 p-2 ': isMobile,
      })}
    >
      {(() => {
        if (document.signed_date) {
          return (
            <div className="v-center space-x-1">
              <Icon name="check-circle" size={16} className="text-success" />
              <div className="key">Contract signed: </div>
              <div className="xs-bold">
                {formatServerTime({
                  date: document.signed_date,
                  includeTime: true,
                  parseFormat: 'YYYY-MM-DDThh:mm:ssZ',
                })}
              </div>
            </div>
          );
        }
        if (showSigners) {
          return (
            <div className="space-y-2 mb-2">
              {renderManageAttachment()}
              {renderSign(
                document.sign_meta.sign_id,
                document.sign_meta.customer
              )}
              {renderSign(document.sign_meta.sign_id, document.sign_meta.rep)}
              {isMobile ? (
                <div className="max-w-min pt-1">
                  <Button
                    label="Void Signatures"
                    size={Size.Medium}
                    variant={Variants.ErrorNegative}
                    onClick={() =>
                      voidSignatureMutation.mutate(document.sign_meta.sign_id)
                    }
                    className="space-x-1"
                    // leftIcon="ban"
                    loading={voidSignatureMutation.isLoading}
                  />
                </div>
              ) : (
                <div
                  className="cursor-pointer text-error text-sm"
                  onClick={() =>
                    voidSignatureMutation.mutate(document.sign_meta.sign_id)
                  }
                >
                  Void Signatures
                </div>
              )}
              {isMobile && (
                <div className="absolute right-1 bottom-3">
                  <Icon
                    name="caret-up"
                    className="text-primary cursor-pointer"
                    onClick={() => setShowSigners(false)}
                    size={20}
                  />
                </div>
              )}
            </div>
          );
        }
        if (!showSigners) {
          return (
            <div className="space-y-2">
              {renderManageAttachment()}
              <div
                className="flex text-primary justify-between pt-1"
                onClick={() => setShowSigners(true)}
              >
                <div className="v-center space-x-1">
                  <Icon name="signature" size={18} />
                  <div className="text-sm">Waiting on Signatures</div>
                </div>
                <Icon
                  name="caret-down-outline"
                  className="cursor-pointer"
                  size={22}
                />
              </div>
            </div>
          );
        }
        return null;
      })()}
      {survey && (
        <AttachSurvey
          entityId={document.sign_meta.sign_id}
          entityName={truncate(document.name, { length: 30 })}
          open={survey}
          closeModal={closeSurvey}
          attachFunction={attachSurveyToDocument}
          queryKey={['documents', { job: jobId }]}
          initial={document.sign_meta?.survey?.id}
        />
      )}
    </div>
  );
};

export default SignatureActions;

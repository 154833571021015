import Icon from 'components/Icon';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useModal from 'hooks/useModal';
import Modal, { Variant as ModalVariant } from 'components/Modal';
import Button, {
  Variants as ButtonVariants,
  Size as ButtonSize,
} from 'components/Button';
import clx from 'classnames';

type AppProps = {
  to?: string | number;
  onClick?: () => any;
  label: string;
  labelStyle?: string;
  showLeaveWarning?: boolean;
  message?: string;
};

const PageBack: React.FC<AppProps> = ({
  to,
  onClick,
  label,
  labelStyle = '',
  message = '',
  showLeaveWarning = false,
}) => {
  const navigate = useNavigate();
  const [warningOpen, showWarningModal, closeWarningModal] = useModal();

  const goBack = () => {
    if (to) {
      // @ts-ignore
      navigate(to);
    } else if (onClick) {
      onClick();
    } else {
      navigate(-1);
    }
  };

  const handleClick = () => {
    if (showLeaveWarning) {
      return showWarningModal();
    } else {
      return goBack();
    }
  };

  return (
    <div className="">
      <div
        className="inline-block cursor-pointer v-center"
        onClick={handleClick}
      >
        <Icon name="arrow-left" size={16} className="mr-1 inline-block w-4" />{' '}
        <span className={clx('text-xs text-text-light ', labelStyle)}>
          {label}
        </span>
      </div>
      <Modal
        variant={ModalVariant.Center}
        open={warningOpen}
        closeModal={closeWarningModal}
      >
        <div className="text-lg font-bold v-center">
          <Icon name="warning" className="text-red-400 mr-2" /> Leave Page?
        </div>
        {message ? (
          <div className="pt-2 text-text-medium">
            {message}
            <br />
            &nbsp;
            <br />
            &nbsp;
          </div>
        ) : (
          <div className="pt-2 text-text-medium">
            This page has unsaved changes.
            <br /> Are you sure you want to leave this page and loose all
            unsaved changes?
          </div>
        )}
        <div className="flex items-center justify-end space-x-2 mt-4">
          <div>
            <Button label="Leave" size={ButtonSize.Medium} onClick={goBack} />
          </div>
          <div>
            <Button
              label="Cancel"
              variant={ButtonVariants.Outline}
              size={ButtonSize.Medium}
              onClick={closeWarningModal}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PageBack;

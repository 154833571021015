import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from 'pages/NotFound';
import RequireAuth from './RequireAuth';
import AppShell from 'components/AppShell';
import Loader, { Variant as LoaderVariant } from 'components/Loader';
import Organisation from 'pages/Organisation';

const VerifyEmail = lazy(() => import('pages/VerifyEmail'));
const Login = lazy(() => import('pages/Login'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword'));
const ResetPassword = lazy(() => import('pages/ResetPassword'));
const Register = lazy(() => import('pages/Register'));
const Onboarding = lazy(() => import('pages/OnBoarding'));
const Onboard = lazy(() => import('pages/Onboard'));
const Home = lazy(() => import('pages/Home'));
const Jobs = lazy(() => import('pages/Jobs'));
const Prospects = lazy(() => import('pages/Prospects'));
const JobList = lazy(() => import('pages/JobList'));
const StarredJobs = lazy(() => import('pages/StarredJobs'));
const RecentJobs = lazy(() => import('pages/RecentJobs'));
const AddLead = lazy(() => import('pages/AddLead'));
const JobDetail = lazy(() => import('pages/JobDetail'));
const CrewWorkorder = lazy(() => import('pages/CrewWorkorder'));
const Map = lazy(() => import('pages/Map'));
const Employees = lazy(() => import('pages/Employees'));
const EmployeeDetail = lazy(() => import('pages/EmployeeDetail'));
const CreateEmployee = lazy(() => import('pages/CreateEmployee'));
const Crews = lazy(() => import('pages/Crews'));
const CreateCrew = lazy(() => import('pages/CreateCrew'));
const CrewDetail = lazy(() => import('pages/CrewDetail'));
const Profile = lazy(() => import('pages/Profile'));
const DocumentSigning = lazy(() => import('pages/DocumentSigning'));
const Calendar = lazy(() => import('pages/Calendar'));
const Todos = lazy(() => import('pages/Todos'));
const Notes = lazy(() => import('pages/Notes'));
const StripeConnect = lazy(() => import('pages/StripeConnect'));
const CreateInsuranceCompany = lazy(
  () => import('pages/CreateInsuranceCompany')
);
const CreateTemplate = lazy(
  () => import('pages/RoofTemplates/components/CreateTemplate')
);
const CreateMortgageCompany = lazy(() => import('pages/CreateMortgageCompany'));
const Impersonate = lazy(() => import('pages/Impersonate'));
const DocTemplates = lazy(() => import('pages/DocTemplates'));
const DocTemplateDetail = lazy(() => import('pages/DocTemplateDetail'));
const DocTemplateBuilder = lazy(() => import('pages/DocTemplateBuilder'));
const RoofTemplates = lazy(() => import('pages/RoofTemplates'));
const RoofTemplateBuilder = lazy(() => import('pages/RoofTemplateBuilder'));
const EventDetails = lazy(() => import('pages/EventDetails'));
const MyEvents = lazy(() => import('pages/MyEvents'));
const Products = lazy(() => import('pages/Products'));
const CreateProduct = lazy(() => import('pages/CreateProduct'));
const Teams = lazy(() => import('pages/Teams'));
const TeamDetail = lazy(() => import('pages/TeamDetail'));
const Surveys = lazy(() => import('pages/Surveys'));
const SurveyBuilder = lazy(() => import('pages/SurveyBuilder'));
const DashboardConfig = lazy(() => import('pages/DashboardConfig'));
const CompanyDocs = lazy(() => import('pages/CompanyDocs'));
const EmailTemplates = lazy(() => import('pages/EmailTemplates'));
const EmailTemplateBuilder = lazy(() => import('pages/EmailTemplateBuilder'));
const EventTemplates = lazy(() => import('pages/EventTemplates'));
const EventTemplateBuilder = lazy(() => import('pages/EventTemplateBuilder'));
const InspectionTemplates = lazy(() => import('pages/InspectionTemplates'));
const InspectionTemplateBuilder = lazy(
  () => import('pages/InspectionTemplateBuilder')
);
const SmsTemplates = lazy(() => import('pages/SmsTemplates'));
const SmsTemplateBuilder = lazy(() => import('pages/SmsTemplateBuilder'));
const NoteTemplates = lazy(() => import('pages/NoteTemplates'));
const NoteTemplateBuilder = lazy(() => import('pages/NoteTemplateBuilder'));
const UserDirectory = lazy(() => import('pages/UserDirectory'));
const InsuranceCompanies = lazy(() => import('pages/InsuranceCompanies'));
const MortgageCompanies = lazy(() => import('pages/MortgageCompanies'));
const UnpaidEstimates = lazy(() => import('pages/UnpaidEstimates'));
const LeadSources = lazy(() => import('pages/LeadSources'));
const LeadStatuses = lazy(() => import('pages/LeadStatuses'));
const Reports = lazy(() => import('pages/Reports'));
const Regions = lazy(() => import('pages/Regions'));
const MC = lazy(() => import('pages/ManagementCompanies'));
const SentEmails = lazy(() => import('pages/SentEmails'));
const SentSms = lazy(() => import('pages/SentSms'));
const PaymentDetails = lazy(() => import('pages/PaymentDetails'));
const Integrations = lazy(() => import('pages/Integrations'));
const Suppliers = lazy(() => import('pages/Suppliers'));
const EstimateTemplates = lazy(() => import('pages/EstimateTemplates'));
const Tags = lazy(() => import('pages/Tags'));
const CreateETLineitem = lazy(
  () => import('pages/EstimateTemplateBuilder/components/CreateLineitem')
);
const EstimateTemplateBuilder = lazy(
  () => import('pages/EstimateTemplateBuilder')
);
const Terms = lazy(() => import('pages/Terms'));
const License = lazy(() => import('pages/License'));

const RLRoutes: React.FC = () => {
  return (
    <AppShell>
      <Suspense
        fallback={
          <Loader
            variant={LoaderVariant.RoofLoader}
            label="Loading requested page..."
          />
        }
      >
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="confirm/:token" element={<VerifyEmail />} />
          <Route path="sign">
            <Route path=":signId" element={<DocumentSigning />} />
          </Route>
          <Route path="/onboarding" element={<RequireAuth />}>
            <Route index element={<Onboarding />} />
          </Route>
          <Route path="onboard" element={<RequireAuth />}>
            <Route index element={<Onboard />} />
          </Route>
          <Route path="/" element={<RequireAuth />}>
            <Route index element={<Home />} />
            <Route path="home" element={<Home />} />
          </Route>
          <Route path="/dashboard-config" element={<RequireAuth />}>
            <Route index element={<DashboardConfig />} />
          </Route>
          <Route path="/insurance-companies" element={<RequireAuth />}>
            <Route path="create" element={<CreateInsuranceCompany />} />
            <Route
              path=":companyId/edit"
              element={<CreateInsuranceCompany />}
            />
          </Route>
          <Route path="/mortgage-companies" element={<RequireAuth />}>
            <Route path="create" element={<CreateMortgageCompany />} />
            <Route path=":companyId/edit" element={<CreateMortgageCompany />} />
          </Route>
          <Route path="jobs" element={<RequireAuth />}>
            <Route path=":jobId/*" element={<JobDetail />} />
            <Route path="new" element={<AddLead />} />
            <Route path="list" element={<JobList />} />
            <Route index element={<Jobs />} />
          </Route>
          <Route path="prospects" element={<RequireAuth />}>
            <Route index element={<Prospects />} />
          </Route>
          <Route path="starred-jobs" element={<RequireAuth />}>
            <Route index element={<StarredJobs />} />
          </Route>
          <Route path="recent-jobs" element={<RequireAuth />}>
            <Route index element={<RecentJobs />} />
          </Route>
          <Route path="map" element={<RequireAuth />}>
            <Route index element={<Map />} />
          </Route>
          <Route path="employees" element={<RequireAuth />}>
            <Route index element={<Employees />} />
            <Route path="create" element={<CreateEmployee />} />
            <Route path=":employeeId/edit" element={<CreateEmployee />} />
            <Route path=":employeeId/*" element={<EmployeeDetail />} />
          </Route>
          <Route path="crews" element={<RequireAuth />}>
            <Route index element={<Crews />} />
            <Route path="create" element={<CreateCrew />} />
            <Route path=":crewId/*" element={<CrewDetail />} />
          </Route>
          <Route path="suppliers" element={<RequireAuth />}>
            <Route index element={<Suppliers />} />
          </Route>
          <Route path="calendar" element={<RequireAuth />}>
            <Route index element={<Calendar />} />
            <Route path="*" element={<Calendar />} />
          </Route>
          <Route path="todos" element={<RequireAuth />}>
            <Route index element={<Todos />} />
          </Route>
          <Route path="notes" element={<RequireAuth />}>
            <Route index element={<Notes />} />
          </Route>
          <Route path="profile" element={<RequireAuth />}>
            <Route index element={<Profile />} />
            <Route path="*" element={<Profile />} />
          </Route>
          <Route path="company" element={<RequireAuth />}>
            <Route index element={<Organisation />} />
            <Route path="*" element={<Organisation />} />
          </Route>
          <Route path="impersonate" element={<RequireAuth />}>
            <Route index element={<Impersonate />} />
          </Route>
          <Route path="doctemplates" element={<RequireAuth />}>
            <Route
              path=":templateId/builder"
              element={<DocTemplateBuilder />}
            />
            <Route path=":templateId/*" element={<DocTemplateDetail />} />
            <Route index element={<DocTemplates />} />
          </Route>
          <Route path="rooftemplates" element={<RequireAuth />}>
            <Route
              path=":templateId/builder"
              element={<RoofTemplateBuilder />}
            />
            <Route index element={<RoofTemplates />} />
            <Route path="create" element={<CreateTemplate />} />
            <Route path=":templateId/edit" element={<CreateTemplate />} />
          </Route>
          <Route path="estimate-templates" element={<RequireAuth />}>
            <Route
              path=":templateId/builder"
              element={<EstimateTemplateBuilder />}
            />
            <Route index element={<EstimateTemplates />} />
            <Route
              path=":templateId/builder/:lineitemType/create"
              element={<CreateETLineitem />}
            />
            <Route
              path=":templateId/builder/:lineitemType/:lineitemId/edit"
              element={<CreateETLineitem />}
            />
          </Route>
          <Route path="events" element={<RequireAuth />}>
            <Route path="me" element={<MyEvents />} />
            <Route path=":eventId" element={<EventDetails />} />
          </Route>
          <Route path="products" element={<RequireAuth />}>
            <Route index element={<Products />} />
            <Route path="create" element={<CreateProduct />} />
            <Route path=":productId/edit" element={<CreateProduct />} />
          </Route>
          <Route path="teams" element={<RequireAuth />}>
            <Route index element={<Teams />} />
            <Route path=":teamId" element={<TeamDetail />} />
          </Route>
          <Route path="tags" element={<RequireAuth />}>
            <Route index element={<Tags />} />
          </Route>
          <Route path="surveys" element={<RequireAuth />}>
            <Route index element={<Surveys />} />
            <Route path=":surveyId" element={<SurveyBuilder />} />
          </Route>
          <Route path="company-docs" element={<RequireAuth />}>
            <Route index element={<CompanyDocs />} />
          </Route>
          <Route path="email-templates" element={<RequireAuth />}>
            <Route index element={<EmailTemplates />} />
            <Route path=":templateId" element={<EmailTemplateBuilder />} />
          </Route>
          <Route path="event-templates" element={<RequireAuth />}>
            <Route index element={<EventTemplates />} />
            <Route path=":templateId" element={<EventTemplateBuilder />} />
          </Route>
          <Route path="inspection-templates" element={<RequireAuth />}>
            <Route index element={<InspectionTemplates />} />
            <Route path=":templateId" element={<InspectionTemplateBuilder />} />
          </Route>
          <Route path="sms-templates" element={<RequireAuth />}>
            <Route index element={<SmsTemplates />} />
            <Route path=":templateId" element={<SmsTemplateBuilder />} />
          </Route>
          <Route path="note-templates" element={<RequireAuth />}>
            <Route index element={<NoteTemplates />} />
            <Route path=":templateId" element={<NoteTemplateBuilder />} />
          </Route>
          <Route path="insurance-companies" element={<RequireAuth />}>
            <Route index element={<InsuranceCompanies />} />
          </Route>
          <Route path="mortgage-companies" element={<RequireAuth />}>
            <Route index element={<MortgageCompanies />} />
          </Route>
          <Route path="user-directory" element={<RequireAuth />}>
            <Route index element={<UserDirectory />} />
          </Route>
          <Route path="unpaid-estimates" element={<RequireAuth />}>
            <Route index element={<UnpaidEstimates />} />
          </Route>
          <Route path="lead-sources" element={<RequireAuth />}>
            <Route index element={<LeadSources />} />
          </Route>
          <Route path="lead-statuses" element={<RequireAuth />}>
            <Route index element={<LeadStatuses />} />
          </Route>
          <Route path="reports" element={<RequireAuth />}>
            <Route index element={<Reports />} />
          </Route>
          <Route path="regions" element={<RequireAuth />}>
            <Route index element={<Regions />} />
          </Route>
          <Route path="management-companies" element={<RequireAuth />}>
            <Route index element={<MC />} />
          </Route>
          <Route path="sent-emails" element={<RequireAuth />}>
            <Route index element={<SentEmails />} />
          </Route>
          <Route path="sent-sms" element={<RequireAuth />}>
            <Route index element={<SentSms />} />
          </Route>
          <Route path="crew-workorders" element={<RequireAuth />}>
            <Route path=":orderId/*" element={<CrewWorkorder />} />
            <Route index element={<Jobs />} />
          </Route>
          <Route path="integrations" element={<RequireAuth />}>
            <Route index element={<Integrations />} />
          </Route>
          <Route path="stripe-connect-setup" element={<RequireAuth />}>
            <Route index element={<StripeConnect />} />
          </Route>
          <Route path="payment-details" element={<RequireAuth />}>
            <Route index element={<PaymentDetails />} />
          </Route>
          <Route path="terms" element={<Terms />} />
          <Route path="license" element={<License />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </AppShell>
  );
};

export default RLRoutes;

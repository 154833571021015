import Icon from 'components/Icon';
import React from 'react';
import clx from 'classnames';
import { useNavigate } from 'react-router-dom';

type AppProps = {
  count?: number;
  icon?: string;
  title?: string;
  titleClass?: string;
  subheader?: string;
  actions?: React.ReactNode;
  className?: string;
  sticky?: boolean;
  back?: boolean;
  backTitle?: string;
  titlePluralise?: boolean;
  leftActions?: React.ReactNode;
  enableSearch?: boolean;
};

const PageHeader: React.FC<AppProps> = ({
  count,
  title = 'Jobs',
  titleClass = '',
  icon = null,
  subheader = '',
  actions = null,
  sticky = true,
  className = '',
  back = false,
  backTitle = 'Go Back',
  titlePluralise = true,
  leftActions = null,
}) => {
  const navigate = useNavigate();
  const bannerStyle = clx(
    `flex items-center justify-between bg-white py-3 z-10 drop-shadow-md ${className}`,
    {
      'sticky top-0 z-[60]': sticky,
    }
  );

  return (
    <div className={bannerStyle}>
      {leftActions}
      <div>
        {back && (
          <div
            className="key v-center space-x-1 mb-1 -mt-2"
            onClick={() => navigate(-1)}
          >
            <Icon size={20} name="arrow-left" />
            <div>{backTitle}</div>
          </div>
        )}
        <div className="flex items-center">
          {icon && (
            <Icon name={icon} size={22} className="mr-2 text-text-medium" />
          )}
          <div className={`text-left text-text text-md ${titleClass}`}>
            <b>{count?.toLocaleString() || ''}</b>{' '}
            <span>{`${title}${
              titlePluralise ? ((count || 0) > 1 ? 's' : '') : ''
            }`}</span>
          </div>
        </div>
        {subheader && <div className="text-xs text-text mt-1">{subheader}</div>}
      </div>
      {actions}
    </div>
  );
};

export default PageHeader;

import { updateCustomerEmail } from 'queries/job';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import React from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { readAxiosErr } from 'utils/misc';
import { FieldType } from 'utils/enum';
import Icon from 'components/Icon';
import { Layout } from 'components/Form';
import Button, { Size } from 'components/Button';
import useJobDetailSidebar from 'hooks/useJobDetailSidebar';

type AppProps = {
  closeModal: () => any;
  onSuccess?: () => any;
};

interface IFormValues {
  email: string;
}

const UpdateEmail: React.FC<AppProps> = ({
  closeModal,
  onSuccess = () => null,
}) => {
  const { job } = useJobDetailSidebar();
  const { jobId = '' } = useParams();
  const queryClient = useQueryClient();

  const _jobId = (job || jobId).toString();

  const addEmailMutation = useMutation(
    (email: string) => updateCustomerEmail(_jobId, email),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: (res: any) => {
        queryClient.invalidateQueries(['job-detail', _jobId]);
        closeModal();
        return onSuccess();
      },
    }
  );

  const schema = yup.object({
    email: yup.string().email(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>({ resolver: yupResolver(schema) });

  const onSubmit: SubmitHandler<IFormValues> = async (formData) => {
    addEmailMutation.mutate(formData.email);
  };

  const fields = [
    {
      type: FieldType.Input,
      label: 'Email',
      placeholder: 'your@email',
      error: errors.email?.message,
      ...register('email'),
    },
  ];

  return (
    <div className="pb-4">
      <div className="flex justify-between">
        <div className="page-heading mb-6">Add Customer Email</div>
        {!addEmailMutation.isLoading && (
          <div>
            <Icon
              name="close"
              size={32}
              onClick={closeModal}
              className="cursor-pointer"
            />
          </div>
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Layout fields={fields} loading={addEmailMutation.isLoading} />

        <Button
          label="Add Email"
          className="mt-8"
          loading={addEmailMutation.isLoading}
          size={Size.Medium}
        />
      </form>
    </div>
  );
};

export default UpdateEmail;

import React, { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FieldType } from 'utils/enum';
import { useParams } from 'react-router-dom';
import { useRLTemplateAndSupplierFieldConfig } from 'queries/estimates';
import { processFields, processInitialValues, processSchema } from 'utils/form';
import { Layout } from 'components/Form';
import Button from 'components/Button';
import { useBidTypeChoices, useRepChoices } from 'queries/company';
import { useLightRLTemplateChoices } from 'queries/job';
import ImportManufacturers from '../ImportManufacturers';
import { wastePctChoices } from 'pages/RoofTemplates/utils';
import useModal from 'hooks/useModal';
import RoofTemplateBuilder from 'pages/RoofTemplateBuilder';
import Modal, { Variant } from 'components/Modal';
import Icon from 'components/Icon';

interface IFormValues {
  rep: number;
  bid_type: string;
  template: number;
  waste_pct_choice: string;
  custom_waste_pct: number;
}

type AppProps = {
  onSubmit: (...args: any) => any;
};

const CreateEstimateFromDrawing: React.FC<AppProps> = ({ onSubmit }) => {
  const { jobId = '' } = useParams();
  const { data: config, isLoading } = useRLTemplateAndSupplierFieldConfig(
    jobId,
    { create_from: 'drawing' }
  );
  const { data: templates, isLoading: templateLoading } =
    useLightRLTemplateChoices(jobId);
  const [templateDetail, showTemplateDetail, closeTemplateDetail] = useModal();

  const schema = yup.object(
    processSchema(
      {
        template: yup.number(),
        rep: yup.number(),
        bid_type: yup.string(),
        waste_pct_choice: yup.string(),
        custom_waste_pct: yup.number(),
      },
      config
    )
  );

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<IFormValues>({ resolver: yupResolver(schema) });
  const _waste_pct_choice = watch('waste_pct_choice');
  const _template = watch('template');

  useEffect(() => {
    if (config) {
      const defaultValues = processInitialValues(config);
      reset(defaultValues);
    }
  }, [config]);

  const _onSubmit: SubmitHandler<IFormValues> = async (formData) => {
    onSubmit({ ...formData, version: 'v2' });
  };

  const fields = [
    {
      name: 'template',
      type: FieldType.AsyncSelect,
      label: 'Select Template',
      placeholder: 'Select Template',
      error: errors.template?.message,
      labelAction: !!_template && (
        <div
          onClick={showTemplateDetail}
          className="relative top-1 text-xs text-primary cursor-pointer"
        >
          View Template Details
        </div>
      ),
      control,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      optionsPromise: () => useLightRLTemplateChoices(jobId),
    },
    {
      name: 'waste_pct_choice',
      type: FieldType.Select,
      label:
        'Would you like to use the default waste factors defined in your Roof Template Lineitems, or would you like to override those with a custom waste factor?',
      placeholder: 'Select Waste Factor Choice',
      error: errors.waste_pct_choice?.message,
      control,
      options: [
        {
          label: 'Default',
          value: 'default',
        },
        {
          label: 'Custom',
          value: 'custom',
        },
      ],
    },
  ];

  const fields1 = [
    {
      name: 'custom_waste_pct',
      type: FieldType.Select,
      label: 'Custom Waste %',
      placeholder: 'Select Waste %',
      error: errors.custom_waste_pct?.message,
      control,
      options: wastePctChoices,
    },
  ];

  const fields2 = [
    {
      name: 'rep',
      type: FieldType.AsyncSelect,
      label: 'Select Rep',
      placeholder: 'Select rep',
      error: errors.rep?.message,
      control,
      optionsPromise: useRepChoices,
    },
    {
      name: 'bid_type',
      type: FieldType.AsyncSelect,
      label: 'Select Bid Type',
      placeholder: 'Select Bid Type',
      error: errors.bid_type?.message,
      control,
      optionsPromise: useBidTypeChoices,
      orderByLabel: false,
    },
  ];

  if (isLoading || templateLoading) {
    return <div className="center h-40">Fetching form config...</div>;
  }

  if (!templates?.length) {
    return <ImportManufacturers />;
  }

  return (
    <div>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <Layout
          className="mt-4 space-y-4"
          fields={processFields(fields, config)}
        />
        {_waste_pct_choice === 'custom' && (
          <Layout
            className="mt-4 space-y-4"
            fields={processFields(fields1, config)}
          />
        )}
        <Layout
          className="mt-4 space-y-4"
          fields={processFields(fields2, config)}
        />
        <Button label="Create Estimate" className="mt-8" />
      </form>
      {templateDetail && (
        <Modal variant={Variant.FullScreen} open={templateDetail}>
          <div className="relative">
            <Icon
              name="close"
              className="absolute top-3 right-2 z-10"
              onClick={closeTemplateDetail}
              size={28}
            />
            <RoofTemplateBuilder template={_template?.toString()} readOnly />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default CreateEstimateFromDrawing;

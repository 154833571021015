import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FieldType } from 'utils/enum';
import { useParams } from 'react-router-dom';
import { Layout } from 'components/Form';
import Button from 'components/Button';
import Icon from 'components/Icon';
import {
  useInspectionChoices,
  useJobDetail,
  useRoofBuildingChoices,
  useRoofMeasuremntChoices,
} from 'queries/job';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createSumoquote } from 'queries/estimates';
import toast from 'react-hot-toast';
import { readAxiosErr } from 'utils/misc';
import { epochNow } from 'utils/time';

interface IFormValues {
  cover_photo: string;
  inspection: number;
  roof_building: number;
  roof_measurement: number;
}

type AppProps = {
  goBack: () => void;
  closeModal: () => void;
};

const CreateSumoquote: React.FC<AppProps> = ({ goBack, closeModal }) => {
  const { jobId = '' } = useParams();
  const { data, isLoading } = useJobDetail(jobId);
  const queryClient = useQueryClient();

  const createMutation = useMutation(
    (formData: any) => createSumoquote(jobId, formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: () => {
        queryClient.setQueryData(['job-detail', jobId], (old: any = {}) => ({
          ...old,
          sumoquote_meta: {
            status: 'processing',
            order_placed_timestamp: epochNow(),
          },
        }));
        queryClient.setQueryData(['job-checklist', jobId], (old: any = {}) => ({
          ...old,
          estimate: {
            ...old.estimate,
            type: 'sumoquote',
          },
        }));
        closeModal();
      },
    }
  );

  const schema = yup.object({
    cover_photo: yup.string(),
    inspection: yup.number(),
    roof_building: yup.number(),
    roof_measurement: yup.number(),
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IFormValues>({ resolver: yupResolver(schema) });
  const _drawing = watch('roof_building');
  const _measurement = watch('roof_measurement');

  const onSubmit: SubmitHandler<IFormValues> = async (formData) => {
    createMutation.mutate(formData);
  };

  const fields: Record<string, any>[] = [];
  if (!data?.cover_photo) {
    fields.push({
      type: FieldType.JobPhotos,
      label: 'Cover Photo',
      name: 'cover_photo',
      error: errors?.cover_photo?.message,
      reset: () => setValue('cover_photo', ''),
      job: jobId,
      control,
      limit: 1,
    });
  }

  fields.push({
    name: 'inspection',
    type: FieldType.AsyncSelect,
    label: 'Select Inspection',
    placeholder: 'Select Inspection',
    error: errors.inspection?.message,
    control,
    // eslint-disable-next-line react-hooks/rules-of-hooks
    optionsPromise: () => useInspectionChoices(jobId),
  });

  const drawingFields = [
    {
      name: 'roof_building',
      type: FieldType.AsyncSelect,
      label: 'Select Drawing',
      placeholder: 'Select Drawing',
      error: errors.roof_building?.message,
      control,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      optionsPromise: () => useRoofBuildingChoices(jobId),
      disabled: !!_measurement,
      isClearable: true,
    },
  ];

  const measurementFields = [
    {
      name: 'roof_measurement',
      type: FieldType.AsyncSelect,
      label: 'Select Measurement',
      placeholder: 'Select Measurement',
      error: errors.roof_measurement?.message,
      control,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      optionsPromise: () => useRoofMeasuremntChoices(jobId),
      disabled: !!_drawing,
      isClearable: true,
    },
  ];

  if (isLoading) {
    return <div className="center h-40">Fetching form config...</div>;
  }

  return (
    <div className="pb-4">
      <div className="flex justify-between mb-8">
        <div className="text-lg font-bold">Create Sumoquote</div>
        <div>
          <Icon name="close" size={30} onClick={closeModal} />
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Layout fields={fields} />
        <div className="mt-4">
          <Layout fields={drawingFields} />
          <div className="pb-2 pt-3 text-xxs center">OR</div>
          <Layout fields={measurementFields} />
        </div>

        <Button label="Create Sumoquote" className="mt-8" />
      </form>
      <div className="center mt-4" onClick={goBack}>
        <Icon name="arrow-left" size={16} />
        <div className="text-sm text-text-light ml-2">
          Select another option
        </div>
      </div>
    </div>
  );
};

export default CreateSumoquote;

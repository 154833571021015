import { useState } from 'react';

const useModal = (initialOpen = false): [boolean, () => void, () => void] => {
  const [open, setOpen] = useState(initialOpen);

  const showModal = () => setOpen(true);
  const hideModal = () => setOpen(false);

  return [open, showModal, hideModal];
};

export default useModal;

import BottomSheet from 'components/BottomSheet';
import Button, { Size, Variants } from 'components/Button';
import useModal from 'hooks/useModal';
import React from 'react';
import Checkbox from './Checkbox';
import VerifyLeadForm from 'components/VerifyLead';
import { formatServerTime } from 'utils/time';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { unverifyLead } from 'queries/job';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { readAxiosErr } from 'utils/misc';
import Confirmation from 'components/Confirmation';
import clx from 'classnames';

type AppProps = {
  data: Record<string, any>;
  renderInTable?: boolean;
  job?: string;
  card?: boolean;
};

const VerifyLead: React.FC<AppProps> = ({
  data,
  renderInTable = false,
  job = '',
}) => {
  const { jobId: _jobId = '' } = useParams();
  const jobId = job || _jobId;
  const [verify, showVerify, closeVerify] = useModal();
  const [confirmOpen, showConfirmModal, closeConfirmModal] = useModal();
  const queryClient = useQueryClient();

  const unverifyMutation = useMutation(() => unverifyLead(jobId), {
    onError: (err: any) => {
      toast.error(readAxiosErr(err));
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(['job-last-checklist', jobId]),
        queryClient.invalidateQueries(['job-checklist', jobId]),
      ]);
      queryClient.invalidateQueries(['job-detail', jobId]);
      queryClient.invalidateQueries(['jobs']);

      closeConfirmModal();
    },
  });

  const incomplete = () => {
    return (
      <div className="apart">
        <div className="value">Lead Not Verified</div>
        <div>
          <Button
            label="Verify"
            size={Size.Small}
            variant={Variants.Tag}
            onClick={showVerify}
          />
        </div>
      </div>
    );
  };

  const complete = () => {
    return (
      <div>
        <div className="apart">
          <div className="value">Lead Verified</div>
          <div>
            <Button
              variant={Variants.ErrorNegative}
              label="Unverify Lead?"
              size={Size.Small}
              onClick={showConfirmModal}
              loading={unverifyMutation.isLoading}
            />
          </div>

          {confirmOpen && (
            <Confirmation
              title="Unverify Lead?"
              message="Are you sure you want to mark this lead as unverified?"
              open={confirmOpen}
              closeConfirm={closeConfirmModal}
              onClick={() => unverifyMutation.mutate()}
              isLoading={unverifyMutation.isLoading}
            />
          )}
        </div>
        <div className="-mt-1">
          <span className="key">Verified By: </span>
          <span className="key">{data.completed_by?.name}</span>
        </div>
        <div className="-mt-1">
          <span className="key">Verified At: </span>
          <span className="key">
            {formatServerTime({
              date: data.date,
              parseFormat: 'YYYY-MM-DDThh:mm:ssZ',
              includeTime: true,
            })}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={clx({ card: !renderInTable, 'px-2 pb-1': renderInTable })}>
      <div className="flex space-x-2">
        <Checkbox checked={data.complete} />
        <div className="w-full">
          {data.complete ? complete() : incomplete()}
        </div>
      </div>
      {verify && (
        <BottomSheet open={verify}>
          <VerifyLeadForm
            jobId={jobId}
            closeModal={() => {
              closeVerify();
            }}
          />
        </BottomSheet>
      )}
    </div>
  );
};

export default VerifyLead;

import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import apiService from 'utils/apiService';
import axios from 'axios';

// ******** Get Photos For Job *********
const getPhotos = async ({ pageParam = 1, ...q }) => {
  const { data } = await apiService.get('/api/light/photos/', {
    ...q,
    page: pageParam,
  });
  return data;
};

export const usePhotos = (q: Record<string, any>) => {
  return useInfiniteQuery(
    ['photos', q],
    ({ pageParam }) => getPhotos({ pageParam, ...q }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next_page ?? undefined;
      },
      staleTime: 15 * 60 * 1000,
    }
  );
};

const getPhotoChoices = async ({ pageParam = 1, ...q }) => {
  const { data } = await apiService.get('/api/light/photo_choices/', {
    ...q,
    page: pageParam,
  });
  return data;
};

export const usePhotoChoices = (q: Record<string, any>) => {
  return useInfiniteQuery(
    ['photo-choices', q],
    ({ pageParam }) => getPhotoChoices({ pageParam, ...q }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next_page ?? undefined;
      },
      staleTime: 2 * 60 * 1000,
    }
  );
};

// ********* Get Photo Detail *********
const getPhotoDetail = async (id: string) => {
  const { data } = await apiService.get(`/api/light/photos/${id}/`);
  return data;
};

export const usePhotoDetail = (id: string) => {
  return useQuery(['photo-detail', id], () => getPhotoDetail(id), {
    staleTime: 15 * 60 * 1000,
  });
};

// ****** Upload Photo *******
export const createPhoto = async (payload: Record<string, any>) => {
  const { data } = await apiService.post('/api/light/photos/', payload);
  return data;
};

export const uploadToS3 = async (
  url: string,
  formData: Record<string, any>
) => {
  await axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return true;
};

export const markPhotoUpload = async (id: number) => {
  await apiService.put(`/api/light/photos/${id}/uploaded/`);
  return true;
};

export const deletePhoto = async (id: string, q?: Record<string, any>) => {
  await apiService.delete(`/api/light/photos/${id}/`, q);
  return true;
};

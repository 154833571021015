import React from 'react';
import { useParams } from 'react-router-dom';
import BottomSheet from 'components/BottomSheet';
import Form from './Form';
import Icon from 'components/Icon';

type AppProps = {
  open: boolean;
  closeModal: () => any;
  claimId?: string;
};

const FileClaim: React.FC<AppProps> = ({ open, closeModal, claimId }) => {
  const { claimId: _claimId = '' } = useParams();

  const claim_id = claimId || _claimId;
  return (
    <BottomSheet open={open} maxWidth="max-w-3xl">
      <div className="pb-4">
        <div className="flex justify-between">
          <div className="page-heading">
            {claim_id ? 'Update Claim' : 'Create Claim'}
          </div>
          <div>
            <Icon
              name="close"
              size={32}
              onClick={closeModal}
              className="cursor-pointer"
            />
          </div>
        </div>
      </div>
      <Form onSuccess={closeModal} claimId={claim_id} />
    </BottomSheet>
  );
};

export default FileClaim;

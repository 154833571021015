import React from 'react';
import Checkbox from './Checkbox';
import clx from 'classnames';
import Button, { Size, Variants } from 'components/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { formatServerTime } from 'utils/time';

type AppProps = {
  data: Record<string, any>;
  renderInTable?: boolean;
};

const FirstPaymentReceived: React.FC<AppProps> = ({
  data,
  renderInTable = false,
}) => {
  const { jobId = '' } = useParams();
  const navigate = useNavigate();

  const incomplete = () => {
    return (
      <div className="apart">
        <div className="value">
          First Payment Not <br /> Received
        </div>
        {data.permissions.can_receive && (
          <div>
            <Button
              label="Receive Payment"
              size={Size.Small}
              variant={Variants.Tag}
              onClick={() => navigate(`/jobs/${jobId}/payments`)}
            />
          </div>
        )}
      </div>
    );
  };

  const complete = () => {
    return (
      <div>
        <div className="apart">
          <div className="value">First Payment Received</div>
          <div>
            <Button
              label="View Payment"
              variant={Variants.Tag}
              size={Size.Small}
              onClick={() => navigate(`/jobs/${jobId}/payments`)}
            />
          </div>
        </div>
        <div className="mt-1">
          <span className="key">Received At: </span>
          <span className="key">
            {formatServerTime({
              date: data.date,
              parseFormat: 'YYYY-MM-DDThh:mm:ssZ',
              includeTime: true,
            })}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={clx({ card: !renderInTable, 'px-2 pb-1': renderInTable })}>
      <div className="flex space-x-2">
        <Checkbox checked={data.complete} />
        <div className="w-full">
          {data.complete ? complete() : incomplete()}
        </div>
      </div>
    </div>
  );
};

export default FirstPaymentReceived;

import React from 'react';
import BottomSheet from 'components/BottomSheet';
import Form from './Form';
import Icon from 'components/Icon';

type AppProps = {
  open: boolean;
  closeModal: () => any;
  companyId?: string;
  initial?: Record<string, any>;
  onSuccess?: (...args: any) => any;
};

const CreateInsuranceCompany: React.FC<AppProps> = ({
  open,
  closeModal,
  initial = {},
  companyId = '',
  onSuccess = () => null,
}) => {
  return (
    <BottomSheet open={open} maxWidth="max-w-2xl">
      <div className="pb-4">
        <div className="flex justify-between">
          <div className="page-heading">
            {companyId
              ? 'Update Insurance Company'
              : 'Create Insurance Company'}
          </div>
          <div>
            <Icon
              name="close"
              size={32}
              onClick={closeModal}
              className="cursor-pointer"
            />
          </div>
        </div>
      </div>
      <Form
        onSuccess={(res) => {
          onSuccess?.(res);
          closeModal();
        }}
        companyId={companyId}
        initial={initial}
      />
    </BottomSheet>
  );
};

export default CreateInsuranceCompany;

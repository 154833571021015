import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import apiService from 'utils/apiService';

export const getMatorders = async ({ pageParam = 1, ...q }) => {
  const { data } = await apiService.get('/api/light/matorders/', {
    ...q,
    page: pageParam,
  });
  return data;
};

export const useMatorders = (q: Record<string, any>) => {
  return useInfiniteQuery(
    ['matorders', q],
    ({ pageParam }) => getMatorders({ pageParam, ...q }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next_page ?? undefined;
      },
      staleTime: 5 * 60 * 1000,
    }
  );
};

export const useDesktopMatorders = (q?: Record<string, any>, page = 1) => {
  return useQuery(
    ['matorders', 'desktop', q, { page }],
    () => getMatorders({ pageParam: page, ...q }),
    {
      staleTime: 15 * 60 * 1000,
      keepPreviousData: true,
    }
  );
};

const getMatorderDetail = async (id: string) => {
  const { data } = await apiService.get(`/api/light/matorders/${id}/`);
  return data;
};

export const useMatorderDetail = (id: string) => {
  return useQuery(['matorders', id.toString()], () => getMatorderDetail(id), {
    staleTime: 5 * 60 * 1000,
  });
};

const getMatorderSupplierLocations = async (id: string) => {
  const { data } = await apiService.get(
    `/api/light/matorders/${id}/supplier_location_choices/`
  );
  return data;
};

export const useMatorderSupplierLocations = (id: string) => {
  return useQuery(
    ['matorders', id, 'supplier-locations'],
    () => getMatorderSupplierLocations(id),
    {
      staleTime: 30 * 60 * 1000,
    }
  );
};

// **** mutations ******
export const deleteMatorder = async (id: string) => {
  await apiService.delete(`/api/light/matorders/${id}/`);
};

export const createBlankMatorder = async (payload: Record<string, any>) => {
  const { data } = await apiService.post('/api/light/matorders/', payload);
  return data;
};

export const updateMatorder = async (
  matorderId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/matorders/${matorderId}/`,
    payload
  );
  return data;
};

export const updateMatorderTotals = async (
  matorderId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/light/matorders/${matorderId}/update_totals/`,
    payload
  );
  return data;
};

const getMatorderFieldConfig = async (q: Record<string, any>) => {
  const { data } = await apiService.get(
    '/api/light/matorders/fields_config/',
    q
  );
  return data;
};

interface IFieldConfig {
  job?: string;
  matorder?: string;
}

export const useMatorderFieldConfig = (q: IFieldConfig = {}) => {
  const { job, matorder } = q;
  return useQuery(
    ['matorders', 'field-config', { job, matorder }],
    () => getMatorderFieldConfig({ job, matorder }),
    {
      staleTime: matorder ? 0 : Infinity,
    }
  );
};

const getMatorderTotalsFieldConfig = async (
  id: string,
  q: Record<string, any>
) => {
  const { data } = await apiService.get(
    `/api/light/matorders/${id}/totals_fields_config/`,
    q
  );
  return data;
};

interface IFieldConfig {
  job?: string;
  matorder?: string;
}

export const useMatorderTotalsFieldConfig = (
  id: string,
  q: IFieldConfig = {}
) => {
  return useQuery(
    ['matorders', id, 'totals-field-config', q],
    () => getMatorderTotalsFieldConfig(id, q),
    {
      staleTime: 0,
    }
  );
};

// ********* Lineitems *********

interface ILineitemFieldConfig {
  matorder?: string;
  lineitem?: string;
}

const getLineItemFieldConfig = async (q: Record<string, any>) => {
  const { data } = await apiService.get(
    `/api/light/matorder-lineitems/fields_config/`,
    q
  );
  return data;
};

export const useLineItemFieldConfig = (q: ILineitemFieldConfig = {}) => {
  return useQuery(
    ['matorder-lineitems', 'field-config', q],
    () => getLineItemFieldConfig(q),
    {
      staleTime: q.lineitem ? 0 : Infinity,
    }
  );
};

const getLineItemProductChoices = async (q: Record<string, any>) => {
  const { data } = await apiService.get(
    '/api/light/matorder-lineitems/product_choices/',
    q
  );
  return data;
};

export const useLineItemProductChoices = (q: Record<string, any>) => {
  return useQuery(
    ['matorder-lineitems', 'product-choices', q],
    () => getLineItemProductChoices(q),
    {
      staleTime: q.lineitem ? 0 : Infinity,
    }
  );
};

export const createLineitem = async (
  payload: Record<string, any>,
  q?: Record<string, any>
) => {
  const { data } = await apiService.post(
    'api/light/matorder-lineitems/',
    payload,
    q
  );
  return data;
};

export const updateLineitem = async (
  id: string,
  payload: Record<string, any>,
  q?: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/matorder-lineitems/${id}/`,
    payload,
    q
  );
  return data;
};

export const deleteLineitem = async (id: string) => {
  await apiService.delete(`/api/light/matorder-lineitems/${id}/`);
};

export const createMatorderFromDrawing = async (
  jobId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/jobs/${jobId}/rooflayout_matorder/`,
    payload
  );
  return data;
};

export const createMatorderFromMeasurements = async (
  jobId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/jobs/${jobId}/manual_rooflayout_matorder/`,
    payload
  );
  return data;
};

export const createLightMatorderFromDrawing = async (
  jobId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/jobs/${jobId}/light_rooflayout_matorder/`,
    payload
  );
  return data;
};

export const createLightMatorderFromMeasurements = async (
  jobId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/jobs/${jobId}/light_manual_rooflayout_matorder/`,
    payload
  );
  return data;
};

// ###### Checklist #####
const getMatorderPipeline = async (id: number) => {
  const { data } = await apiService.get(
    `/api/light/matorders/${id}/checklist/`
  );
  return data;
};

export const useMatorderPipeline = (id: number) => {
  return useQuery(
    ['matorders', id?.toString(), 'checklist'],
    () => getMatorderPipeline(id),
    {
      staleTime: 5 * 60 * 1000,
    }
  );
};

export const submit = async (id: number, payload: Record<string, any>) => {
  const { data } = await apiService.post(
    `/api/light/matorders/${id}/submit/`,
    payload
  );
  return data;
};

export const unsubmit = async (id: number, payload: Record<string, any>) => {
  const { data } = await apiService.post(
    `/api/light/matorders/${id}/unsubmit/`,
    payload
  );
  return data;
};

export const reject = async (id: number, payload: Record<string, any>) => {
  const { data } = await apiService.post(
    `/api/light/matorders/${id}/reject/`,
    payload
  );
  return data;
};

export const undoReject = async (id: number, payload: Record<string, any>) => {
  const { data } = await apiService.post(
    `/api/light/matorders/${id}/undo_reject/`,
    payload
  );
  return data;
};

export const approve = async (id: number, payload: Record<string, any>) => {
  const { data } = await apiService.post(
    `/api/light/matorders/${id}/approve/`,
    payload
  );
  return data;
};

const getHoldOrderFieldConfig = async (id: string) => {
  const { data } = await apiService.get(
    `/api/light/matorders/${id}/hold_fields_config/`
  );
  return data;
};

export const useHoldOrderFieldConfig = (id: string) => {
  return useQuery(
    ['matorders', id.toString(), 'hold-order-field-config'],
    () => getHoldOrderFieldConfig(id),
    {
      staleTime: 0,
    }
  );
};

const getPlaceOrderRecipientsChoices = async (id: string) => {
  const { data } = await apiService.get(
    `/api/light/matorders/${id}/place_order_recipients_choices/`
  );
  return data;
};

export const usePlaceOrderRecipientsChoices = (id: string) => {
  return useQuery(
    ['matorders', id.toString(), 'recipinets-choices'],
    () => getPlaceOrderRecipientsChoices(id),
    {
      staleTime: Infinity,
    }
  );
};

export const holdOrder = async (id: string, payload: Record<string, any>) => {
  const { data } = await apiService.patch(
    `/api/light/matorders/${id}/hold/`,
    payload
  );
  return data;
};

const getCancelOrderFieldConfig = async (id: string) => {
  const { data } = await apiService.get(
    `/api/light/matorders/${id}/cancel_fields_config/`
  );
  return data;
};

export const useCancelOrderFieldConfig = (id: string) => {
  return useQuery(
    ['matorders', id.toString(), 'cancel-order-field-config'],
    () => getCancelOrderFieldConfig(id),
    {
      staleTime: 0,
    }
  );
};

export const cancelOrder = async (id: string, payload: Record<string, any>) => {
  const { data } = await apiService.patch(
    `/api/light/matorders/${id}/cancel/`,
    payload
  );
  return data;
};

const getOffHoldOrderFieldConfig = async (id: string) => {
  const { data } = await apiService.get(
    `/api/light/matorders/${id}/off_hold_fields_config/`
  );
  return data;
};

export const useOffHoldOrderFieldConfig = (id: string) => {
  return useQuery(
    ['matorders', id.toString(), 'off_hold-order-field-config'],
    () => getOffHoldOrderFieldConfig(id),
    {
      staleTime: 0,
    }
  );
};

export const off_holdOrder = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/light/matorders/${id}/off_hold/`,
    payload
  );
  return data;
};

const getMatorderDeliveryTimeChoices = async () => {
  const { data } = await apiService.get(
    `/api/light/matorders/delivery_time_choices/`
  );
  return data;
};

export const useMatorderDeliveryTimeChoices = () => {
  return useQuery(
    ['matorders-delivery-time-choices'],
    getMatorderDeliveryTimeChoices,
    {
      staleTime: Infinity,
    }
  );
};

const getPlaceOrderFieldConfig = async (id: string) => {
  const { data } = await apiService.get(
    `/api/light/matorders/${id}/place_order_fields_config/`
  );
  return data;
};

export const usePlaceOrderFieldConfig = (id: string) => {
  return useQuery(
    ['matorders', id.toString(), 'place-order-field-config'],
    () => getPlaceOrderFieldConfig(id),
    {
      staleTime: 0,
    }
  );
};

export const placeOrder = async (id: string, payload: Record<string, any>) => {
  const { data } = await apiService.patch(
    `/api/light/matorders/${id}/place_order/`,
    payload
  );
  return data;
};

export const pickupOrder = async (id: string, payload: Record<string, any>) => {
  const { data } = await apiService.patch(
    `/api/light/matorders/${id}/pickup/`,
    payload
  );
  return data;
};

export const undoPickup = async (id: string) => {
  const { data } = await apiService.post(
    `/api/light/matorders/${id}/undo_pickup/`
  );
  return data;
};

export const payOrder = async (id: string, payload: Record<string, any>) => {
  const { data } = await apiService.post(
    `/api/light/matorders/${id}/pay/`,
    payload
  );
  return data;
};

export const undoPay = async (id: string) => {
  const { data } = await apiService.post(
    `/api/light/matorders/${id}/undo_paid/`
  );
  return data;
};

export const syncToQb = async (id: string) => {
  const { data } = await apiService.post(`/api/matorders/${id}/qb_sync/`);
  return data;
};

export const pullFromQB = async (id: string) => {
  const { data } = await apiService.post(`/api/matorders/${id}/pull_from_qb/`);
  return data;
};

export const savePdfToDocs = async (id: string, q?: Record<string, any>) => {
  const { data } = await apiService.get(`/api/matorders/${id}/save_pdf/`, q);
  return data;
};

export const printPdf = async (id: string) => {
  return await apiService.get(`/api/matorders/${id}/print_pdf/`);
};

export const copyMatorder = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(`/api/matorders/${id}/copy/`, payload);
  return data;
};

export const pullLatestPricing = async (id: string) => {
  const { data } = await apiService.get(
    `/api/matorders/${id}/pull_latest_pricing/`
  );
  return data;
};

import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import apiService from 'utils/apiService';

export const getTemplates = async ({ pageParam = 1, ...q }) => {
  const { data } = await apiService.get('/api/light/rooftemplates/', {
    ...q,
    page: pageParam,
  });
  return data;
};

export const useTemplates = (q?: Record<string, any>) => {
  return useInfiniteQuery(
    ['rooftemplates', q],
    ({ pageParam }) => getTemplates({ pageParam, ...q }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next_page ?? undefined;
      },
      staleTime: 5 * 60 * 1000,
    }
  );
};

export const useDesktopTemplates = (q?: Record<string, any>, page = 1) => {
  return useQuery(
    ['rooftemplates', 'desktop', q, { page }],
    () => getTemplates({ pageParam: page, ...q }),
    {
      staleTime: 15 * 60 * 1000,
      keepPreviousData: true,
    }
  );
};

const getTemplateDetail = async (id: string, q?: Record<string, any>) => {
  const { data } = await apiService.get(`/api/light/rooftemplates/${id}/`, q);
  return data;
};

export const useTemplateDetail = (id: string, q?: Record<string, any>) => {
  return useQuery(['rooftemplates', id, q], () => getTemplateDetail(id, q), {
    staleTime: 15 * 60 * 1000,
  });
};

const getTempalteFieldConfig = async (q: Record<string, any>) => {
  const { data } = await apiService.get(
    `/api/light/rooftemplates/field_config/`,
    q
  );
  return data;
};

export const useTemplateFieldConfig = (q: Record<string, any>) => {
  return useQuery(
    ['rooftemplate-field-config', q],
    () => getTempalteFieldConfig(q),
    { staleTime: q?.template ? 0 : Infinity }
  );
};

export const createRoofTemplate = async (payload: Record<string, any>) => {
  const { data } = await apiService.post('/api/light/rooftemplates/', payload);
  return data;
};

export const updateRoofTemplate = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/rooftemplates/${id}/`,
    payload
  );
  return data;
};

export const deleteRoofTemplate = async (id: string) => {
  await apiService.delete(`/api/light/rooftemplates/${id}/`);
  return true;
};

export const copyRoofTemplate = async (
  id: string,
  payload?: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/rooftemplates/${id}/copy/`,
    payload
  );
  return data;
};

export const bulkCopyRoofTemplateAcrossCompany = async (
  payload?: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/rooftemplates/bulk_copy_across_company/`,
    payload
  );
  return data;
};

// Rooftemplate lineitems
const getLineitemFieldConfig = async (q: Record<string, any>) => {
  const { data } = await apiService.get(
    `/api/light/rooftemplate-lineitems/fields_config/`,
    q
  );
  return data;
};

export const useLineitemFieldConfig = (q: Record<string, any>) => {
  return useQuery(
    ['rooftemplate-lineitems-field-config', q],
    () => getLineitemFieldConfig(q),
    { staleTime: q?.lineitem ? 0 : Infinity }
  );
};

export const createRoofTemplateLineitem = async (
  payload: Record<string, any>,
  q?: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/rooftemplate-lineitems/`,
    payload,
    q
  );
  return data;
};

export const updateRoofTemplateLineitem = async (
  id: number,
  payload: Record<string, any>,
  q?: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/rooftemplate-lineitems/${id}/`,
    payload,
    q
  );
  return data;
};

export const deleteRoofTemplateLineitem = async (id: number) => {
  await apiService.delete(`/api/light/rooftemplate-lineitems/${id}/`);
};

export const activateLineitem = async (id: number) => {
  const { data } = await apiService.post(
    `/api/light/rooftemplate-lineitems/${id}/activate/`
  );
  return data;
};

export const deactivateLineitem = async (id: number) => {
  const { data } = await apiService.post(
    `/api/light/rooftemplate-lineitems/${id}/deactivate/`
  );
  return data;
};

export const createRoofTemplateAccessory = async (
  payload: Record<string, any>,
  q?: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/light/rooftemplate-accessories/`,
    payload,
    q
  );
  return data;
};

export const updateRoofTemplateAccessory = async (
  id: number,
  payload: Record<string, any>,
  q?: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/rooftemplate-accessories/${id}/`,
    payload,
    q
  );
  return data;
};

export const deleteRoofTemplateAccessory = async (id: number) => {
  await apiService.delete(`/api/light/rooftemplate-accessories/${id}/`);
};

export const activateAccessory = async (id: number) => {
  const { data } = await apiService.post(
    `/api/light/rooftemplate-accessories/${id}/activate/`
  );
  return data;
};

export const deactivateAccessory = async (id: number) => {
  const { data } = await apiService.post(
    `/api/light/rooftemplate-accessories/${id}/deactivate/`
  );
  return data;
};

export const copyToCompanies = async (payload: Record<string, any>) => {
  const { data } = await apiService.post(
    `/api/light/rooftemplates/copy_to_companies/`,
    payload
  );
  return data;
};

import React from 'react';
import Icon from 'components/Icon';
import Form from './Form';

type AppProps = {
  goBack: () => void;
  closeModal: () => void;
};

const CreateFromEstimateTemplates: React.FC<AppProps> = ({
  goBack,
  closeModal,
}) => {
  return (
    <div className="pb-4">
      <div className="flex justify-between pb-4">
        <div className="text-lg font-bold">Create Estimate from Templates</div>
        <div>
          <Icon
            name="close"
            size={30}
            onClick={closeModal}
            className="cursor-pointer"
          />
        </div>
      </div>
      <Form />
      <div className="center mt-4 cursor-pointer" onClick={goBack}>
        <Icon name="arrow-left" size={16} />
        <div className="text-sm text-text-light ml-2">
          Select another option
        </div>
      </div>
    </div>
  );
};

export default CreateFromEstimateTemplates;

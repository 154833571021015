import BottomSheet from 'components/BottomSheet';
import Icon from 'components/Icon';
import React from 'react';
import Form from './Form';

type AppProps = {
  mrlId?: string;
  open: boolean;
  closeModal: () => any;
};

const CreateManualRoofLayout: React.FC<AppProps> = ({
  mrlId = '',
  open,
  closeModal,
}) => {
  return (
    <BottomSheet open={open} maxWidth="max-w-2xl">
      <div className="pb-4">
        <div className="flex justify-between">
          <div className="page-heading">
            {mrlId ? 'Update Roof Measurement' : 'Create Roof Measurement'}
          </div>
          <div>
            <Icon
              name="close"
              size={32}
              onClick={closeModal}
              className="cursor-pointer"
            />
          </div>
        </div>
      </div>
      <Form mrlId={mrlId} onSuccess={closeModal} />
    </BottomSheet>
  );
};

export default CreateManualRoofLayout;

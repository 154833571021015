import Icon from 'components/Icon';
import useJobDetailSidebar from 'hooks/useJobDetailSidebar';
import {
  useJobDetail,
  useJobQuickChecklist,
  useProspectQuickChecklist,
} from 'queries/job';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link, useParams } from 'react-router-dom';
import { checklistMap } from '../../Checklist/Checklist.mobile';

const LastCompletedProspectChecklist = () => {
  const { jobId = '' } = useParams();
  const { job: sidebarJob } = useJobDetailSidebar();

  const _jobId = sidebarJob || jobId;

  // const { data, isLoading } = useJobLastChecklist(_jobId);
  const { data, isLoading } = useProspectQuickChecklist(_jobId);

  return (
    <div className="card cursor-pointer shadow-md border-t">
      <div className="w-full apart mb-5">
        <div className="text-sm text-text-medium font-bold">Checklist</div>
        <Link to={`/jobs/${_jobId}/checklist`}>
          <div className="v-center space-x-1 text-xs text-primary">
            <div>View full Checklist</div>
            <Icon name="arrow-right" size={16} />
          </div>
        </Link>
      </div>
      {(() => {
        if (isLoading) {
          return <Skeleton height={40} />;
        }

        return (
          <div className="space-y-3">
            {data?.map((item: any) => {
              const Component = checklistMap[item.key] || null;
              if (!Component) {
                return null;
              }
              return <Component key={item.key} data={item} renderInTable />;
            })}
          </div>
        );
      })()}
    </div>
  );
};

const LastCompletedJobChecklist = () => {
  const { jobId = '' } = useParams();
  const { job: sidebarJob } = useJobDetailSidebar();

  const _jobId = sidebarJob || jobId;

  const { data, isLoading } = useJobQuickChecklist(_jobId);

  return (
    <div className="card cursor-pointer shadow-md border-t">
      <div className="w-full apart mb-5">
        <div className="text-sm text-text-medium font-bold">Checklist</div>
        <Link to={`/jobs/${_jobId}/checklist`}>
          <div className="v-center space-x-1 text-xs text-primary">
            <div>View full Checklist</div>
            <Icon name="arrow-right" size={16} />
          </div>
        </Link>
      </div>
      {(() => {
        if (isLoading) {
          return <Skeleton height={40} />;
        }

        return (
          <div className="space-y-3">
            {data?.map((item: any) => {
              const Component = checklistMap[item.key] || null;
              if (!Component) {
                return null;
              }
              return <Component key={item.key} data={item} renderInTable />;
            })}
          </div>
        );
      })()}
    </div>
  );
};

const LastCompletedChecklist = () => {
  const { jobId = '' } = useParams();
  const { job: sidebarJob } = useJobDetailSidebar();

  const _jobId = sidebarJob || jobId;
  const { data: job } = useJobDetail(_jobId);

  if (job?.date_approved) {
    return <LastCompletedJobChecklist />;
  }

  return <LastCompletedProspectChecklist />;
};

export default LastCompletedChecklist;

import Icon from 'components/Icon';
import Modal, { Variant as ModalVariant } from 'components/Modal';
import React from 'react';
import Button, {
  Variants as ButtonVariants,
  Size as ButtonSize,
} from 'components/Button';

type AppProps = {
  title: string;
  message: string | React.ReactNode;
  open: boolean;
  isLoading?: boolean;
  closeConfirm: () => void;
  onClick: () => void;
};

const Confirmation: React.FC<AppProps> = ({
  title,
  message,
  open = false,
  isLoading = false,
  closeConfirm = () => null,
  onClick,
}) => {
  return (
    <Modal
      variant={ModalVariant.Center}
      open={open}
      closeModal={isLoading ? () => null : closeConfirm}
    >
      <div className="text-lg font-bold v-center">
        <Icon name="warning" className="text-red-400 mr-2" /> {title}
      </div>
      <div className="pt-2 text-text-medium">{message}</div>
      <div className="flex items-center justify-end space-x-4 mt-6">
        <div>
          <Button
            label="Cancel"
            variant={ButtonVariants.Outline}
            size={ButtonSize.Medium}
            onClick={closeConfirm}
            disabled={isLoading}
          />
        </div>
        <div>
          <Button
            label="Confirm"
            size={ButtonSize.Medium}
            onClick={onClick}
            loading={isLoading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default Confirmation;

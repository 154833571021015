import Button from 'components/Button';
import SkeletonLoader, { ContentType } from 'components/SkeletonLoader';
import React, { useEffect } from 'react';
import { processFields, processInitialValues, processSchema } from 'utils/form';
import { actionLabel, getFNName, readAxiosErr } from 'utils/misc';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FieldType } from 'utils/enum';
import { InputVariant, Layout } from 'components/Form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useActiveUserChoices } from 'queries/company';
import {
  createSupplier,
  updateSupplier,
  useSupplierFieldConfig,
} from 'queries/suppliers';

interface IFormValues {
  name: string;
  abbr: string;
  primary_company_contact: number;
  supplier_contact_name: string;
  supplier_contact_phone: string;
  supplier_contact_email: string;
}

type AppProps = {
  onSuccess: (...args: any) => any;
  id?: number;
  initial?: Record<string, any>;
};

const Form: React.FC<AppProps> = ({ onSuccess, id, initial }) => {
  const queryClient = useQueryClient();
  const { data: config, isLoading } = useSupplierFieldConfig({
    csr: id,
  });

  const createMutation = useMutation(
    (formData: any) => createSupplier(formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: async (res: any) => {
        await queryClient.invalidateQueries(['suppliers']);
        onSuccess(res);
      },
    }
  );

  const editMutation = useMutation(
    (formData: any) => updateSupplier(id || 0, formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: async (res: any) => {
        await queryClient.invalidateQueries(['suppliers']);
        onSuccess(res);
      },
    }
  );

  const schema = yup.object(
    processSchema(
      {
        name: yup.string(),
        abbr: yup.string(),
        primary_company_contact: yup.number(),
        supplier_contact_name: yup.string(),
        supplier_contact_phone: yup.string().phone('US', false),
        supplier_contact_email: yup.string().email(),
      },
      config
    )
  );

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: yupResolver(schema),
    defaultValues: initial,
  });

  useEffect(() => {
    if (config) {
      const defaultValues = processInitialValues(config);
      reset(defaultValues);
    }
  }, [config]);

  const onSubmit: SubmitHandler<IFormValues> = async (formData: any) => {
    const {
      first_name: supplier_contact_first_name,
      last_name: supplier_contact_last_name,
    } = getFNName(formData.supplier_contact_name);

    const payload = {
      ...formData,
      supplier_contact_first_name,
      supplier_contact_last_name,
    };

    if (id) {
      editMutation.mutate(payload);
    } else {
      createMutation.mutate(payload);
    }
  };

  const fields = [
    {
      type: FieldType.Input,
      label: 'Name',
      placeholder: 'Supplier Name',
      error: errors.name?.message,
      ...register('name'),
    },
    {
      type: FieldType.Input,
      label: 'Abbr',
      placeholder: 'Supplier Abbr',
      error: errors.abbr?.message,
      ...register('abbr'),
    },
    {
      name: 'primary_company_contact',
      type: FieldType.AsyncSelect,
      label: 'Primary Company Contact',
      placeholder: 'Select User',
      error: errors.primary_company_contact?.message,
      control,
      optionsPromise: useActiveUserChoices,
    },
    {
      type: FieldType.Input,
      label: 'Supplier Contact Name',
      placeholder: 'Supplier Contact Name',
      error: errors.supplier_contact_name?.message,
      ...register('supplier_contact_name'),
    },
    {
      type: FieldType.Input,
      label: 'Supplier Contact Phone',
      placeholder: '8888888888',
      error: errors.supplier_contact_phone?.message,
      ...register('supplier_contact_phone'),
    },
    {
      type: FieldType.Input,
      label: 'Supplier Contact Email',
      placeholder: 'jon@gmail.com',
      error: errors.supplier_contact_email?.message,
      variant: InputVariant.Email,
      ...register('supplier_contact_email'),
    },
  ];

  if (isLoading) {
    return <SkeletonLoader contentType={ContentType.Form} size={5} />;
  }

  const _isLoading = createMutation.isLoading || editMutation.isLoading;
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Layout fields={processFields(fields, config)} loading={_isLoading} />
        <Button
          label={actionLabel(id, 'Supplier', _isLoading)}
          className="mt-8"
          loading={_isLoading}
        />
      </form>
    </div>
  );
};

export default Form;

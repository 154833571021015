import { useMediaQuery } from 'react-responsive';

const useMedia = () => {
  const isMobile = useMediaQuery({ maxWidth: 680 });
  const isTablet = useMediaQuery({ maxWidth: 768 });

  return {
    isMobile,
    isTablet,
  };
};

export default useMedia;

import React, { ReactNode } from 'react';
import clx from 'classnames';
import Icon, { Loader } from '../Icon';

export enum Variants {
  Primary = 'primary',
  Negative = 'negative',
  Outline = 'outline',
  PrimaryOutline = 'primary-outline',
  Tag = 'tag',
  Error = 'error',
  ErrorNegative = 'error-negative',
  Warning = 'warning',
  Link = 'link',
  Text = 'text',
}

export enum Size {
  Small = 'small',
  Medium = 'medium',
  Normal = 'normal',
  Big = 'big',
  Large = 'large',
}

export enum RenderAs {
  Button = 'button',
  Div = 'div',
}

export type AppProps = {
  variant?: Variants;
  size?: Size;
  as?: RenderAs;
  label: string;
  disabled?: boolean;
  leftIcon?: string;
  rightIcon?: string;
  loading?: boolean;
  onClick?: (args?: any) => void;
  className?: string;
  render?: React.ReactNode;
  rounded?: boolean;
  iconSize?: number;
  iconClassName?: string;
  enableHover?: boolean;
  containerClass?: string;
};

const Button: React.FC<AppProps> = ({
  variant = Variants.Primary,
  size = Size.Large,
  as = RenderAs.Button,
  label,
  disabled = false,
  onClick,
  leftIcon = null,
  rightIcon = null,
  loading = false,
  className = '',
  render = null,
  rounded = false,
  iconSize,
  iconClassName = '',
  enableHover = false,
  containerClass = '',
}) => {
  const btnStyle = clx(
    `w-full inline-flex items-center justify-center shadow rounded-lg cursor-pointer ${className}`,
    {
      'hover:bg-primary-dark hover:shadow-lg translate-shadow duration-200':
        enableHover && !(disabled || loading),
      'pointer-events-none opacity-50': disabled || loading,
      'bg-primary': variant === Variants.Primary,
      'bg-primary-negative': variant === Variants.Negative,
      'bg-warning': variant === Variants.Warning,
      'bg-error': variant === Variants.Error,
      'bg-red-100 hover:bg-red-400': variant === Variants.ErrorNegative,
      'bg-white border border-primary': variant === Variants.PrimaryOutline,
      'bg-white border border-gray-600': variant === Variants.Outline,
      'bg-none border-0 shadow-none': variant === Variants.Link,
      'rounded-md bg-primary-negative': variant === Variants.Tag,
      'px-3 py-2': size === Size.Large,
      'px-2 py-[7px]': size === Size.Big,
      'px-2 py-1.5': size === Size.Medium,
      'px-2 py-[3px]': size === Size.Normal,
      'py-1 px-1.5': size === Size.Small,
      'rounded-full': rounded,
    }
  );

  const textStyle = clx({
    'text-white':
      variant === Variants.Primary ||
      variant === Variants.Warning ||
      variant === Variants.Error,
    'text-primary':
      variant === Variants.Negative ||
      variant === Variants.Link ||
      variant === Variants.Tag ||
      variant === Variants.PrimaryOutline,
    'text-text': variant === Variants.Outline,
    'text-[red]': variant === Variants.ErrorNegative,
    'hover:text-white':
      enableHover &&
      (variant === Variants.Negative ||
        variant === Variants.Link ||
        variant === Variants.Tag ||
        variant === Variants.PrimaryOutline ||
        variant === Variants.ErrorNegative),
    'text-base': size === Size.Normal,
    'text-[15px]': size === Size.Big || size === Size.Medium,
    'text-xs': size === Size.Small,
  });

  const labelStyle = clx('whitespace-nowrap', {
    'text-lg': size === Size.Large,
    'text-base': size === Size.Big,
    'text-md': size === Size.Medium || size === Size.Normal,
    'text-[12px]': size === Size.Small,
    'pl-[2px] pr-1': leftIcon,
    'pr-[2px] pl-1': rightIcon,
    'px-2': !leftIcon && !rightIcon,
  });

  const iconStyle = clx(textStyle, iconClassName, {
    'h-3.5 w-3.5 text-text-lighter': size === Size.Small,
    'h-5 w-5': size !== Size.Small,
  });

  const renderAsButton = (child: ReactNode) => {
    return (
      <button type="submit" className={btnStyle}>
        {child}
      </button>
    );
  };

  const renderAsDiv = (child: ReactNode) => {
    return <div className={btnStyle}>{child}</div>;
  };

  const btnContent = (
    <div className={`inline-flex justify-center items-center ${textStyle}`}>
      {!loading && leftIcon && (
        <Icon
          name={leftIcon}
          className={iconSize ? iconClassName : iconStyle}
          size={iconSize}
        />
      )}
      {loading && <Loader className={textStyle} />}
      <span className={labelStyle}>{label}</span>
      {render && <span>{render}</span>}
      {rightIcon && (
        <Icon
          name={rightIcon}
          className={`h-5 w-5 ${textStyle} ${iconClassName}`}
          size={iconSize}
        />
      )}
    </div>
  );

  const renderButton = () => {
    if (variant === Variants.Text) {
      return (
        <div
          className={clx(
            'text-primary text-sm cursor-pointer inline-flex items-center',
            {
              'opacity-50': loading,
            }
          )}
        >
          <div className="mr-2">{label}</div>
          {loading && <Loader className={textStyle} />}
        </div>
      );
    }
    return as === RenderAs.Button
      ? renderAsButton(btnContent)
      : renderAsDiv(btnContent);
  };

  return (
    <div
      className={clx('w-full', containerClass)}
      onClick={disabled || loading ? () => null : onClick}
    >
      {renderButton()}
    </div>
  );
};

export default Button;

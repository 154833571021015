import React from 'react';
import { useController } from 'react-hook-form';
import clx from 'classnames';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import useMedia from 'hooks/useMedia';
import useModal from 'hooks/useModal';
import InfoModal from 'components/InfoModal';

export enum Border {
  Full = 'full',
  Underline = 'underline',
}

export enum Variant {
  Integer = 'INTEGER',
  Decimal = 'DECIMAL',
  Amount = 'AMOUNT',
}

type AppProps = {
  label: string;
  labelAction?: React.ReactNode;
  name: string;
  border?: Border;
  control: any;
  placeholder?: string;
  className?: string;
  style?: Record<string, any>;
  error?: string;
  variant?: Variant;
  required?: boolean;
  autoFocus?: boolean;
  loading?: boolean;
  disabled?: boolean;
  info?: string;
};

const Number: React.FC<AppProps> = ({
  name,
  label,
  control,
  labelAction = null,
  border = Border.Full,
  placeholder = '',
  className = '',
  error = null,
  style = {},
  variant = Variant.Decimal,
  required = false,
  autoFocus = false,
  loading = false,
  disabled = false,
  info = '',
}) => {
  const { field } = useController({ name, control });
  const { isMobile } = useMedia();
  const [infoOpen, showInfo, hideInfo] = useModal();

  const inputStyle = clx(
    'w-full outline-none placeholder:text-gray-300 text-text focus:outline-none focus:ring-0',
    {
      'focus:border-error-dark border-error': !!error,
      'focus:border-primary border-border ': !error,
      'rounded-lg ': border !== Border.Underline,
      'border-0 border-b-2': border === Border.Underline,
      'bg-gray-200': disabled || loading,
      'pl-6': variant === Variant.Amount,
    }
  );

  const handleChange = (e: any) => {
    const value = e.target.value;
    const arr = value?.split('.');
    if (variant === Variant.Integer) {
      field.onChange(arr[0]);
    } else {
      if (arr[1] && arr[1]?.length > 2) {
        e.target.value = parseFloat(parseFloat(value).toFixed(2));
      }
      // let value = e.target.value;
      // value = parseFloat(value).toFixed(2);
      // e.target.value = parseFloat(value);
      field.onChange(e);
    }
  };

  return (
    <div className={`space-y-1 mb-2 relative ${className}`}>
      <div className="text-text text-sm flex justify-between w-full">
        <div className="v-center">
          {label}
          {required && <span className="text-error">*</span>}
          {info &&
            (isMobile ? (
              <Icon
                name="info"
                className="text-text-medium ml-2"
                size={18}
                onClick={showInfo}
              />
            ) : (
              <Tooltip tooltipContent={info}>
                <Icon name="info" className="text-text-light ml-2" size={18} />
              </Tooltip>
            ))}
        </div>
        {labelAction}
      </div>
      {variant === Variant.Amount && (
        <div className="absolute left-1 top-7">
          <Icon name="dollar" size={22} className="text-text-medium" />
        </div>
      )}
      <input
        className={inputStyle}
        style={style}
        name={name}
        value={field.value}
        type="number"
        placeholder={placeholder}
        onChange={handleChange}
        step="any"
        onFocus={(e) => {
          if (['0.00', '0', '0.0'].includes(e.target.value)) {
            e.target.value = '';
          }
        }}
        onBlur={(e) => {
          if (e.target.value === '') {
            e.target.value = '0';
          }
        }}
        autoFocus={autoFocus}
        disabled={disabled || loading}
        onWheel={(e) => e.currentTarget.blur()}
      />
      {error && <div className="text-error text-xs">*{error}</div>}
      {infoOpen && (
        <InfoModal
          open={infoOpen}
          closeModal={hideInfo}
          title=""
          description={info}
        />
      )}
    </div>
  );
};

Number.displayName = 'Number';

export default Number;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import Confirmation from 'components/Confirmation';
import Icon from 'components/Icon';
import useModal from 'hooks/useModal';
import { deleteRoofTemplate } from 'queries/rooftemplates';
import React, { useMemo } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { readAxiosErr } from 'utils/misc';
import CreateRoofTemplate from './CreateTemplate/ModalForm';
import Menu from 'components/Menu';
import useMedia from 'hooks/useMedia';
import CopyTemplate from './CopyTemplate';

type AppProps = {
  rooftemplate: Record<string, any>;
  optionClass?: string;
  iconSize?: number;
  renderAsActions?: boolean;
  flattenOptions?: string[];
};

const RoofTemplateOptions: React.FC<AppProps> = ({
  rooftemplate,
  iconSize = 18,
  renderAsActions = false,
  flattenOptions = [],
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [confirmOpen, showConfirmModal, closeConfirmModal] = useModal();
  const [copyOpen, showCopyModal, closeCopyModal] = useModal();
  const [editModal, showEditModal, closeEditModal] = useModal();
  const { isMobile } = useMedia();

  const removeMutation = useMutation(
    () => deleteRoofTemplate(rooftemplate.id),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['rooftemplates']);
        navigate(`/rooftemplates`);
      },
      onSettled: () => {
        closeConfirmModal();
      },
    }
  );

  const options = useMemo(() => {
    const _options = [
      {
        key: 'edit',
        label: 'Edit',
        icon: 'edit',
        iconClassName: 'text-primary cursor-pointer',
        iconSize,
        onClick: isMobile
          ? () => navigate(`/rooftemplates/${rooftemplate?.id}/edit`)
          : () => showEditModal(),
      },
      {
        key: 'copy',
        label: 'Copy',
        icon: 'copy',
        iconClassName: 'text-primary cursor-pointer',
        iconSize: iconSize - 3,
        onClick: showCopyModal,
      },
      {
        key: 'delete',
        label: 'Delete',
        icon: 'delete',
        iconClassName: 'text-error cursor-pointer',
        iconSize,
        onClick: showConfirmModal,
      },
    ];
    return _options;
  }, []);

  return (
    <>
      <Menu
        renderAsActions={renderAsActions}
        flattenOptions={flattenOptions}
        trigger={
          <div>
            <Icon
              name="more"
              size={20}
              className="text-text-light relative top-[2px] right-[2px]"
            />
          </div>
        }
        options={options}
      />
      <Confirmation
        title="Delete Roof template?"
        message="Are you sure you want to delete this Roof template? This action can not be undone."
        open={confirmOpen}
        closeConfirm={closeConfirmModal}
        onClick={() => removeMutation.mutate()}
        isLoading={removeMutation.isLoading}
      />
      {copyOpen && (
        <CopyTemplate
          open={copyOpen}
          closeModal={closeCopyModal}
          data={rooftemplate}
        />
      )}
      <CreateRoofTemplate
        open={editModal}
        rooftemplateId={rooftemplate.id.toString()}
        closeModal={closeEditModal}
      />
    </>
  );
};

export default RoofTemplateOptions;

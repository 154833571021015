import React, { useState } from 'react';
import useModal from 'hooks/useModal';
import { useParams } from 'react-router-dom';
import PhotoSelectorModal from 'components/PhotoSelector';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateCoverPhoto } from 'queries/job';
import toast from 'react-hot-toast';
import { readAxiosErr } from 'utils/misc';
import FixedCropperTool from '../FixedCropperTool';

type AppProps = {
  open: boolean;
  closeModal: () => any;
};

const JobCoverImage: React.FC<AppProps> = ({ open, closeModal }) => {
  const { jobId = '' } = useParams();
  const [photo, setPhoto] = useState<any>(null);
  const [crop, showCrop, closeCrop] = useModal();
  const queryClient = useQueryClient();

  const updateMutation = useMutation(
    (photoId) => updateCoverPhoto(jobId, { photo: photoId }),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['job', jobId]);
        queryClient.invalidateQueries(['job-detail', jobId]);
        closeCrop();
        closeModal();
      },
    }
  );

  return (
    <>
      {open && (
        <PhotoSelectorModal
          open={open}
          closeModal={(close = false) => {
            if (!close && photo) {
              showCrop();
            }
            closeModal();
          }}
          selectedPhotos={photo ? [photo] : []}
          onSelect={(ids: any) => {
            setPhoto(ids[0]);
          }}
          q={{ job: jobId }}
          limit={1}
        />
      )}

      {crop && (
        <FixedCropperTool
          open={crop}
          photo={photo}
          closeModal={closeCrop}
          q={{ job: jobId }}
          submitMutation={updateMutation}
        />
      )}
    </>
  );
};

export default JobCoverImage;

import Icon, { Loader } from 'components/Icon';
import React, { useEffect, useState } from 'react';
import clx from 'classnames';
import { SubmitHandler } from 'react-hook-form';
import { getFileIcon, getFileType } from 'utils/misc';
import { FileType } from 'utils/enum';
import { truncate } from 'lodash';

type AppProps = {
  label: string;
  name: string;
  className?: string;
  boxClass?: string;
  onChange: SubmitHandler<any>;
  error?: string;
  required?: boolean;
  reset?: () => any;
  loading?: boolean;
  accept?: string;
  initialPreview?: string;
  fetchingFromWeb?: boolean;
};

const FileUpload = React.forwardRef<HTMLInputElement, AppProps>(
  (
    {
      name,
      label,
      className = '',
      boxClass = '',
      onChange,
      error = null,
      required = false,
      reset = () => {},
      loading = false,
      initialPreview = '',
      accept = 'image/*,.pdf,.doc',
      fetchingFromWeb = false,
    },
    ref
  ) => {
    const [preview, setPreview] = useState<any>(null);
    const [fileName, setFileName] = useState<string>('');

    useEffect(() => {
      setPreview(initialPreview);
    }, [initialPreview]);

    return (
      <div className={className}>
        <div className="text-text text-sm mb-1">
          {label}
          {required && <span className="text-error">*</span>}
        </div>
        <label className="cursor-pointer">
          {!fetchingFromWeb && (
            <input
              type="file"
              name={name}
              accept={accept}
              hidden
              disabled={loading}
              onChange={(e) => {
                onChange(e);
                const _file = e.target.files?.[0];
                if (_file) {
                  const _fileName = _file.name;
                  const _fileType = getFileType(_fileName);
                  if (_fileType === FileType.Img) {
                    setPreview(URL.createObjectURL(_file));
                  } else {
                    setFileName(_fileName);
                  }
                }
              }}
              ref={ref}
            />
          )}
          <div
            className={clx(
              'rounded-md border border-border center p-2 bg-white relative',
              boxClass
            )}
          >
            {preview || fileName ? (
              <div>
                {!loading && (
                  <div
                    className="absolute right-1 top-1"
                    onClick={(e) => {
                      e.preventDefault();
                      reset();
                      setPreview(null);
                      setFileName('');
                    }}
                  >
                    <Icon name="delete" className="text-error" />
                  </div>
                )}
                {(() => {
                  if (preview) {
                    return (
                      <img src={preview} className="object-contain h-24" />
                    );
                  }
                  const icon = getFileIcon(fileName);
                  return (
                    <div className="flex flex-col items-center">
                      <Icon
                        name={icon}
                        size={48}
                        className="text-text-medium"
                      />
                      <div className="mt-2 text-sm text-text-light">
                        {truncate(fileName, { length: 40 })}
                      </div>
                    </div>
                  );
                })()}
              </div>
            ) : (
              <div className="flex flex-col items-center">
                {fetchingFromWeb ? (
                  <div className="py-4">
                    <Loader />
                  </div>
                ) : (
                  <>
                    <Icon
                      name="upload"
                      size={32}
                      className="text-text-lighter"
                    />
                    <div className="text-sm">Upload</div>
                  </>
                )}
              </div>
            )}
          </div>
        </label>
        {error && <div className="text-error text-xs">*{error}</div>}
      </div>
    );
  }
);

FileUpload.displayName = 'FileUpload';

export default FileUpload;

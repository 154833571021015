import useModal from 'hooks/useModal';
import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import clx from 'classnames';
import useMedia from 'hooks/useMedia';
import PhotoSelectorModal from 'components/PhotoSelector';

type AppProps = {
  label: string;
  name: string;
  className?: string;
  job?: string;
  error?: string;
  required?: boolean;
  reset?: () => any;
  disabled?: boolean;
  loading?: boolean;
  control: any;
  limit?: number;
};

const JobPhotoSelector: React.FC<AppProps> = ({
  name,
  label,
  control,
  className = '',
  job = '',
  error = null,
  required = false,
  loading = false,
  disabled = false,
  limit = 5,
}) => {
  const { field } = useController({ name, control });
  const [photos, setPhotos] = useState<any[]>([]);
  const { isMobile } = useMedia();
  const FILE_LIMIT = limit;

  useEffect(() => {
    field.onChange(photos.map((p) => p.value));
  }, [photos]);

  const [modal, showModal, closeModal] = useModal();

  const inputStyle = clx(
    'w-full p-2 rounded-lg cursor-pointer outline-none placeholder:text-gray-300 text-text focus:outline-none focus:ring-0',
    {
      'focus:border-error-dark border-error': !!error,
      'border focus:border-primary border-border ': !error,
      'bg-gray-200': disabled || loading,
    }
  );

  const LIMIT = isMobile ? 3 : 5;

  return (
    <div className={className}>
      <div className="v-center space-x-1">
        <div className="text-text-medium text-xs mb-1">
          {label}
          {required && <span className="text-error">*</span>}
        </div>
        {!!photos.length && photos.length < FILE_LIMIT && (
          <div className="text-xs font-bold text-primary center relative bottom-[1px] left-2">
            + Upload More
          </div>
        )}
      </div>
      <div onClick={showModal}>
        {photos?.length ? (
          <div className="flex flex-wrap cursor-pointer">
            {photos?.slice(0, LIMIT).map((photo: any) => (
              <div key={photo.value} className="mr-2 p-1 border rounded-md">
                <div className="h-20 w-20">
                  <img
                    src={photo.thumb || photo.url}
                    className="h-20 w-20 object-cover"
                  />
                </div>
              </div>
            ))}
            {photos.length > LIMIT && (
              <div className="ml-4 flex items-center text-lg text-text-medium">
                + {photos?.length - LIMIT}
              </div>
            )}
          </div>
        ) : (
          <div className={inputStyle}>
            <span className="text-sm text-text-lighter">Select Job Photos</span>
          </div>
        )}
      </div>
      <PhotoSelectorModal
        open={modal}
        closeModal={closeModal}
        selectedPhotos={photos}
        onSelect={setPhotos}
        q={{ job }}
        limit={FILE_LIMIT}
      />
    </div>
  );
};

export default JobPhotoSelector;

import useModal from 'hooks/useModal';
import React, { useEffect, useMemo, useState } from 'react';
import { useController } from 'react-hook-form';
import clx from 'classnames';
import useMedia from 'hooks/useMedia';
import DocumentSelectorModal from 'components/DocumentSelector';
import Icon from 'components/Icon';
import { getFileIcon } from 'utils/misc';
import { useDocumentChoices } from 'queries/documents';
import { find } from 'lodash';

type AppProps = {
  label: string;
  name: string;
  className?: string;
  job?: string;
  error?: string;
  required?: boolean;
  reset?: () => any;
  disabled?: boolean;
  loading?: boolean;
  control: any;
};

const FILE_LIMIT = 5;

const JobDocSelector: React.FC<AppProps> = ({
  name,
  label,
  control,
  className = '',
  job = '',
  error = null,
  required = false,
  loading = false,
  disabled = false,
}) => {
  const { field } = useController({ name, control });
  const [docs, setDocs] = useState<any[]>([]);
  const { isMobile } = useMedia();
  const { data } = useDocumentChoices({ job });

  const initialValue = useMemo(() => field.value, []);

  useEffect(() => {
    field.onChange(docs.map((p) => p.value));
  }, [docs]);

  useEffect(() => {
    if (initialValue && !docs.length) {
      const doc = find(data?.pages?.[0]?.results, { value: initialValue?.[0] });
      if (doc) {
        setDocs((d) => [...d, doc]);
      }
    }
  }, [data]);

  const [modal, showModal, closeModal] = useModal();

  const inputStyle = clx(
    'w-full p-2 rounded-lg cursor-pointer outline-none placeholder:text-gray-300 text-text focus:outline-none focus:ring-0',
    {
      'focus:border-error-dark border-error': !!error,
      'border focus:border-primary border-border ': !error,
      'bg-gray-200': disabled || loading,
    }
  );

  const LIMIT = isMobile ? 3 : 5;

  return (
    <div className={className}>
      <div className="v-center space-x-1">
        <div className="text-text-medium text-xs mb-1">
          {label}
          {required && <span className="text-error">*</span>}
        </div>
        {!!docs.length && docs.length < FILE_LIMIT && (
          <div className="text-xs font-bold text-primary center relative bottom-[1px] left-2">
            + Upload More
          </div>
        )}
      </div>
      <div onClick={showModal}>
        {docs?.length ? (
          <div className="cursor-pointer">
            {docs?.slice(0, LIMIT).map((doc: any) => (
              <div
                key={doc.value}
                className="mt-2 p-1 border border-border rounded-md v-center space-x-2"
              >
                <div className="h-14 w-14">
                  {doc.thumb ? (
                    <img src={doc.thumb} className="h-14 w-14 object-cover" />
                  ) : (
                    <div
                      key={doc.name}
                      className="h-14 w-14 border bg-primary-negative rounded-md relative flex flex-col justify-center items-center"
                    >
                      <Icon
                        name={getFileIcon(doc.name)}
                        size={32}
                        className="text-text-light"
                      />
                    </div>
                  )}
                </div>
                <div className="text-sm text-text-medium">
                  {doc.display_name}
                </div>
              </div>
            ))}
            {docs.length > LIMIT && (
              <div className="ml-4 flex items-center text-lg text-text-medium">
                + {docs?.length - LIMIT}
              </div>
            )}
          </div>
        ) : (
          <div className={inputStyle}>
            <span className="text-sm text-text-lighter">
              Select Job Documents
            </span>
          </div>
        )}
      </div>
      <DocumentSelectorModal
        open={modal}
        closeModal={closeModal}
        selectedDocs={docs}
        onSelect={setDocs}
        q={{ job }}
        limit={FILE_LIMIT}
      />
    </div>
  );
};

export default JobDocSelector;

import {
  createClaim,
  updateClaim,
  useClaimFieldConfig,
  useClaimStatusChoices,
  useTypeOfLossChoices,
} from 'queries/insurance';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FieldType } from 'utils/enum';
import { actionLabel, emptyStringToNull, readAxiosErr } from 'utils/misc';
import { processFields, processInitialValues, processSchema } from 'utils/form';
import { serverTimeToDate } from 'utils/time';
import { useInsuranceCompanies, useMortgageCompanies } from 'queries/company';
import { InputVariant, Layout } from 'components/Form';
import Button from 'components/Button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import SkeletonLoader, { ContentType } from 'components/SkeletonLoader';
import clx from 'classnames';
import useModal from 'hooks/useModal';
import CreateInsuranceCompany from 'pages/CreateInsuranceCompany/ModalForm';
import CreateMortgageCompany from 'pages/CreateMortgageCompany/ModalForm';

interface IForm {
  insurance_company: number;
  claim_number: string;
  deductible: number;
  policy_number?: string;
  policy_holder_name?: string;
  type_of_loss?: string;
  loss_date?: Date;
  date_damage_discovered?: Date;
  claim_status?: string;
  mortgage_company?: number;
  mortgage_account_number?: string;
}

type AppProps = {
  grid?: boolean;
  onSuccess: () => any;
  claimId?: string;
};

const FileClaim: React.FC<AppProps> = ({
  grid = false,
  onSuccess,
  claimId,
}) => {
  const { jobId = '', claimId: _claimId = '' } = useParams();
  const queryClient = useQueryClient();
  const [customInsuranceCompany, setCustomInsuranceCompany] = useState('');
  const [customMortgageCompany, setCustomMortgageCompany] = useState('');
  const navigate = useNavigate();
  const [
    createInsuranceCompany,
    showCreateInsuranceCompany,
    closeCreateInsuranceCompany,
  ] = useModal();
  const [
    createMortgageCompany,
    showCreateMortgageCompany,
    closeCreateMortgageCompany,
  ] = useModal();

  const claim_id = claimId || _claimId;
  const { data: config, isLoading } = useClaimFieldConfig({
    job: jobId,
    claim: claim_id,
  });

  const addClaim = useMutation((formData: any) => createClaim(formData), {
    onError: (err: any) => {
      toast.error(readAxiosErr(err));
    },
    onSuccess: (res: any) => {
      queryClient.invalidateQueries(['job-detail', jobId]);
      queryClient.invalidateQueries(['job-checklist', jobId]);
      queryClient.invalidateQueries(['claim-detail']);
      if (customInsuranceCompany) {
        queryClient.invalidateQueries(['insurance-companies']);
      }
      if (customMortgageCompany) {
        queryClient.invalidateQueries(['mortgage-companies']);
      }
      return navigate(`/jobs/${jobId}/claims/${res.id}/claim_handler`);
      // return onSuccess();
    },
  });

  const editClaim = useMutation(
    (formData: any) => updateClaim(claim_id, formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['job-detail', jobId]);
        queryClient.invalidateQueries(['claim-detail', claim_id]);
        if (customInsuranceCompany) {
          queryClient.invalidateQueries(['insurance-companies']);
        }
        if (customMortgageCompany) {
          queryClient.invalidateQueries(['mortgage-companies']);
        }
        return onSuccess();
      },
    }
  );

  const schema = yup.object(
    processSchema(
      {
        insurance_company: yup.string(),
        claim_number: yup.string(),
        deductible: yup.number().transform(emptyStringToNull),
        policy_number: yup.string(),
        policy_holder_name: yup.string(),
        type_of_loss: yup.string(),
        loss_date: yup.date(),
        date_damage_discovered: yup.date(),
        claim_status: yup.string(),
        mortgage_company: yup.string(),
        mortgage_account_number: yup.string(),
      },
      config
    )
  );

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm<IForm>({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (config) {
      const defaultValues = processInitialValues(config);
      if (config.loss_date?.initial) {
        defaultValues['loss_date'] = serverTimeToDate(config.loss_date.initial);
      }
      if (config.date_damage_discovered?.initial) {
        defaultValues['date_damage_discovered'] = serverTimeToDate(
          config.date_damage_discovered.initial
        );
      }
      reset(defaultValues);
    }
  }, [config]);

  const onSubmit: SubmitHandler<IForm> = async (formData) => {
    if (claim_id) {
      editClaim.mutate({ ...formData, job: jobId });
    } else {
      addClaim.mutate({ ...formData, job: jobId });
    }
  };

  const fields = [
    {
      name: 'insurance_company',
      type: FieldType.AsyncSelect,
      label: 'Insurance Company',
      error: errors.insurance_company?.message,
      control,
      optionsPromise: useInsuranceCompanies,
      isCreatable: config?.can_add_insurance_company,
      onCreate: (v: any) => {
        setCustomInsuranceCompany(v);
        showCreateInsuranceCompany();
      },
      // postSelect: (selected: any) => {
      //   if (!selected?.value) {
      //     setCustomInsuranceCompany(true);
      //   }
      // },
    },
    {
      type: FieldType.Input,
      label: 'Claim Number',
      placeholder: 'Claim Number',
      error: errors.claim_number?.message,
      ...register('claim_number'),
    },
    {
      type: FieldType.Input,
      variant: InputVariant.Number,
      label: 'Deductible',
      placeholder: '0.00',
      error: errors.deductible?.message,
      ...register('deductible'),
    },
    {
      type: FieldType.Input,
      label: 'Policy Number',
      placeholder: 'Policy Number',
      error: errors.policy_number?.message,
      ...register('policy_number'),
    },
    {
      type: FieldType.Input,
      label: 'Policy Holder Name',
      placeholder: 'Policy Holder Name',
      error: errors.policy_holder_name?.message,
      ...register('policy_holder_name'),
    },
    {
      name: 'type_of_loss',
      type: FieldType.AsyncSelect,
      label: 'Type of Loss',
      error: errors.type_of_loss?.message,
      control,
      optionsPromise: useTypeOfLossChoices,
      orderByLabel: false,
    },
    {
      name: 'loss_date',
      type: FieldType.DatePicker,
      label: 'Loss Date',
      placeholder: 'Loss Date',
      error: errors.loss_date?.message,
      isClearable: true,
      control,
      showPastDates: true,
    },
    {
      name: 'date_damage_discovered',
      type: FieldType.DatePicker,
      label: 'Date Damage Discovered',
      placeholder: 'Date Damage Discovered',
      error: errors.date_damage_discovered?.message,
      isClearable: true,
      control,
      showPastDates: true,
    },
    {
      name: 'claim_status',
      type: FieldType.AsyncSelect,
      label: 'Claim Status',
      error: errors.claim_status?.message,
      control,
      optionsPromise: useClaimStatusChoices,
      isSearchable: false,
      orderByLabel: false,
    },
    {
      name: 'mortgage_company',
      type: FieldType.AsyncSelect,
      label: 'Mortgage Company',
      error: errors.mortgage_company?.message,
      control,
      optionsPromise: useMortgageCompanies,
      isCreatable: config?.can_add_mortgage_company,
      onCreate: (v: any) => {
        setCustomMortgageCompany(v);
        showCreateMortgageCompany();
      },
      // postSelect: (selected: any) => {
      //   if (!selected?.value) {
      //     setCustomMortgageCompany(true);
      //   }
      // },
    },
    {
      type: FieldType.Input,
      label: 'Mortgage Account Number',
      placeholder: 'Mortgage Account Number',
      error: errors.mortgage_account_number?.message,
      ...register('mortgage_account_number'),
    },
  ];

  if (isLoading) {
    return <SkeletonLoader contentType={ContentType.Form} size={10} />;
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Layout
          fields={processFields(fields, config)}
          loading={addClaim.isLoading || editClaim.isLoading}
          className={clx({
            'grid grid-cols-2 gap-2 gap-x-8': grid,
            'space-y-4': !grid,
          })}
        />

        <Button
          label={actionLabel(
            claim_id,
            'Claim',
            addClaim.isLoading || editClaim.isLoading
          )}
          className="mt-8"
          loading={addClaim.isLoading || editClaim.isLoading}
        />
      </form>
      {createInsuranceCompany && (
        <CreateInsuranceCompany
          open={createInsuranceCompany}
          closeModal={closeCreateInsuranceCompany}
          initial={{ name: customInsuranceCompany }}
          onSuccess={(res) => {
            setValue('insurance_company', res.id);
          }}
        />
      )}
      {createMortgageCompany && (
        <CreateMortgageCompany
          open={createMortgageCompany}
          closeModal={closeCreateMortgageCompany}
          initial={{ name: customMortgageCompany }}
          onSuccess={(res) => {
            setValue('mortgage_company', res.id);
          }}
        />
      )}
    </div>
  );
};

export default FileClaim;

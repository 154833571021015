import BottomSheet from 'components/BottomSheet';
import Icon from 'components/Icon';
import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldType } from 'utils/enum';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Layout } from 'components/Form';
import Button from 'components/Button';

type AppProps = {
  initial?: string;
  open: boolean;
  closeModal: () => any;
  title: string;
  fieldName: string;
  mutation: any;
};

interface IFormValues {
  comment: any;
}

const AddComment: React.FC<AppProps> = ({
  initial = '',
  open,
  closeModal,
  title,
  fieldName,
  mutation,
}) => {
  const schema = yup.object({
    comment: yup.string().max(500),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: yupResolver(schema),
    defaultValues: { comment: initial },
  });

  const fields = [
    {
      type: FieldType.TextArea,
      label: 'Comment',
      placeholder: 'Type here...',
      error: errors.comment?.message,
      row: 4,
      ...register('comment'),
    },
  ];

  const onSubmit: SubmitHandler<IFormValues> = async (data) => {
    mutation.mutate({ [fieldName]: data.comment || undefined });
  };

  return (
    <BottomSheet open={open} maxWidth="max-w-2xl">
      <div className="pb-4">
        <div className="flex justify-between">
          <div className="page-heading mb-6">{title}</div>
          <div>
            {!mutation.isLoading && (
              <Icon
                name="close"
                size={32}
                onClick={() => {
                  closeModal();
                }}
                className="cursor-pointer"
              />
            )}
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Layout fields={fields} loading={mutation.isLoading} />
          <Button
            label="Submit"
            className="mt-8"
            loading={mutation.isLoading}
          />
        </form>
      </div>
    </BottomSheet>
  );
};

export default AddComment;

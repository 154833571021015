import { range } from 'lodash';

export const estimateFormatOptions = [
  {
    label: 'Single Lineitem',
    value: 's',
  },
  {
    label: 'Detailed Lineitems',
    value: 'd',
  },
];

export const wastePctChoices = range(0, 50.5, 0.5).map((s) => ({
  label: `${s}%`,
  value: s,
}));

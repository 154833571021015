import React from 'react';
import Checkbox from './Checkbox';
import clx from 'classnames';
import Button, { Size, Variants } from 'components/Button';
import useModal from 'hooks/useModal';
import DateForm from './DateForm';
import { setAllTradesCompleteDate } from 'queries/job';
import { useParams } from 'react-router-dom';
import Confirmation from 'components/Confirmation';
import { formatServerTime } from 'utils/time';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { readAxiosErr } from 'utils/misc';

type AppProps = {
  data: Record<string, any>;
  renderInTable?: boolean;
};

const AllTradesCompleted: React.FC<AppProps> = ({
  data,
  renderInTable = false,
}) => {
  const { jobId = '' } = useParams();
  const [openComplete, showComplete, closeComplete] = useModal();
  const [confirm, showConfirm, closeConfirm] = useModal();
  const queryClient = useQueryClient();

  const uncompleteMutation = useMutation(
    () => setAllTradesCompleteDate(jobId, { date: null }),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(['job-last-checklist', jobId]),
          queryClient.invalidateQueries(['job-checklist', jobId]),
        ]);
        queryClient.invalidateQueries(['job-detail', jobId]);
        queryClient.invalidateQueries(['jobs']);

        closeConfirm();
      },
    }
  );

  const incomplete = () => {
    return (
      <div className="apart">
        <div className="value">
          All Trades Not <br />
          Completed
        </div>
        {data.permissions.can_complete && (
          <div>
            <Button
              label="Complete"
              size={Size.Small}
              variant={Variants.Tag}
              onClick={showComplete}
            />
          </div>
        )}
      </div>
    );
  };

  const complete = () => {
    return (
      <div>
        <div className="apart">
          <div className="value">All Trades Completed</div>
          {data.permissions.can_undo && (
            <div>
              <Button
                label="Mark Not Complete"
                variant={Variants.Tag}
                size={Size.Small}
                onClick={showConfirm}
              />
            </div>
          )}

          {confirm && (
            <Confirmation
              title="Mark all trades as not complete?"
              message="Are you sure you want mark all trades as not complete?"
              open={confirm}
              closeConfirm={closeConfirm}
              onClick={() => uncompleteMutation.mutate()}
              isLoading={uncompleteMutation.isLoading}
            />
          )}
        </div>
        <div className="mt-1">
          <span className="key">Completed At: </span>
          <span className="key">
            {formatServerTime({
              date: data.date,
              parseFormat: 'YYYY-MM-DDThh:mm:ssZ',
              includeTime: true,
            })}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={clx({ card: !renderInTable, 'px-2 pb-1': renderInTable })}>
      <div className="flex space-x-2">
        <Checkbox checked={data.complete} />
        <div className="w-full">
          {data.complete ? complete() : incomplete()}
        </div>
      </div>
      {openComplete && (
        <DateForm
          title="Set all trades completed date"
          label="Completed Date"
          open={openComplete}
          closeModal={closeComplete}
          jobId={jobId}
          initial_date={data?.date}
          mutationFn={setAllTradesCompleteDate}
        />
      )}
    </div>
  );
};

export default AllTradesCompleted;

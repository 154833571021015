import { useInterval } from 'hooks/useInterval';
import { chunk } from 'lodash';
import React, { createContext, useState } from 'react';
import { QueueItemType } from 'utils/enum';
import { useBeforeunload } from 'react-beforeunload';

export const QueueContext = createContext({
  queue: [],
  completed: [],
  failedTasks: [],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  enqueue: (items: any[]): void => {},
});

type AppProps = {
  children: React.ReactNode;
};

const QueueProvider: React.FC<AppProps> = ({ children }) => {
  const [queue, setQueue] = useState([]);
  const [completed, setCompleted] = useState<any>([]);
  const [failedTasks, setFailedTasks] = useState([]);
  const [processing, setProcessing] = useState(false);

  useBeforeunload((event: any) => {
    if (queue.length) {
      event.preventDefault();
      return 'You have photos in queue. Please wait for them to upload. Refreshing will make you loose them.';
    }
  });

  const enqueue = (items: any[]) => {
    // @ts-ignore
    setQueue((q) => [...q, ...items]);
  };

  const processQueue = async () => {
    if (!processing) {
      setProcessing(true);
      const _queue = [...queue];
      const batches = chunk(_queue, 4);
      for (const batch of batches) {
        const refetches = batch.filter(
          (item: any) => item.type === QueueItemType.Refetch
        );
        const execs = batch.filter(
          (item: any) => item.type !== QueueItemType.Refetch
        );
        try {
          await Promise.all(execs.map((item: any) => item.promise()));
        } catch (e) {}

        setTimeout(() => {
          Promise.all(refetches.map((item: any) => item.promise()));
        }, 5000);

        const _completed = _queue.splice(0, batch.length);
        setCompleted((c: any) => [..._completed, ...c]);
        setQueue(_queue);
      }
      setCompleted([]);
      setProcessing(false);
    }
  };

  useInterval(processQueue, 4 * 1000);

  return (
    <QueueContext.Provider value={{ queue, completed, failedTasks, enqueue }}>
      {children}
    </QueueContext.Provider>
  );
};

export default QueueProvider;

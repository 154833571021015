import Icon from 'components/Icon';
import React from 'react';
import { Link } from 'react-router-dom';
import { formatAmt } from 'utils/misc';

type AppProps = {
  job: Record<string, any>;
};

const ExpenseBox: React.FC<AppProps> = ({ job }) => {
  const expenses = [
    {
      id: 'material',
      label: 'Material',
      value: job.estimate?.matorders,
    },
    {
      id: 'labor',
      label: 'Labor',
      value: job.estimate?.workorders,
    },
    {
      id: 'other',
      label: 'Other',
      value: job.estimate?.other_expenses,
    },
    {
      id: 'total',
      label: 'Total',
      value: job.estimate?.total,
    },
  ];

  return (
    <Link to={`/jobs/${job.id}/expenses`}>
      <div className="px-3 py-2 bg-white shadow-md rounded-lg">
        <div className="v-center">
          <Icon name="tools" size={18} className="text-primary" />{' '}
          <span className="pl-1 title">Expenses</span>
        </div>
        <div className="mt-2">
          {expenses.map((ex) => (
            <div key={ex.id} className="v-center space-y-1">
              <div className="w-[78px] key">{ex.label}:</div>{' '}
              <div className="value">{formatAmt(ex.value)}</div>
            </div>
          ))}
          <div className="v-center text-xs text-primary mt-2">
            View expenses <Icon name="arrow-right" className="ml-1" size={16} />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ExpenseBox;

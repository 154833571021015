import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import clx from 'classnames';
import useMedia from 'hooks/useMedia';
import Modal, { Variant } from 'components/Modal';
import useAuth from 'hooks/useAuth';

type AppProps = {
  children: React.ReactNode;
  open: boolean;
  closeSheet?: () => any;
  padded?: boolean;
  blocking?: boolean;
  modalForDesktop?: boolean;
  maxWidth?: string;
  overflow?: string;
};

const BottomSheet: React.FC<AppProps> = ({
  open,
  closeSheet,
  children,
  padded = true,
  blocking = true,
  modalForDesktop = true,
  maxWidth,
  overflow = 'overflow-auto',
}) => {
  const { isMobile } = useMedia();
  const { embedMode } = useAuth();

  if (!isMobile && modalForDesktop) {
    return (
      <Modal
        maxWidth={maxWidth}
        open={open}
        closeModal={closeSheet}
        variant={Variant.Center}
        padded={padded}
        overflow={overflow}
      >
        {children}
      </Modal>
    );
  }

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[99999]"
        onClose={closeSheet ? closeSheet : () => null}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={blocking ? 'fixed inset-0 bg-black bg-opacity-50' : ''}
          />
        </Transition.Child>

        <div className="fixed bottom-0 w-screen max-h-[90%] overflow-y-auto">
          <div className="text-center">
            <Transition.Child
              enter="transition ease-in-out duration-400 transform"
              enterFrom="translate-y-full"
              enterTo="translate-y-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-y-0"
              leaveTo="translate-y-full"
            >
              <Dialog.Panel
                className={clx(
                  'w-full max-w-md transform overflow-hidden rounded-t-2xl bg-white text-left align-middle shadow-xl transition-all',
                  {
                    'p-4 py-6': padded,
                    'p-0': !padded,
                    'relative left-[40%]': !isMobile,
                    'min-h-[60vh]': embedMode,
                  }
                )}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default BottomSheet;

import React, { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FieldType } from 'utils/enum';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { getFNName, readAxiosErr } from 'utils/misc';
import { processFields, processInitialValues, processSchema } from 'utils/form';
import { InputVariant, Layout } from 'components/Form';
import Button from 'components/Button';
import Icon from 'components/Icon';
import SkeletonLoader, { ContentType } from 'components/SkeletonLoader';
import { useVerifyLeadFieldConfig, verifyLead } from 'queries/job';
import { useBidTypeChoices, useLeadSources, useRegions } from 'queries/company';

interface IFormValues {
  name: string;
  cell?: string;
  email?: string;
  source: number;
  region: number;
  bid_type: string;
}

type AppProps = {
  jobId: string;
  closeModal: () => void;
};

const VerifyLead: React.FC<AppProps> = ({ jobId, closeModal }) => {
  const queryClient = useQueryClient();
  const { data: config, isLoading } = useVerifyLeadFieldConfig(jobId);

  const verifyLeadMutation = useMutation(
    (formData: any) => verifyLead(jobId, formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(['job-last-checklist', jobId]),
          queryClient.invalidateQueries(['job-checklist', jobId]),
        ]);
        queryClient.invalidateQueries(['job-detail', jobId]);
        queryClient.invalidateQueries(['jobs']);

        return closeModal();
      },
    }
  );

  const schema = yup.object(
    processSchema(
      {
        name: yup.string(),
        cell: yup.string().phone('US', false),
        email: yup.string().email(),
        source: yup.number(),
        region: yup.number(),
        bid_type: yup.string(),
      },
      config
    )
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm<IFormValues>({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (config) {
      const defaultValues = processInitialValues(config);
      reset(defaultValues);
    }
  }, [config]);

  const onSubmit: SubmitHandler<IFormValues> = async (formData) => {
    if (!formData.cell && !formData.email) {
      return toast.error('One of Cell or Email must be provided');
    }

    const payload = {
      ...formData,
      ...getFNName(formData.name),
    };
    verifyLeadMutation.mutate(payload);
  };

  const fields = [
    {
      type: FieldType.Input,
      label: 'Name',
      placeholder: 'Customer Name',
      error: errors.name?.message,
      ...register('name'),
    },
    {
      type: FieldType.Input,
      label: 'Email',
      placeholder: 'jon@gmail.com',
      error: errors.email?.message,
      variant: InputVariant.Email,
      ...register('email'),
    },
    {
      type: FieldType.Input,
      label: 'Cell',
      placeholder: '8888888888',
      error: errors.cell?.message,
      ...register('cell'),
    },
    {
      name: 'bid_type',
      type: FieldType.AsyncSelect,
      label: 'Bid Type',
      error: errors.bid_type?.message,
      control,
      optionsPromise: useBidTypeChoices,
    },
    {
      name: 'source',
      type: FieldType.AsyncSelect,
      label: 'Lead Source',
      error: errors.source?.message,
      control,
      optionsPromise: useLeadSources,
    },
    {
      name: 'region',
      type: FieldType.AsyncSelect,
      label: 'Region',
      error: errors.region?.message,
      control,
      optionsPromise: useRegions,
    },
  ];

  if (isLoading) {
    return <SkeletonLoader contentType={ContentType.Form} size={6} />;
  }

  return (
    <div className="pb-4">
      <div className="flex justify-between mb-4">
        <div className="page-heading">Verify Lead</div>
        {!verifyLeadMutation.isLoading && (
          <div className="relative bottom-1">
            <Icon
              name="close"
              size={36}
              onClick={closeModal}
              className="cursor-pointer"
            />
          </div>
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Layout
          fields={processFields(fields, config)}
          loading={verifyLeadMutation.isLoading}
        />

        <Button
          label={verifyLeadMutation.isLoading ? 'Verifying...' : 'Verify Lead'}
          className="mt-8"
          loading={verifyLeadMutation.isLoading}
        />
      </form>
    </div>
  );
};

export default VerifyLead;

import { useQuery } from '@tanstack/react-query';
import apiService from 'utils/apiService';

// ******** Get Events For Job *********
export const getEvents = async (jobId: string) => {
  const { data } = await apiService.get(`/api/light/jobs/${jobId}/events/`);
  return data;
};

export const useJobEvents = (jobId: string) => {
  return useQuery(['job-events', jobId], () => getEvents(jobId), {
    staleTime: 15 * 60 * 1000,
  });
};

const getTodaysEvents = async (q: Record<string, any>) => {
  const { data } = await apiService.get(`/api/light/events/todays_events/`, q);
  return data;
};

export const useTodaysEvents = (
  q: Record<string, any> = {},
  enabled = true
) => {
  return useQuery(['todays-events', q], () => getTodaysEvents(q), {
    staleTime: 15 * 60 * 1000,
    enabled,
  });
};

const getNext5DaysEvents = async (q: Record<string, any>) => {
  const { data } = await apiService.get(
    `/api/light/events/next_five_days_events/`,
    q
  );
  return data;
};

export const useNext5DaysEvents = (q: Record<string, any> = {}) => {
  return useQuery(['next-5-days-events', q], () => getNext5DaysEvents(q), {
    staleTime: 15 * 60 * 1000,
  });
};

// ********* Get Event Detail *********
const getEventDetail = async (id: string, job: string) => {
  const { data } = await apiService.get(`/api/light/events/${id}/`, { job });
  return data;
};

export const useEventDetail = (id: string, job: string) => {
  return useQuery(['event-detail', id], () => getEventDetail(id, job), {
    staleTime: 15 * 60 * 1000,
  });
};

// ********** Get Event field config **********
const getEventFieldConfig = async (q: Record<string, any>) => {
  const { data } = await apiService.get('/api/light/events/fields_config/', q);
  return data;
};

interface IFieldConfig {
  job?: string;
  event?: string;
  template?: number;
}

export const useEventFieldConfig = (q: IFieldConfig = {}) => {
  const { job, event, template } = q;
  return useQuery(
    ['event-field-config', { job, event, template: template?.toString() }],
    () => getEventFieldConfig({ job, event, template }),
    {
      staleTime: event ? 0 : Infinity,
    }
  );
};

const getCalEvents = async ({ ...q }) => {
  const { data } = await apiService.get(`/api/light/events/cal/`, { ...q });
  return data;
};

export const useCalEvents = (q: Record<string, any>) => {
  return useQuery(['cal-events', q], () => getCalEvents({ ...q }), {
    staleTime: 15 * 60 * 1000,
  });
};

// ****** Create event *******
export const createEvent = async (payload: Record<string, any>) => {
  const { data } = await apiService.post('/api/light/events/', payload);
  return data;
};

export const updateEvent = async (
  eventId: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/events/${eventId}/`,
    payload
  );
  return data;
};

export const deleteEvent = async (eventId: string) => {
  await apiService.delete(`/api/light/events/${eventId}/`);
};

import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FieldType } from 'utils/enum';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { readAxiosErr } from 'utils/misc';
import {
  useDocumentTemplates,
  useLightDocumentTemplates,
} from 'queries/company';
import { Layout } from 'components/Form';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { importDocTemplate } from 'queries/documents';
import useAuth from 'hooks/useAuth';

interface IFormValues {
  template: number;
  lite_template: number;
}

type AppProps = {
  closeModal: () => void;
  title?: string;
  q?: Record<string, any>;
};

const ImportDoctemplate: React.FC<AppProps> = ({
  closeModal,
  title = 'Import Doctemplate',
  q = {},
}) => {
  const { jobId = '' } = useParams();
  const queryClient = useQueryClient();
  const { userInfo } = useAuth();
  const { data: v2Templates } = useLightDocumentTemplates(q);

  const importTemplate = useMutation(
    (formData: any) => importDocTemplate(jobId, formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: async () => {
        toast.success(
          'Doctemplate being created. Please check your job docs in 5-10 seconds'
        );
        await Promise.all([
          queryClient.invalidateQueries(['documents', { job: jobId }]),
          queryClient.invalidateQueries(['job-checklist', jobId]),
        ]);

        closeModal();
      },
    }
  );

  const schema = yup.object({
    template: yup.number().optional(),
    lite_template: yup.number().optional(),
  });

  const {
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>({ resolver: yupResolver(schema) });

  const _template = watch('template');
  const _lite_template = watch('lite_template');

  const onSubmit: SubmitHandler<IFormValues> = async (formData) => {
    importTemplate.mutate({ ...formData, job: jobId });
  };

  const fields1 = [
    {
      name: 'template',
      type: FieldType.AsyncSelect,
      label: 'Select v1 Template',
      placeholder: 'Select v1 template',
      error: errors.template?.message,
      control,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      optionsPromise: () => useDocumentTemplates(q),
      orderByLabel: false,
      position: 'top',
      isClearable: true,
      disabled: !!_lite_template,
    },
  ];

  const liteLabel = userInfo?.is_toolkit_user
    ? 'Select Template'
    : 'Select v2 Template';

  const fields2 = [
    {
      name: 'lite_template',
      type: FieldType.AsyncSelect,
      label: liteLabel,
      placeholder: liteLabel,
      error: errors.lite_template?.message,
      control,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      optionsPromise: () => useLightDocumentTemplates(q),
      orderByLabel: false,
      position: 'top',
      isClearable: true,
      disabled: !!_template,
    },
  ];

  const v2Docs = !!v2Templates?.length;

  return (
    <div className="pb-4">
      <div className="flex justify-between">
        <div className="text-2xl font-bold mb-8">{title}</div>
        {!importTemplate.isLoading && (
          <div>
            <Icon name="close" size={32} onClick={closeModal} />
          </div>
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {userInfo?.is_enterprise_user && (
          <Layout fields={fields1} loading={importTemplate.isLoading} />
        )}
        {userInfo?.is_enterprise_user && v2Docs && (
          <div className="pb-3 pt-4 text-xxs center">OR</div>
        )}
        {(userInfo?.is_toolkit_user || v2Docs) && (
          <Layout fields={fields2} loading={importTemplate.isLoading} />
        )}

        <Button
          label={importTemplate.isLoading ? 'Importing...' : 'Import Template'}
          className="mt-8"
          loading={importTemplate.isLoading}
        />
      </form>
    </div>
  );
};

export default ImportDoctemplate;
